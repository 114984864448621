"use strict";

(function(z){

    z.PriceRuleForm = z.FormView.extend({
        el:"#priceruleform-modal",


        events: function() {
            return _.extend({}, z.FormView.prototype.events,{
                "blur #priceruleform-remainingDays-field" : "_displayPriceRule",
                "blur #priceruleform-capacityLoad-field" : "_displayPriceRule",
                "blur #priceruleform-priceAction-field" : "_displayPriceRule"
            });
        },

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function(){
            z.FormView.prototype.render.apply(this, arguments);
            this._initializeChosen();
            return this;
        },

        /**
         * Initializing the chosen js library
         * @private
         * @return {undefined}
         */
        _initializeChosen: function () {

            this.$('.chosen-select-service').chosen({width: "100%"});

            this.$("#priceruleform-exceptions-field").chosen({
                search_contains: true,
                width: "100%",
                placeholder_text_multiple: Translator.trans('js.label_choose_reseller')
            });

        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            z.FormView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addPriceRule';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editPriceRule';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.label_add_price_rule');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.label_edit_price_rule');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#priceruleform-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#priceruleform-add-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'price-rules';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'pricerule.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_priceruleform');
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{brandName: *, companyName: *, address: *, phoneNumber: *, status: *, apiUser: *, apiKey: *}}
         */
        _getFormData: function() {

            var $nameField           = this.$('#priceruleform-name-field');
            var $serviceField        = this.$('#priceruleform-service-field');
            var $statusField         = this.$('#priceruleform-status-field');
            var $remainingDaysField  = this.$('#priceruleform-remainingDays-field');
            var $capacityLoad        = this.$('#priceruleform-capacityLoad-field');
            var $priceActionField    = this.$('#priceruleform-priceAction-field');
            var $exceptions          = this.$('#priceruleform-exceptions-field');

            // Get the reseller exceptions
            var resellerExceptions = this._getExceptions($exceptions);

            return {
                'name'          : $nameField.val(),
                'service'       : $serviceField.val(),
                'status'        : $statusField.val(),
                'remainingDays' : $remainingDaysField.val(),
                'capacityLoad'  : $capacityLoad.val(),
                'priceAction'   : $priceActionField.val(),
                'exceptions'    : resellerExceptions
            };
        },

        /**
         * Get the reseller exceptions
         * @param {Object} $exceptions - the exceptions field
         * @return {Array} - resellers exceptions array
         * @private
         */
        _getExceptions: function($exceptions) {

            var exceptionsValues = $exceptions.val();
            var resellerExceptions = [];

            if (_.isArray(exceptionsValues) && exceptionsValues.length !== 0) {

                resellerExceptions = _.map(exceptionsValues, function(reseller) {

                    if (reseller.length !== 0) {
                        return { 'reseller': reseller };
                    }
                });

                resellerExceptions = _.filter(resellerExceptions, function(reseller) {

                    return !_.isUndefined(reseller);
                });
            }

            return resellerExceptions;
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            if (this.model.isNew()) {
                return;
            }

            var map = {
                "priceruleform-name-field":"name",
                "priceruleform-service-field":"service",
                "priceruleform-status-field": "status",
                "priceruleform-remainingDays-field": "remainingDays",
                "priceruleform-capacityLoad-field": "capacityLoad",
                "priceruleform-priceAction-field": "priceAction",
                "priceruleform-exceptions-field": "exceptions",
            };

            Object.keys(map).forEach(function(key) {

                var value = this.model.get(map[key]);

                switch (key) {

                    case "priceruleform-service-field":
                        this._prefillChosenDropdownField(key, value);
                        break;

                    case "priceruleform-exceptions-field":
                        this._prefillExceptionsField(key, value);
                        break;

                    default:
                        this.$el.find("#"+key).val(this.model.get(map[key]));
                }

                this._updatePriceRule(key, this.model.get(map[key]));

            }.bind(this));
        },

        /**
         * Prefill chosen dropdown fields
         *
         * @param {string} key - the id of the field
         * @param {string} value - the value from the model
         * @private
         * @return {undefined}
         */
        _prefillChosenDropdownField: function(key, value) {

            if(_.isUndefined(value)){
                return;
            }

            this.$("#" + key)
                .find('option[value=' + value.id + ']').prop('selected', true)
                .end().trigger('chosen:updated');
        },

        /**
         * Prefill price rule reseller exceptions field (multiple select dropdown)
         *
         * @param {string} key - the id of the field
         * @param {string} value - the value from the model
         * @private
         * @return {undefined}
         */
        _prefillExceptionsField: function(key, value) {

            if(_.isUndefined(value) || value.length <= 0){
                return;
            }

            var resellerExceptions = _.map(value, function(resellerException) {
                return resellerException.reseller.id;
            });

            if (!_.isUndefined(resellerExceptions) && _.isArray(resellerExceptions)) {

                for (var resellerIndex in resellerExceptions) {

                    if( !resellerExceptions.hasOwnProperty(resellerIndex) ){
                        continue;
                    }

                    // Add the elements in dropdowns with chosen jquery
                    this.$("#" + key)
                        .find('option[value=' + resellerExceptions[resellerIndex] + ']').prop('selected', true)
                        .end().trigger('chosen:updated');
                }
            }
        },

        /**
         * Method that displays the price rule as a preview before submitting the form
         *
         * @param {object} event The event that is being triggered on mouse out
         * @private
         * @return {undefined}
         */
        _displayPriceRule: function(event) {

            var $target = $(event.target);

            if (!$target.val()){
                switch($target.attr('id')){
                    case 'priceruleform-remainingDays-field':
                        var remainingDaysValue = Translator.trans('js.price_rule_preview_first_segment');
                        this.$("#rule-preview-remaining-days").text(remainingDaysValue);
                        break;
                    case 'priceruleform-capacityLoad-field':
                        var capacityLoad = Translator.trans('js.price_rule_preview_second_segment');
                        this.$("#rule-preview-capacity-load").text(capacityLoad);
                        break;
                    case 'priceruleform-priceAction-field':
                        var priceAction = Translator.trans('js.price_rule_preview_third_segment');
                        this.$("#rule-preview-price-action").text(priceAction);
                        break;
                }
                return;
            }

            this._updatePriceRule($target.attr('id'), $target.val());
        },

        /**
         * Method that updates the price rule preview with given values
         * @param {string} selector The selector used
         * @param {string} value The field's value
         * @private
         * @return {undefined}
         */
        _updatePriceRule: function(selector, value){

            switch(selector){
                case 'priceruleform-remainingDays-field':
                    this.$("#rule-preview-remaining-days").text(value);
                    break;
                case 'priceruleform-capacityLoad-field':
                    this.$("#rule-preview-capacity-load").text(value + "%");
                    break;
                case 'priceruleform-priceAction-field':
                    this.$("#rule-preview-price-action").text(value + "%.");
                    break;
            }
        },

        /**
         * Method that handles the displaying of an error message if the price rule
         * exists in the database
         *
         * @private
         * @return {undefined}
         */
        _displayAlreadyCreatedError: function() {
            var message = Translator.trans('js.label_already_existing_price_rule_error');
            this.$("#priceruleform-error-panel").removeClass("hidden").text(message);
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         */
        _onSubmitForm: function (e) {

            e.preventDefault();

            var formData = this._getFormData();
            this.model.set(formData);

            var isValid = this.model.isValid(true);

            if( !isValid ){
                this.showMobileAlert();
                return;
            }

            this._isLoading(true, this.getSubmitButtonSelector());

            this.model.save(null, {
                success: function (i) {
                    this.model.trigger("success", i);
                }.bind(this),
                error: function(model, response) {

                    var errors = response.responseJSON.errors;

                    var isDuplicate = _.find(errors, function(error){
                        return error.message === 'already_created';
                    });

                    if (isDuplicate) {
                        this._displayAlreadyCreatedError();
                    }

                }.bind(this)

            });
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.price_rule_create_error_message_mobile');

            this.$el.find('#price-rule-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#price-rule-create-general-error-panel').addClass('hidden');
        }

    });

})(window.z = window.z || {});
