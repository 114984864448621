"use strict";

(function(z, Backbone){

    var GlobalSearchView = Backbone.View.extend({

        /**
         * Url for orders
         */
        ORDERS_URL: '/orders',

        el: "#global-search-container",

        events: {
            "submit #global-search-form": "_onSubmitForm",
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {
            // Initialize
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var url = this._getOrderSearchUrl();

            // Open the order in a new tab
            var win = window.open(url, '_blank');
            win.focus();
        },

        /**
         * Get the url to see the order that was searched
         *
         * @returns {string} - the url to see the order that was searched
         * @private
         */
        _getOrderSearchUrl: function() {

            return window.location.origin + this.ORDERS_URL + '/' + this.$('#global-search-field').val();
        }

    });

    z.GlobalSearchView = new GlobalSearchView();

})(window.z = window.z || {}, Backbone);

