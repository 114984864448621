"use strict";

(function(z, Backbone){

    var OffersCollection = Backbone.Collection.extend({

        /**
         * The model
         */
        model: z.OfferModel,

        /**
         * Parses the response
         * @param {Object} response The response
         * @return {Object} The parsed data
         */
        parse: function(response){

            return response.data;
        },

        /**
         * Sets order id
         * @param {string|int} id The order id
         * @return {Backbone.Collection} Return myself
         */
        setOrderId: function(id){

            this.orderId = id;

            return this;
        },

        /**
         * Set strict
         * @param {boolean} enable Enable or disable
         * @return {Backbone.Collection} Return myself
         */
        setStrict: function(enable){

            this.strict = !! enable; // convert to bool

            return this;
        },

        /**
         * Set vendor
         * @param {int} vendor Vendor id
         * @return {Backbone.Collection} Return myself
         */
        setVendorId: function(vendor){

            this.vendorId = vendor;

            return this;
        },

        /**
         * Set sort
         * @param {int} type Sort type
         * @return {Backbone.Collection} Return myself
         */
        setSort: function(type){

            this.sortType = type;

            return this;
        },

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function(){

            var url = '/api/order/offers';

            if (this.orderId) {

                url = '/api/order/' + this.orderId + '/offers';
            }

            var params = {};

            if (!_.isUndefined(this.strict)) {
                params.strict = this.strict ? 1 : 0;
            }

            if (this.vendorId) {
                params.vendor = this.vendorId;
            }

            if (this.sortType) {
                params.sort = this.sortType;
            }

            if (Object.keys(params).length) {

                var result = _.map(params, function(value, key){
                    return key + '=' + value;
                });

                result = result.join('&');

                url += '?' + result;
            }

            return url;
        }
    });

    z.OffersCollection = OffersCollection;

})(window.z = window.z || {}, Backbone);