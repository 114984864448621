'use strict';

(function(z, Backbone){

    var OrderClientModel = Backbone.Model.extend({

        urlRoot: function(){
            return '/api/customers/' + this.get('customer') + '/clients';
        },

        validation: {
            'name': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_client_name_required')
            },{
                minLength: 3,
                msg: Translator.trans('js.error_message_orderform_client_name_minlength')
            }],
            'type': [{
                required: false
            }],
            'company': [{
                required: function(value, attr, computedState){

                    // It's required if the type is checked (the client is a company, not a person)
                    return computedState.type;
                },
                msg: Translator.trans("js.error_message_orderform_client_company_required")
            }],
            'vat': [{
                required: false
            }],
            'splitVat': [{
                required: false
            }],
            'active': [{
                required: false
            }],
            'fiscalCode': [{
                required: function(value, attr, computedState){

                    // It's required if the type is checked (the client is a company, not a person)
                    return computedState.type;
                },
                msg: Translator.trans('js.error_message_orderform_client_fiscalCode_required')
            }, {
                pattern: /^(RO |RO)?([0-9]){2,10}$/,
                msg: Translator.trans('js.error_message_orderform_client_fiscalCode_invalid')
            }],
            'regNr': [{
                required: function(value, attr, computedState){

                    // It's required if the type is checked (the client is a company, not a person)
                    return computedState.type;
                },
                msg: Translator.trans('js.error_message_orderform_client_regNr_required')
            }, {
                maxLength: 16,
                msg: Translator.trans('js.error_message_orderform_client_regNr_maxlength')
            }],
            'phoneNumber': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_required')
            },{
                minLength: 10,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_minlength')
            },{
                maxLength: 16,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_maxlength')
            }, {
                pattern: 'digits',
                msg: Translator.trans('js.error_message_phoneNumber_format')
            }],
            'email': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_client_email_required')
            },{
                minLength: 4,
                msg: Translator.trans('js.error_message_orderform_client_email_minlength')
            },{
                maxLength: 64,
                msg: Translator.trans('js.error_message_orderform_client_email_maxlength')
            },{
                pattern: 'email',
                msg: Translator.trans('js.error_message_email_format')
            }],
            'county': [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_county_required")
            }],
            'locality': [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_locality_required")
            }],
            'address': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_client_address_required')
            }, {
                minLength: 4,
                msg: Translator.trans('js.error_message_orderform_client_address_minlength')
            }, {
                maxLength: 255,
                msg: Translator.trans('js.error_message_orderform_client_address_maxlength')
            }],
            'bank': [{
                required: false
            }],
            'iban': [{
                required: false
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.OrderClientModel = OrderClientModel;

})(window.z = window.z || {}, Backbone);
