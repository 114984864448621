'use strict';

(function(z, Backbone){

    var OfferModel = Backbone.Model.extend({

        /**
         * Url root
         */
        urlRoot: '/api/offer',

        /**
         * Parses the response
         * @param {Object} response The response
         * @return {Object} The parsed data
         */
        parse: function(response){

            if (response.data) { // not proud of this, but no other way

                return response.data;
            }

            return response;
        }
    });

    z.OfferModel = OfferModel;

})(window.z = window.z || {}, Backbone);