'use strict';

(function(app, $, Backbone){

    var SidebarView = Backbone.View.extend({

        /**
         * Constructor
         * @return {undefined}
         */
        initialize: function() {

            if ($('body').hasClass('fixed-sidebar')) {

                // slimscroll checks if it is already instantiated so we are cool
                $('.sidebar-collapse').slimScroll({
                    height: '100%',
                    railOpacity: 0.9
                });
            }
        }
    });

    app.sidebarView = new SidebarView();

})(window.z = window.z || {}, $, Backbone);
