"use strict";

(function(z){

    z.ServiceForm = z.FormView.extend({
        el:"#serviceform-modal",

        events: function() {
            return _.extend({}, z.FormView.prototype.events,{
                "change #serviceform-isQuoted-field": "_onIsQuotedChange",
                "change #serviceform-hasUpload-field": "_onHasUploadChange",
                "change #serviceform-hasTransportCost-field": "_onHasTransportCostChange"
            });
        },


        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            z.FormView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#serviceform-category-field").chosen({
                search_contains: true,
                width: "100%"
            });
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addService';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editService';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_service');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_service');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#serviceform-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#serviceform-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'services';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'service.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_serviceform');
        },

        /**
         * Set custom labels for the reseller modal form
         *
         * @param {string} action - action name
         * @return {undefined}
         */
        setCustomLabels: function(action) {

            // todo uncomment when API Details will be implemented
            switch (action) {
                case 'add':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
                    break;
                case 'edit':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{brandName: *, companyName: *, address: *, phoneNumber: *, status: *, apiUser: *, apiKey: *}}
         */
        getFormData: function() {

            var $nameField              = this.$('#serviceform-name-field');
            var $categoryField          = this.$('#serviceform-category-field');
            var $statusField            = this.$('#serviceform-status-field');
            var $isQuotedField          = this.$('#serviceform-isQuoted-field');
            var $deliveryDays           = this.$('#serviceform-deliveryDays-field');
            var $multipleQuantityField  = this.$('#serviceform-multipleQuantity-field');
            var $basePriceField         = this.$('#serviceform-basePrice-field');
            var $hasUploadField         = this.$('#serviceform-hasUpload-field');
            var $uploadNameField        = this.$('#serviceform-uploadName-field');
            var $uploadDescriptionField = this.$('#serviceform-uploadDescription-field');
            var $isUploadRequiredField  = this.$('#serviceform-isUploadRequired-field');
            var $description            = this.$('#serviceform-description-field');
            var $vat                    = this.$('#serviceform-vat-field');
            var $hasTransportCost       = this.$('#serviceform-hasTransportCost-field');
            var $unpaidDistance         = this.$('#serviceform-unpaidDistance-field');
            var $pricePerKm             = this.$('#serviceform-pricePerKm-field');

            return {
                'name'              : $nameField.val(),
                'category'          : $categoryField.val(),
                'status'            : $statusField.val(),
                'isQuoted'          : $isQuotedField.val(),
                'deliveryDays'      : parseInt($deliveryDays.val()),
                'multipleQuantity'  : $multipleQuantityField.val(),
                'basePrice'         : $basePriceField.val(),
                'hasUpload'         : $hasUploadField.val(),
                'uploadName'        : $uploadNameField.val(),
                'uploadDescription' : $uploadDescriptionField.val(),
                'isUploadRequired'  : $isUploadRequiredField.val(),
                'description'       : $description.val(),
                'vat'               : $vat.val(),
                'hasTransportCost'  : $hasTransportCost.val(),
                'unpaidDistance'    : $unpaidDistance.val(),
                'pricePerKm'        : $pricePerKm.val()
            };
        },

        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        resetForm: function() {

            this.$el.find('.modal-form')[0].reset();

            if (this.$el.find(".chosen-select").length) {

                this.$el.find(".chosen-select").trigger('chosen:updated');
            }

            this.$(this.getErrorPanelId()).addClass('hidden').text('');
            this.model.clear();
            this.dataValidation();

            // Make all the fields enable
            this.$('fieldset').find('input, select, textarea').prop('disabled', false);
            this.$('#serviceform-uploadDescription-field').css('background-color', '#fff');
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                'serviceform-name-field': 'name',
                'serviceform-category-field':  'category',
                'serviceform-status-field': 'status',
                'serviceform-isQuoted-field': 'isQuoted',
                'serviceform-basePrice-field': 'basePrice',
                'serviceform-hasUpload-field': 'hasUpload',
                'serviceform-deliveryDays-field': 'deliveryDays',
                'serviceform-multipleQuantity-field': 'multipleQuantity',
                'serviceform-uploadName-field': 'uploadName',
                'serviceform-uploadDescription-field': 'uploadDescription',
                'serviceform-isUploadRequired-field': 'isUploadRequired',
                'serviceform-description-field': 'description',
                'serviceform-vat-field': 'vat',
                'serviceform-hasTransportCost-field': 'hasTransportCost',
                'serviceform-unpaidDistance-field': 'unpaidDistance',
                'serviceform-pricePerKm-field': 'pricePerKm'

            };

            Object.keys(map).forEach(function(key){

                var value = this.model.get(map[key]);

                switch (key) {
                    case "serviceform-status-field":
                    case "serviceform-isQuoted-field":
                    case "serviceform-hasUpload-field":
                    case "serviceform-isUploadRequired-field":
                    case "serviceform-multipleQuantity-field":

                        if (value === true || value === 1) {
                            this.$("#" + key).val("1");
                        } else if(value === false || value === 0) {
                            this.$("#" + key).val("0");
                        } else {
                            this.$("#" + key).val("");
                        }


                        break;
                    case "serviceform-category-field":

                        if(typeof value === 'undefined'){
                            return;
                        }

                        this.$("#" + key)
                            .find('option[value=' + value.id + ']').prop('selected', true)
                            .end().trigger('chosen:updated');

                        break;
                    case "serviceform-hasTransportCost-field":

                        if (value === true || value === 1) {
                            this.$("#" + key).val("1");
                        } else if(value === false || value === 0) {
                            this.$("#" + key).val("0");
                        } else {
                            this.$("#" + key).val("");
                        }
                        break;
                    default:
                        this.$el.find("#"+key).val(value);
                }

            }.bind(this));

            this._onIsQuotedChange();
            this._onHasUploadChange();
            this._onHasTransportCostChange();
        },


        /**
         * Method that will enable/disable the base price field
         * based on what is selected on isQuoted value
         *
         * @return {undefined}
         * @private
         */
        _onIsQuotedChange: function() {

            var $isQuoted = this.$('#serviceform-isQuoted-field');
            var $basePrice = this.$('#serviceform-basePrice-field');

            if ($isQuoted.val() === '1') {

                // Set the value 0 and disable the base price field
                $basePrice.val('0');
                $basePrice.prop("disabled", true);
            } else {

                // Make the field enabled again and reset its value
                $basePrice.prop("disabled", false);
            }
        },

        /**
         * Method that will enable/disable the upload fields
         * based on what is selected on hasUpload value
         *
         * @return {undefined}
         * @private
         */
        _onHasUploadChange: function() {

            var $hasUpload = this.$('#serviceform-hasUpload-field');
            var $uploadName = this.$('#serviceform-uploadName-field');
            var $uploadDescription = this.$('#serviceform-uploadDescription-field');
            var $isUploadRequired = this.$('#serviceform-isUploadRequired-field');

            if ($hasUpload.val() === '0') {

                $uploadName.prop("disabled", true);
                $uploadName.val('');

                $uploadDescription.prop("disabled", true);
                $uploadDescription.val('');

                // Overwrite the color because a transparent background will be added automatically if the field was completed previously
                $uploadDescription.css('background', '#eee');

                $isUploadRequired.prop("disabled", true);
                $isUploadRequired.val('');

            } else {

                $uploadName.prop("disabled", false);
                $uploadDescription.prop("disabled", false);
                $isUploadRequired.prop("disabled", false);

                $uploadDescription.css('background', '#fff');
            }
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.service_create_error_message_mobile');

            this.$el.find('#service-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#service-create-general-error-panel').addClass('hidden');
        },

        /**
         * Method that enable or disable unpaidDistance and pricePerKm according with the value of hasTransportCost
         * @private
         * @return {undefined}
         */
        _onHasTransportCostChange: function () {
            var $hasTransportCost = this.$('#serviceform-hasTransportCost-field');
            var $unpaidDistance = this.$('#serviceform-unpaidDistance-field');
            var $pricePerKm = this.$('#serviceform-pricePerKm-field');

            if ($hasTransportCost.val() === '0') {

                // Set the value 0 and disable the unpaidDistance and pricePerKm fields
                $unpaidDistance.val('0');
                $unpaidDistance.prop("disabled", true);
                $pricePerKm.val('0');
                $pricePerKm.prop("disabled", true);
            } else {

                // Make the fields enabled again and reset their value
                $unpaidDistance.prop("disabled", false);
                $pricePerKm.prop("disabled", false);
            }
        }

    });

    // z.serviceForm = new ServiceForm({
    //     model: new z.ServiceModel()
    // });

})(window.z = window.z || {});
