"use strict";

(function(z, Backbone){

    var CountyModel = Backbone.Model.extend({

    });

    z.CountyModel = CountyModel;

})(window.z, Backbone);
