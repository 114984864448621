"use strict";

(function(z, Backbone, _){
    z.OrderCustomer = Backbone.View.extend({

        el: "#order-customer-data",

        events: {
            "submit .modal-form#ordercustomer-modal-form": "_onSubmitForm",
            'click #edit-customer-modal-btn': '_displayEditCustomerModalForm',
            "click #ordercustomerform-modal .close-link" : "_hideEditCustomerModalForm",
            'change #ordercustomerform-county-field': '_onCountyChange',
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            $(document).on('customer.success', function() {

                this._showSuccessMessage();
                this._displayUpdatedCustomerDetails();
                $(document).trigger("comments.updateComments");

            }.bind(this));

            this.modalElement = this.$('#ordercustomerform-modal');
            this.$errorMsg = this.$el.find('#ordercustomerform-error-panel');

            // Adding collections to view
            this.localitiesCollection = new z.LocalitiesCollection();

            // Listening on collection events
            this.listenTo(this.localitiesCollection, 'add', this._onLocalitiesCollectionAdd);
            this.listenTo(this.localitiesCollection, 'reset', this._onLocalitiesCollectionReset);
            
            Backbone.Validation.bind(this, {
                valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Hide the edit customer button
                this.$('#edit-customer-modal-btn').hide();

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.finalized", function() {

                // Hide the edit customer button
                this.$('#edit-customer-modal-btn').hide();

            }.bind(this));

            this.setEvents();
        },

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function(){

            // todo implement this function
            this._removeErrorMsg();
            this._fillForm();

            return this;
        },

        /**
         * Setting the events on the model.
         *
         * @return {undefined}r
         */
        setEvents: function(){
            
            // On success
            this.listenTo(this.model, 'success', function(){
                this._hideEditCustomerModalForm();
                this._isLoading(false, this.$("#ordercustomer-save-button"));
                $(document).trigger('customer.success');
            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(){
                this._setErrorMsg();
                this._isLoading(false, this.$("#ordercustomer-save-button"));
            }.bind(this));
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that shows the success message in a page with a grid
         *
         * @private
         * @return {undefined}
         */
        _showSuccessMessage: function() {

            var $successContainer = this.$('#ordercustomer-success-message');

            $successContainer.removeClass('hidden')
                .fadeTo('slow', 1)
                .show('slow')
                .delay(3000)
                .fadeTo('slow',0)
                .hide('slow');
        },

        /**
         * Method that sets the error messages.
         *
         * @private
         * @return {undefined}
         */
        _setErrorMsg: function(){

            this.$errorMsg
                .removeClass('hidden');
        },

        /**
         * Method that removes the error message.
         *
         * @private
         * @return {undefined}
         */
        _removeErrorMsg: function() {

            this.$errorMsg
                .addClass('hidden');
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method that displays the modal window.
         *
         * @return {undefined}
         * @private
         */
        _displayEditCustomerModalForm: function() {

            this._getCustomerData();

            this.modalElement.modal("show");

            // Focus on the first field
            this._focusOnFirstField();
        },

        /**
         * Get customer data and prefill the form
         *
         * @return {undefined}
         * @private
         */
        _getCustomerData:  function() {

            var customerId = this.$el.data('id');

            this._beforeEditEntity(customerId);

            this.model.set('id', customerId);

            this.model.fetch({
                success: function(){

                    this.model.set('county', this.model.get('county').id);
                    this.model.set('locality', this.model.get('locality').id);

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Method called before router edit entity.
         * @return {undefined}
         * @private
         */
        _beforeEditEntity: function() {
            this._isLoadingMask(true);
            this._isLoading(true, this.$("#ordercustomer-save-button"));
        },

        /**
         * Method called after router edit entity.
         * @return {undefined}
         * @private
         */
        _afterEditEntity: function() {
            this._isLoadingMask(false);
            this._isLoading(false, this.$("#ordercustomer-save-button"));

            if(!this.model.isNew()){
                this.render();
            }
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $submitBtn   = this.$(buttonSelector);
            var $formFields  = this.$('fieldset');
            var ladda =  $submitBtn.data('ladda') ?  $submitBtn.data('ladda') :  $submitBtn.ladda().data('ladda');

            if( loading ){
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Show the loading mask for modal window.
         * @param {bool} loading - should show or hide the mask
         * @return {undefined}
         * @private
         */
        _isLoadingMask: function(loading) {

            loading = loading ? loading : _.isUndefined(loading);

            var $content = this.modalElement.find('.modal-content.ibox-content');

            if( loading ){

                $content.addClass('sk-loading');

            } else {

                $content.removeClass('sk-loading');
            }
        },

        /**
         * Method that hides the modal window.
         *
         * @return {undefined}
         * @private
         */
        _hideEditCustomerModalForm: function() {

            this.modalElement.modal("hide");
        },

        /**
         * Focus on the first field from the form modal.
         * If the first field is not an input, you can override this function in your view.
         *
         * @private
         * @return {undefined}
         */
        _focusOnFirstField: function() {

            this.$('fieldset').find('input:first').focus();
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                "ordercustomerform-name-field": "name",
                "ordercustomerform-email-field": "email",
                "ordercustomerform-address-field": "address",
                "ordercustomerform-phoneNumber-field": "phoneNumber",
                "ordercustomerform-county-field": "county",
                "ordercustomerform-locality-field": "locality"
            };

            Object.keys(map).forEach(function(key){
                this.$el.find("#"+key).val(this.model.get(map[key]));
                    switch (key) {
                        case 'ordercustomerform-county-field':

                            this.$('#ordercustomerform-county-field').trigger("chosen:updated");
                            this._onCountyChange();
                            break;

                        case 'ordercustomerform-locality-field':

                            // Load the locality field once
                            this.$('#ordercustomerform-locality-field').one('load', function() {

                                // Prefill the field
                                this.$('#ordercustomerform-locality-field').val(this.model.get('locality'));
                                this.$('#ordercustomerform-locality-field').trigger("chosen:updated");

                            }.bind(this));

                            break;
                    }

            }.bind(this));
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var formData = this._getFormData();
            this.model.set(formData);

            if( !this.model.isValid(true) ){
                return;
            }

            this._isLoading(true, this.$("#ordercustomer-save-button"));

            this.model.save(null,{
                success: function(i) {
                    debugger;
                    this.model.trigger("success", i);

                    // Update z.boot.order.customer
                    z.boot.order.customer = this.model.attributes;
                    location.reload();
                }.bind(this)
            });
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{name: *, address: *,  phoneNumber: *, email: *}}
         */
        _getFormData: function() {

            var $nameField = this.$('#ordercustomerform-name-field');
            var $addressField = this.$('#ordercustomerform-address-field');
            var $phoneNumberField = this.$('#ordercustomerform-phoneNumber-field');
            var $emailField = this.$('#ordercustomerform-email-field');
            var $countyField = this.$('#ordercustomerform-county-field');
            var $localityField = this.$('#ordercustomerform-locality-field');

            return {
                'name'     : $nameField.val(),
                'address'       : $addressField.val(),
                'phoneNumber'   : $phoneNumberField.val(),
                'email'        : $emailField.val(),
                'county'        : $countyField.val(),
                'locality'        : $localityField.val()
            };
        },

        /**
         * Display updated customer details
         *
         * @private
         * @return {undefined}
         */
        _displayUpdatedCustomerDetails: function() {
            var map = {
                "order-customer-name": "name",
                "order-customer-email": "email",
                "order-customer-address": "address",
                "order-customer-phoneNumber": "phoneNumber",
                // "order-customer-county": "county",
                // "order-customer-locality": "locality"
            };

            Object.keys(map).forEach(function(key){

                var text = this.model.get(map[key]);

                if (text.length === 0) {
                    text = '-';
                }

                this.$el.find("#"+key).text(text);

                    // switch (key) {
                    //
                    //     case "order-customer-county":
                    //         if (typeof value === 'undefined') {
                    //             return;
                    //         }
                    //         debugger;
                    //         this.$("#" + key)
                    //             .find('option[value=' + value + ']').prop('selected', true)
                    //             .end().trigger('chosen:updated');
                    //         break;
                    //
                    //     case "order-customer-locality":
                    //         if (typeof value === 'undefined') {
                    //             return;
                    //         }
                    //         debugger;
                    //         this.$("#" + key)
                    //             .find('option[value=' + value + ']').prop('selected', true)
                    //             .end().trigger('chosen:updated');
                    //         break;
                    // }

            }.bind(this));
        },

        /**
         * Method triggered on the locality collection add
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionAdd: function(model) {

            this._renderLocality(model);
        },

        /**
         * Method triggered on the locality collection reset
         *
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionReset: function() {

            this._renderLocalityReset();
        },

        /**
         * Method that renders the locality render
         *
         * @param {object} model - Model bound to the collection
         * @private
         * @return {undefined}
         */
        _renderLocality: function(model) {

            var $option = $('<option>').attr('value', model.get('id')).text(model.get('name'));
            this.$('#ordercustomerform-locality-field').append($option);
        },


        /**
         * Method that resets the locality select
         *
         * @private
         * @return {undefined}
         */
        _renderLocalityReset: function() {

            var $option = this.$("#ordercustomerform-locality-field option:eq(0)").detach();
            this.$('#ordercustomerform-locality-field').empty().append($option);
        },

        /**
         * Method that makes the request to get the data
         * for the localities select
         *
         * @private
         * @return {undefined}
         */
        _onCountyChange: function () {

            // Get the county id
            var id = this.$("#ordercustomerform-county-field option:selected").val();

            this.localitiesCollection.reset(null);

            if (id) {

                this.localitiesCollection.setCountyId(id);

                // Get the localities collection for the given county
                this.localitiesCollection.fetch({
                    success: function() {
                        this.$('#ordercustomerform-locality-field').trigger("chosen:updated");
                        this.$("#ordercustomerform-locality-field").trigger('load');
                    }.bind(this)
                });

            } else {

                this.$('#ordercustomerform-locality-field').trigger("chosen:updated");
            }
        },

    });

})(window.z = window.z || {}, Backbone, _);

