"use strict";

(function(z, Backbone, _, moment){
    z.OrderStatus = Backbone.View.extend({

        STATUS_FOR_PAYMENT: '2',
        STATUS_APPOINTED: '3',
        STATUS_FINALIZED: '4',
        STATUS_CANCELED: '5',
        STATUS_ALLOCATED: '6',

        /**
         * Constants used to fill the sort field
         */
        SORT_BY_AVAILABILITY: 1,
        SORT_BY_DISTANCE: 2,

        /**
         * Api for finalizing an uninvoiced order
         */
        API_FINALIZE_UNINVOICED_ORDER: '/api/orders/{order}/finalize',

        el: "#order-processing-section",
        events: {
            'click #orderform-change-button': '_onFormSubmit',
            'change #orderform-status-field': '_onStatusChange',
            'change #orderform-sort-field': '_onSortChange'
            // 'change #orderform-vendor-field': '_onVendorChange'
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function(){
            // TODO: move in abstract class to be reused
            // Initializing backbone validation
            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: this.model
            });

            this.listenTo(this, "order.status.finalized", function() {

                // Hide the status form only if the user is not admin
                if (!z.boot.isAdmin) {
                    this.$('#order-status-form').hide();
                }

            }.bind(this));

            this.listenTo(this, "order.status.canceled", function() {
            
                // Hide the status form only if the user is not admin
                if (!z.boot.isAdmin) {
                    this.$('#order-status-form').hide();
                }

            }.bind(this));

            this.listenTo(this, "order.status.allocated", function() {

                // Hide the status form only if the user is not admin
                if (!z.boot.isAdmin) {
                    this.$('#order-status-form').hide();
                }

            }.bind(this));

            this.orderVendorsCollection = new z.VendorsCollection();

            this.listenTo(this.orderVendorsCollection, 'sync', this._onOrderVendorsCollectionSync);
            this.listenTo(this.orderVendorsCollection, 'reset', this._onOrderVendorsCollectionReset);
        },

        /**
         * Display order status closed panel
         * @return {undefined}
         */
        displayOrderStatusClosedPanel: function() {

            var $panelDiv = '<div id="order-closed-warning" class="panel panel-danger"></div>';
            var $panelHeading = '<div class="panel-heading"></div>';
            var rescheduledMessage = Translator.trans("js.label_order_has_been_closed");

            $('.order-is-closed').html($panelDiv);
            $('#order-closed-warning').append($panelHeading);
            $('.panel-heading').append(rescheduledMessage);

        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function (view, attr) {
            // TODO: move in abstract class to be reused
            this._dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid
         *
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @param {Object} error Validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function (view, attr, error) {
            // TODO: move in abstract class to be reused
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method that validates the fields in the form
         *
         * @param {Object} attr The attribute being set
         * @private
         * @return {undefined}
         */
        _dataValidation: function (attr) {
            // TODO: move in abstract class to be reused
            var $fields = this.$('[data-validation]');
            if (attr) {
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that returns the value from the select
         * @return {*} The value from the select
         * @private
         */
        _getFormValue: function() {
            return this._returnStatusSelect().val();
        },

        /**
         * Return the comment status value
         * @return {string} The comment status value
         * @private
         */
        _getStatusCommentValue: function() {
            return this.$("#orderform-status-comment-input").val();
        },

        /**
         * Display the modal when trying to finalized an invoiceable order that doesn't have an invoice
         * @return {undefined}
         * @private
         */
        _displayFinalizeUninvoicedOrderModal: function() {

            var _this = this;

            swal({
                title: Translator.trans('js.order_finalize_status_finalize_uninvoiced_order_window_title'),
                text: Translator.trans('js.order_finalize_status_finalize_uninvoiced_order_window_message'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.order_finalize_status_confirm_btn'),
                allowOutsideClick: false
            }).then(function() {

                swal({
                    title: Translator.trans('js.order_finalize_status_finalize_order_window_title'),
                    text: Translator.trans('js.order_finalize_status_finalize_order_window_message'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#18a689',
                    cancelButtonColor: 'rgb(113, 125, 145)',
                    confirmButtonText: Translator.trans('js.order_finalize_status_confirm_btn'),
                    showLoaderOnConfirm: true,
                    input: 'textarea',
                    inputPlaceholder: 'Completati motivul aici...',
                    allowOutsideClick: false,
                    preConfirm: function (reason) {

                        if (!reason) {

                            swal.showValidationError('Campul este obligatoriu');
                            swal.hideLoading();

                            return;
                        } else {

                            swal.resetValidationError();
                        }

                        return new Promise(function() {

                            _this._submitForm(reason);
                            swal.close();

                        });
                    }
                });

            }.bind(this)).catch(swal.noop);

        },

        /**
         * Display the model for trying to finalize an order with the
         * appointment date in the future
         * @private
         * @return {undefined}
         */
        _displayFinalizeOrderFutureAppointmentModal: function() {

            // Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.order_finalize_status_confirm_window_title'),
                text: Translator.trans('js.order_finalize_status_confirm_window_message'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.order_finalize_status_confirm_btn')
            }).then(function () {

                this._submitForm();
                setTimeout(function () {
                    location.reload();
                },1500)


            }.bind(this)).catch(swal.noop);
        },

        /**
         * Verify if the order is valid for finalizing without invoice
         * @param {string} newStatus the new status
         * @return {boolean|*} valid or not
         * @private
         */
        _isValidForFinalizingWithoutInvoice: function(newStatus) {

            var order = z.boot.order;
            var validOldStatusesForFinalizing = [parseInt(this.STATUS_APPOINTED), parseInt(this.STATUS_CANCELED), parseInt(this.STATUS_ALLOCATED)];

            // The conditions are
            // the old status is appointed or canceled
            // the new status is finalized
            // the quantity is valid for finalizing the order
            // the order is invoiceable
            // the order doesn't have a valid invoice

            return _.contains(validOldStatusesForFinalizing, parseInt(order.status)) &&
                parseInt(newStatus) === parseInt(this.STATUS_FINALIZED) &&
                this._isQuantityValidForFinalizing() &&
                order.invoiceable &&
                _.isUndefined(order.invoiceNumber);
        },

        /**
         * Verify if the order is valid for finalizing
         * @param {string} newStatus the new status
         * @return {boolean|*} valid or not
         * @private
         */
        _isValidForFinalizing: function(newStatus) {

            var order = z.boot.order;
            var today = moment();
            var orderDate = moment(order.appointmentDate);
            var diff = today.diff(orderDate);
            var validOldStatusesForFinalizing = [parseInt(this.STATUS_APPOINTED), parseInt(this.STATUS_CANCELED),parseInt(this.STATUS_ALLOCATED)];

            // The conditions are
            // the new status is finalized
            // the quantity is valid for finalizing the order
            // the order appointment date is in the future

            return _.contains(validOldStatusesForFinalizing, parseInt(order.status)) &&
                parseInt(newStatus) === parseInt(this.STATUS_FINALIZED) &&
                this._isQuantityValidForFinalizing() &&
                (diff < 0);
        },

        _isQuantityValidForFinalizing: function() {

            var order = z.boot.order;
            var isProgressComplete =  order.quantity === order.progress;
            var hasMultipleQuantity = order.service.multipleQuantity;

            // If the order has multiple quantity, the progress must be completed before finalizing
            return (hasMultipleQuantity && isProgressComplete) || !hasMultipleQuantity;
        },

        /**
         * Method called after user submits the vendor capacities form
         *
         * @param {object} event The jQuery event object
         * @private
         * @return {undefined}
         */
        _onFormSubmit: function(event) {

            event.preventDefault();

            var newStatus = this._getFormValue();

            // Verify if to submit the form or to display an alert
            if (this._isValidForFinalizingWithoutInvoice(newStatus)) {

                // If the order is valid for finalizing without invoice, display the reason alert
                this._displayFinalizeUninvoicedOrderModal();

            } else if (this._isValidForFinalizing(newStatus)) {

                // If the order has a status of finalized check date and show alert
                this._displayFinalizeOrderFutureAppointmentModal();

            } else {

                // Simply submit the form
                this._submitForm();

            }
        },

        /**
         * Method that returns the form status field
         * @return {*} The status field
         * @private
         */
        _returnStatusSelect: function() {
            return  this.$('#orderform-status-field');
        },

        /**
         * Method that resets the form
         * @return {undefined}
         * @private
         */
        _resetForm: function() {
            this._returnStatusSelect().val('');
            this.$('#orderform-status-comment-input').val("");
        },

        /**
         * Retrieves the order id if it is available
         * @return {number|null} The order id
         * @private
         */
        _getOrderId: function() {

            return z.boot.order.id;
        },

        /**
         * Method that returns the value of the current order status
         * @return {*} The value of the current order status
         * @private
         */
        _getOrderCurrentStatus: function() {
            return this.$(".order-status-group").attr("data-status");
        },

        /**
         * Method that returns the value of the order vendor
         * @return {*} The value of the current order vendor
         * @private
         */
        _getOrderCurrentVendor: function() {
            return this.$("#orderform-vendor-field").val();
        },


        /**
         * Method that returns the name of the status based on the id
         * being passed
         *
         * @param {int} id The status id
         * @return {*|String} The status name
         * @private
         */
        _getOrderStatusName: function(id) {

            switch(id) {
                case this.STATUS_FOR_PAYMENT:
                    return Translator.trans('js.order_status_for_payment');
                case this.STATUS_APPOINTED:
                    return Translator.trans('js.order_status_appointed');
                case this.STATUS_FINALIZED:
                    return Translator.trans('js.order_status_finalized');
                case this.STATUS_CANCELED:
                    return Translator.trans('js.order_status_canceled');
                case this.STATUS_ALLOCATED:
                    return Translator.trans('js.order_status_allocated');
            }
        },

        /**
         * Method called after the order's vendors collection is synced
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionSync: function(){
            this._renderOrderVendors();
        },

        /**
         * Method called after the order's vendors collection is reset
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionReset: function(){
            this._renderOrderVendors();
        },

        /**
         * Renders all items from vendors collection into html elements
         * @return {undefined}
         * @private
         */
        _renderOrderVendors: function(){

            var $field = this.$("#orderform-vendor-field");

            var collection = this.orderVendorsCollection;

            this._renderChosenField($field, collection, function(model){

                return model.get('companyName');
            });
        },

        /**
         * Renders the options inside the chosen field based on the supplied collection
         * @param {object} $field The select field
         * @param {Backbone.Collection} collection The collection
         * @param {string|function} name How to select the name of the item
         * @return {undefined}
         * @private
         */
        _renderChosenField: function($field, collection, name){

            if (!collection) {

                $field.trigger('chosen:updated');

                return;
            }

            var $placeholder = $field.find('option:first').detach();

            var options = [];

            $field.empty();

            // Add the allocated afterward option
            if ($field.attr('id') === 'orderform-vendor-field') {

                var $allocatedAfterwardOption = this._getAllocatedAfterwardOption();
                // options.push($allocatedAfterwardOption);
            }

            collection.each(function(item){

                var display;

                if (_.isFunction(name)) {
                    display = name.call(this, item);
                } else {
                    display = item.get(name);
                }

                var $option = $('<option>')
                    .attr('value', item.get('id'))
                    .text(display);

                options.push($option);
            });

            $field.append(options);

            $field.trigger('chosen:updated');
        },

        /**
         * Method called after the sort gets changed
         * @return {undefined}
         * @private
         */
        _onSortChange: function() {

            this._loadVendors();
        },

        /**
         * Method that updates the vendor name fields in the order
         * @param {object} vendorName the name of the vendor
         * @returns {undefined}
         */
        _changeVendorFieldsValues: function(vendorName)
        {
            this.$('#associated-vendor').text(vendorName);
            this.$('#vendor-company-name').text(vendorName);
        },


        /**
         * Retrieves the list of vendors (syncs the vendors collection)
         * @param {Function} [callback] The callback
         * @return {undefined}
         * @private
         */
        _loadVendors: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var sort = this._getSortFieldEl().val();
            var serviceId = this._getServiceId();
            var countyId = this._getCountyId();

            this.orderVendorsCollection.reset();

            // if (!this._isVendorFieldVisible()) {
            //
            //     return;
            // }
            //
            // if (!this._isValidVendorRequest()) {
            //
            //     return;
            // }

            this.orderVendorsCollection.setAvailable(true);
            this.orderVendorsCollection.setServiceId(serviceId);
            this.orderVendorsCollection.setCountyId(countyId);

            if (sort === this.SORT_BY_DISTANCE + '') {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_EXTENDED);
            } else {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_FIXED);
            }

            this.orderVendorsCollection.setQuantity(1);

            this.orderVendorsCollection
                .fetch()
                .then(function(){

                    callback.apply(this, arguments);

                })
                .catch(function(){

                    callback.apply(this, arguments);
                });
        },

        /**
         * Checks if the vendor container field is visible
         * @return {boolean} Is visible
         * @private
         */
        _isVendorFieldVisible: function(){

            var $container = this._getVendorGroupEl();

            return !$container.is('.hidden');
        },

        /**
         * Retrieves the vendor field element
         * @returns {object} The element
         * @private
         */
        _getVendorFieldEl: function(){
            return this.$('#orderform-vendor-field');
        },

        /**
         * Retrieves the vendor group element
         * @returns {object} The element
         * @private
         */
        _getVendorGroupEl: function(){
            return this._getVendorFieldEl().parents('.form-group:first');
        },

        /**
         * Checks if all required fields for a vendor request are not empty
         * @return {boolean} Is valid
         * @private
         */
        _isValidVendorRequest: function(){
            var serviceId = this._getServiceId();

            if (!serviceId) {
                return false;
            }

            var countyId = this._getCountyId();

            if (!countyId) {
                return false;
            }

            return true;
        },

        /**
         * Retrieves the current service's id
         * @return {string} The service id
         * @private
         */
        _getServiceId: function(){

            return $('#order-service').data('id');
        },

        /**
         * Retrieves the current county's id
         * @return {string} The service id
         * @private
         */
        _getCountyId: function(){

            return $('#order-customer-county').data('id');
        },

        /**
         * Retrieves the order's sort field element
         * @returns {object} The element
         * @private
         */
        _getSortFieldEl: function(){
            return this.$('#orderform-sort-field');
        },


        /**
         * Method called after the vendor gets changed
         * @return {undefined}
         * @private
         */
        _onVendorChange: function(callback){

            callback = _.isFunction(callback) ? callback : _.noop;

            var id = this._getOrderId();
            var sort = this._getSortFieldEl().val();
            var vendor = this.$("#orderform-vendor-field").val();

            if (!_.isUndefined(vendor)) {

                this.orderVendorsCollection.setVendorId(vendor);
            }

            this.offersCollection.setOrderId(id);
            this.offersCollection.setSort(sort);

            this.offersCollection.reset();

            this.offersCollection.fetch({

                success: function(){

                    this.trigger('schedule.load.offers');
                    callback.apply(this, arguments);

                }.bind(this),

                error: function(model, res){

                    // todo change this after the branch with offerVendors list will be integrated
                    if (res.responseJSON.errors[0]['class'] === 'OrderBundle\\Exceptions\\VendorUnavailableException') {
                        this._loadVendors();
                    }

                    callback.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Submit the form
         *
         * @param {string} reason status comment as reason from the alert
         * @private
         * @return {undefined}
         */
        _submitForm: function(reason) {

            var reasonText =  Translator.trans('js.order_uninvoiced_finalized') + ' ' + reason;

            var statusCommentReason = this._getStatusCommentValue() ? (this._getStatusCommentValue() + ' - ' +  reasonText) : reasonText;
            var statusComment = _.isUndefined(reason) ? this._getStatusCommentValue() : statusCommentReason;

            var record = {
                "orderId": this._getOrderId(),
                "status": this._getFormValue(),
                "oldStatus" :  this._getOrderCurrentStatus(),
                "statusComment" : statusComment,
                "vendorId" : this._getOrderCurrentVendor()
            };

            if (record.status === record.oldStatus) {
                this._resetForm();
                this._showError(Translator.trans('js.error_message_order_status_equivalent'));
                return;
            }

            // verify if vendors exist for the order id and status is order allocated; length 1 is for deafult values
            if (this.$('#orderform-vendor-field option').length == 1 && record.status == "6")
            {
                // this._resetForm();
                this._showError(Translator.trans('js.error_message_no_vendor'));
                $('#div_individual_sort').addClass('hidden');
                $('#div_individual_vendor').addClass('hidden');
                setTimeout(function () {
                    return;
                }, 4000);

            }


            this.model.clear();
            this.model.set(record);

            var isProgressComplete =  z.boot.order.quantity === z.boot.order.progress;
            var hasMultipleQuantity = z.boot.order.service.multipleQuantity;

            this.model.set({
                isProgressComplete: hasMultipleQuantity ? isProgressComplete : true
            });

            if (!this.model.isValid(true)) {
                return;
            }

            this._isLoading();

            this.model.save(null, {

                success: function(model) {

                    z.boot.order = this.model.get('order');
                    this._showSuccess(Translator.trans('js.success_message_order_status'));

                    // Changing status for order in page
                    this._setOrderDataStatus();
                    this.$("#order-status").text(this._getOrderStatusName(this._getFormValue()));
                    // this._changeVendorFieldsValues(model.get('vendor').companyName + ' - ' + model.get('vendor').brandName);
                    // Update the z.boot.order object

                    // z.boot.order.status = model.attributes.status;

                    if (this._getFormValue() !== this.STATUS_APPOINTED) {

                        var orderIsOpen = true;

                        // If the new status is "canceled", trigger an event to hide some of the order panels
                        if (this._getFormValue() === this.STATUS_CANCELED) {

                            this.trigger('order.status.canceled');

                            orderIsOpen = false;
                        }

                        if (this._getFormValue() === this.STATUS_ALLOCATED) {

                            this.trigger('order.status.allocated');

                            orderIsOpen = true;
                        }

                        // If the new status is "finalized", trigger an event to hide some of the order panels
                        if (this._getFormValue() === this.STATUS_FINALIZED) {

                            this.trigger('order.status.finalized');

                            orderIsOpen = false;
                        }

                        // If the order is closed (canceled or finalized)
                        if (!orderIsOpen) {

                            this._handleClosedOrder();
                        }
                    }

                    // Adding elements in collection
                    $(document).trigger("comments.updateComments");

                    this._resetForm();

                    location.reload();
                }.bind(this),
                error: function(model, res) {

                    // Showing error messages
                    this._displayError(res);

                }.bind(this)
            });
        },

        /**
         * Handle closed order
         * @private
         * @return {undefined}
         */
        _handleClosedOrder: function() {

            // Display the message that the order was closed
            this.displayOrderStatusClosedPanel();

            this.$('#order-current-status-wrapper').find('p').addClass('order-status-close');

            this.trigger('order.closed', this._getFormValue());
        },

        /**
         * Method that sets the data-status attribute to the
         * current value selected and saved in the database
         * @return {undefined}
         * @private
         */
        _setOrderDataStatus: function() {
            this.$(".order-status-group").attr("data-status", this._getFormValue());
        },

        /**
         * Method that returns the form element
         * @return {*} The form element
         * @private
         */
        _returnForm: function() {
            // TODO: move in abstract class to be reused
            return this.$('#order-status-form');
        },

        /**
         * Enables or disables the loading mask
         *
         * @param {bool} loading Should show or hide the mask?
         * @private
         * @return {undefined}
         */
        _isLoading: function (loading) {
            // TODO: move in abstract class to be reused
            loading = loading ? loading : _.isUndefined(loading);
            var $form = this._returnForm();
            var $submitBtn = $form.find('button');
            var $formFields = $form.find('fieldset');

            var ladda = $submitBtn.data('ladda') ? $submitBtn.data('ladda') : $submitBtn.ladda().data('ladda');

            if (loading) {
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Display the success message
         *
         * @param {string} message The message to be displayed
         * @private
         * @return {undefined}
         */
        _showSuccess: function(message){
            // TODO: move in abstract class to be reused
            this._isLoading(false);

            var $success = this._getInformationPanel();
            $success
                .removeClass('hidden alert-danger')
                .addClass('alert-success')
                .text(message)
                .fadeTo('slow', 1)
                .delay(5000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Display the error message
         *
         * @param {string} message The message to be shown
         * @private
         * @return {undefined}
         */
        _showError: function(message) {
            // TODO: move in abstract class to be reused
            this._isLoading(false);

            var $error = this._getInformationPanel();
            $error
                .removeClass('hidden alert-success')
                .addClass('alert-danger')
                .text(message)
                .fadeTo('slow', 1)
                .delay(5000);
        },

        /**
         * Method that returns the name of the information panel
         *
         * @private
         * @return {*} The information panel
         */
        _getInformationPanel: function() {
            // TODO: move in abstract class to be reused
            return this.$('#orderstatus-information-panel');
        },

        /**
         * Method that manage offer and vendor fields
         *
         * @private
         */
        _onStatusChange: function () {
            if (this._getFormValue() === this.STATUS_ALLOCATED) {
                $('#div_individual_sort').removeClass('hidden');
                $('#div_individual_vendor').removeClass('hidden');
            }
            else
            {
                $('#div_individual_sort').addClass('hidden');
                $('#div_individual_vendor').addClass('hidden');
            }
        },

        /**
         * Add allocated afterwards options
         * @return {undefined}
         * @private
         */
        _addAllocatedAdfterwardOption: function() {

            // var $option = this._getAllocatedAfterwardOption();
            // this.$('#orderform-vendor-field').append($option).trigger("chosen:updated");
        },

        /**
         * Get allocated afterward option
         * @return {*|jQuery} - return the allocated afterward option
         * @private
         */
        _getAllocatedAfterwardOption: function() {

            // return $('<option>')
            //     .attr('value', this.ALLOCATED_AFTERWARD_VALUE)
            //     .text(Translator.trans('js.allocated_afterward'));
        },

        _displayError: function(res){

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var message = _.first(errors).message;
                if (typeof res.responseJSON != "undefined")
                {
                    if (res.responseJSON.errors[0]['class'] === 'OrderBundle\\Exceptions\\NewOrderException') {

                        this._showError(Translator.trans('js.error_message_order_invalid_for_action'));

                    } else {

                        this._showError(Translator.trans('js.error_message_order_status'));
                    }
                }

                if (typeof message !== 'undefined') {

                    switch (message) {
                        case "no_tariff_vendor_defined":
                            this._showError(Translator.trans('js.no_tariff_vendor_defined'));
                            return;
                        case "no_tariff_reseller_defined":
                            this._showError(Translator.trans('js.no_tariff_reseller_defined'));
                            return;
                        default:
                            this._showError(Translator.trans('js.order_reschedule_error_message'));
                    }
                }
            }
            this._resetForm();
        },

    });

})(window.z = window.z || {}, Backbone, _, moment);
