'use strict';

(function (app, $, _, Backbone) {

    /**
     * Filters view
     *
     * The filters form has to be inside a div with class "page-grid-filters"
     * and to have the class grid-filters(this is how the "submit" event is handled);
     *
     * The column filters(filters for datatable columns) need to have:
     *      - data-grid-column-index: column index
     *      - data-grid-column-name: column name (for filters URL)
     *
     * The custom filters need to have:
     *      - data-grid-custom-filters: the name of the custom filter
     *          - they will be sent as AJAX params in the "filters" array
     *          - their functionality will be implemented server-side in indexResults actions
     *
     * The "Submit" button needs to have the data-grid-filters-submit="true" attribute.
     *
     * The "Reset" button needs to have the data-grid-filters-reset="true" attribute.
     *
     * All the views that will extends the Filter View will have to implement the next methods:
     *
     * 1. getDatatableId() - get the datatable id (eg. 'page-reseller-datatable')
     * 2. getPath() - get the page path (eg. 'resellers')
     */
    var FiltersView = Backbone.View.extend({

        /**
         * Bounding element.
         */
        el: '.grid-container',

        /**
         * Events
         */
        events: {
            'click .page-grid-filters button[data-grid-filters-submit="true"]': '_onSubmitFiltersForm',
            'click .page-grid-filters button[data-grid-filters-reset="true"]': '_onResetButtonClick',
            'submit .grid-filters': '_onSubmitFiltersForm',
            'order.dt': '_onSortingGridEvent',
            'search.dt': '_onGlobalSearchingGridEvent',
            'page.dt': '_onPageChange',
            'length.dt': '_onLengthChange'
        },

        /**
         * Defaults const
         */
        DEFAULT_PAGE: 1,
        DEFAULT_LENGTH: 10,

        /**
         * Constructor.
         *
         * @return {void}
         */
        initialize: function () {

            this.urlFilters = {};

            // Reset the chosen fields and render the view
            this.render();

            // Prefill the filters
            this._prefillFilters();

            // Override the getExtraAjaxFilters from the Filters service
            app.filters.getExtraAjaxFilters = this.getExtraAjaxFilters.bind(this);

            // Override the applyDefaultSorting from the Filters service
            app.filters.applyDefaultSorting = this.applyDefaultSorting.bind(this);

            // Override the markRow from the Filters service
            app.filters.markRow = this.markRow.bind(this);

            // Override the applyDefaultGlobalSearching from the Filters service
            app.filters.applyDefaultGlobalSearching = this.applyDefaultGlobalSearching.bind(this);

            // Save the current filters and access the getFiltersUrl method from FormView.js
            app.filters.getFiltersUrl = this.getFiltersUrl.bind(this);

            // For the views that will override the FilterView
            Backbone.View.prototype.initialize.apply(this, arguments);
        },

        /**
         * Render the view
         *
         * @return {Object} - this object
         */
        render: function () {

            // Deselect all the options for chosen select to display the placeholder
            this.$(".chosen-select")
                .find('option').prop('selected', false)
                .end().trigger('chosen:updated');

            return this;
        },

        /**
         * Get datatable id (it must be overridden by all the views that extend the FilterView)
         *
         * @return {string} - datatable id
         */
        getDatatableId: function () {
            return '';
        },


        /**
         * Get the page path (it must be overridden by all the views that extend the FilterView)
         *
         * @return {string} - page path (e.g. localities)
         */
        getPath: function () {
            return '';
        },

        /**
         * Set the datatable
         *
         * @return {FiltersView} - the datatable object
         */
        getDatatable: function () {

            if (this.datatable) {
                return this.datatable;
            }

            var datatableId = this.getDatatableId();

            if (datatableId.length > 0) {
                this.datatable = this.$('#' + datatableId).DataTable();
            } else {

                this.datatable = this.$('.dataTable').DataTable();
            }

            return this.datatable;
        },

        /**
         * This function will override the getExtraAjaxFilters function from the Filters service.
         * Get the extra ajax filters.
         *
         * @return {Object} - extra ajax filters
         */
        getExtraAjaxFilters: function () {

            return this.extraAjaxFilters;
        },

        /**
         * Set extra ajax filters.
         *
         * @param {*} extraAjaxFilters - extra ajax filters array
         * @return {FiltersView} - this object
         */
        setExtraAjaxFilters: function (extraAjaxFilters) {

            this.extraAjaxFilters = extraAjaxFilters;

            return this;
        },

        /**
         * Get the URL parameters
         *
         * @return {Object} - object with all the URL parameters
         */
        getUrlParameters: function () {

            //Gets the url parameters and builds an object
            var params = app.helper.deparam(location.search);

            return params;
        },

        /**
         * Get URL parameter value by name
         *
         * @param {string} paramName - parameter name
         * @return {string} - parameter value or undefined if it doesn't exist
         */
        getUrlParameterValue: function (paramName) {

            if (typeof this.urlParameters === 'undefined') {
                this.urlParameters = this.getUrlParameters();
            }

            if (this.urlParameters.hasOwnProperty(paramName) && _.isArray(this.urlParameters[paramName])) {

                // Decode each element and return an array
                return _.map(this.urlParameters[paramName], function (filter) {
                    return this.decodeURICustom(filter);
                }.bind(this));

            } else {
                return this.decodeURICustom(this.urlParameters[paramName]);
            }
        },

        /**
         * Apply filters for the grid.
         *
         * @return {undefined}
         */
        applyFilters: function () {

            // Display the loader
            // this.isLoading();

            var $filterElements = this.$('.page-grid-filters :input');
            var customData = {};

            $filterElements.each(function (index, element) {

                var $element = $(element);

                // Column filter

                if (typeof $element.data('grid-column-index') !== 'undefined') {
                    
                    // Search by column index
                    this.getDatatable().columns($element.data('grid-column-index')).search(element.value);

                    // Add the filter in URL
                    this.urlFilters[$element.data('grid-column-name')] = element.value;
                }

                // Custom filter
                if (typeof $element.data('grid-custom-filters') !== 'undefined') {

                    var filterValue = '';

                    if ($element.is(':checkbox')) {

                        filterValue = $element.is(':checked');
                    } else {

                        filterValue = $element.val();
                    }

                    // If the filter value is an array, sanitize the data and remove the empty elements
                    if (_.isArray(filterValue)) {
                        filterValue = this.sanitizeMultipleSelectFilterValues(filterValue);
                    }

                    // Add the value as a custom filter for AJAX request
                    // (the functionality will be handled server-side)
                    customData[$element.data('grid-custom-filters')] = filterValue;

                    // Add the filter in URL
                    this.urlFilters[$element.data('grid-custom-filters')] = filterValue;
                }
            }.bind(this));

            // Navigate to the URL with filters
            app.router.navigate(this.getFiltersUrl());

            // Get the page from filters
            var page =  _.isUndefined(this.urlFilters['page']) ? this.DEFAULT_PAGE : this.urlFilters['page'];
            page = parseInt(page) - 1;

            // Get the length from filters
            var length =  _.isUndefined(this.urlFilters['length']) ? this.DEFAULT_LENGTH : this.urlFilters['length'];
            length = parseInt(length);

            // Apply the filters and redraw the grid
            this
                .setExtraAjaxFilters(customData)
                .getDatatable()
                .page(page)
                .page.len(length);
                // .draw('page');
            location.reload();
            // After the datable is redrawn
            this.getDatatable().on('draw', function () {
                // Hide the loader
                this.isLoading(false);
            }.bind(this));
        },

        /**
         * Remove the empty values from the multiple select elements.
         *
         * @param {Array} filterValue - array with the values from the multiple select
         * @returns {Array} - array with all the values that are not empty
         */
        sanitizeMultipleSelectFilterValues: function (filterValue) {

            var multipleSelectFilter = [];

            if (!_.isArray(filterValue)) {
                return;
            }

            for (var index in filterValue) {
                if (!filterValue.hasOwnProperty(index)) {
                    continue;
                }

                if (filterValue[index].length !== 0) {
                    multipleSelectFilter.push(filterValue[index]);
                }
            }

            return multipleSelectFilter;
        },

        /**
         * Get the URL with filters
         *
         * @return {string} - the URL
         */
        getFiltersUrl: function () {

            var filtersQueryString = '';

            // Remove the empty of undefined filter values
            for (var index in this.urlFilters) {

                if (!this.urlFilters.hasOwnProperty(index)) {
                    continue;
                }

                if (typeof this.urlFilters[index] === 'undefined' || this.urlFilters[index].length === 0) {
                    delete this.urlFilters[index];
                }
            }

            // Create the filter query string
            filtersQueryString = $.param(this.urlFilters);

            if (filtersQueryString.length) {
                return this.getPath() + '?' + filtersQueryString;
            }

            return this.getPath();
        },

        /**
         * Reset the filters.
         *
         * @return {FiltersView} - this object
         */
        resetFilters: function () {

            this.urlFilters = {};
            return this;
        },

        /**
         * Reset the grid & the filters
         *
         * @return {undefined}
         */
        resetAll: function () {
            var $filterElements = this.$('.page-grid-filters :input');

            $filterElements.each(function (index, element) {

                var $element = $(element);

                // Delete the filter inputs values
                $element.val('');

                // Remove the search
                this.getDatatable().columns($element.data('grid-column-index')).search(element.value);
            }.bind(this));

            // reset checked checkboxes
            $filterElements.filter(":checked").each(function (index, element) {
                element.checked = false;
            });

            $filterElements.filter("select").each(function (index, element) {

                var $element = $(element);

                // Select the first element for dropdowns
                if ($element.hasClass('chosen-select')) {
                    // Reset the dropdowns with chosen jquery
                    $element
                        .find('option').prop('selected', false)
                        .end().trigger('chosen:updated');
                } else {
                    // Normal dropdowns
                    $element.val($element.find('option:first-child').val());
                }

                // Remove the search
                this.getDatatable().columns($element.data('grid-column-index')).search(element.value);
            }.bind(this));

            // Navigate to the URL with filters
            app.router.navigate(this.resetFilters().getFiltersUrl());

            // Reset the AJAX filters & the default sorting & the global search  and redraw the grid
            this
                .setExtraAjaxFilters({})
                .getDatatable()
                .order([0, 'asc'])
                .search('')
                .draw();
        },

        /**
         * Apply the default sorting.
         * Datatables initial order is [0, 'asc'].
         * If we have a default order param in URL, it will sort the grid by it.
         * The URL filter for sorting is like 'order=1_desc', where 1 is the column index.
         *
         * @returns {Object} - order parameter value from the datatable [column_index,  'asc/desc']
         */
        applyDefaultSorting: function () {

            var orderFilter = this.getUrlParameterValue('order');
            var orderArray = [0 , 'asc'];

            if (typeof orderFilter !== 'undefined') {
                orderArray = [
                    orderFilter.split('_')
                ];
            }

            return orderArray;
        },

        /**
         * Apply the default global searching.
         *
         * The URL filter for global search is like 'search=search_value'
         *
         * @returns {string} - search parameter value from datatable
         */
        applyDefaultGlobalSearching: function () {

            var searchFilter = this.getUrlParameterValue('search');

            if (typeof searchFilter === 'undefined') {
                return '';
            }

            return searchFilter;
        },

        /**
         * Method that can modify the properties(color, etc) of a row in a datatable
         *
         * @private
         * @return {undefined}
         */
        markRow: function() {},

        /**
         * Method that shows loader spinner button
         *
         * @param {Object} loading Should show or hide the mask
         * @private
         * @return {undefined}
         */
        isLoading: function (loading) {

            var $filterFields = this.$('fieldset');
            var $submitButton = this.$('.page-grid-filters button[data-grid-filters-submit="true"]');
            var ladda = $submitButton.data('ladda') ? $submitButton.data('ladda') : $submitButton.ladda().data('ladda');

            loading = _.isUndefined(loading);

            if (loading) {
                ladda.start();
                $filterFields.attr('disabled', true);
            } else {
                ladda.stop();
                $filterFields.removeAttr('disabled');
            }
            return this;
        },

        /**
         * Return the decoded version only if the string is not undefined
         *
         * @param {mixed} param - URL param value
         * @returns {mixed} - the decoded parameter URI or undefined
         */
        decodeURICustom: function (param) {

            if (typeof param !== 'undefined') {
                return decodeURI(param);
            }

            return param;
        },

        /**
         * On sorting grid event
         *
         * @private
         * @return {undefined}
         */
        _onSortingGridEvent: function () {

            // Set the filter for order
            var order = this.getDatatable().order();

            // If the grid is sorted with a value different from the initial one, add the parameter in URL
            if (typeof order[0] !== 'undefined' && typeof order[0][0] !== 'undefined' && typeof order[0][1] !== 'undefined') {

                var orderQueryString = order[0][0] + '_' + order[0][1];

                if (orderQueryString !== '0_asc') {
                    this.urlFilters['order'] = orderQueryString;
                }

                // Navigate to the URL with filters
                app.router.navigate(this.getFiltersUrl());
            }
        },

        /**
         * On searching grid event
         *
         * @private
         * @return {undefined}
         */
        _onGlobalSearchingGridEvent: function () {

            var globalSearch = this.getDatatable().search();
            
            if (typeof globalSearch !== 'undefined' && globalSearch.length) {
                // Create the global search filter
                this.urlFilters['search'] = this.getDatatable().search();

                // Navigate to the URL with filters
                app.router.navigate(this.getFiltersUrl());
            }
        },

        /**
         * Pre-fill the filter inputs with values from URL.
         *
         * @private
         * @return {undefined}
         */
        _prefillFilters: function () {

            var customData = {};
            var $filterElements = this.$('.page-grid-filters :input');

            $filterElements.each(function (index, element) {

                var $element = $(element);

                // Prefill column filter
                this._prefillColumnFilter($element);

                // Custom filter
                if (typeof $element.data('grid-custom-filters') !== 'undefined' &&
                    typeof this.getUrlParameterValue($element.data('grid-custom-filters')) !== 'undefined') {

                    var filterValue = this.getUrlParameterValue($element.data('grid-custom-filters'));

                    if (typeof filterValue !== 'undefined' && _.isArray(filterValue)) {

                        for (var filterIndex in filterValue) {

                            if (!filterValue.hasOwnProperty(filterIndex)) {
                                continue;
                            }

                            // Add the elements in dropdowns with chosen jquery
                            if ($element.hasClass('chosen-select')) {

                                $element
                                    .find('option[value=' + filterValue[filterIndex] + ']').prop('selected', true)
                                    .end().trigger('chosen:updated');
                            }

                            // Save the value as a custom filter for the AJAX call (as array)
                            customData[$element.data('grid-custom-filters')] = filterValue;

                            // Add the value as filter for URL (as json string)
                            this.urlFilters[$element.data('grid-custom-filters')] = filterValue;
                        }

                    } else {

                        // If the element is checkbonx
                        if ($element.is(':checkbox') && (filterValue === 'true' || filterValue === '1')) {

                            $element.attr('checked', true);
                        }

                        // Set the empty values only if the element is not a multiple select
                        // Without this condition, the placeholder for multiple select won't be displayed if there is no option selected
                        if (!$element.hasClass('multiple-select')) {

                            // Add the value in the filter input
                            $element.val(filterValue).trigger('change');

                            // Save the value as a custom filter for the AJAX call
                            customData[$element.data('grid-custom-filters')] = element.value;

                            // Add the value as filter for URL
                            this.urlFilters[$element.data('grid-custom-filters')] = element.value;

                            // Add the elements in dropdowns with chosen jquery
                            if ($element.hasClass('chosen-select')) {

                                $element
                                    .find('option[value=' + filterValue + ']').prop('selected', true)
                                    .end().trigger('chosen:updated');
                            }
                        }
                    }
                }
            }.bind(this));

            // Save the default page
            this.urlFilters['page'] = _.isUndefined(this.getUrlParameterValue('page')) ? this.DEFAULT_PAGE : this.getUrlParameterValue('page');

            // Save the default length
            this.urlFilters['length'] = _.isUndefined(this.getUrlParameterValue('length')) ? this.DEFAULT_LENGTH : this.getUrlParameterValue('length');

            // Set the custom AJAX filters
            this.setExtraAjaxFilters(customData);
        },

        /**
         * Prefill custom filters
         *
         * @param {mixed} $element - filter element
         * @return {undefined}
         * @private
         */
        _prefillColumnFilter: function ($element) {

            // Column filter
            if (typeof $element.data('grid-column-index') !== 'undefined' &&
                typeof this.getUrlParameterValue($element.data('grid-column-name')) !== 'undefined') {

                // Add the value in the filter input
                $element.val(this.getUrlParameterValue($element.data('grid-column-name'))).trigger('change');

                if ($element.hasClass('chosen-select')) {
                    $element.trigger('chosen:updated');
                }

                // Add the value as filter for URL
                this.urlFilters[$element.data('grid-column-name')] = this.getUrlParameterValue($element.data('grid-column-name'));
            }
        },

        /**
         * Click on submit filters button.
         *
         * @param {mixed} event - the event
         * @private
         * @return {undefined}
         */
        _onSubmitFiltersForm: function (event) {

            // event.preventDefault();

            // Apply the filters
            this.applyFilters();
        },

        /**
         * Click on reset filters button.
         *
         * @private
         * @return {void}
         */
        _onResetButtonClick: function () {
            // Reset the grid and the filters
            this.resetAll();
        },

        /**
         * Click on a button to change the page
         *
         * @private
         * @return {undefined}
         */
        _onPageChange: function () {

            // Set the current page
            this.urlFilters['page'] = this.getDatatable().page.info().page + 1;

            // Navigate to the URL with filters
            app.router.navigate(this.getFiltersUrl());
        },

        /**
         * Change on length page dropdown to change the page
         *
         * @private
         * @return {undefined}
         */
        _onLengthChange: function () {

            // Set the current length
            this.urlFilters['length'] = this.getDatatable().page.info().length;

            // Update the page number
            this._onPageChange();

            // Navigate to the URL with filters
            app.router.navigate(this.getFiltersUrl());


        }
    });

    app.FiltersView = FiltersView;

})(window.z = window.z || {}, $, _, Backbone);
