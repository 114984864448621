"use strict";

(function(z, Backbone){

    var ServiceOptionModel = Backbone.Model.extend({

        /**
         * Model validation rules
         */
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceconfig_name_required")
            },{
                minLength: 1,
                msg: Translator.trans("js.error_message_serviceconfig_name_minlength")
            },{
                maxLength: 128,
                msg: Translator.trans("js.error_message_serviceconfig_name_maxlength")
            }, {
                fn: function(value){

                    if (!value) {

                        return;
                    }

                    var view = this.associatedViews[0];
                    var collection = view.collection;

                    var exists = false;

                    var model = collection.findWhere({ name: value });

                    var firstId = model ? model.cid : null;
                    var secondId = view.model.cid;

                    if (model && firstId !== secondId) {

                        exists = true;
                    }

                    if (exists) {

                        return Translator.trans("js.error_message_serviceconfig_option_exists");
                    }
                }
            }],
            value: [{
                required: function(value, attr, computedState) {

                    return computedState.isValueRequired;
                },
                msg: Translator.trans("js.error_message_serviceconfig_value_required")
            }, {
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceconfig_float_format")
            }, {
                min: 0,
                msg: Translator.trans("js.error_message_serviceconfig_min_value")
            }],
            percent: [{
                required: function(value, attr, computedState) {

                    return computedState.isPercentRequired;
                },
                msg: Translator.trans("js.error_message_serviceconfig_percent_required")
            }, {
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceconfig_float_format")
            }, {
                min: 0,
                msg: Translator.trans("js.error_message_serviceconfig_min_value")
            }]
        }
    });

    z.ServiceOptionModel = ServiceOptionModel;

})(window.z = window.z || {}, Backbone);
