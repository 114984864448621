"use strict";

(function(z, Backbone){

    var CategoriesCollection = Backbone.Collection.extend({

        /**
         * The model
         */
        model: z.CategoryModel,

        /**
         * Get the URL
         * @return {string} - the url
         */
        url: function(){
            return "/api/categories";
        },

        /**
         * Parse the response
         * @param {Object} response - response
         * @return {*} - response data
         */
        parse: function (response) {
            return response.data;
        },

        /**
         * Where functionality with insensitive search
         * @param {string} key - the item key
         * @param {string} val - the item value
         * @return {*} - the model item
         */
        iwhere: function(key, val) {
            return this.filter( function( item ){
                return item.get( key ).toLowerCase() === val.toLowerCase();
            });
        },

        /**
         * Verify if a category name is unique in the collection
         * @param {string} name - category name
         * @param {string} categoryEditId - model cid if we're in editing mode, undefined otherwise
         * @return {boolean} - is the name unique or not
         */
        isNameUnique: function(name, categoryEditId) {

            var categories = this.iwhere('name', name);
            var isUnique = true;

            // Verify that the name is unique, but for editing mode, exclude the edited model
            for (var index in categories) {

                if (!categories.hasOwnProperty(index) || categoryEditId === categories[index].cid) {
                    continue;
                }

                isUnique = false;
            }

            return isUnique;
        }

    });

    z.CategoriesCollection = CategoriesCollection;

})(window.z = window.z || {}, Backbone);