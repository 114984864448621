"use strict";

(function(z, Backbone){

    var VendorsCollection = Backbone.Collection.extend({

        /**
         * Constants used when searching for Vendors to fill the vendors field
         */
        AREA_TYPE_FIXED: 1,
        AREA_TYPE_EXTENDED: 2,

        /**
         * The model
         */
        model: z.VendorModel,

        /**
         * Search by availability
         */
        available: false,

        /**
         * Parses the response
         * @param {Object} response The response
         * @return {Object} The parsed data
         */
        parse: function(response){

            return response.data;
        },

        /**
         * Set available
         * @param {boolean} available Enable or disable
         * @return {Backbone.Collection} Return myself
         */
        setAvailable: function(available){

            this.available = !! available; // convert to bool

            return this;
        },

        /**
         * Sets service id
         * @param {string|int} id The service id
         * @return {Backbone.Collection} Return myself
         */
        setServiceId: function(id){

            this.serviceId = id;

            return this;
        },

        /**
         * Sets county id
         * @param {string|int} id The county id
         * @return {Backbone.Collection} Return myself
         */
        setCountyId: function(id){

            this.countyId = id;

            return this;
        },

        /**
         * Sets quantity
         * @param {string|int} quantity The quantity
         * @return {Backbone.Collection} Return myself
         */
        setQuantity: function(quantity){

            this.quantity = quantity;

            return this;
        },

        /**
         * Sets the type of area
         * @param {string|int} type The type of area
         * @return {Backbone.Collection} Return myself
         */
        setArea: function(type){

            this.area = type;

            return this;
        },

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function(){

            var url;

            if (this.available) {

                url = '/api/vendors/available/' + this.serviceId + '/' + this.countyId + '/' + this.area;

                if (this.quantity) {

                    url += '?quantity=' + this.quantity;
                }
            }

            return url;
        }
    });

    z.VendorsCollection = VendorsCollection;

})(window.z = window.z || {}, Backbone);