"use strict";

(function(z, Backbone){

    z.CategoriesTree = Backbone.View.extend({

        el: "#categories-page",

        events: {
            'submit #category-form': '_onSubmitForm',
            'click .category-edit-action' : '_onEditCategory',
            'click .category-delete-action' : '_onDeleteCategory',
            'change .dd' : '_onCategoriesTreeChange'
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            // Bind Backbone validation
            this._bindValidation();

            // Listening on collection events
            this.listenTo(this.collection, 'add', this._onCollectionAdd);
            this.listenTo(this.collection, 'remove', this._onCollectionRemove);
            this.listenTo(this.collection, 'change', this._onCollectionChange);
            this.listenTo(this.collection, 'sync', this._onCollectionFinished);
            this.listenTo(this.collection, 'reset', this._onCollectionReset);

            this.$categoriesTree = this.$('#categories-tree');

            // Load categories tree
            this._loadCategoriesTree();

            this.render();
        },

        /**
         * Render function
         * @return {undefined}
         */
        render: function() {

            var _this = this;

            // Initialize the nestable tree
            $('.dd').nestable({
                maxDepth: 3,

                callback: function(l, e) {

                    // Don't save the category if the user has not confirmed
                    if (!_this.confirmTreeChange) {
                        return;
                    }

                    // Set the cid of the category that is edited
                    _this.categoryEditId = e.data('id');

                    // Update the category
                    _this._updateCategory();

                    _this.confirmTreeChange = undefined;
                }
            });
        },

        /**
         * Get the serialized array
         * @return {jQuery} serialized array
         */
        getSerializedArray: function() {
            return $('.dd').nestable('serialize');
        },

        /**
         * Event triggered when a new item is added to collection
         * @param {object} model - Model bound to the collection
         * @private
         * @return {undefined}
         */
        _onCollectionAdd: function(model) {
            // Render category item
            this._renderCategoryItem(model);
        },

        /**
         * Event triggered when an item is removed from collection
         * @param {object} model - Model bound to the collection
         * @private
         * @return {undefined}
         */
        _onCollectionRemove: function(model) {
            // Render category item
            this._renderCategoryItem(model, true);
        },

        /**
         * Event triggered when the collection is changed
         * @param {Object} model - the model
         * @private
         * @return {undefined}
         */
        _onCollectionChange: function(model) {
            // Render category item
            this._renderCategoryItem(model);
        },

        /**
         * Event triggered when the collection has been loaded
         * @private
         * @return {undefined}
         */
        _onCollectionFinished: function() {
            this._onLoad(false);
        },

        /**
         * Event triggered when the collection is loaded
         * @private
         * @return {undefined}
         */
        _onCollectionLoad: function() {
            this._onLoad(true);
        },

        /**
         * Event triggered when the collection is reset
         * @private
         * @return {undefined}
         */
        _onCollectionReset: function() {
            // Render category item
            this._renderCategoriesTreeReset();
        },

        /**
         * Remove the items from the categories tree
         * @return {undefined}
         * @private
         */
        _renderCategoriesTreeReset: function() {
            this.$categoriesTree.find(".dd-list:first-child li").remove();
        },

        /**
         * Load the categories tree
         * @private
         * @return {undefined}
         */
        _loadCategoriesTree: function() {
            // Listen on request to show the loader
            this.listenTo(this.collection, 'request', this._onCollectionLoad);

            // Initialize the collection
            this._fetchCollection();
        },

        /**
         * Fetch the collection
         * @private
         * @return {undefined}
         */
        _fetchCollection: function() {
            this.collection.fetch();
        },

        /**
         * Method that binds validation to the chosen model
         * @param {object} model The model that the validation is performed on
         * @private
         * @return {undefined}
         */
        _bindValidation: function(model) {
            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Method that shows the loader
         * @param {bool} loading Whether to show the loader or not
         * @private
         * @return {undefined}
         */
        _onLoad: function(loading) {
            if( loading ){
                this.$('.ibox-content').addClass('sk-loading');
            } else {
                this.$('.ibox-content').removeClass('sk-loading');
            }
        },

        /**
         * Method that is called if the form is valid
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function (view, attr) {
            this._dataValidation(attr);
        },

        /**
         *  Method that is called if the form is invalid
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @param {Object} error Validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function (view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Render category item in the categories tree
         * @param {object} model - Model bound to the collection
         * @param {bool} remove - true if the category has to be removed
         * @private
         * @return {undefined}
         */
        _renderCategoryItem: function(model, remove) {

            // Use model cid as identifier (for those cases when we add/edit/delete a new category that was not saved in database)
            var categoryIdentifier = model.cid;

            // See if the category was edited and the item has to be visually updated
            var $categoryItem = this.$categoriesTree.find('li.dd-item[data-id=' + categoryIdentifier + ']');
            var isEdit = !!$categoryItem.length;

            // Get model parent
            var parent = model.get('parent');

            // Actions
            var $editAction = $('<i class="fa fa-pencil category-edit-action" data-id="' + categoryIdentifier + '"></i>');
            var $deleteActionIcon = $('<i class="fa fa-trash-o category-delete-action" data-id="' + categoryIdentifier + '"></i>');
            var $actions = $('<div class="category-item-actions"></div>');

            // Category tree item
            var $category = $('<div class="dd-name"></div>');
            var $li = $('<li class="dd-item" data-id="' + categoryIdentifier + '"></li>');
            var $ddHandle = $('<div class="dd-handle"></div>');

            // Build the category tree item
            $actions
                .append($editAction)
                .append($deleteActionIcon)
                .appendTo($category);

            $ddHandle
                .text(model.get('name'))
                .appendTo($category);

            $li.append($category);

            this.$categoriesTree.removeClass('hidden');

            remove = _.isUndefined(remove) ? false : remove;

            // Delete mode
            if (remove) {
                $categoryItem.remove();
                this.collection.remove(model.cid);
                return;
            }

            // Add mode
            if (!isEdit) {
                // If the category has parent, add it to the subcategories tree
                if (typeof parent !== 'undefined') {

                    var parentModel = this.collection.findWhere({id: parent.id});
                    var $parentLi = this.$categoriesTree.find('li.dd-item[data-id="' + parentModel.cid + '"]');

                    // Render the parent category if it wasn't already rendered
                    if ($parentLi.length === 0) {
                        this._renderCategoryItem(parentModel);
                        $parentLi = this.$categoriesTree.find('li.dd-item[data-id="' + parentModel.cid + '"]');
                    }

                    var $subcategoriesTree = $parentLi.children('.dd-list');

                    if ($subcategoriesTree.length === 0) {
                        $parentLi.append('<ol class="dd-list"></ol>');
                    }

                    $parentLi.children('.dd-list').append($li);

                } else {
                    // The category is on level 0
                    this.$categoriesTree.children('.dd-list').append($li);
                }
            } else {
                // Edit mode
                $categoryItem.children('.dd-name').children('.dd-handle').text(model.get('name'));
            }
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @private
         * @return {undefined}
         */
        _dataValidation: function (attr) {
            var $fields = this.$('[data-validation]');
            if (attr) {
                $fields = this.$('[data-validation~="' + attr + '"]');
            }
            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Update a category
         * @param {Object} record - the model to be updated
         * @return {undefined}
         * @private
         */
        _updateCategory: function(record) {

            // Get the model from the collection
            var category = this.collection.get(this.categoryEditId);

            // Create a new model for the category that will be edited, so the one from the collection won't be changed until it's
            // persisted in db
            var categoryToBeEdited = new z.CategoryModel(category.toJSON());

            if (typeof record !== 'undefined') {
                categoryToBeEdited.set(record.toJSON());
            }

            // // Update the category in db
            this._persistCategory(this._prepareCategoryModelForSaving(categoryToBeEdited));
        },

        /**
         * On add category event
         * @param {Event} e - the event
         * @private
         * @return {undefined}
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            // Populating data array
            var data = this._populateCategoryData();
            var record = new z.CategoryModel(data);

            this._bindValidation(record);

            if(!record.isValid(true)){
                return;
            }

            if (!this.collection.isNameUnique(record.get('name'), this.categoryEditId)) {
                this._showError('nameNotUnique');
                return;
            }

            if (typeof this.categoryEditId !== 'undefined') {
                this._updateCategory(record);
            } else {

                // Save and add the category to the collection
                this._persistCategory(this._prepareCategoryModelForSaving(record));
            }

            // Remove the errors
            this.$('.category-validation-error').addClass('hidden');

            // Resetting fields after the value is being added in tree
            this._resetFields();

            this._bindValidation();
        },

        /**
         * Prepare category model for saving
         * @param {Object} record - the model that will be saved
         * @return {Object} - the model that will have only id, name, and parent
         * @private
         */
        _prepareCategoryModelForSaving: function(record) {
            record.unset('children');
            record.unset('updatedAt');
            record.unset('createdAt');
            record.unset('services');

            if (typeof record.get('parent') !== 'undefined') {
                record.set({ 'parent': record.get('parent').id });
            } else {
                record.set({ 'parent': '' });
            }

            return record;
        },

        /**
         * Persist the category in db
         * @param {Object} record - the model that will be persisted
         * @return {undefined}
         * @private
         */
        _persistCategory: function(record) {

            record.save(null,{

                // On success
                success: function(model) {

                    if (typeof this.categoryEditId !== 'undefined') {

                        // If we're in edit mode, we update the category from the collection with the new data
                        var category = this.collection.get(this.categoryEditId);
                        category.set({ 'name': model.get('name')});

                        // Reset the edit flag
                        this.categoryEditId = undefined;

                        this._resetFields();

                    } else {

                        // If we're in add mode, we add the new model to the collection

                        // Set children and services as an empty array(because a new category is on level 0 without children and services)
                        model.set('children', []);
                        model.set('services', []);
                        model.unset('parent');

                        // Add the category to the collection
                        this.collection.push(model);
                    }

                }.bind(this),

                // On error
                error: function(model, response) {

                    // Display different errors based on the error key from the backend validation
                    if (response.hasOwnProperty('responseJSON') && response.responseJSON.hasOwnProperty('error')) {
                        switch (response.responseJSON.error) {
                            case 'invalid.form.nameNotUnique':
                                this._showError('nameNotUnique');
                                break;
                            default:
                                this._showError();
                        }
                    }

                }.bind(this)
            });
        },

        /**
         * Populate category data
         * @return {Object} {{name: *, children: Array}} - the fields from the form
         * @private
         */
        _populateCategoryData: function() {
            var $data = this._getCategoryData();

            return {
                name: $data.name,
                children: []
            };
        },

        /**
         * Method that returns the category data fields
         * @private
         * @return {object} {{name}} - Fields values
         */
        _getCategoryData: function() {
            var $name = this.$('#categoryform-name-field');

            return {
                'name': $name.val()
            };
        },

        /**
         * Method that resets the specified fields according to id passed
         * @private
         * @return {undefined}
         */
        _resetFields: function () {
            this.$('fieldset input').val("");

            // Modify the add button text
            var $addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#categoryform-add-button').html($addingButtonTitle);

        },

        /**
         * On click on edit category button
         * @param {Event} e - the event object
         * @private
         * @return {undefined}
         */
        _onEditCategory: function(e) {

            var editingButtonTitle = Translator.trans('js.btn_edit');
            var id = $(e.target).data('id');
            var category = this.collection.get(id);

            // Modify button name
            this.$('#categoryform-add-button').html(editingButtonTitle);

            // Set an edit category id
            this.categoryEditId = id;

            // Prefill the form
            this.$('#categoryform-name-field').val(category.get('name'));
        },

        /**
         * On click on delete category button
         * @param {Event} e - the event object
         * @return {undefined}
         * @private
         */
        _onDeleteCategory: function(e) {

            var id = $(e.target).data('id');

            // Create a new category model, so in case of error the model won't be remove from the collection
            var category = new z.CategoryModel(this.collection.get(id).toJSON());

            var confirmDelete = confirm(Translator.trans('js.category_delete_confirmation_message') + ": " + category.get('name'));

            if (!this._isValidForRemoval(category) || !confirmDelete) {
                return;
            }

            // Delete the category
            category.destroy({

                // On success
                success: function(model) {

                    this.collection.remove(model);

                }.bind(this),

                // on error
                error: function(model, response) {

                    // Display different errors based on the error key from the backend validation
                    if (response.responseJSON && response.responseJSON.errors) {

                        var error = _.first(response.responseJSON.errors);

                        switch (error.message) {
                            case 'invalid.form.categoryHasChildren':
                                this._showError('children');
                                break;
                            case 'invalid.form.categoryHasServices':
                                this._showError('services');
                                break;
                            default:
                                this._showError();
                        }
                    }

                }.bind(this)
            });

            this.categoryEditId = undefined;

            this._onLoad(false);
        },

        /**
         * Verify if a category is valid for removal and if it's not, display an error message
         * @param {Object} category - category model
         * @return {boolean} - if it's valid or not to be removed
         * @private
         */
        _isValidForRemoval: function(category) {

            if (typeof category.get('children') !== 'undefined' && category.get('children').length !== 0) {
                this._showError('children');
                return false;
            }

            if (typeof category.get('services') !== 'undefined' && category.get('services').length !== 0) {
                this._showError('services');
                return false;
            }

            return true;
        },

        /**
         * Show error message
         * @param {string} type - error type
         * @private
         * @return {undefined}
         */
        _showError: function(type) {

            var $errorContainer = this.$('#categoriestree-error-message');
            var errorMessage = Translator.trans('js.error_message_category_form');

            switch(type) {
                case "children":
                    errorMessage = Translator.trans('js.error_message_category_has_children');
                    break;
                case "services":
                    errorMessage = Translator.trans('js.error_message_category_has_associated_services');
                    break;
                case "nameNotUnique":
                    errorMessage = Translator.trans('js.error_message_category_name_notUnique');
                    break;
            }

            $errorContainer.removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .show('slow')
                .delay(3000)
                .fadeTo('slow',0)
                .hide('slow');
        },

        /**
         * On categories tree change
         * @return {undefined}
         * @private
         */
        _onCategoriesTreeChange: function() {
            // @TODO implement sweetalert
            // swal({
            //     title: Translator.trans('js.save_changes_category_title'),
            //     text: Translator.trans('js.save_changes_category_confirm_message'),
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonText: Translator.trans('js.save_changes_category_confirm_btn'),
            //     cancelButtonText: Translator.trans('js.save_changes_category_cancel_btn')
            // }).then(function() {
            //
            //     this.confirmTreeChange = true;
            //
            //     swal(
            //         'Deleted!',
            //         'Your imaginary file has been deleted.',
            //         'success'
            //     );
            //
            //     this._updateCollectionBasedOnTree();
            //
            // }.bind(this),
            // function(dismiss) {
            //
            //     this.confirmTreeChange = false;
            //
            //     if (dismiss === 'cancel') {
            //         // Reset and load again to not apply the changes of the drag and drop
            //         this.collection.reset();
            //
            //         // Load categories tree
            //         this._loadCategoriesTree();
            //     }
            //
            // }.bind(this));

            this.confirmTreeChange = confirm(Translator.trans('js.save_changes_category_confirm_message'));

            if (!this.confirmTreeChange) {

                // Reset and load again to not apply the changes of the drag and drop
                this.collection.reset();

                // Load categories tree
                this._loadCategoriesTree();
            } else {
                this._updateCollectionBasedOnTree();
            }
        },

        /**
         * Update the collection based on the tree serialized array
         * @return {undefined}
         * @private
         */
        _updateCollectionBasedOnTree: function() {
            var serializedCategories = $('.dd').nestable('serialize');

            // Update the collection based on the serialized categories tree
            this._updateCategoriesTreeBasedOnTreeItem(serializedCategories);
        },

        /**
         * Update parent and children based on category tree item
         * @param {Array} serializedCategories - nestable serialized categories
         * @param {Object} parent - parent - parent category model
         * @return {undefined}
         * @private
         */
        _updateCategoriesTreeBasedOnTreeItem: function(serializedCategories, parent) {

            for (var index in serializedCategories) {

                if (!serializedCategories.hasOwnProperty(index)) {
                    continue;
                }

                var categoryTreeItem = serializedCategories[index];
                var category = this.collection.get(categoryTreeItem['id']);
                var serializedChildren = categoryTreeItem['children'];

                // Set category parent
                if (typeof parent !== 'undefined') {
                    category.set({ 'parent': parent.toJSON() });
                } else {
                    category.unset('parent');
                }

                // If the category doesn't have children, update it and continue
                if (typeof serializedChildren === 'undefined') {

                    // Remove it's children
                    category.set({ 'children': []});
                    continue;
                }

                var newChildren = [];

                // If the category has children, update the children array and go recursively through them to update the parent
                for (var i in serializedChildren) {

                    if (!serializedChildren.hasOwnProperty(i)) {
                        continue;
                    }

                    this._updateCategoriesTreeBasedOnTreeItem(serializedChildren, category);

                    var childrenModel = this.collection.get(serializedChildren[i]['id']);
                    newChildren.push(childrenModel.toJSON());
                }

                // Set the new children for the current category
                category.set({ 'children': newChildren });
            }
        }
    });

})(window.z = window.z || {}, Backbone);
