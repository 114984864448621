'use strict';

(function (window, app, Backbone, _) {

    app.LoginView = Backbone.View.extend({

        /**
         * Bounding element.
         */
        el: '#login-box-panel',

        /**
         * The url which will perform the authentication.
         */
        AUTH_URL: '/authenticate',

        CAPTCHA_LOGIN_ATTEMPTS: 3,

        /**
         * Events
         */
        events: {
            'submit #login-box-form' : '_onLoginFormSubmit'
        },

        /**
         * Constructor.
         * @return {undefined}
         */
        initialize: function () {

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: this.model
            });
        },

        /**
         * Method called after the view is removed.
         * @returns {*} Returns parent.
         */
        remove: function () {

            Backbone.Validation.unbind(this, {
                model: this.model
            });

            // call parent
            return Backbone.View.prototype.remove.apply(this, arguments);
        },

        /**
         * Method called if the form validation succeeds.
         * @param {object} view The view.
         * @param {string} attr The valid attribute.
         * @return {undefined}
         * @private
         */
        _onValid: function (view, attr) {

            this.$('[data-validation~="' + attr + '"]')
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails.
         * @param {object} view The view.
         * @param {string} attr The invalid attribute.
         * @param {string} error The error message.
         * @return {undefined}
         * @private
         */
        _onInvalid: function (view, attr, error) {

            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after user submits the login form.
         * @param {object} event The jQuery event object
         * @return {undefined}
         * @private
         */
        _onLoginFormSubmit: function(event){

            event.preventDefault();

            this._submit();
        },

        /**
         * Enables or disables the loading mask.
         * @param {bool} loading Should show or hide the mask?
         * @return {undefined}
         * @private
         */
        _isLoading: function (loading) {

            loading = loading ? loading : _.isUndefined(loading);

            var $loginBtn = this.$('#login-submit-btn');
            var $loginFields = this.$('fieldset');

            var ladda = $loginBtn.data('ladda') ? $loginBtn.data('ladda') : $loginBtn.ladda().data('ladda');

            if (loading) {

                ladda.start();

                $loginFields.attr('disabled', true);

            } else {

                ladda.stop();

                $loginFields.removeAttr('disabled');
            }
        },

        /**
         * Submit the login form.
         * @return {undefined}
         * @private
         */
        _submit: function () {

            var credentials = this._getCredentials();

            this.model.set(credentials);

            if (!this.model.isValid(true)) {
                return;
            }

            this._isLoading();

            this._clearErrorMsg();

            this._authenticate(credentials, function (jqxhr, status){


                var msg = this._buildErrorMsg(jqxhr, status);

                if (msg) {

                    this._setErrorMsg(msg);

                    this._isLoading(false);

                    app.boot.loginAttempts = jqxhr.responseJSON.errors[0].loginAttempts+1;

                    this.displayCaptcha();

                } else {

                    this._clearErrorMsg();

                    var url = this._buildRedirectUrl(jqxhr);

                    window.location = url;
                }

            }.bind(this));
        },

        /**
         * Submits login using the credentials from fields.
         * @param {object} credentials The credentials required for authentication.
         * @param {string} credentials.username The username.
         * @param {string} credentials.password The password.
         * @param {callback} callback The callback to be called after authentication is made.
         * @return {undefined}
         * @private
         */
        _authenticate: function (credentials, callback) {

            app.request.make(this.AUTH_URL, credentials, callback);
        },

        /**
         * Retrieves credentials from html elements.
         * @return {object} The credentials.
         * @private
         */
        _getCredentials: function () {

            var $usernameField = this.$('#login-username-field');
            var $passwordField = this.$('#login-password-field');

            var credentials =  {
                'username': $usernameField.val(),
                'password': $passwordField.val(),
            };

            if (!_.isUndefined(this.googleRecaptcha)) {
                credentials.captcha = grecaptcha.getResponse(this.googleRecaptcha);
            }

            return credentials;
        },

        /**
         * Builds an error message based on request info.
         * @param {object} jqxhr The jqXHR object from the request.
         * @param {string} status The status of the request.
         * @return {string} The error message.
         * @private
         */
        _buildErrorMsg: function (jqxhr, status) {

            if (status === 'timeout') {

                return Translator.trans('js.security_login_error_timeout');
            }

            if (!_.isUndefined(this.googleRecaptcha) && grecaptcha.getResponse(this.googleRecaptcha) === "") {
                return Translator.trans('js.security_login_error_captcha');
            }

            if (status === 'error') {

                if (jqxhr.status === 500) {

                    return Translator.trans('js.security_login_error_server');
                }

                if (jqxhr.status === 401) {

                    return Translator.trans('js.error_message_invalid_login');
                }

                return Translator.trans('js.security_login_error_server');
            }

            return '';
        },

        /**
         * Builds the redirect url according to the response.
         * @param {object} jqxhr The jqXHR object from the request.
         * @returns {string|undefined} The url where to perform the redirect or null.
         * @private
         */
        _buildRedirectUrl: function(jqxhr){

            if (!jqxhr.responseJSON) {
                return;
            }

            if (!jqxhr.responseJSON.data) {
                return;
            }

            if (!jqxhr.responseJSON.data.redirect) {
                return;
            }

            return jqxhr.responseJSON.data.redirect;
        },

        /**
         * Sets the error message for the panel.
         * @param {string} text The error message.
         * @return {undefined}
         * @private
         */
        _setErrorMsg: function (text) {

            var $errorMsg = this.$('#login-error-panel');

            $errorMsg
                .removeClass('hidden')
                .text(text);
        },

        /**
         * Method that render the captcha. If is already rendered its just resets the captcha
         *
         * @return {void}
         */
        displayCaptcha: function(){

            if (app.boot.loginAttempts < this.CAPTCHA_LOGIN_ATTEMPTS) {
                return;
            }

            if(!_.isUndefined(this.googleRecaptcha)) {

                grecaptcha.reset(this.googleRecaptcha);

                return;
            }

            this.googleRecaptcha = grecaptcha.render('login-captcha-holder', {
                'sitekey': app.boot.googleRecaptchaSitekey
            });
        },

        /**
         * Removes the error message from the panel.
         * @return {undefined}
         * @private
         */
        _clearErrorMsg: function () {

            var $errorMsg = this.$('#login-error-panel');

            $errorMsg
                .addClass('hidden')
                .text('');
        }
    });

})(window, window.z = window.z || {}, Backbone, _);
