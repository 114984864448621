'use strict';
(function(app, Backbone, _){

    var Security = app.Class.extend({

        /**
         * User roles
         */
        ROLE_ADMIN: 'ROLE_ADMIN',
        ROLE_TEAM_LEADER: 'ROLE_TEAM_LEADER',
        ROLE_OPERATOR: 'ROLE_OPERATOR',
        ROLE_VENDOR_ADMIN: 'ROLE_VENDOR_ADMIN',
        ROLE_VENDOR_OPERATOR: 'ROLE_VENDOR_OPERATOR',
        ROLE_RESELLER_ADMIN: 'ROLE_RESELLER_ADMIN',
        ROLE_RESELLER_OPERATOR: 'ROLE_RESELLER_OPERATOR',

        /**
         * Retrieves the current user
         * @return {Object} The user
         */
        getUser: function() {
            return app.boot.user;
        },

        /**
         * Retrieves the gatekeeper information about the user
         * @return {Object} The gatekeeper
         */
        getGatekeeper: function(){
            return app.boot.gatekeeper;
        },

        /**
         * Set the current user
         * @param {Object} data - New data to update the user
         * @return {undefined}
         */
        setUser: function(data) {
            app.boot.user = data;
        },

        /**
         * Checks to see if the current user is authenticated or not
         * @return {boolean} Is authenticated
         */
        isAuthenticated: function() {

            var user = this.getUser();

            return _.isObject(user);
        },

        /**
         * Checks if the current user has a reseller associated with it
         * @return {boolean} Is reseller associated
         */
        isResellerAssociated: function() {

            if (!this.isAuthenticated()) {

                throw Error('NotAuthenticated');
            }

            var user = this.getUser();

            return _.isObject(user.reseller);
        },

        /**
         * Checks if the current user has a vendor associated with it
         * @return {boolean} Is vendor associated
         */
        isVendorAssociated: function() {

            if (!this.isAuthenticated()) {

                throw Error('NotAuthenticated');
            }

            var user = this.getUser();

            return _.isObject(user.vendor);
        },

        /**
         * Retrieves the vendor roles
         *
         * @return {Object} - the vendor roles
         */
        getVendorRoles: function() {

            return [this.ROLE_VENDOR_ADMIN, this.ROLE_VENDOR_OPERATOR];
        },

        /**
         * Retrieves the reseller roles
         *
         * @return {Object} - the reseller roles
         */
        getResellerRoles: function() {

            return [this.ROLE_RESELLER_ADMIN, this.ROLE_RESELLER_OPERATOR];
        },

        /**
         * Retrieves the internal roles
         *
         * @return {Object} - the internal roles
         */
        getInternalRoles: function() {

            return [this.ROLE_ADMIN, this.ROLE_TEAM_LEADER, this.ROLE_OPERATOR];
        },

        /**
         * Checks if the current user is admin
         * @return {boolean} Is admin
         */
        isAdmin: function(){

            var gatekeeper = this.getGatekeeper();

            return gatekeeper.isAdmin;
        },

        /**
         * Checks if the current user is reseller operator
         * @return {boolean} Is reseller operator
         */
        isResellerOperator: function(){

            var gatekeeper = this.getGatekeeper();

            return gatekeeper.isResellerOperator;
        },

        /**
         * Checks if the attributes are granted against the current authentication token and optionally supplied object.
         * This method should not be used publicly, instead create a helper method (check out gatekeeper from backend).
         * @param {string|array} attributes The attributes
         * @return {boolean} Is granted
         */
        _isGranted: function(attributes) {

            if (!this.isAuthenticated()) {

                return false;
            }

            var user = this.getUser();

            var isAllowed = false;

            _.each(user.dbRoles, function(role){

                if (role.name === attributes) {

                    isAllowed = true;
                }

            }, this);

            return isAllowed;
        }
    });

    app.security = new Security();

})(window.z = window.z || {}, Backbone, _);