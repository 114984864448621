'use strict';

(function(app, Backbone){

    /**
     * View with validations for file uploads
     */
    var AttachmentFormView = Backbone.View.extend({

        /**
         * Maximum upload size in bytes
         */
        UPLOAD_MAX_SIZE: 5000000,

        /**
         * Allowed file types
         */
        UPLOAD_ALLOWED_TYPES: [
            'image/gif',
            'image/png',
            'image/jpeg',
            'application/pdf',
            'application/msword', // .doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
        ],

        /**
         * Element
         */
        el: 'form',

        /**
         * Constructor
         *
         * @return {undefined}
         */
        initialize: function(){

        },

        /**
         * Checks if the upload fields contain valid files
         * @param {Object} $file File object
         * @param {boolean} [required] Must be required or not
         * @param {string} [field] - The name of the field
         * @returns {boolean} Is valid
         */
        isValidUpload: function($file, required, field) {

            required = _.isUndefined(required) ? false : required;
            field = _.isUndefined(field) ? 'file' : field;

            if (!$file.length) {
                return true;
            }

            var file = $file.get(0).files[0];

            var invalid = function(error){
                this._onInvalid(this, field, error);
            }.bind(this);

            var valid = function(){
                this._onValid(this, field);
            }.bind(this);

            if (required && !file) {

                invalid(Translator.trans('js.error_message_attachmentform_name_required'));

                return false;
            }


            if (file && !this._isValidUploadType(file.type)) {

                invalid(Translator.trans('js.error_message_attachmentform_name_invalidtype'));

                return false;
            }

            if (file && !this._isValidUploadSize(file.size)) {

                invalid(Translator.trans('js.error_message_attachmentform_name_invalidsize'));

                return false;
            }

            valid();

            return true;
        },

        /**
         * Checks if the supplied type is a valid upload type
         * @param {string} type The type
         * @returns {boolean} Is valid
         */
        _isValidUploadType: function(type){

            return this.UPLOAD_ALLOWED_TYPES.indexOf(type) >= 0;
        },

        /**
         * Checks if the supplied size is less then or equal to maximum upload size
         * @param {int} size The size
         * @returns {boolean} Is valid
         */
        _isValidUploadSize: function(size){

            return size <= this.UPLOAD_MAX_SIZE;
        }
    });

    app.AttachmentFormView = AttachmentFormView;

})(window.z = window.z || {}, Backbone);