'use strict';

(function(app, Backbone){

    //noinspection UnnecessaryLocalVariableJS
    var AttachmentModel = Backbone.Model.extend({

        /**
         * The url for this model
         * @returns {string} The url
         */
        urlRoot: function(){
            return '/api/orders/' + this.get('order') + '/attachments';
        }
    });

    app.OrderAttachmentModel = AttachmentModel;

})(window.z = window.z || {}, Backbone);