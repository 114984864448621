'use strict';
(function(app){

    var Filters = app.Class.extend({

        /**
         * This function will return the custom extra AJAX filters for datatables.
         * It will be overwritten by Backbone filter views.
         *
         * @return {undefined}
         */
        getExtraAjaxFilters: function() {},

        /**
         * This function will return the array for the default datatable order parameter value.
         * It will be overwritten by Backbone filter views.
         *
         * @return {undefined}
         */
        applyDefaultSorting: function() {},

        /**
         * This function will return the string for the default global searching value.
         * It will be overwritten by Backbone filter views.
         *
         * @return {undefined}
         */
        applyDefaultGlobalSearching: function() {}
    });

    app.filters = new Filters({});

})(window.z = window.z || {}, Backbone, _);
