'use strict';
(function (z) {
    z.AllocateOrdersGrid = z.GridView.extend({
        el: "#orders-listing",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);
            $('#page-orders-allocation-datatable_multiselect_actions').hide();

            $('#ordersallocationfilters-locality-field').change(function () {

                if (this.value != '')
                {
                    document.getElementById('set_locality_check').style.display = 'none';
                }
                else
                {
                    document.getElementById('set_locality_check').style.display = 'inline';
                }
            }),

            $('#ordersallocationfilters-search-button').click(function() {

                var localityorder = $('#ordersallocationfilters-locality-field').val();
                $('#vendorsallocationfilters-locality-field').val(localityorder) ;
                $('#vendorsallocationfilters-search-button').trigger('click');
                console.log(localityorder);

            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-orders-allocation-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return `allocate-orders/county/${countyId}/service/${serviceId}`;
        },
    })

})(window.z = window.z || {});