"use strict";

(function(z, Backbone, _){

    z.OrderRescheduleHistory = Backbone.View.extend({

        el:"#reschedule-order-form",

        events: {
            'click #reschedule-order-history-modal-btn': '_displayRescheduleHistoryModal',
            'click #order-reschedule-history-form-modal .close-link' : '_hideRescheduleHistoryModal'
        },

        /**
         * Constructor.
         * @return {undefined}
         */
        initialize: function(){
            this.$modalElement = this.$('#order-reschedule-history-form-modal');
            this.rescheduleCollection = new z.OrderReschedulesCollection();
            this.listenTo(z.orderRescheduleForm, 'orderRescheduleSuccess', this._displayRescheduleHistoryButton);
        },

        /**
         * Method that displays the reschedule history button when
         * a reschedule is being done
         * @private
         * @return {undefined}
         */
        _displayRescheduleHistoryButton: function()
        {
            var $rescheduleHistoryButton = this._getRescheduleHistoryButton();
            var $rescheduleHistoryCheck = $('#not_display_button');

            if (this._isRescheduled() < 1 && !$rescheduleHistoryCheck) {
                this.$("#reschedule-history-button-container").append($rescheduleHistoryButton);
            }
        },

        /**
         * Method that returns if the order is rescheduled or not
         * @private
         * @return {undefined}
         */
        _isRescheduled: function()
        {
            return this.$("#reschedule-order-history-modal-btn").length;
        },

    /**
         * Method that constructs the html for the reschedule
         * history button
         * @returns {string} The html for the reschedule history button
         * @private
         */
        _getRescheduleHistoryButton: function()
        {
            var buttonName = Translator.trans("js.reschedule_history_button");

            return "<div class='col-lg-2'><a id='reschedule-order-history-modal-btn' class='btn btn-w-m btn-warning align-btn adjust-margin-left' data-style='slide-left'>" +
            buttonName + "</a></div>";
        },

        /**
         * Method that displays the modal element
         * @private
         * @return {undefined}
         */
        _displayRescheduleHistoryModal: function()
        {
            var id = this._getOrderId();

            this.rescheduleCollection.reset();
            this._removeTableElements();
            this.rescheduleCollection.setOrderId(id);

            this.$modalElement.modal("show");

            //Fetching collection data
            this.rescheduleCollection.fetch({
                success: function(response){
                    this._renderRescheduleRows(response);
                }.bind(this),
                error: function(){
                    var message = Translator.trans("js.error_fetching_reschedule_history_collection");
                    this._showError(message);
                }.bind(this)});
        },

        /**
         * Method that hides the modal
         * @private
         * @return {undefined}
         */
        _hideRescheduleHistoryModal: function()
        {
            this.$modalElement.modal("hide");
        },

        /**
         * Method that returns the order's id
         * @private
         * @return {*} The order's id
         */
        _getOrderId: function()
        {
            return $('#reschedule-order-form').data('id');
        },

        /**
         * Display the error message
         *
         * @param {string} message The message to be shown
         * @private
         * @return {undefined}
         */
        _showError: function(message)
        {
            this._isLoading(false);

            var $error = this._getInformationPanel();
            $error
                .removeClass('hidden alert-success')
                .addClass('alert-danger')
                .text(message)
                .fadeTo('slow', 1)
                .delay(5000)
                .fadeTo('slow', 0, function(){
                    $error.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that returns the information panel element
         * for the modal
         * @private
         * @return {undefined}
         */
        _getInformationPanel: function()
        {
            return this.$("#order-reschedule-history-general-error-panel");
        },

        /**
         * Method used to render reschedule rows
         * @param {*} response The collection response
         * @private
         * @returns {undefined}
         */
        _renderRescheduleRows: function(response)
        {
            response.each(function(data){
                this._renderRescheduleRow(data);
            }.bind(this));
        },

        /**
         * Method that returns the table element
         * @private
         * @returns {string} Table element
         */
        _getTableName: function()
        {
            return this.$('#order-reschedule-history');
        },

        /**
         * Method that removes all the table rows from the
         * table body
         * @private
         * @returns {undefined}
         */
        _removeTableElements: function()
        {
            this.$('#order-reschedule-history tbody tr').remove();
        },

        /**
         * Method used to render the rows in the
         * reschedule history modal
         * @param {*} model Collection model that holds data
         * @private
         * @returns {undefined}
         */
        _renderRescheduleRow: function(model)
        {
            var $tableName = this._getTableName();
            $tableName.removeClass('hidden');

            // Declaring elements used to display the table values
            var reasonText = this._getRescheduleTypeText(model.get("type"));

            // If the reschedule reason is other, display the text comment provided by the user
            if (model.get("additionalComment") !== undefined) {
                reasonText = model.get("additionalComment");
            }

            var $row = $('<tr>');

            // Initial date
            $('<td>')
                .text(moment(model.get("initialDate")).format('DD-MM-YYYY'))
                .appendTo($row);

            // Reschedule date
            $('<td>')
                .text(moment(model.get("rescheduleDate")).format('DD-MM-YYYY'))
                .appendTo($row);
            // User
            $('<td>')
                .text(model.get("user"))
                .appendTo($row);
            if(model.get('canViewVendorInfo')){
                // Old vendor
                $('<td>')
                    .text(model.get("oldVendor"))
                    .appendTo($row);
                // New vendor
                $('<td>')
                    .text(model.get("newVendor"))
                    .appendTo($row);
            }

            // Reason comment
            $('<td>')
                .text(reasonText)
                .appendTo($row);

            $tableName.find("tbody").append($row);
        },

        /**
         * Method used to generate a javascript label for each type of
         * reschedule
         * @param {*} type The type of the reschedule
         * @private
         * @returns {string} The translation string used for each reschedule type
         */
        _getRescheduleTypeText: function(type)
        {
            var message = '';

            switch (type) {
                case 1:
                    message = Translator.trans("js.label_reschedule_history_client_not_available");
                    break;
                case 2:
                    message = Translator.trans("js.label_reschedule_history_needs_reassembly");
                    break;
                case 3:
                    message = Translator.trans("js.label_reschedule_history_other");
                    break;
            }

            return message;
        }
    });

})(window.z = window.z || {}, Backbone, _);
