"use strict";

(function(z){

    z.ResellerForm = z.FormView.extend({
        el:"#resellerform-modal",

        events: function() {

            return _.extend({}, z.FormView.prototype.events,{
                "click #showroomform-save-button": "_onSubmitForm",
                "change #showroomform-county-field": "_onCountyChange",
                "click #showroomform-add-button": "_onCreateShowroom",
                "click .edit-showroom-form": "_onEditShowroomForm",
            });
        },
        
        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {

            z.FormView.prototype.initialize.apply(this, arguments);

            // Adding collections to view
            this.localitiesCollection = new z.LocalitiesCollection();
            this.showroomsCollection = new z.ShowroomsCollection();

            // Listening on collection events
            this.listenTo(this.localitiesCollection, 'add', this._onLocalitiesCollectionAdd);
            this.listenTo(this.localitiesCollection, 'reset', this._onLocalitiesCollectionReset);
            this.listenTo(this.showroomsCollection, 'add remove', this._onShowroomsAddRemove);
            this.listenTo(this.showroomsCollection, 'reset', this._onShowroomsCollectionReset);
            this.listenTo(this.showroomsCollection, 'change', this._onShowroomsChange);
        },

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function() {

            // Clean up the form
            this._cleanupForm();

            z.FormView.prototype.render.apply(this, arguments);

            // Initialize the chosen fields
            this._initializeChosen();

            return this;
        },

        /**
         * Initializing the chosen js library
         *
         * @private
         * @return {undefined}
         */
        _initializeChosen: function () {

            this.$('.chosen-select-locality').chosen({width: "100%"});
            this.$('.chosen-select-county').chosen({width: "100%"});
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addReseller';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editReseller';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_reseller');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_reseller');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#resellerform-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#resellerform-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'resellers';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'reseller.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_resellerform');
        },

        /**
         * Set custom labels for the reseller modal form
         *
         * @param {string} action - action name
         * @return {undefined}
         */
        setCustomLabels: function(action) {

            // todo uncomment when API Details will be implemented
            switch (action) {
                case 'add':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
                    break;
                case 'edit':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{brandName: *, companyName: *, phoneNumber: *, apiUser: *, apiKey: *}}
         */
        getFormData: function() {

            // Get the fields
            var $brandNameField     = this.$('#resellerform-brandName-field');
            var $companyNameField   = this.$('#resellerform-companyName-field');
            var $phoneNumberField   = this.$('#resellerform-phoneNumber-field');
            var $apiUserField       = this.$('#resellerform-apiUser-field');
            var $apiKeyField        = this.$('#resellerform-apiKey-field');
            
            return {
                'brandName'     : $brandNameField.val(),
                'companyName'   : $companyNameField.val(),
                'phoneNumber'   : $phoneNumberField.val(),
                'apiUser'       : $apiUserField.val(),
                'apiKey'        : $apiKeyField.val()
            };
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         *
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.reseller_create_error_message_mobile');

            // Display mobile alert
            this.$el.find('#reseller-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         *
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#reseller-create-general-error-panel').addClass('hidden');
        },

        /**
         * Method that resets the form fields, resets the collections and
         * hides the tables holding data
         *
         * @private
         * @return {undefined}
         */
        _cleanupForm: function() {

            var $showroomWrapperName = this.$('.showroom-list');

            // Emptying collections
            this.showroomsCollection.reset(null);
            this.localitiesCollection.reset(null);

            // Resetting form
            this._resetFields("showroomform");
            this._resetFields("resellerform");

            // Hiding all extra elements
            this._hideWrapper($showroomWrapperName);

            // Hiding all extra divs
            this._hideShowroomValidationError();

            // Seting the Add button
            var addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#showroomform-add-button').html(addingButtonTitle);
            this.showroomEditId = undefined;

            this.$('.chosen-select-locality').trigger("chosen:updated");
            this.$('.chosen-select-county').trigger("chosen:updated");
        },

        /**
         * Method that hides the wrapper that holds the table
         *
         * @param {Object} $wrapperName - the div object that acts as a wrapper
         * @private
         * @return {undefined}
         */
        _hideWrapper: function($wrapperName) {

            $wrapperName.addClass("hidden");
        },

        /**
         * Method used to hide the showroom validation error div
         *
         * @private
         * @return {undefined}
         */
        _hideShowroomValidationError: function()
        {
            this.$('.showroom-validation-error').addClass('hidden');
        },

        /**
         * Method that resets the specified fields according to id passed
         *
         * @param {string} value Name of the id to search for
         * @private
         * @return {undefined}
         */
        _resetFields: function (value) {

            this.$('[id^=' + value + ']').val("");
        },

        /**
         * Method that modifies the generate button title
         *
         * @param {string} text - name of the action that is being performed
         * @private
         * @return {undefined}
         */
        _modifyModalGenerateButton: function(text) {

            var $modalGenerateButton = this.$('#resellerform-generate-button');
            $modalGenerateButton.text(text);
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            this._fillShowroomForm(this.model);

            var map = {
                "resellerform-brandName-field": "brandName",
                "resellerform-companyName-field": "companyName",
                "resellerform-phoneNumber-field": "phoneNumber",
                "resellerform-apiUser-field": "apiUser",
                "resellerform-apiKey-field": "apiKey"
            };

            Object.keys(map).forEach(function(key){

                this.$el.find("#"+key).val(this.model.get(map[key]));

            }.bind(this));
        },

        /**
         * Method that fills in the tables with the showroom data
         *
         * @param {object} model - The reseller model
         * @private
         * @return {undefined}
         */
        _fillShowroomForm: function(model) {

            // Getting showrooms data from model
            var data = model.attributes.showrooms;

            if (!data) {
                return;
            }

            // Showing table
            var $wrapperName = this.$('.shorooms-list');
            this._displayWrapper($wrapperName);

            // Pushing data in collection
            Object.keys(data).forEach(function(key) {

                if (data[key].status === 1) {

                    var status = 1;
                    var statusName = Translator.trans("js.label_status_active");
                } else {

                    status = 0;
                    statusName = Translator.trans("js.label_status_inactive");
                }

                // Create the showroom model
                var showroom = {
                    name: data[key].name,
                    county: data[key].county.id,
                    county_name: data[key].county.nameLatin,
                    locality: data[key].locality.id,
                    locality_name: data[key].locality.nameLatin + " - " + data[key].locality.region3Latin ,
                    status: status,
                    status_name: statusName,
                    address: data[key].address,
                    email: data[key].email
                };

                // Add the model to the showrooms collection
                this.showroomsCollection.push(showroom);

            }.bind(this));
        },

        /**
         * Method that makes the request to get the data
         * for the localities select
         *
         * @private
         * @return {undefined}
         */
        _onCountyChange: function () {

            // Get the county id
            var id = this.$("#showroomform-county-field option:selected").val();

            this.localitiesCollection.setCountyId(id);
            this.localitiesCollection.reset(null);

            // Get the localities collection for the given county
            this.localitiesCollection.fetch({
                success: function(){
                    this.$('.chosen-select-locality').trigger("chosen:updated");
                    this.$("#showroomform-locality-field").trigger('load');
                }.bind(this)
            });
        },

        /**
         * Method triggered on the locality collection add
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionAdd: function(model) {

            this._renderLocality(model);
        },

        /**
         * Method triggered on the locality collection reset
         *
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionReset: function() {

            this._renderLocalityReset();
        },

        /**
         * Method that renders the locality select
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _renderLocality: function(model) {

            var $option = $('<option>').attr('value', model.get('id')).text(model.get('name'));
            this.$('#showroomform-locality-field').append($option);
        },

        /**
         * Method that resets the locality select
         *
         * @private
         * @return {undefined}
         */
        _renderLocalityReset: function() {

            var $option = this.$("#showroomform-locality-field option:eq(0)").detach();
            this.$('#showroomform-locality-field').empty().append($option);
        },

        /**
         * Method that creates the table that holds the list with all the
         * showroom the user has added
         *
         * @private
         * @return {undefined}
         */
        _onCreateShowroom: function () {

            // Populating data array
            var data = this._populateShowroomData();
            var record = new z.ShowroomModel(data);

            // Bind the showroom model for validation
            this._bindValidation(record);

            // Validate showroom model
            if(!record.isValid(true)){
                return;
            }

            this._bindValidation();

            // Verify if it's an add or edit action
            if (this.showroomEditId) {

                var showroom = this.showroomsCollection.get(this.showroomEditId);
                showroom.set(record.toJSON());

            } else {

                this.showroomsCollection.push(record);
            }

            var $addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#showroomform-add-button').html($addingButtonTitle);

            // Reset the edit flag
            this.showroomEditId = undefined;

            // Hide the showroom validation error
            this.$('.showroom-validation-error').addClass('hidden');

            //this._readonlyAndDisabled(false);

            // Resetting fields after value is being added in table
            this._resetFields("showroom");

            // Trigger chosen update fields
            this.$('.chosen-select-county').trigger("chosen:updated");
            this.$('.chosen-select-locality').trigger("chosen:updated");
        },

        /**
         * Method that populates the array with the data that is
         * added by the user in showroom form
         *
         * @private
         * @return {object} Object filled with model data
         */
        _populateShowroomData: function () {

            // Getting form data
            var data = this._getShowroomData();

            // Getting fields text values
            var county = this._getCountyValue();
            var locality = this._getLocalityValue();
            var status = this._getStatusValue();

            return {
                name: data.name,
                county: data.county,
                county_name: county,
                locality: data.locality,
                locality_name: locality,
                status: data.status,
                status_name: status,
                address: data.address,
                email: data.email
            };
        },

        /**
         * Method that gathers the data associated with the showroom form
         *
         * @private
         * @return {object} {{county, locality, address, status, email}} - Fields values
         */
        _getShowroomData: function() {

            // Get showroom data
            var $name = this.$('#showroomform-name-field');
            var $county = this.$('#showroomform-county-field');
            var $locality = this.$('#showroomform-locality-field');
            var $address = this.$('#showroomform-address-field');
            var $status = this.$('#showroomform-status-field');
            var $email = this.$('#showroomform-email-field');

            return {
                'name': $name.val(),
                'county': $county.val(),
                'locality': $locality.val(),
                'address': $address.val(),
                'status': $status.val(),
                'email': $email.val()
            };
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected county field option
         */
        _getCountyValue: function() {

            return this.$('#showroomform-county-field').find("option:selected").text();
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected locality field option
         */
        _getLocalityValue: function() {

            return this.$('#showroomform-locality-field').find("option:selected").text();
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected status field option
         */
        _getStatusValue: function() {

            return this.$('#showroomform-status-field').find("option:selected").text();
        },

        /**
         * Method that binds validation to the chosen model
         *
         * @param {object} model The model that the validation is being performend on
         * @private
         * @return {undefined}
         */
        _bindValidation: function(model) {

            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Method that listens to the add and remove events of the collection
         *
         * @param {object} model - The model
         * @param {object} collection - The collection
         * @param {object} event - The event
         * @private
         * @return {undefined}
         */
        _onShowroomsAddRemove: function(model, collection, event) {

            if (event.remove) {
                return;
            }

            this._renderRow(model);
        },

        /**
         * Method that is triggered on showrooms collection reset
         *
         * @private
         * @return {undefined}
         */
        _onShowroomsCollectionReset: function() {

            this._renderShowroomReset();
        },

        /**
         * Method that listens to the change event of the collection
         *
         * @param {object} model - The model
         * @private
         * @return {undefined}
         */
        _onShowroomsChange: function(model) {

            this._renderRow(model);
        },

        /**
         * Method that deletes the table rows on showroom collection reset
         *
         * @private
         * @return {undefined}
         */
        _renderShowroomReset: function() {

            this.$("#showroomform-list-field").find("tbody tr").remove();
        },

        /**
         * Method that creates a button to be used in the table
         *
         * @param {string} name - Button name
         * @param {string} label - String that will be translated
         * @private
         * @return {string} - The html for the button created
         */
        _createEditButton: function(name, label) {

            return "<span class='"+ name +"-form btn btn-primary'>" + label + "</span>";
        },

        /**
         * Method that shows the wrapper that holds the table
         *
         * @param {Object} $wrapper - Name of the wrapper o show
         * @private
         * @return {undefined}
         */
        _displayWrapper: function($wrapper) {

            if ($wrapper.hasClass("hidden")) {

                $wrapper.removeClass("hidden");
            }
        },

        /**
         * Method that renders the table row
         *
         * @param {object} model - The model used
         * @private
         * @return {undefined}
         */
        _renderRow: function(model) {

            // Get model id
            var id = model.get('id') ? model.get('id') : model.cid;

            // General elements
            var $tableName = this.$('#showroomform-list-field');
            var $wrapperName = this.$('.showroom-list');
            var $row = $tableName.find('tbody tr[data-id="' + id + '"]');
            var isEdit = !!$row.length;
            var email = '-';

            if (typeof model.get('email') !== 'undefined' && model.get('email').length) {

                email = model.get('email');
            }

            if (!isEdit) {

                // Add a new row

                var label = Translator.trans('js.btn_edit');

                // Creating dom elements
                var $editButton = this._createEditButton('edit-showroom', label);

                // Displaying table wrapper
                this._displayWrapper($wrapperName);

                $row = $('<tr>')
                    .attr({
                        "data-county-id": model.get('county'),
                        "data-locality-id": model.get('locality'),
                        "data-status-value": model.get('showroomsStatus'),
                        "data-email-value": model.get('email'),
                        "data-id": id
                    });

                // Showroom
                $('<td>')
                    .text(model.get('name'))
                    .appendTo($row);

                // Address
                $('<td>')
                    .text(model.get('county_name') + ", " + model.get('locality_name') + ", " + model.get('address'))
                    .appendTo($row);

                // Email
                $('<td>')
                    .text(email)
                    .appendTo($row);

                // Status
                $('<td>')
                    .text(model.get('status_name'))
                    .appendTo($row);

                // Edit button
                $('<td>')
                    .append($editButton)
                    .appendTo($row);

                $tableName.find("tbody").append($row);

            } else {

                // Edit an existing row

                $row.find('td:eq(0)').text(model.get('name'));
                $row.find('td:eq(1)').text(model.get('county_name') + ", " + model.get('locality_name') + ", " + model.get('address'));
                $row.find('td:eq(2)').text(email);
                $row.find('td:eq(3)').text(model.get('status_name'));
                $row.attr({
                    "data-county-id": model.get('county'),
                    "data-locality-id": model.get('locality'),
                    "data-status-value": model.get('status'),
                    "data-email-value": model.get('email'),
                    "data-id": id
                });
            }
        },

        /**
         * Method that fills in the values for the fields that are being edited
         *
         * @param {object} e The element on which the click is being made
         * @private
         * @return {undefined}
         */
        _onEditShowroomForm: function (e) {

            // Change the button from add to edit
            var $editingButonTitle = Translator.trans('js.btn_edit');
            this.$('#showroomform-add-button').html($editingButonTitle);

            // Get showroom id
            var id = $(e.target).parents("tr").data("id");
            var showroom = this.showroomsCollection.get(id);
            this.showroomEditId = id;

            // Load the locality field once
            this.$('#showroomform-locality-field').one('load', function() {

                // Prefill the field
                this.$('#showroomform-locality-field').val(showroom.get('locality'));
                this.$('.chosen-select-locality').trigger("chosen:updated");

            }.bind(this));

            // Prefill the fields
            this.$('#showroomform-name-field').val(showroom.get('name'));
            this.$('#showroomform-county-field').val(showroom.get('county')).trigger("change");
            this.$('#showroomform-address-field').val(showroom.get('address'));
            this.$('#showroomform-status-field').val(showroom.get('status'));
            this.$('#showroomform-email-field').val(showroom.get('email'));

            // Refresh the chosen library fields
            this.$('.chosen-select-county').trigger("chosen:updated");

        },

        /**
         * Verify if there is at least one item in the showroom collection
         *
         * @private
         * @returns {boolean} - If the collection is being populated
         */
        _isValidShowrooms: function() {

            var errorValidationShowroom = Translator.trans('js.error_validation_showroom');
            this.$('.showroom-validation-error').removeClass('hidden').text(errorValidationShowroom);

            return this.showroomsCollection.size() > 0;
        },

        /**
         * Method that sets the response in a specific form before saving it to the database.
         * It adds the showrooms collection to the reseller model.
         *
         * @param {Object} model - The vendor model
         * @private
         * @return {undefined}
         */
        _addShowroomsModel: function(model) {

            var showrooms = [];

            // Unsetting data from the showrooms collection
            this.showroomsCollection.each( function(showroom) {

                var data = showroom.toJSON();

                data.locality = showroom.get('locality');

                showrooms.push(data);
            });

            // Setting extra fields showrooms on model
            model.set("showrooms", showrooms);
        },

        /**
         * Method that handles the form submission
         *
         * @param {Event} e - the event
         * @return {undefined}
         * @private
         */
        _onSubmitForm: function (e) {

            e.preventDefault();

            // Get form data
            var formData = this.getFormData();
            this.model.set(formData);


            var hasShowroom = this._isValidShowrooms();
            var isValid = this.model.isValid(true);

            // Validate if there is at leas one showroom
            if (hasShowroom) {
                this.$('.showroom-validation-error').addClass('hidden');
            }

            // Verify if the reseller model is valid
            if( !isValid ){
                this.showMobileAlert();
                return;
            }

            this._isLoading(true, this.getSubmitButtonSelector());
            this._addShowroomsModel(this.model);

            // Save the reseller
            this.model.save(null, {

                success: function (i) {

                    this.model.trigger("success", i);
                }.bind(this)
            });
        },
    });

})(window.z = window.z || {});
