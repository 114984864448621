"use strict";

(function(z, Backbone){

    var ShowroomModel = Backbone.Model.extend({
        urlRoot: "/api/showrooms",
        validation: {
            county: [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_county_required")
            }],
            locality: [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_locality_required")
            }],
            address: [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_address_required")
            }, {
                minLength: 4,
                msg: Translator.trans("js.error_message_showroomform_address_minlength")
            }, {
                maxLength: 255,
                msg: Translator.trans("js.error_message_showroomform_address_maxlength")
            }],
            status: [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_status_required")
            }],
            email: [{
                required: false,
                msg: Translator.trans("js.error_message_showroomform_email_required")
            }, {
                pattern: 'email',
                msg: Translator.trans("js.error_message_showroomform_email_invalid")
            }],
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_showroomform_name_required")
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            if( !_.isUndefined(response.data) ){

                return response.data;
            }

            return response;
        }
    });

    z.ShowroomModel = ShowroomModel;

})(window.z = window.z || {}, Backbone);
