"use strict";

(function(z, Backbone, _){

    var OrderCommentModel = Backbone.Model.extend({

        /**
         * The url for this model
         * @returns {string} The url
         */
        urlRoot: function(){
            return '/api/orders/' + this.get('order') + '/comments';
        },
        validation: {
            comment: [{
                required: true,
                msg: Translator.trans("js.error_message_orderform_comment_required")
            }, {
                minLength: 1,
                msg: Translator.trans("js.error_message_orderform_comment_minlength")
            }, {
                maxLength: 250,
                msg: Translator.trans("js.error_message_orderform_comment_maxlength")
            }]
        },
        parse: function (response) {

            if( !_.isUndefined(response.data) ){

                return response.data;
            }

            return response;
        }
    });

    z.OrderCommentModel = OrderCommentModel;

})(window.z = window.z || {}, Backbone, _);