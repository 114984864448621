'use strict';

(function(app, $, Backbone){

    var DatepickerView = Backbone.View.extend({
        

        /**
         * Constructor (initialize the datepicker elements)
         *
         * @return {void}
         */
        initialize: function() {
            var $datepickerElement = $('.date');

            if ($datepickerElement) {
                $datepickerElement.datepicker({
                    todayHighlight: true,
                    format: 'dd-mm-yyyy',
                    autoclose: true
                });
            }
        }
    });

    app.datepickerView = new DatepickerView();

})(window.z = window.z || {}, $, Backbone);
