"use strict";

(function(z, Backbone){

    var ServiceParamModel = Backbone.Model.extend({

        /**
         * Validation
         */
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceconfig_name_required")
            },{
                minLength: 1,
                msg: Translator.trans("js.error_message_serviceconfig_name_minlength")
            },{
                maxLength: 80,
                msg: Translator.trans("js.error_message_serviceconfig_name_maxlength")
            }, {
                fn: function(value){

                    if (!value) {

                        return;
                    }

                    var view = this.associatedViews[0];
                    var collection = view.collection;

                    var exists = false;

                    var model = collection.findWhere({ name: value });

                    var firstId = model ? model.cid : null;
                    var secondId = view.model.cid;

                    if (model && firstId !== secondId) {

                        exists = true;
                    }

                    if (exists) {

                        return Translator.trans("js.error_message_serviceconfig_param_exists");
                    }
                }
            }],
            type: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceconfig_type_required")
            }],
            overridesBasePrice: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceconfig_overridesBasePrice_required")
            }],
            increaseType: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceconfig_increaseType_required")
            }],
            options: [{
                fn: function(){

                    var collection = z.serviceOptionsForm.collection;

                    if (!collection.length) {

                        return Translator.trans("js.error_message_serviceconfig_param_options_empty");
                    }
                }
            }]
        },

        /**
         * Parse the response
         * @param {object} response The response
         * @param {object} options The options
         * @return {*} The response data
         */
        parse: function (response, options) {

            if (options.collection) {

                return response;
            }

            return response.data;
        }
    });

    z.ServiceParamModel = ServiceParamModel;

})(window.z = window.z || {}, Backbone);
