"use strict";

(function(z, Backbone){

    var CategoryModel = Backbone.Model.extend({
        urlRoot: "/api/categories",
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_categoriesform_name_required")
            },{
                minLength: 2,
                msg: Translator.trans("js.error_message_categoriesform_name_minlength")
            },{
                maxLength: 80,
                msg: Translator.trans("js.error_message_categoriesform_name_maxlength")
            }],
            parent: [{
                required: false,
                msg: Translator.trans("js.error_message_categoriesform_parent_required")
            }]
        },

        parse: function (response) {

            if( !_.isUndefined(response.data) ){
                return response.data;
            }
            return response;
        }
    });

    z.CategoryModel = CategoryModel;

})(window.z = window.z || {}, Backbone);
