'use strict';

(function(z, _) {

    /**
     * Tariff association grid
     */
    z.TariffResellerAdminGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#tariffReseller-page",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click #add-tariffResellerAdmin-modal-btn": "onAddEntry",
                "click .tariffReseller-edit-button": "onEditEntry",
                "change #tariffReseller-service-field" : 'onChangeServiceFilter',
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        onChangeServiceFilter: function(){
            $('#tariffReseller-service-field').addClass(' chosen-select ');
            var serviceId = $("#tariffReseller-service-field").val();
            var $holder = $("#tariffReseller-service-params-container");
            $holder.empty();

            if (!serviceId) {
                return;
            }
            var url =  '/tariff-reseller/create/filter/service/{service}';
            url = url.replace('{service}', serviceId);
            var opts = {
                method      : 'GET',
                dataType    : 'html',
                contentType : 'text/plain'
            };

            z.request.make(url, null, function(jqXhr, status){

                if (status !== 'success') {
                    return;
                }

                $holder.append(jqXhr.responseText);
            }.bind(this), opts);
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#tariffReseller-service-field").chosen({
                search_contains: true
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-tariffReseller-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {

            return '/tariff-reseller-admin';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'tariffReseller-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_tariffReseller_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'tariffReseller.success';
        },

    });

})(window.z = window.z || {}, _);
