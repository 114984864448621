"use strict";

(function(z, Backbone, _){

    z.OrderReallocationForm = Backbone.View.extend({

        // Setting the element to be the order page so that we can update the info warning
        // panel when the order is being reallocated
        el:"#order-page",

        events: {
            'click #reallocation-order-modal-btn': '_displayReallocationModalForm',
            'click #orderreallocatingform-modal .close-link' : '_hideReallocationModalForm',
            'submit .modal-form#order-reallocation-form': '_onSubmitForm',
            'change #orderreallocationform-sort-field': '_onSortChange',
        },

        /**
         * Constants used to fill the sort field
         */
        SORT_BY_AVAILABILITY: 1,
        SORT_BY_DISTANCE: 2,

        /**
         * Constructor.
         * @return {undefined}
         */
        initialize: function(){

            this.modalElement = this.$('#orderreallocatingform-modal');
            this.$errorMsg = this.$el.find('#orderreallocationform-error-panel');

            if (typeof z.boot.order.worksite != "undefined")
            {
                var orderVendor = z.boot.order.worksite.id;

                $("#orderreallocationform-vendor-field option[value='"+orderVendor+"']").remove();
            }


            this.orderVendorsCollection = new z.VendorsCollection();

            Backbone.Validation.bind(this, {
                // valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });

            this._bindChosen();

            this.setEvents();
        },

        /**
         * Binds the chosen to the select fields
         * @return {undefined}
         * @private
         */
        _bindChosen: function(){
            this._getVendorFieldEl().chosen({ width: '100%' });
            this._getReasonTypeFieldEl().chosen({ width: '100%' });
        },

        /**
         * Method used for hiding the reallocation order button
         * @private
         * @return {undefined}
         */
        _hideReallocationButton: function() {

            this.$el.find('#reallocation-order-modal-btn').addClass('hidden');
        },

        /**
         * Method used for showing the reallocation order button
         * @private
         * @return {undefined}
         */
        _showReallocationButton: function() {

            this.$el.find('#reallocation-order-modal-btn').removeClass('hidden');
        },

        /**
         * Setting events when the modal is being loaded
         * @return {undefined}
         */
        setEvents: function()
        {

            // On success
            this.listenTo(this.model, 'success', function(res) {
                var successMessage = Translator.trans('js.order_reallocation_success_message');

                // Show success message, stop loader, hide modal
                this._hideReallocationModalForm();
                this._isLoading(false, this._getSubmitButtonSelector());

                // Changing fields values
                this._changeVendorFieldsValues(res.get('vendor'));
                this._updateDateFields(this.model);

                // Showing success message
                this._showSuccess(successMessage);

                // Updating order history section
                $(document).trigger("comments.updateComments");
                setTimeout(function () {
                    location.reload();
                },1500);
            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(model, res) {
                // Show error message and stop button loader
                this._isLoading(false, this._getSubmitButtonSelector());

                // Showing error messages
                this._displayError(res);

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Hide the reallocation button if the order was canceled
                this._hideReallocationButton();

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.finalized", function() {

                // Hide the reallocation button if the order was finalized
                this._hideReallocationButton();

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.appointed", function() {

                // Hide the reallocation button if the order was finalized
                this._showReallocationButton();

            }.bind(this));

            // On order appointed, reallocation the button
            $(document).on("order.appointed", function() {
                this._showReallocationButton();

            }.bind(this));

            // Display the reallocation order button if the order was successfully scheduled afterward
            this.listenTo(z.orderReallocationForm, 'orderReallocationSuccess', this._showReallocationButton);

            this._onOrderProgressSync(z.orderProgress.model);
            this.listenTo(z.orderProgress.model, 'sync', this._onOrderProgressSync);
        },

        /**
         * Method that updates the vendor name fields in the order
         * @param {object} vendorName the name of the vendor
         * @returns {undefined}
         */
        _changeVendorFieldsValues: function(vendorName)
        {
            this.$('#associated-vendor').text(vendorName['companyName']);
            // this.$('#vendor-company-name').text(vendorName);
        },

        /**
         * Method used for field update
         * @param {Object} model the view's model
         * @returns {undefined}
         * @private
         */
        _updateDateFields: function(model) {
            var newDate = this._renderReallocationDate(model.get('order').appointmentDate);

            // this.$('#order-appointment-date').html(newDate);
            // this.$('#order-processing-scheduled-date').html(newDate);
        },

        /**
         * Renders a date as string
         * @param {string} value The string representation of the date
         * @return {string} The formatted date
         * @private
         */
        _renderReallocationDate: function(value){
            return moment(value).locale('ro').format('DD-MM-Y');
        },

        /**
         * Methid that returns the selector for the save button
         * @returns {*} string - the selector for the save button
         * @private
         */
        _getSubmitButtonSelector: function(){
            return this.$('#orderreallocationform-save-button');
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after the order progress is being synced
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _onOrderProgressSync: function(model){

            // console.log(model);
            if (!model.get('progress') && model.isOpen()) {
                this._showReallocationButton();

                return;
            }

            this._hideReallocationButton();
        },


        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        _showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.order_reallocation_error_message_mobile');

            this.$el.find('#order-reallocation-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this._hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the general error message shown if the form is not valid
         * @return {undefined}
         */
        _hideMobileAlert: function()
        {
            this.$el.find('#order-reallocation-general-error-panel').addClass('hidden');
        },

        /**
         * Retrieves the sort field element
         * @returns {object} The element
         * @private
         */
        _getSortFieldEl: function(){
            return this.$('#orderreallocationform-sort-field');
        },

        /**
         * Retrieves the sort group element
         * @returns {object} The element
         * @private
         */
        _getSortGroupEl: function(){
            return this._getSortFieldEl().parents('.form-group:first');
        },

        /**
         * Retrieves the vendor field element
         * @returns {object} The element
         * @private
         */
        _getVendorFieldEl: function(){
            return this.$('#orderreallocationform-vendor-field');
        },

        /**
         * Retrieves the reason type field element
         * @returns {object} The element
         * @private
         */
        _getReasonTypeFieldEl: function(){
            return this.$('#orderreallocationform-type-field');
        },

        /**
         * Retrieves the vendor group element
         * @returns {object} The element
         * @private
         */
        _getVendorGroupEl: function(){
            return this._getVendorFieldEl().parents('.form-group:first');
        },


        /**
         * Method that displays the reallocation order modal
         * @return {undefined}
         * @private
         */
        _displayReallocationModalForm: function() {

            var id = this._getOrderId();
            // this._resetForm();

            // Displaying the modal
            this.modalElement.modal("show");

            // Setting the order id on the model
            this.model.setOrderId(id);


        },

        /**
         * Method that hides the modal window.
         * @return {undefined}
         * @private
         */
        _hideReallocationModalForm: function() {

            this.modalElement.modal("hide");

            // this._resetForm();

        },

        /**
         * Method that resets the form
         * @return {undefined}
         * @private
         */
        _resetForm: function() {

            this.$('#order-reallocation-form').get(0).reset();
            this.$el.find('#orderreallocationform-type-field').trigger('change');

            // this._toggleSortEl();
            // this._toggleVendorEl();

            this.dataValidation();

            this._clearError();

            this._hideMobileAlert();
        },


        /**
         * Collecting data from form inputs.
         * @return {Object} {{brandName: *, companyName: *, address: *, phoneNumber: *, status: *, apiUser: *, apiKey: *}}
         * @private
         */
        _getFormData: function() {
            var $typeField = this._getReasonTypeFieldEl();
            var $newVendor = this.$('#orderreallocationform-vendor-field');

            if ($newVendor.val() === '')
            {
                this._resetForm();
                this._showError(Translator.trans('js.error_message_no_vendor_reallocation'));
                return;
            }

            return {
                'type': $typeField.val(),
                'newVendor' : $newVendor.val()

            };
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $panel = this.$('#orderreallocatingform-modal .modal-content');

            $panel.toggleClass('sk-loading', loading);

            if (!buttonSelector) {

                return;
            }

            var $button = this.$(buttonSelector);
            var ladda = $button.data('ladda') ? $button.data('ladda') : $button.ladda().data('ladda');

            if( loading ){

                ladda.start();

            } else {

                ladda.stop();
            }
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showSuccess: function(message){
            var $success = this.$('#order-reallocation-success-message');

            $success
                .removeClass('hidden')
                .html(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that shows the error message
         * @param {string} message the error message
         * @return {undefined}
         * @private
         */
        _showError: function(message) {

            var $error = this.$('#orderreallocationform-error-panel');

            $error
                .text(message)
                .removeClass('hidden')
                .slideUp(0)
                .slideDown()
                .delay(5000);
        },

        /**
         * Clears the error from the user interface
         * @return {undefined}
         * @private
         */
        _clearError: function(){

            this._hideMobileAlert();

            var $error = this.$('#orderreallocationform-error-panel');

            $error
                .finish()
                .slideUp(0)
                .addClass('hidden');
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         * @private
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var formData = this._getFormData();

            this.model.clear();

            this.model.set(formData);
            //
            // if( !this.model.isValid(true) ){
            //     this._showMobileAlert();
            //     return;
            // }

            this._isLoading(true, this._getSubmitButtonSelector());

            this.model.save(null,{
                success: function(i) {
                    this.model.trigger("success", i);
                    this.trigger('orderReallocationSuccess');
                }.bind(this)
            });

        },

        /**
         * Toggle sort element
         * @param {boolean} [show] Show or hide the element
         * @return {undefined}
         * @private
         */
        _toggleSortEl: function(show) {

            this._getSortGroupEl().toggleClass('hidden', !show);
        },

        /**
         * Toggle vendor element
         * @param {boolean} [show] Show or hide the element
         * @return {undefined}
         * @private
         */
        _toggleVendorEl: function(show){

            this._getVendorGroupEl().toggleClass('hidden', !show);
        },

        /**
         * Method called when the sort dropdown gets changed
         * @return {undefined}
         * @private
         */
        _onSortChange: function(){

            var isChecked = this.$('#orderreallocationform-strict-field').is(':checked');
            var sort = this._getSortFieldEl().val();
            var vendor = this._getVendorFieldEl().val();

            this._loadVendors();

        },

        /**
         * Retrieves the current order's id
         * @return {string} The order id
         * @private
         */
        _getOrderId: function(){

            return this.$('#order-reallocation-form').data('id'); //reschedule-order-form
        },

        /**
         * Retrieves the first error class from the supplied response
         * @param {Object} response The response
         * @return {*|string} The error class
         * @private
         */
        _getFirstErrorClass: function(response){

            if (!response.responseJSON){
                return;
            }

            if (!response.responseJSON.errors) {
                return;
            }

            if (!response.responseJSON.errors[0]) {
                return;
            }

            if (!response.responseJSON.errors[0].class) {
                return;
            }

            return response.responseJSON.errors[0].class;
        },

        /**
         * Method used for displaying the reallocation order button
         * @private
         * @return {undefined}
         */
        _displayReallocationButton: function() {

            var $reallocationButton = this._getReallocationButton();

            if (!z.security.isResellerAssociated()) {
                this.$("#reallocation-button-container").append($reallocationButton);
            }
        },

        /**
         * Method that constructs the html for the reallocation button
         * history button
         * @returns {string} The html for the reallocation button
         * @private
         */
        _getReallocationButton: function()
        {
            var buttonName = Translator.trans("js.reallocation_button");

            return "<div class='col-lg-2 pull-right'><a id='reallocation-order-modal-btn' class='btn btn-primary align-btn' data-style='slide-left'>" +
                buttonName + "</a></div>";
        },


        /**
         * Renders the options inside the chosen field based on the supplied collection
         * @param {object} $field The select field
         * @param {Backbone.Collection} collection The collection
         * @param {string|function} name How to select the name of the item
         * @return {undefined}
         * @private
         */
        // _renderChosenField: function($field, collection, name){
        //
        //     if (!collection) {
        //
        //         $field.trigger('chosen:updated');
        //
        //         return;
        //     }
        //
        //     var $placeholder = $field.find('option:first').detach();
        //
        //     var options = [$placeholder];
        //
        //     $field.empty();
        //
        //     collection.each(function(item){
        //
        //         var display;
        //
        //         if (_.isFunction(name)) {
        //             display = name.call(this, item);
        //         } else {
        //             display = item.get(name);
        //         }
        //
        //         var $option = $('<option>')
        //             .attr('value', item.get('id'))
        //             .text(display);
        //
        //         options.push($option);
        //     });
        //
        //     $field.append(options);
        //
        //     $field.trigger('chosen:updated');
        // },

        /**
         * Retrieves the list of vendors (syncs the vendors collection)
         * @param {Function} [callback] The callback
         * @return {undefined}
         * @private
         */
        _loadVendors: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            this.orderVendorsCollection.reset();

            if (!this._isVendorFieldVisible()) {

                return;
            }

            this.orderVendorsCollection.setAvailable(true);
            this.orderVendorsCollection.setServiceId(z.boot.order.service.id);
            this.orderVendorsCollection.setCountyId(z.boot.order.customer.county.id);

            var sort = this._getSortFieldEl().val();

            if (sort === this.SORT_BY_DISTANCE + '') {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_EXTENDED);
            } else {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_FIXED);
            }

            this.orderVendorsCollection.setQuantity(z.boot.order.quantity);

            this.orderVendorsCollection
                .fetch()
                .then(function(){

                    callback.apply(this, arguments);

                })
                .catch(function(){

                    callback.apply(this, arguments);
                });
        },

        /**
         * Checks if the vendor container field is visible
         * @return {boolean} Is visible
         * @private
         */
        _isVendorFieldVisible: function(){

            var $container = this._getVendorGroupEl();

            return !$container.is('.hidden');
        },

        _displayError: function(res){

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var message = _.first(errors).message;

                if (typeof message !== 'undefined') {

                    switch (message) {
                        case "no_tariff_vendor_defined":
                            this._showError(Translator.trans('js.no_tariff_vendor_defined'));
                            return;
                        case "no_tariff_reseller_defined":
                            this._showError(Translator.trans('js.no_tariff_reseller_defined'));
                            return;
                        default:
                            this._showError(Translator.trans('js.order_reallocation_error_message'));
                    }
                }
            }
        },
    });

})(window.z = window.z || {}, Backbone, _);
