'use strict';

(function(z, Backbone, Translator){

    z.ImportModel = Backbone.Model.extend({

        /**
         * Url
         */
        url: '/orders/import',

        /**
         * Model validation
         */
        validation: {
            'service': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_service_required')
            }],
            'template': {
                fn: function(val, attr, computed){

                    if (computed.isTemplateEmpty) {

                        return Translator.trans('js.error_message_importform_template_required');
                    }

                    if (computed.isTemplateInvalidType) {

                        return Translator.trans('js.error_message_importform_template_invalidtype');
                    }

                    if (computed.isTemplateInvalidType) {

                        return Translator.trans('js.error_message_importform_template_invalidsize');
                    }
                }
            }
        }
    });

})(window.z = window.z || {}, Backbone, Translator);