'use strict';
(function (z) {
    z.VendorSuggestionsGrid = z.GridView.extend({
        el: "#vendor-suggestions",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);
            let that = this;
            $('#processing-complete-btn').click(function(event) {
                that.onProcessOrdersComplete(event);
            });
            $('#processing-status').hide();
        },

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click .process-orders-allocation": "onProcessOrdersAllocation"
            });
        },

        _updateOrderStatus: async function (orderId, vendorId, worksiteId) {
            let record = {
                "orderId": orderId,
                "status": "6",
                "oldStatus" :  "1",
                "statusComment" : "",
                "vendorId" : vendorId,
                "worksiteId" : worksiteId
            };
            let response = await fetch("/api/orders/status", {
                "headers": {
                    "x-requested-with": "XMLHttpRequest"
                },
                "body": JSON.stringify(record),
                "method": "POST"
            });
            if (response.status === 201) {
                return true;
            } else {
                return false;
            }
        },

        onProcessOrdersComplete: function(event) {
            event.preventDefault();
            window.z.allocateOrdersGrid.getDatatable().draw();
            $('#processing_failed_orders_label').hide();
            $('#processing_failed_orders').hide();
            $('#processing-status').hide();
            $('#allocation-grids').show();
        },

        onProcessOrdersAllocation: async function(event) {
            event.preventDefault();

            let orders = [
                ...document.querySelectorAll('.page-orders-allocation-datatable_multiselect_checkbox')
            ].filter(el => el.checked === true).map(el => el.value);

            if (orders && orders.length > 0) {

                if(confirm(Translator.trans('js.confirm_allocate_orders'))) {
                    $('#allocation-grids').hide();
                    const vendorId = $(event.target).data('id');
                    const worksiteId = $(event.target).parent().parent().attr('id');
                    let success = 0;
                    let failed = 0;
                    let count = 0;
                    $('#total_count').html(orders.length);
                    $('#processing-status').show();
                    $('#processing-complete').hide();
                    for (const orderId of orders) {
                        count++;
                        $('#count').html(count);
                        let status = await this._updateOrderStatus(orderId, vendorId, worksiteId);
                        if (status) {
                            success++;
                        } else {
                            failed++;
                        }
                        $('#processing_success').html(success);
                        $('#processing_failed').html(failed);

                        if (failed > 0)
                        {
                            $('#processing_failed_orders_label').show();
                            $('#processing_failed_orders').show();
                            $('#processing_failed_orders').html((Translator.trans('js.no_tariff_vendor_defined_multiple')));
                        }
                    }
                    $('#processing-complete').show();
                }
            } else {
                alert(Translator.trans('js.label_no_orders_selected'));
            }
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-vendor-suggestions-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return `allocate-orders/county/${countyId}/service/${serviceId}`;
        },
    })

})(window.z = window.z || {});