"use strict";

(function(z, Backbone){

    var WorksiteServiceAssocsCollection = Backbone.Collection.extend({
        model: z.ServiceWorksiteAssocCapacityModel
    });

    z.WorksiteServiceAssocsCollection = WorksiteServiceAssocsCollection;

})(window.z = window.z || {}, Backbone);