"use strict";

(function(z, Backbone){

    var CapacitiesModel = Backbone.Model.extend({

        /**
         * Retrieves the URL for this model.
         * If the model contains a vendorId it means that has been edited by an admin,
         * otherwise it must have been edited by the vendor himself.
         * @returns {string} The url
         */
        url: function(){

            return this.has('vendorId') ? '/api/vendors/capacities/' + this.get('vendorId') : '/api/vendors/capacities';
        }
    });

    z.CapacitiesModel = CapacitiesModel;

})(window.z = window.z || {}, Backbone);
