'use strict';

(function(z, _) {

    /**
     * Price Rule grid
     */
    z.PriceRuleGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#page-wrapper",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click #add-price-rule-modal-btn": "onAddEntry",
                "click .pricerule-edit-button": "onEditEntry",
                "click .pricerule-delete-button": "onDeleteEntry"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);
            this.$(".chosen-select").chosen({
                search_contains: true
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-price-rule-datatable';
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'pricerule.success';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function () {
            return 'price-rules';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'pricerule-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_pricerule_form');
        },

        /**
         * Method that opens a confirm box when an entry is deleted
         *
         * @param {Event} event click event
         * @return {undefined}
         */
        onDeleteEntry: function(event) {
            event.preventDefault();

            this.entryId = $(event.currentTarget).attr('data-id');

            // Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.price_rule_delete_confirm_window_title'),
                text: Translator.trans('js.price_rule_delete_confirm_window_message'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.price_rule_delete_confirm_btn')
            }).then(function () {
                this._deleteEntry(this.entryId);
            }.bind(this)).catch(swal.noop);
        },

        /**
         * Delete the entry
         *
         * @param {int} id - entry id
         * @return {undefined}
         * @private
         */
        _deleteEntry: function(id) {

            var entryModel = new z.PriceRuleModel({ id: id });

            // Delete the model
            entryModel.destroy({

                // On success
                success: function() {

                    // On success, apply the filters
                    this.applyFilters();

                }.bind(this),

                // on error
                error: function() {

                    // todo

                }.bind(this)
            });
        }
    });

})(window.z = window.z || {}, _);