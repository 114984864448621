"use strict";

(function(z, Backbone, _){

    z.OrderRescheduleForm = Backbone.View.extend({

        // Setting the element to be the order page so that we can update the info warning
        // panel when the order is being rescheduled
        el:"#order-page",

        events: {
            'click #reschedule-order-modal-btn': '_displayRescheduleModalForm',
            'click #orderrescheduleform-modal .close-link' : '_hideRescheduleModalForm',
            'change #orderrescheduleform-type-field': '_onFieldChange',
            'submit .modal-form#order-reschedule-form': '_onSubmitForm',
            'ifChecked #orderrescheduleform-offers-table input[type="radio"]': '_onOfferCheck',
            'change #orderrescheduleform-strict-field': '_onStrictChange',
            'change #orderrescheduleform-sort-field': '_onSortChange',
            'change #orderrescheduleform-vendor-field': '_onVendorChange',
            'click #orderrescheduleform-refresh-offers': '_onRefreshOffers'
        },

        /**
         * The time in seconds after which the offers expire
         */
        OFFER_EXPIRE_TIME: 3 * 60,

        /**
         * Constants used to fill the sort field
         */
        SORT_BY_AVAILABILITY: 1,
        SORT_BY_DISTANCE: 2,

        /**
         * Constructor.
         * @return {undefined}
         */
        initialize: function(){
            this.modalElement = this.$('#orderrescheduleform-modal');
            this.$errorMsg = this.$el.find('#orderrescheduleform-error-panel');
            this.additionalCommentGroup = this.$el.find("#reschedule-order-form .additional-comment-group");

            this.orderVendorsCollection = new z.VendorsCollection();
            this.offersCollection = new z.OffersCollection();

            Backbone.Validation.bind(this, {
                valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });

            this._bindChosen();

            this.setEvents();
        },

        /**
         * Binds the chosen to the select fields
         * @return {undefined}
         * @private
         */
        _bindChosen: function(){
            this._getVendorFieldEl().chosen({ width: '100%' });
        },

        /**
         * Method used for hiding the reschedule order button
         * @private
         * @return {undefined}
         */
        _hideRescheduleButton: function() {

            this.$el.find('#reschedule-order-modal-btn').addClass('hidden');
        },

        /**
         * Method used for showing the reschedule order button
         * @private
         * @return {undefined}
         */
        _showRescheduleButton: function() {

            this.$el.find('#reschedule-order-modal-btn').removeClass('hidden');
        },

        /**
         * Setting events when the modal is being loaded
         * @return {undefined}
         */
        setEvents: function()
        {
            this.additionalCommentGroup.addClass('hidden');

            // On success
            this.listenTo(this.model, 'success', function(res) {
                var successMessage = Translator.trans('js.order_reschedule_success_message');

                // Show success message, stop loader, hide modal
                this._hideRescheduleModalForm();
                this._isLoading(false, this._getSubmitButtonSelector());

                // Changing fields values
                this._changeVendorFieldsValues(res.get('vendor').companyName);
                this._updateDateFields(this.model);

                // Showing success message
                this._showSuccess(successMessage);

                // Updating order history section
                $(document).trigger("comments.updateComments");
            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(model, res) {

                // Show error message and stop button loader
                this._isLoading(false, this._getSubmitButtonSelector());

                // Showing error messages
                this._displayError(res);

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Hide the reschedule button if the order was canceled
                this._hideRescheduleButton();

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.finalized", function() {

                // Hide the reschedule button if the order was finalized
                this._hideRescheduleButton();

            }.bind(this));

            // On order appointed, reschedule the button
            $(document).on("order.appointed", function() {

                this._showRescheduleButton();

            }.bind(this));

            // Display the reschedule order button if the order was successfully scheduled afterward
            // this.listenTo(z.orderScheduleForm, 'orderScheduleSuccess', this._displayRescheduleButton);
            this.listenTo(z.orderScheduleForm, 'orderScheduleSuccess', this._showRescheduleButton);

            this.listenTo(this.orderVendorsCollection, 'sync', this._onOrderVendorsCollectionSync);
            this.listenTo(this.orderVendorsCollection, 'reset', this._onOrderVendorsCollectionReset);

            this.listenTo(this.offersCollection, 'request',   this._onOffersCollectionRequest);
            this.listenTo(this.offersCollection, 'sync',    this._onOffersCollectionSync);
            this.listenTo(this.offersCollection, 'reset',   this._onOffersCollectionReset);
            this.listenTo(this.offersCollection, 'update',  this._onOffersCollectionUpdate);
            this.listenTo(this.offersCollection, 'error',   this._onOffersCollectionError);

            this.listenTo(z.orderProgress.model, 'sync', this._onOrderProgressSync);
        },

        /**
         * Method that updates the vendor name fields in the order
         * @param {object} vendorName the name of the vendor
         * @returns {undefined}
         */
        _changeVendorFieldsValues: function(vendorName)
        {
            this.$('#associated-vendor').text(vendorName);
            this.$('#vendor-company-name').text(vendorName);
        },

        /**
         * Method used for field update
         * @param {Object} model the view's model
         * @returns {undefined}
         * @private
         */
        _updateDateFields: function(model) {
            var newDate = this._renderRescheduleDate(model.get('order').appointmentDate);

            this.$('#order-appointment-date').html(newDate);
            this.$('#order-processing-scheduled-date').html(newDate);
        },

        /**
         * Renders a date as string
         * @param {string} value The string representation of the date
         * @return {string} The formatted date
         * @private
         */
        _renderRescheduleDate: function(value){
            return moment(value).locale('ro').format('DD-MM-Y');
        },

        /**
         * Methid that returns the selector for the save button
         * @returns {*} string - the selector for the save button
         * @private
         */
        _getSubmitButtonSelector: function(){
            return this.$('#orderrescheduleform-save-button');
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after the order progress is being synced
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _onOrderProgressSync: function(model){

            if (!model.get('progress') && model.isOpen()) {

                this._showRescheduleButton();

                return;
            }

            this._hideRescheduleButton();
        },

        /**
         * Method called after the order's vendors collection is synced
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionSync: function(){
            this._renderOrderVendors();
        },

        /**
         * Method called after the order's vendors collection is reset
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionReset: function(){
            this._renderOrderVendors();
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        _showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.order_reschedule_error_message_mobile');

            this.$el.find('#order-reschedule-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this._hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the general error message shown if the form is not valid
         * @return {undefined}
         */
        _hideMobileAlert: function()
        {
            this.$el.find('#order-reschedule-general-error-panel').addClass('hidden');
        },

        /**
         * Retrieves the sort field element
         * @returns {object} The element
         * @private
         */
        _getSortFieldEl: function(){
            return this.$('#orderrescheduleform-sort-field');
        },

        /**
         * Retrieves the sort group element
         * @returns {object} The element
         * @private
         */
        _getSortGroupEl: function(){
            return this._getSortFieldEl().parents('.form-group:first');
        },

        /**
         * Retrieves the vendor field element
         * @returns {object} The element
         * @private
         */
        _getVendorFieldEl: function(){
            return this.$('#orderrescheduleform-vendor-field');
        },

        /**
         * Retrieves the vendor group element
         * @returns {object} The element
         * @private
         */
        _getVendorGroupEl: function(){
            return this._getVendorFieldEl().parents('.form-group:first');
        },

        /**
         * Retrieves the offers table element
         * @returns {object} The element
         * @private
         */
        _getOffersTableEl: function(){
            return this.$('#orderrescheduleform-offers-table');
        },

        /**
         * Retrieves the offers table row elements
         * @returns {object} The element
         * @private
         */
        _getOffersTableRowEls: function(){
            return this._getOffersTableEl().find('tr');
        },

        /**
         * Retrieves the offers empty element
         * @returns {object} The element
         * @private
         */
        _getOffersEmptyEl: function(){
            return this.$('#orderrescheduleform-offers-empty');
        },

        /**
         * Retrieves the offer field el
         * @returns {object} The element
         * @private
         */
        _getOfferFieldEl: function(){
            return this.$('#orderrescheduleform-offer-field');
        },

        /**
         * Retrieves the offers table body element
         * @returns {object} The element
         * @private
         */
        _getOffersTableBodyEl: function(){
            return this._getOffersTableEl().find('tbody');
        },

        /**
         * Method that displays the reschedule order modal
         * @return {undefined}
         * @private
         */
        _displayRescheduleModalForm: function() {

            var id = this._getOrderId();

            this._resetForm();

            // Displaying the modal
            this.modalElement.modal("show");

            // Setting the order id on the model
            this.model.setOrderId(id);

            // Fetching collection data
            this._loadOffers();
        },

        /**
         * Method that hides the modal window.
         * @return {undefined}
         * @private
         */
        _hideRescheduleModalForm: function() {

            var $table = this._getOffersTableEl();

            this.modalElement.modal("hide");

            this._resetForm();

            $table.addClass('hidden');

            this.offersCollection.reset();

        },

        /**
         * Method that resets the form
         * @return {undefined}
         * @private
         */
        _resetForm: function() {

            this.$('#order-reschedule-form').get(0).reset();
            this.$el.find('#orderrescheduleform-type-field').trigger('change');

            this._toggleSortEl();
            this._toggleVendorEl();

            this.dataValidation();

            this._clearError();

            this._hideMobileAlert();
        },

        /**
         * If the third option is selected, the second field is being shown
         * @param {Object} element The element that is selected
         * @return {undefined}
         * @private
         */
        _onFieldChange: function(element) {
            if (element.target.value === "3") {
                this.additionalCommentGroup.removeClass('hidden');
                this.additionalCommentGroup.find("label[for='orderrescheduleform-additional-comment-field']").addClass('required');
                this.additionalCommentGroup.find("input").prop('required', true);
            } else {
                this.additionalCommentGroup.addClass("hidden");
            }
        },

        /**
         * Collecting data from form inputs.
         * @return {Object} {{brandName: *, companyName: *, address: *, phoneNumber: *, status: *, apiUser: *, apiKey: *}}
         * @private
         */
        _getFormData: function() {
            var $typeField              = this.$('#orderrescheduleform-type-field');
            var $additionalCommentField = this.$('#orderrescheduleform-additional_comment-field');
            var $strictField            = this.$('#orderrescheduleform-strict-field');

            return {
                'type'              : $typeField.val(),
                'additionalComment' : $additionalCommentField.val(),
                'strict'            : !$strictField.is(':checked'),
                'offer'             : this._getOfferRecord().id
            };
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $panel = this.$('#orderrescheduleform-modal .modal-content');

            $panel.toggleClass('sk-loading', loading);

            if (!buttonSelector) {

                return;
            }

            var $button = this.$(buttonSelector);
            var ladda = $button.data('ladda') ? $button.data('ladda') : $button.ladda().data('ladda');

            if( loading ){

                ladda.start();

            } else {

                ladda.stop();
            }
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showSuccess: function(message){
            var $success = this.$('#order-reschedule-success-message');

            $success
                .removeClass('hidden')
                .html(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that shows the error message
         * @param {string} message the error message
         * @return {undefined}
         * @private
         */
        _showError: function(message) {

            var $error = this.$('#orderrescheduleform-error-panel');

            $error
                .text(message)
                .removeClass('hidden')
                .slideUp(0)
                .slideDown()
                .delay(5000)
                .slideUp(function(){

                    $error.addClass('hidden');
                });
        },

        /**
         * Clears the error from the user interface
         * @return {undefined}
         * @private
         */
        _clearError: function(){

            this._hideMobileAlert();

            var $error = this.$('#orderrescheduleform-error-panel');

            $error
                .finish()
                .slideUp(0)
                .addClass('hidden');
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         * @private
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var formData = this._getFormData();

            this.model.clear();

            this.model.set(formData);

            if( !this.model.isValid(true) ){
                this._showMobileAlert();
                return;
            }

            this._isLoading(true, this._getSubmitButtonSelector());

            this.model.save(null,{
                success: function(i) {
                    this._clearOfferTimer();
                    this.model.trigger("success", i);
                    this.trigger('orderRescheduleSuccess');
                }.bind(this)
            });
        },

        /**
         * Refreshes the order offers
         * @return {undefined}
         * @private
         */
        _onRefreshOffers: function(){

            var isChecked = this.$('#orderrescheduleform-strict-field').is(':checked');
            var sort = this._getSortFieldEl().val();
            var vendor = this._getVendorFieldEl().val();

            this._loadOffers(!isChecked, sort, vendor);
        },

        /**
         * Marks the offer as reserved
         * @param {function} success The callback
         * @param {function} error The callback
         * @return {undefined}
         * @private
         */
        _reserveSelectedOffer: function(success, error){

            success = _.isFunction(success) ? success : _.noop;

            var record = this._getOfferRecord();
            var offer = this.offersCollection.get(record.id);
            var date = moment();

            offer.set('reservationDate', date.toISOString());

            offer.save(null, {
                success: function(){

                    success.apply(this, arguments);

                }.bind(this),
                error: function(){

                    error.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Selects the offer with the specified id from the list
         * @return {undefined}
         * @private
         */
        _selectPreviousOffer: function(){

            var $rows = this._getOffersTableRowEls();
            var $row = null;

            if (!this.previousOffer) {

                $check = this._getOffersChecksEl().filter(':checked');

                $check.iCheck('uncheck');

                return;
            }

            var offerId = this.previousOffer.id;

            $rows.each(function(){

                var $this = $(this);

                if ('' + $this.data('id') === '' + offerId) {

                    $row = $this;

                    return false;
                }
            });

            if (!$row) {

                $check = this._getOffersChecksEl().filter(':checked');

                $check.iCheck('uncheck');

                return;
            }

            var $check = $row.find('input[type="radio"]');

            $check
                .data('silentCheck', true) // workaround
                .iCheck('check');
        },

        /**
         * Sets the offer alert expiration timer
         * @return {undefined}
         * @private
         */
        _setOfferTimer: function(){

            this._clearOfferTimer();

            this.offerTimer = setTimeout(function(){

                this._showError(Translator.trans('js.order_reschedule_offers_expired_message'));

            }.bind(this), this.OFFER_EXPIRE_TIME * 1000);
        },

        /**
         * Removes the offer alert expiration timer
         * @return {undefined}
         * @private
         */
        _clearOfferTimer: function(){

            if (this.offerTimer) {
                clearTimeout(this.offerTimer);
            }
        },

        /**
         * Attaches the offer to the form
         * @return {undefined}
         * @private
         */
        _attachSelectedOffer: function(){

            var record = this._getOfferRecord();

            this.previousOffer = record;

            this._setOffer(record.id);
        },

        /**
         * Sets the offer into the form
         * @param {string} offer The offer id
         * @return {undefined} - undefined
         * @private
         */
        _setOffer: function(offer){

            var $field = this._getOfferFieldEl();

            $field.val(offer);
        },

        /**
         * Renders the offer
         * @param {object} offer The offer model
         * @return {undefined}
         * @private
         */
        _renderOffer: function(offer){
            var $tbody = this._getOffersTableBodyEl();

            var $row = $('<tr>').attr({
                'data-id': offer.get('id')
            });

            var $checkbox = $('<input>').attr({
                type: 'radio',
                name: 'offer'
            });

            var date = this._renderDate(offer.get('appointmentDate'));

            var $check = $('<td>').append($checkbox);
            var $date = $('<td>').text(date);

            if (!z.security.isVendorAssociated()) {
                var $vendor = $('<td>').text(this._getVendorNameFromOffer(offer));
                this._getOrderRescheduleFormVendor().removeClass('hidden');
            } else {
                this._getOrderRescheduleFormVendor().addClass('hidden');
                $vendor = $('<td>').text('');
            }

            $row.append($check, $date, $vendor);

            $tbody.append($row);
        },

        /**
         * Method that builds the name of the vendor from brandName and Company name
         * @param {object} offer - the offer object
         * @return {string} - the vendor name
         * @private
         */
        _getVendorNameFromOffer: function (offer) {
            return offer.get('worksite').vendor.companyName;
        },

        /**
         * Retrieves the vendor el
         * @return {object} - the vendor el
         * @private
         */
        _getOrderRescheduleFormVendor: function () {
            return this.$('#order-reschedule-form-vendor');
        },

        /**
         * Renders a date as string
         * @param {string} value The string representation of the date
         * @return {string} The formatted date
         * @private
         */
        _renderDate: function(value){
            return moment(value).locale('ro').format('dddd, D MMMM');
        },

        /**
         * Retrieves the checks from offers table
         * @returns {object} The element
         * @private
         */
        _getOffersChecksEl: function(){
            return this._getOffersTableBodyEl().find('input[type="radio"]');
        },

        /**
         * Bind iCheck from radios and checkboxes
         * @return {undefined}
         * @private
         */
        _unbindICheck: function(){
            var $els = this._getOffersChecksEl();

            $els.iCheck('destroy');
        },

        /**
         * Bind iCheck to radios and checkboxes
         * @return {undefined}
         * @private
         */
        _bindICheck: function(){
            var $els = this._getOffersChecksEl();

            $els.iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
                increaseArea: '20%' // optional
            });
        },

        /**
         * Method that renders the offers in the modal window
         * @return {undefined}
         * @private
         */
        _renderOffers: function() {
            var $tbody = this._getOffersTableBodyEl();
            var $table = this._getOffersTableEl();
            var $empty = this._getOffersEmptyEl();

            this._unbindICheck();

            $tbody.empty();

            if (!this.offersCollection.length) {

                $empty.removeClass('hidden');
                $table.addClass('hidden');

                return;
            }

            $empty.addClass('hidden');
            $table.removeClass('hidden');

            this.offersCollection.each(function (offer) {

                this._renderOffer(offer);

            }, this);

            this._bindICheck();
        },

        /**
         * Retrieves the record form with the offer
         * @returns {object} The offer
         * @private
         */
        _getOfferRecord: function() {
            var $checked = this._getOffersChecksEl().filter(':checked');

            if (!$checked.length) {

                return {
                    id: null
                };
            }

            var $row = $checked.parents('tr:first');

            return {
                id: $row.data('id')
            };
        },

        /**
         * Method called after offers get checked
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onOfferCheck: function(event){

            var $check = $(event.currentTarget);

            if ($check.data('silentCheck')) {

                $check.removeData('silentCheck');

                return;
            }

            this._reserveSelectedOffer(function(){

                this._setOfferTimer();

                this._attachSelectedOffer();

                this._clearError();

            }, function(){

                this._selectPreviousOffer();
            });
        },

        /**
         * Method called after the request event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionRequest: function(){

            this._clearOfferTimer();

            this._clearError();

            this._isLoading(true);
        },

        /**
         * Method called after the sync event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionSync: function(){

            this._isLoading(false);
        },

        /**
         * Method called after the reset event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionReset: function(){

            this._renderOffers();
        },

        /**
         * Method called after the sync event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionUpdate: function(){

            this._renderOffers();
        },

        /**
         * Method called after the error event is triggered for offers collection
         * @param {Object} collection The collection
         * @param {Object} response The response
         * @return {undefined}
         * @private
         */
        _onOffersCollectionError: function(collection, response){

            this._displayOffersError(response);
        },

        /**
         * Displays the appropriate offer error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayOffersError: function(res){

            this._isLoading(false);

            this._clearError();

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._showError(Translator.trans('js.error_message_orderform_offers_unknown_error'));

                return;
            }

            var errors = res.responseJSON.errors;
            var exception = _.first(errors).class;

            switch (exception) {
                case 'OrderBundle\\Service\\Pricing\\Exception\\OfferUnavailableException':
                case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                    this._showError(Translator.trans('js.error_message_orderform_offers_unavailable'));
                    return;
                default:
                    this._showError(Translator.trans('js.error_message_orderform_offers_unknown_error'));
                    return;
            }
        },

        /**
         * Method called when the "strict" checkbox gets checked/unchecked
         * @return {undefined}
         * @private
         */
        _onStrictChange: function(){

            var isChecked = this.$('#orderrescheduleform-strict-field').is(':checked');
            var sort = this._getSortFieldEl().val();
            var vendor = this._getVendorFieldEl().val();

            this._toggleSortEl(isChecked);
            this._toggleVendorEl(isChecked);

            this._loadVendors();

            this._loadOffers(!isChecked, sort, vendor);
        },

        /**
         * Toggle sort element
         * @param {boolean} [show] Show or hide the element
         * @return {undefined}
         * @private
         */
        _toggleSortEl: function(show) {

            this._getSortGroupEl().toggleClass('hidden', !show);
        },

        /**
         * Toggle vendor element
         * @param {boolean} [show] Show or hide the element
         * @return {undefined}
         * @private
         */
        _toggleVendorEl: function(show){

            this._getVendorGroupEl().toggleClass('hidden', !show);
        },

        /**
         * Method called when the sort dropdown gets changed
         * @return {undefined}
         * @private
         */
        _onSortChange: function(){

            var isChecked = this.$('#orderrescheduleform-strict-field').is(':checked');
            var sort = this._getSortFieldEl().val();
            var vendor = this._getVendorFieldEl().val();

            this._loadVendors();

            this._loadOffers(!isChecked, sort, vendor);
        },

        /**
         * Method called when the vendor dropdown gets changed
         * @return {undefined}
         * @private
         */
        _onVendorChange: function(){

            var isChecked = this.$('#orderrescheduleform-strict-field').is(':checked');
            var sort = this._getSortFieldEl().val();
            var vendor = this._getVendorFieldEl().val();

            this._loadOffers(!isChecked, sort, vendor);
        },

        /**
         * Loads the offers collection
         * @param {boolean} [strict] Search only for current vendor or for all
         * @param {int} [sort] How to sort the offers
         * @param {int} [vendor] The vendor id
         * @return {undefined}
         * @private
         */
        _loadOffers: function(strict, sort, vendor){

            strict = _.isUndefined(strict) ? true : strict;

            var id = this._getOrderId();

            if (!z.security.isVendorAssociated()) {

                this.offersCollection.setStrict(strict);
                this.offersCollection.setSort(strict ? null : sort);
                this.offersCollection.setVendorId(vendor);
            }

            this.offersCollection.setOrderId(id);

            this.offersCollection.reset();

            this.offersCollection.fetch();
        },

        /**
         * Retrieves the current order's id
         * @return {string} The order id
         * @private
         */
        _getOrderId: function(){

            return this.$('#reschedule-order-form').data('id');
        },

        /**
         * Retrieves the first error class from the supplied response
         * @param {Object} response The response
         * @return {*|string} The error class
         * @private
         */
        _getFirstErrorClass: function(response){

            if (!response.responseJSON){
                return;
            }

            if (!response.responseJSON.errors) {
                return;
            }

            if (!response.responseJSON.errors[0]) {
                return;
            }

            if (!response.responseJSON.errors[0].class) {
                return;
            }

            return response.responseJSON.errors[0].class;
        },

        /**
         * Method used for displaying the reschedule order button
         * @private
         * @return {undefined}
         */
        _displayRescheduleButton: function() {

            var $rescheduleButton = this._getRescheduleButton();

            if (!z.security.isResellerAssociated()) {
                this.$("#reschedule-button-container").append($rescheduleButton);
            }
        },

        /**
         * Method that constructs the html for the reschedule button
         * history button
         * @returns {string} The html for the reschedule button
         * @private
         */
        _getRescheduleButton: function()
        {
            var buttonName = Translator.trans("js.reschedule_button");

            return "<div class='col-lg-2 pull-right'><a id='reschedule-order-modal-btn' class='btn btn-primary align-btn' data-style='slide-left'>" +
                buttonName + "</a></div>";
        },

        /**
         * Renders all items from vendors collection into html elements
         * @return {undefined}
         * @private
         */
        _renderOrderVendors: function(){

            var $field = this._getVendorFieldEl();

            var collection = this.orderVendorsCollection;

            this._renderChosenField($field, collection, function(model){

                return model.get('companyName');
            });
        },

        /**
         * Renders the options inside the chosen field based on the supplied collection
         * @param {object} $field The select field
         * @param {Backbone.Collection} collection The collection
         * @param {string|function} name How to select the name of the item
         * @return {undefined}
         * @private
         */
        _renderChosenField: function($field, collection, name){

            if (!collection) {

                $field.trigger('chosen:updated');

                return;
            }

            var $placeholder = $field.find('option:first').detach();

            var options = [$placeholder];

            $field.empty();

            collection.each(function(item){

                var display;

                if (_.isFunction(name)) {
                    display = name.call(this, item);
                } else {
                    display = item.get(name);
                }

                var $option = $('<option>')
                    .attr('value', item.get('id'))
                    .text(display);

                options.push($option);
            });

            $field.append(options);

            $field.trigger('chosen:updated');
        },

        /**
         * Retrieves the list of vendors (syncs the vendors collection)
         * @param {Function} [callback] The callback
         * @return {undefined}
         * @private
         */
        _loadVendors: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            this.orderVendorsCollection.reset();

            if (!this._isVendorFieldVisible()) {

                return;
            }

            this.orderVendorsCollection.setAvailable(true);
            this.orderVendorsCollection.setServiceId(z.boot.order.service.id);
            this.orderVendorsCollection.setCountyId(z.boot.order.customer.county.id);

            var sort = this._getSortFieldEl().val();

            if (sort === this.SORT_BY_DISTANCE + '') {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_EXTENDED);
            } else {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_FIXED);
            }

            this.orderVendorsCollection.setQuantity(z.boot.order.quantity);

            this.orderVendorsCollection
                .fetch()
                .then(function(){

                    callback.apply(this, arguments);

                })
                .catch(function(){

                    callback.apply(this, arguments);
                });
        },

        /**
         * Checks if the vendor container field is visible
         * @return {boolean} Is visible
         * @private
         */
        _isVendorFieldVisible: function(){

            var $container = this._getVendorGroupEl();

            return !$container.is('.hidden');
        },

        _displayError: function(res){

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._showError(Translator.trans('js.order_reschedule_error_message'));

                return;
            }

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var message = _.first(errors).message;

                if (typeof message !== 'undefined') {

                    switch (message) {
                        case "no_tariff_vendor_defined":
                            this._showError(Translator.trans('js.no_tariff_vendor_defined'));
                            return;
                        case "no_tariff_reseller_defined":
                            this._showError(Translator.trans('js.no_tariff_reseller_defined'));
                            return;
                        default:
                            this._showError(Translator.trans('js.order_reschedule_error_message'));
                    }
                }
            }
        },
    });

})(window.z = window.z || {}, Backbone, _);
