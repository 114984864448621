'use strict';

(function(z, _) {

    /**
     * Service worksite association grid
     */
    z.ServiceWorksiteAssocGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#serviceWorksiteAssoc-page",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click #add-serviceWorksiteAssoc-modal-btn": "onAddEntry",
                "click .serviceWorksiteAssoc-edit-button": "onEditEntry",
                "click .serviceWorksiteAssoc-delete-button": "onDeleteEntry"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);

            this.vendorId = this._getVendorId();

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#servicesWorksiteAssoc-service-field").chosen({
                search_contains: true
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-serviceWorksiteAssoc-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {

            return 'vendors/service-worksites/' + this.vendorId;
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'serviceWorksiteAssoc-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_serviceWorksiteAssoc_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'serviceWorksiteAssoc.success';
        },

        /**
         * Retrieves the vendor id if it is available
         * @returns {number|null} The vendor id
         * @private
         */
        _getVendorId: function(){

            var regex = /^\/vendors\/service-worksites\/(\d+)(.*)$/g;

            var result = regex.exec(window.location.pathname);

            if (!result) {

                return null;
            }

            return result[1];
        },

        /**
         * Method that opens a confirm box when an entry is deleted
         *
         * @param {Event} event click event
         * @return {undefined}
         */
        onDeleteEntry: function(event) {

            event.preventDefault();

            this.entryId = $(event.currentTarget).attr('data-id');

            // Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.service_assoc_delete_confirm_window_title'),
                text: Translator.trans('js.service_assoc_delete_confirm_window_message'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.service_assoc_delete_confirm_btn')
            }).then(function () {
                this._deleteEntry(this.entryId);
            }.bind(this)).catch(swal.noop);
        },

        /**
         * Delete the entry
         *
         * @param {int} id - entry id
         * @return {undefined}
         * @private
         */
        _deleteEntry: function(id) {

            var entryModel = new z.ServiceWorksiteAssocModel({ id: id });

            // Delete the model
            entryModel.destroy({

                // On success
                success: function() {

                    // On success, apply the filters
                    this.applyFilters();

                }.bind(this),

                // on error
                error: function() {

                   // todo

                }.bind(this)
            });
        }
    });
    
})(window.z = window.z || {}, _);
