'use strict';

(function(app, $, _, Backbone){

    /**
     * Form view
     */
    var FormView = Backbone.View.extend({

        /**
         * Bounding element.
         */
        el: '.modal-manage-form',

        /**
         * Events
         */
        events: {
            "submit .modal-form": "_onSubmitForm",
            "click .close-link" : "_hideModal"
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {

            // For the views that will override the FormView
            Backbone.View.prototype.initialize.apply(this, arguments);

            this.listenTo(z.router, 'route:' + this.getAddEntityRouteName(), this._onRouterAddEntity.bind(this));
            this.listenTo(z.router, 'route:' + this.getEditEntityRouteName(), this._onRouterEditEntity.bind(this));

            this.resetForm();

            Backbone.Validation.bind(this, {
                valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });

            this.modal = this.$el.modal({show:false});
            this.$errorMsg = this.$el.find(this.getErrorPanelId());

            this.setEvents();
        },

        /**
         * Get add entity route name.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return '';
        },

        /**
         * Get edit entity route name.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return '';
        },

        /**
         * Get modal form add title text.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return '';
        },

        /**
         * Get modal form edit title text.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return '';
        },

        /**
         * Get error panel container id.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '';
        },

        /**
         * Get submit button selector.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '';
        },

        /**
         * Get page path.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return '';
        },

        /**
         * Get success event name.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return '';
        },

        /**
         * Get form error message text.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return '';
        },

        /**
         * Collecting data from form inputs.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {Object} - the object with data from the form inputs
         */
        getFormData: function() {
            return {};
        },

        /**
         * Method that modifies the modal title.
         *
         * @param {string} text - name of the action that is being performed
         * @private
         * @return {undefined}
         */
        modifyModalTitle: function(text) {

            var $modalTitle = this.$('.modal-title');
            $modalTitle.text(text);
        },

        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        resetForm: function() {

            // reset form
            if (this.$el.hasClass('modal-form')) {

                this.$el.get(0).reset();

            } else {

                this.$el.find('.modal-form').get(0).reset();
            }

            // reset error panel if exists
            if (this.getErrorPanelId()) {

                this.$(this.getErrorPanelId()).addClass('hidden').text('');
            }

            // reset model if exists
            if (this.model) {

                this.model.clear();
            }

            // Reset chosen fields
            this.resetChosenFields();

            this.dataValidation();
        },

        /**
         * Method that resets chosen fields
         *
         * @private
         * @return {undefined}
         */
        resetChosenFields: function() {

            // reset chosen fields
            if (this.$('.chosen-select').length) {

                this.$('.chosen-select').prop('disabled', false).trigger('chosen:updated');
            }
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called after the view is removed.
         *
         * @return {*} - returns parent
         */
        remove: function() {

            Backbone.Validation.unbind(this, {
                model: this.model
            });

            // Call parent
            return Backbone.View.prototype.remove.apply(this, arguments);
        },

        /**
         * Setting the events on the model.
         *
         * @return {undefined}
         */
        setEvents: function(){
            
            // On hide modal
            this.modal.on("hidden.bs.modal", function(){

                // Navigate to the route with the saved filters
                z.router.navigate(z.filters.getFiltersUrl(), {trigger:true});
            }.bind(this));

            // On success
            this.listenTo(this.model, 'success', function() {

                this._hideModal();
                this._isLoading(false, this.getSubmitButtonSelector());

                $(document).trigger(this.getSuccessEventName());
            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(model, jqxhr){

                var description = this.getFormErrorMessageText();

                if (typeof jqxhr.responseJSON !== 'undefined') {

                    var errors = jqxhr.responseJSON.errors;

                    var message = _.first(errors).message;
                    switch (message) {
                        case 'invalid.form.emailNotUnique':
                            description = Translator.trans('js.error_message_email_notUnique');
                            break;
                        case 'invalid.form.passwordsNotEqual':
                            description = Translator.trans('js.error_message_userform_passwords_notEqual');
                            break;
                        case 'already_created':
                            description = Translator.trans('js.error_message_duplicate_entry');
                            break;
                        case 'invalid.form.period_overlapse':
                            description = Translator.trans('js.error_message_period_overlapse');
                            break;
                        case 'invalid.form.invalid_date':
                            description = Translator.trans('js.error_message_invalid_date');
                            break;
                        case 'invalid.form.invalid_promo_interval':
                            description = Translator.trans('js.error_message_invalid_promo_interval');
                            break;
                        case 'invalid.form.promo_period_overlapse':
                            description = Translator.trans('js.error_message_promo_period_overlapse');
                            break;
                    }
                }
                if (message.includes("Tariful nu a")){
                    description = message;
                }

                if (message.includes("Tariful nu a putut fi aplicat")){
                    $(document).trigger(this.getSuccessEventName());
                }

                this._setErrorMsg(description);
                this.showMobileAlert();
                this._isLoading(false, this.getSubmitButtonSelector());
            }.bind(this));
        },

        /**
         * Method that will set the custom labels that are not set in the FormView.
         * This function will be overridden by all the views that will extend FormView if they need
         * to have custom labels. You can pass this function "action" parameter.
         *
         * @return {undefined}
         */
        setCustomLabels: function() {},

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function(){

            this._removeErrorMsg();
            this._fillForm();

            return this;
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function(){},

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var formData = this.getFormData();
            this.model.set(formData);

            if( !this.model.isValid(true) ){
                this.showMobileAlert();
                return;
            }

            this._isLoading(true, this.getSubmitButtonSelector());

            this.model.save(null,{
                success: function(i) {
                    this.model.trigger("success", i);
                }.bind(this)
            });
        },

        /**
         * Method that will handle the showing of an alert
         * above the submit button, when the form is not valid
         * @return {undefined}
         */
        showMobileAlert: function ()
        {

        },

        /**
         * Method that will handle the hiding of the alert
         * above the submit button, when the form is not valid
         * @return {undefined}
         */
        hideMobileAlert: function()
        {

        },

        /**
         * Method that hides the modal window.
         *
         * @return {undefined}
         */
        _hideModal: function() {

            this.modal.modal("hide");
        },

        /**
         * Method that shows the modal window.
         *
         * @return {undefined}
         */
        _showModal: function() {

            this.modal.modal("show");

            // Focus on the first field
            this._focusOnFirstField();
        },

        /**
         * Focus on the first field from the form modal.
         * If the first field is not an input, you can override this function in your view.
         *
         * @private
         * @return {undefined}
         */
        _focusOnFirstField: function() {

            this.$('fieldset').find('input:first').focus();
        },

        /**
         * Method that modifies the modal title and button label
         * according to the action name being passed as param
         *
         * @param {string} action - name of the action that is being performed
         * @private
         * @return {undefined}
         */
        _setLabels: function(action) {

            switch (action) {
                case 'add':
                    this.modifyModalTitle(this.getModalFormAddTitleText());
                    break;
                case 'edit':
                    this.modifyModalTitle(this.getModalFormEditTitleText());
                    break;
            }

            // Set custom labels in every view that will extend FormView
            this.setCustomLabels(action);
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $submitBtn   = this.$(buttonSelector);
            var $formFields  = this.$('fieldset');
            var ladda =  $submitBtn.data('ladda') ?  $submitBtn.data('ladda') :  $submitBtn.ladda().data('ladda');

            if( loading ){
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Show the loading mask for modal window.
         * @param {bool} loading - should show or hide the mask
         * @return {undefined}
         * @private
         */
        _isLoadingMask: function(loading) {
            
            loading = loading ? loading : _.isUndefined(loading);

            var $content = this.modal.find('.modal-content.ibox-content');

            if( loading ){

                $content.addClass('sk-loading');

            } else {

                $content.removeClass('sk-loading');
            }
        },

        /**
         * Method that sets the error messages.
         *
         * @param {string} text - error message field text
         * @private
         * @return {undefined}
         */
        _setErrorMsg: function(text){

            this.$errorMsg
                .removeClass('hidden')
                .text(text);
        },

        /**
         * Method that removes the error message.
         *
         * @private
         * @return {undefined}
         */
        _removeErrorMsg: function() {

            this.$errorMsg
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that adds the entity when the correct route is being reached.
         *
         * @return {undefined}
         */
        _onRouterAddEntity: function() {

            this._setLabels('add');
            this.resetForm();
            this._showModal();
            this._afterRouterAddEntity();
        },
        
        /**
         * Method that edits the entity when the correct route is being reached.
         *
         * @param {object} id - the entity id that is being taken from the request
         * @private
         * @return {undefined}
         */
        _onRouterEditEntity: function(id){

            this._beforeEditEntity(id);
            
            this._setLabels('edit');
            this.resetForm();
            this.model.set('id', id);

            this.model.fetch({
                success: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
            this._showModal();
        },

        /**
         * Method that runs when the entity is being added
         * @private
         * @return {undefined}
         */
        _afterRouterAddEntity: function(){

            this.render();
        },

        /**
         * Method called before router edit entity.
         * @return {undefined}
         * @private
         */
        _beforeEditEntity: function() {
            this._isLoadingMask(true);
            this._isLoading(true, this.getSubmitButtonSelector());

            // Reset chosen fields
            this.resetChosenFields();
        },

        /**
         * Method called after router edit entity.
         * @return {undefined}
         * @private
         */
        _afterEditEntity: function() {
            this._isLoadingMask(false);
            this._isLoading(false, this.getSubmitButtonSelector());

            // Reset chosen fields after editing the entity because when we remove the loading, we enable the fieldset, so the chosen fields won't be disabled anymore
            this.resetChosenFields();

            if(!this.model.isNew()){
                this.render();
            }
        }
    });

    app.FormView = FormView;

})(window.z = window.z || {}, $, _, Backbone);
