"use strict";

(function(z, Backbone){

    var ServiceWorksiteAssocModel = Backbone.Model.extend({
        urlRoot: "/api/service-worksite",
        
        validation: {
            worksite: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceWorksiteAssoc_worksite_required")
            }],
            service: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceWorksiteAssoc_service_required")
            }],
            commission: [{
                required: false,
            }, {
                pattern: 'number',
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_commission_format')
            }, {
                min: 0,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_commission_min_value')
            }, {
                max: 100,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_commission_max_value')
            }],
            capacity: [{
                required: function(value, attr, computedState) {

                    return computedState.showAdminFields;
                },
                msg: Translator.trans("js.error_message_serviceWorksiteAssoc_capacity_required")
            }, {
                pattern: /^[0-9]{1}$|^[1-9]\d*$/,
                msg: Translator.trans("js.error_message_capacitiesform_capacity_invalid")
            }, {
                max: 9999,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_capacity_maxlength')
            }],
            saturdayCapacity: [{
                required: function(value, attr, computedState) {

                    return computedState.showAdminFields;
                },
                msg: Translator.trans("js.error_message_serviceWorksiteAssoc_saturdayCapacity_required")
            }, {
                pattern: /^[0-9]{1}$|^[1-9]\d*$/,
                msg: Translator.trans("js.error_message_capacitiesform_saturdayCapacity_invalid")
            }, {
                max: 9999,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_saturdayCapacity_maxlength')
            }],
            status: [{
                required: function(value, attr, computedState) {

                    return computedState.showAdminFields;
                },
                msg: Translator.trans("js.error_message_serviceWorksiteAssoc_status_required")
            }],
            priority: [{
                required: false
            }, {
                min: 0,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_priority_minlength')
            }, {
                max: 5,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_priority_maxlength')
            }],
            priorityInterval: [{
                required: false
            }, {
                min: 1,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_priorityInterval_minlength')
            }, {
                max: 5,
                msg: Translator.trans('js.error_message_serviceWorksiteAssoc_priorityInterval_maxlength')
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.ServiceWorksiteAssocModel = ServiceWorksiteAssocModel;

})(window.z = window.z || {}, Backbone);