"use strict";

(function(z){

    z.UserForm = z.FormView.extend({
        el:"#userform-modal",

        events: function() {
            return _.extend({}, z.FormView.prototype.events,{
                "change #userform-vendor-field": "_onVendorChange",
                "change #userform-reseller-field": "_onResellerChange",
                "change #userform-dbRoles-field" : "_onRolesChange"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {

            z.FormView.prototype.initialize.apply(this, arguments);

            this.showroomsCollection = new z.ShowroomsCollection();

            // Listening on collection events
            this.listenTo(this.showroomsCollection, 'add', this._onShowroomsCollectionAdd);
            this.listenTo(this.showroomsCollection, 'reset', this._onShowroomsCollectionReset);

            // On success update chosen fields and activate reseller and vendor fields
            this.listenTo(this.model, 'success', function(){
                this._activateReseller();
                this._activateVendor();
            }.bind(this));

            this.initializeEvents();
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addUser';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editUser';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_user');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_user');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#userform-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#userform-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'users';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'user.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_userform');
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#userform-reseller-field, #userform-vendor-field, #userform-showroom-field").chosen({
                search_contains: true,
                width: "100%"
            });

            this.$("#userform-dbRoles-field").chosen({
                search_contains: true,
                width: "100%",
                placeholder_text_multiple: Translator.trans('js.label_choose_role')
            });

            // Activate the vendor and the reseller fields
            this._activateVendor();
            this._activateResellerFields();
        },

        /**
         * Method that will set the custom labels that are not set in the FormView.
         * This function will be overridden by all the views that will extend FormView if they need
         * to have custom labels. You can pass this function "action" parameter.
         *
         * @param {string} action - action name (eg. add, edit)
         * @return {undefined}
         */
        setCustomLabels: function(action) {
            var $passwordsAdditionalLabel = this.$('.passwords-additional-label');

            switch (action) {
                case 'add':

                    if (!$passwordsAdditionalLabel.hasClass('hidden')) {
                        $passwordsAdditionalLabel.addClass('hidden');
                    }

                    break;
                case 'edit':

                    if ($passwordsAdditionalLabel.hasClass('hidden')) {
                        $passwordsAdditionalLabel.removeClass('hidden');
                    }

                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {
         *      email: *,
         *      password: *,
         *      confirmPassword: *,
         *      firstName: *,
         *      lastName: *,
         *      language: *,
         *      roles: *,
         *      status: *,
         *      vendor: *,
         *      reseller: *,
         * }
         */
        getFormData: function() {

            var $emailField = this.$('#userform-email-field');
            var $passwordField = this.$('#userform-password-field');
            var $confirmPasswordField = this.$('#userform-confirmPassword-field');
            var $firstNameField = this.$('#userform-firstName-field');
            var $lastNameField = this.$('#userform-lastName-field');
            var $languageField = this.$('#userform-language-field');
            var $dbRolesField = this.$('#userform-dbRoles-field');
            var $statusField = this.$('#userform-status-field');
            var $vendorField = this.$('#userform-vendor-field option:selected');
            var $resellerField = this.$('#userform-reseller-field option:selected');
            var $showroomField = this.$('#userform-showroom-field option:selected');

            var roleValues = $dbRolesField.val();
            var roles = [];

            if (_.isArray(roleValues) && roleValues.length !== 0) {
                roles = _.map(roleValues, function(role) {
                    if (role.length !== 0) {
                        return { 'name': role };
                    }
                });

                roles = _.filter(roles, function(role) {
                    return typeof role !== 'undefined';
                });
            }

            return {
                'email': $emailField.val().trim(),
                'passwordPlain': {
                    'first': $passwordField.val().trim(),
                    'second': $confirmPasswordField.val().trim()
                },
                'firstName': $firstNameField.val().trim(),
                'lastName': $lastNameField.val().trim(),
                'language': $languageField.val().trim(),
                'dbRoles': roles,
                'status': $statusField.val().trim(),
                'vendor': $vendorField.val().trim(),
                'reseller': $resellerField.val().trim(),
                'showroom': $showroomField.val().trim(),
            };
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                "userform-email-field":"email",
                "userform-firstName-field":"firstName",
                "userform-lastName-field": "lastName",
                "userform-language-field": "language",
                "userform-status-field": "status",
                "userform-dbRoles-field": "dbRoles",
                "userform-vendor-field": "vendor",
                "userform-reseller-field": "reseller",
                "userform-showroom-field": "showroom"

            };

            Object.keys(map).forEach(function(key){

                var value = this.model.get(map[key]);

                switch (key) {
                    case "userform-status-field":

                        if (value === true || value === 1) {
                            this.$("#" + key).val("1");
                        } else {
                            this.$("#" + key).val("0");
                        }

                        break;
                    case "userform-dbRoles-field":
                        this._prefillDbRolesField(key, value);
                        break;
                    case "userform-vendor-field":
                        this._prefillChosenDropdownField(key, value);
                        break;
                    case "userform-reseller-field":
                        this._prefillChosenDropdownField(key, value);
                        break;
                    case "userform-showroom-field":
                        this._prefillShowroomField(key, value);
                        break;
                    default:
                        this.$el.find("#"+key).val(value);
                }
            }.bind(this));

            // Activate fields based on gatekeeper
            this._gatekeeperManageFields();
        },

        /**
         * Activate fields based on gatekeeper
         * @return {undefined}
         * @private
         */
        _gatekeeperManageFields: function() {

            var gatekeeper = z.security.getGatekeeper();

            // If the logged user is vendor admin
            if (gatekeeper.isVendorAdmin) {

                // Disable the reseller field
                this._deactivateResellerFields();

            } else if (gatekeeper.isResellerAdmin) {

                // Disable the vendor field
                this._deactivateVendor();
            }
        },

        /**
         * Method that runs when the entity is being added (overwrites the method from FormView.js)
         * @private
         * @return {undefined}
         */
        _afterRouterAddEntity: function(){

            this.$('.chosen-select').prop('disabled', false).trigger('chosen:updated');
            this.render();
        },

        /**
         * Prefill roles field (multiple select dropdown)
         *
         * @param {string} key - the id of the field
         * @param {string} value - the value from the model
         * @private
         * @return {undefined}
         */
        _prefillDbRolesField: function(key, value) {

            if (typeof value === 'undefined') {
                return;
            }

            var roles = {};

            if (value.length > 0) {

                roles = _.map(value, function(role) {
                    return role.name;
                });

                if (typeof roles !== 'undefined' && _.isArray(roles)) {

                    for (var roleIndex in roles) {

                        if( !roles.hasOwnProperty(roleIndex) ){
                            continue;
                        }

                        this._manageFieldsBySelectedRole(roles[roleIndex]);

                        // Add the elements in dropdowns with chosen jquery
                        this.$("#" + key)
                            .find('option[value=' + roles[roleIndex] + ']').prop('selected', true)
                            .end().trigger('chosen:updated');
                    }
                }
            }
        },

        /**
         * Prefill chosen dropdown field
         *
         * @param {string} key - the id of the field
         * @param {string} value - the value from the model
         * @private
         * @return {undefined}
         */
        _prefillChosenDropdownField: function(key, value) {

            if(typeof value === 'undefined'){
                return;
            }

            this.$("#" + key)
                .find('option[value=' + value.id + ']').prop('selected', true)
                .end().trigger('chosen:updated');
        },

        /**
         * Prefill showroom dropdown with showrooms from the chosen reseller and
         * select by default the user's showroom
         *
         * @param {string} key - the id of the field
         * @param {string} value - the value from the model
         * @private
         * @return {undefined}
         */
        _prefillShowroomField: function(key, value) {

            if (typeof this.model.get('dbRoles') !== 'undefined') {

                var modelRole = this.model.get('dbRoles')[0];

                // Activate the showroom for role reseller operator
                if (modelRole.name === z.security.ROLE_RESELLER_OPERATOR) {

                    this._activateShowroom();
                }
            }

            this._loadShowrooms({key: key, value: value});
        },

        /**
         * Method triggered on the showrooms collection add
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _onShowroomsCollectionAdd: function(model) {

            this._renderShowroom(model);
        },

        /**
         * Method triggered on the showrooms collection reset
         *
         * @private
         * @return {undefined}
         */
        _onShowroomsCollectionReset: function() {

            this._renderShowroomReset();
        },

        /**
         * Method that renders the showroom select
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _renderShowroom: function(model) {

            var $option = $('<option>')
                .attr('value', model.get('id'))
                .text(model.get('name'));

            if (model.get('status') === 0) {
                $option.attr('disabled', 'disabled');
            }

            $('#userform-showroom-field').append($option);
        },

        /**
         * Method that resets the showroom select
         *
         * @private
         * @return {undefined}
         */
        _renderShowroomReset: function() {

            var $option = $("#userform-showroom-field option:eq(0)").detach();
            $('#userform-showroom-field').empty().append($option);

            this.$('.chosen-select-showroom').trigger("chosen:updated");
        },

        /**
         * On vendor change, disable the reseller dropdown and
         * remove its value
         *
         * @private
         * @return {undefined}
         */
        _onVendorChange: function () {

            var $vendor = this.$("#userform-vendor-field");

            // If the first option is selected, enable the reseller dropdown
            if ($vendor[0].selectedIndex === 0) {

                this._activateResellerFields();

            } else {

                this._deactivateResellerFields();
            }
        },

        /**
         * On reseller change, disable the vendor dropdown and
         * remove its value
         *
         * @private
         * @return {undefined}
         */
        _onResellerChange: function () {

            var $reseller = this.$("#userform-reseller-field");

            // If the first option is selected, enable the vendor dropdown
            if ($reseller[0].selectedIndex === 0) {

                this._activateVendor();

            } else {

                this._deactivateVendor();
            }

            // Load the showrooms
            this._loadShowrooms();
        },

        /**
         * Load the showrooms
         * @param {array} params the params
         * @private
         * @return {undefined}
         */
        _loadShowrooms: function(params) {

            var id = this.$("#userform-reseller-field option:selected").val();

            if (!id) {

                this.showroomsCollection.reset(null);
                return;
            }

            this.showroomsCollection.setResellerId(id);
            this.showroomsCollection.reset(null);

            this.showroomsCollection.fetch({

                success: function() {

                    this.$('.chosen-select-showroom').trigger("chosen:updated");
                    this.$("#userform-showroom-field").trigger('load');

                    if (typeof params !== 'undefined' && typeof params.key !== 'undefined' && typeof params.value !== 'undefined') {

                        this._prefillChosenDropdownField(params.key, params.value);
                    }

                }.bind(this)
            });
        },

        /**
         * Method that checks what option is checked in the role select
         * and according to the option it will deactivate the
         * reseller or vendor select
         *
         * @param {string} role The role select value
         * @private
         * @return {undefined}
         */
        _manageFieldsBySelectedRole: function(role) {

            switch(role) {

                case z.security.ROLE_ADMIN:
                    this._deactivateResellerFields();
                    this._deactivateVendor();
                    break;

                case z.security.ROLE_TEAM_LEADER:
                    this._deactivateResellerFields();
                    this._deactivateVendor();
                    break;

                case z.security.ROLE_OPERATOR:
                    this._deactivateResellerFields();
                    this._deactivateVendor();
                    break;

                case z.security.ROLE_VENDOR_ADMIN:
                    this._deactivateResellerFields();
                    this._activateVendor();
                    break;

                case z.security.ROLE_VENDOR_OPERATOR:
                    this._deactivateResellerFields();
                    this._activateVendor();
                    break;

                case z.security.ROLE_RESELLER_ADMIN:
                    this._deactivateVendor();
                    this._deactivateShowroom();
                    this._activateReseller();
                    break;

                case z.security.ROLE_RESELLER_OPERATOR:
                    this._deactivateVendor();
                    this._activateResellerFields();
                    break;
            }
        },

        /**
         * Method that deactivates the reseller fields (reseller & showroom) select
         *
         * @private
         * @return {undefined}
         */
        _deactivateResellerFields: function() {

            // Deactivate the reseller
            this._deactivateReseller();

            // Deactivate the showroom
            this._deactivateShowroom();
        },

        /**
         * Method that deactivates the reseller select
         *
         * @private
         * @return {undefined}
         */
        _deactivateReseller: function() {

            this.$("#userform-reseller-field").prop('selectedIndex', 0);
            this.$("#userform-reseller-field").prop('disabled', true).trigger("chosen:updated");
        },

        /**
         * Method that deactivates theshowroom select
         *
         * @private
         * @return {undefined}
         */
        _deactivateShowroom: function() {

            // Reset the showroom field
            this._renderShowroomReset();

            // Disable the showroom field
            this.$("#userform-showroom-field").prop('disabled', true).trigger("chosen:updated");
        },

        /**
         * Method that deactivates the vendor select
         * @private
         * @return {undefined}.
         */
        _deactivateVendor: function() {

            this.$("#userform-vendor-field").prop('selectedIndex', 0);
            this.$("#userform-vendor-field").prop('disabled', true).trigger("chosen:updated");
        },

        /**
         * Method that activates the vendor select
         *
         * @private
         * @return {undefined}
         */
        _activateVendor: function() {

            this.$("#userform-vendor-field").prop('selectedIndex', 0);
            this.$("#userform-vendor-field").prop('disabled', false).trigger("chosen:updated");
        },

        /**
         * Method that activates the reseller select
         * @private
         * @return {undefined}
         */
        _activateReseller: function() {

            this.$("#userform-reseller-field").prop('selectedIndex', 0);
            this.$("#userform-reseller-field").prop('disabled', false).trigger("chosen:updated");
        },

        /**
         * Method that activates the showroom select
         * @private
         * @return {undefined}
         */
        _activateShowroom: function() {

            this._renderShowroomReset();
            this.$("#userform-showroom-field").prop('disabled', false).trigger("chosen:updated");
        },

        /**
         * Method that activates the reseller fields (reseller & showroom)
         * @private
         * @return {undefined}
         */
        _activateResellerFields: function() {

            // Activate the reseller field
            this._activateReseller();

            // Activate the showroom field
            this._activateShowroom();
        },

        /**
         * Method that activates or deactivates the vendor and reseller
         * selects according to the roles selected in the role select
         *
         * @private
         * @return {undefined}
         */
        _onRolesChange: function() {

            var $roles = this._getSelectedRoles();

            if ($roles.length > 1) {

                this._manageFieldsBySelectedRole($roles[1]);

            } else {

                var gatekeeper = z.security.getGatekeeper();

                // If the logged user is vendor admin
                if (gatekeeper.isVendorAdmin) {

                    // Disable the reseller field
                    this._deactivateResellerFields();

                } else if (gatekeeper.isResellerAdmin) {

                    // Disable the vendor field
                    this._deactivateVendor();

                } else {

                    this._activateResellerFields();
                    this._activateVendor();
                }
            }
        },

        /**
         * Get selected roles
         *
         * @return {array} an array with the selected roles
         * @private
         */
        _getSelectedRoles: function() {

            return this.$("#userform-dbRoles-field").val();
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.user_create_error_message_mobile');

            this.$el.find('#user-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#user-create-general-error-panel').addClass('hidden');
        }

    });

})(window.z, Backbone);
