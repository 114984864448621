"use strict";

(function(z, Backbone){

    var OrderReschedulesCollection = Backbone.Collection.extend({

        url: function(){
            return "/api/orders/reschedules/"+ this.orderId;
        },

        setOrderId: function(id){
            this.orderId = id;
        },

        parse: function (response) {
            return response.data;
        }
    });

    z.OrderReschedulesCollection = OrderReschedulesCollection;

})(window.z = window.z || {}, Backbone);