"use strict";

(function(z){

    z.ServiceWorksiteAssocForm = z.FormView.extend({

        /**
         * Consts for status
         */
        STATUS_ACTIVE: 1,
        STATUS_INACTIVE: 0,

        el: "#serviceWorksiteAssocForm-modal",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            z.FormView.prototype.initialize.apply(this, arguments);

            this.vendorId = this._getVendorId();

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#serviceWorksiteAssocForm-worksite-field").chosen({
                search_contains: true,
                width: "100%"
            });

            this.$("#serviceWorksiteAssocForm-service-field").chosen({
                search_contains: true,
                width: "100%"
            });
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addServiceWorksiteAssoc';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editServiceWorksiteAssoc';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_service_worksite_assoc');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_service_worksite_assoc');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#serviceWorksiteAssocForm-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#serviceWorksiteAssocForm-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'vendors/service-worksites/' + this._getVendorId();
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'serviceWorksiteAssoc.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_serviceWorksiteAssocForm');
        },

        /**
         * Set custom labels for the reseller modal form
         *
         * @param {string} action - action name
         * @return {undefined}
         */
        setCustomLabels: function(action) {

            // todo uncomment when API Details will be implemented
            switch (action) {
                case 'add':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
                    break;
                case 'edit':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{brandName: *, companyName: *, address: *, phoneNumber: *, status: *, apiUser: *, apiKey: *}}
         */
        getFormData: function() {

            var $worksiteField         = this.$('#serviceWorksiteAssocForm-worksite-field');
            var $serviceField          = this.$('#serviceWorksiteAssocForm-service-field');
            var $commissionField       = this.$('#serviceWorksiteAssocForm-commission-field');
            var $capacityField         = this.$('#serviceWorksiteAssocForm-capacity-field');
            var $saturdayCapacityField = this.$('#serviceWorksiteAssocForm-saturdayCapacity-field');
            var $statusField           = this.$('#serviceWorksiteAssocForm-status-field');
            var $priorityField         = this.$('#serviceWorksiteAssocForm-priority-field');
            var $priorityIntervalField = this.$('#serviceWorksiteAssocForm-priorityInterval-field');

            return {
                'worksite'         : $worksiteField.val(),
                'service'          : $serviceField.val(),
                'commission'       : $commissionField.val(),
                'capacity'         : $capacityField.val(),
                'saturdayCapacity' : $saturdayCapacityField.val(),
                'status'           : $statusField.val(),
                'priority'         : $priorityField.val(),
                'priorityInterval' : $priorityIntervalField.val(),
                'showAdminFields'  : z.boot.showAdminFiels
            };
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                'serviceWorksiteAssocForm-worksite-field'           : 'worksite',
                'serviceWorksiteAssocForm-service-field'            : 'service',
                'serviceWorksiteAssocForm-commission-field'         : 'commission',
                'serviceWorksiteAssocForm-capacity-field'           : 'capacity',
                'serviceWorksiteAssocForm-saturdayCapacity-field'   : 'saturdayCapacity',
                'serviceWorksiteAssocForm-status-field'             : 'status',
                'serviceWorksiteAssocForm-priority-field'           : 'priority',
                'serviceWorksiteAssocForm-priorityInterval-field'   : 'priorityInterval'
            };

            Object.keys(map).forEach(function(key){

                var value = this.model.get(map[key]);

                switch (key) {

                    case "serviceWorksiteAssocForm-status-field":

                        if (value === true || value === this.STATUS_ACTIVE) {

                            this.$("#" + key).val("1");

                        } else if(value === false || value === this.STATUS_INACTIVE) {

                            this.$("#" + key).val("0");

                        } else {

                            this.$("#" + key).val("");
                        }

                        break;

                    case "serviceWorksiteAssocForm-worksite-field":
                    case "serviceWorksiteAssocForm-service-field":

                        if(typeof value === 'undefined'){
                            return;
                        }

                        this.$("#" + key)
                            .find('option[value=' + value.id + ']').prop('selected', true)
                            .end().trigger('chosen:updated');

                        break;

                    case "serviceWorksiteAssocForm-capacity-field":
                    case "serviceWorksiteAssocForm-saturdayCapacity-field":

                        if(typeof value === 'undefined'){

                            this.$el.find("#"+key).val(0);
                        } else {

                            this.$el.find("#"+key).val(value);
                        }

                        break;

                    case "serviceWorksiteAssocForm-priority-field":
                    case "serviceWorksiteAssocForm-priorityInterval-field":

                        if (_.isUndefined(value)) {
                            return;
                        }

                        this.$el.find("#"+key).val(value);

                        break;

                    default:
                        this.$el.find("#"+key).val(value);
                }

            }.bind(this));
        },

        /**
         * Retrieves the vendor id if it is available
         * @returns {number|null} The vendor id
         * @private
         */
        _getVendorId: function() {

            var regex = /^\/vendors\/service-worksites\/(\d+)(.*)$/g;

            var result = regex.exec(window.location.pathname);

            if (!result) {

                return null;
            }

            return result[1];
        },

        /**
         * Method that edits the entity when the correct route is being reached.
         *
         * @param {int} id - the vendor id that is being taken from the request
         * @param {int} serviceWorksiteAssocId - the serviceWorskiteAssoc id that is being taken from the request
         * @private
         * @return {undefined}
         */
        _onRouterEditEntity: function(id, serviceWorksiteAssocId){

            this._beforeEditEntity(serviceWorksiteAssocId);

            this._setLabels('edit');
            this.resetForm();
            this.model.set('id', serviceWorksiteAssocId);

            this.model.fetch({
                success: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
            this._showModal();
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.service_assoc_create_error_message_mobile');
            this.$el.find('#service-assoc-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#service-assoc-create-general-error-panel').addClass('hidden');
        }
    });

})(window.z = window.z || {});
