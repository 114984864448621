"use strict";

(function(z, Backbone){

    var ShowroomsCollection = Backbone.Collection.extend({

        model: z.ShowroomModel,

        /**
         * Sets reseller id
         * @param {string|int} id The reseller id
         * @return {Backbone.Collection} Return myself
         */
        setResellerId: function(id) {

            this.resellerId = id;

            return this;
        },

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function( ){

            return '/api/resellers/' + this.resellerId + '/showrooms' ;
        },

        /**
         * Parses the response sent from the server
         * @param {object} response Server response
         * @return {array} List of localities
         */
        parse: function(response){

            return response.data;
        }

    });

    z.ShowroomsCollection = ShowroomsCollection;

})(window.z = window.z || {}, Backbone);