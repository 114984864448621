'use strict';

(function (app, Backbone) {

    //noinspection UnnecessaryLocalVariableJS
    var UserAuthModel = Backbone.Model.extend({
        validation: {
            username: [
                {
                    required: true,
                    msg: Translator.trans("js.error_message_loginform_email_required")
                },
                {
                    pattern: 'email',
                    msg: Translator.trans("js.error_message_loginform_email_invalid")
                }
            ],
            password: {
                required: true,
                msg: Translator.trans('js.error_message_loginform_password_required')
            }
        }
    });

    app.UserAuthModel = UserAuthModel;

})(window.z = window.z || {}, Backbone);