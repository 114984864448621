'use strict';

(function(z) {

    /**
     * User grid
     */
    z.UsersGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#page-wrapper",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.GridView.prototype.events,{
                "click #add-user-modal-btn": "onAddEntry",
                "click .user-edit-button": "onEditEntry"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            
            z.GridView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#usersfilters-reseller-field, #usersfilters-vendor-field, #usersfilters-showroom-field").chosen({
                search_contains: true
            });

            this.$("#usersfilters-dbRoles-field").chosen({
                search_contains: true,
                placeholder_text_multiple: Translator.trans('js.label_choose_role')
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-user-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return 'users';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'user-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_user_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'user.success';
        }
    });

})(window.z = window.z || {});

