"use strict";

(function(z, Backbone){

    var WorksiteModel = Backbone.Model.extend({
        validation: {
            county: [{
                required: true,
                msg: Translator.trans("js.error_message_worksiteform_county_required")
            }],
            locality: [{
                required: true,
                msg: Translator.trans("js.error_message_worksiteform_locality_required")
            }],
            address: [{
                required: true,
                msg: Translator.trans("js.error_message_worksiteform_address_required")
            }, {
                minLength: 4,
                msg: Translator.trans("js.error_message_worksiteform_address_minlength")
            }, {
                maxLength: 255,
                msg: Translator.trans("js.error_message_worksiteform_address_maxlength")
            }],
            status: [{
                required: true,
                msg: Translator.trans("js.error_message_worksiteform_status_required")
            }],
            email: [{
                required: false,
                msg: Translator.trans("js.error_message_worksiteform_email_required")
            }, {
                pattern: 'email',
                msg: Translator.trans("js.error_message_worksiteform_email_invalid")
            }]
        }
    });

    z.WorksiteModel = WorksiteModel;

})(window.z = window.z || {}, Backbone);
