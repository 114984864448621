"use strict";

(function(z, Backbone){

    var VendorModel = Backbone.Model.extend({
        urlRoot: "/api/vendors",
        validation: {
            brandName: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_brandName_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_vendorform_brandName_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_brandName_maxlength")
            }],
            companyName: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_companyName_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_vendorform_companyName_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_companyName_maxlength")
            }],
            type: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_type_required")
            }],
            companyIdentificationNumber: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_companyIdentificationNumber_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_vendorform_companyIdentificationNumber_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_companyIdentificationNumber_maxlength")
            }],
            registryOfCommerceNumber: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_registryOfCommerceNumber_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_vendorform_registryOfCommerceNumber_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_registryOfCommerceNumber_maxlength")
            }],
            bankAccount: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_bankAccount_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_vendorform_bankAccount_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_bankAccount_maxlength")
            }],
            bankName: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_bankName_required")
            },{
                minLength: 3,
                msg: Translator.trans("js.error_message_vendorform_bankName_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_bankName_maxlength")
            }],
            accountHolder: [{
                required: true,
                msg: Translator.trans("js.error_message_vendorform_accountHolder_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_vendorform_accountHolder_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_accountHolder_maxlength")
            }],
            contractNumber: [{
                maxLength: 64,
                msg: Translator.trans("js.error_message_vendorform_contractNumber_maxlength")
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            if( !_.isUndefined(response.data) ){

                return response.data;
            }

            return response;
        }
    });

    z.VendorModel = VendorModel;

})(window.z = window.z || {}, Backbone);
