"use strict";

(function(z, Backbone){

    var OrderCommentsCollection = Backbone.Collection.extend({
        url: function(){
            return "/api/orders/" + this.orderId + "/comments";
        },

        model: z.OrderCommentModel,

        setOrderId: function(id){
            this.orderId = id;
        },

        parse: function (response) {
            return response.data;
        }
    });

    z.OrderCommentsCollection = OrderCommentsCollection;

})(window.z = window.z || {}, Backbone);