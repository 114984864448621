"use strict";

(function(z, Backbone){

    var WorksitesCollection = Backbone.Collection.extend({
        // nothing
    });

    z.WorksitesCollection = WorksitesCollection;

})(window.z = window.z || {}, Backbone);