"use strict";

(function(z, Backbone){

    var NotificationModel = Backbone.Model.extend({
        urlRoot: "/api/notifications",
        validation: {
            subject: [{
                required: true,
                msg: Translator.trans("js.error_message_notificationform_subject_required")
            },{
                minLength: 1,
                msg: Translator.trans("js.error_message_notificationform_subject_minlength")
            },{
                maxLength: 255,
                msg: Translator.trans("js.error_message_notificationform_subject_maxlength")
            }],
            body: [{
                required: true,
                msg: Translator.trans("js.error_message_notificationform_body_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_notificationform_body_minlength")
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.NotificationModel = NotificationModel;

})(window.z = window.z || {}, Backbone);
