"use strict";

(function(z, Backbone, _){

    z.OrderTemplate = Backbone.View.extend({

        /**
         * Template download url
         */
        TEMPLATE_DOWNLOAD_URL: '/orders/import/template/{service}',

        /**
         * Element
         */
        el: '#template-panel',

        /**
         * Events
         */
        events: {
            'click #template-download-button': '_onDownloadClick'
        },

        /**
         * Initialize method.
         * @return {undefined}
         */
        initialize: function() {

            this._bindModel();
        },

        /**
         * Binds the model to this view.
         *
         * @param {object} [model] The model
         * @return {undefined}
         * @private
         */
        _bindModel: function(model){

            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Method called after the user clicks the download button.
         * @return {undefined}
         * @private
         */
        _onDownloadClick: function(){

            this._downloadTemplate();
        },

        /**
         * Method called if the form validation succeeds.
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr){

            var $form = this.$('#template-form');

            $form
                .find('[data-validation="' + attr + '"]')
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails.
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error){

            var $form = this.$('#template-form');

            $form
                .find('[data-validation="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Redirects to download page.
         * @return {undefined}
         * @private
         */
        _downloadTemplate: function(){

            var record = this._getFormRecord();

            this.model.set(record);

            if (!this.model.isValid(true)) {

                return;
            }

            var url = this._getTemplateOriginDownloadUrl(this.model.get('service'));

            window.open(url);
        },

        /**
         * Retrieves the form record.
         * @return {object} The form record
         * @private
         */
        _getFormRecord: function(){

            return {
                service: this.$('#template-service-field').val()
            };
        },

        /**
         * Returns the url from where you can download the template.
         * @param {int|string} serviceId The service id
         * @returns {string} The url
         * @private
         */
        _getTemplateOriginDownloadUrl: function(serviceId){
            return window.location.origin + this.TEMPLATE_DOWNLOAD_URL.replace('{service}', serviceId);
        }
    });

})(window.z = window.z || {}, Backbone, _);