"use strict";

(function(z, Backbone){

    var LocalitiesCollection = Backbone.Collection.extend({

        /**
         * Constants used when searching for Vendors to fill the vendors field
         */
        AREA_TYPE_FIXED: 1,
        AREA_TYPE_EXTENDED: 2,

        /**
         * The model
         */
        model: z.LocalityModel,

        /**
         * Sets county id
         * @param {string|int} id The county id
         * @return {Backbone.Collection} Return myself
         */
        setCountyId: function(id){

            this.countyId = id;

            return this;
        },

        /**
         * Sets service id
         * @param {string|int} id The service id
         * @return {Backbone.Collection} Return myself
         */
        setServiceId: function(id){

            this.serviceId = id;

            return this;
        },

        /**
         * Sets the type of area
         * @param {string|int} type The type of area
         * @return {Backbone.Collection} Return myself
         */
        setArea: function(type){

            this.area = type;

            return this;
        },

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function(){

            if (this.serviceId) {

                var url = '/api/county/' + this.countyId + '/service/' + this.serviceId + '/localities';

                if (this.area) {

                    url += '/' + this.area;
                }

                return url;
            }

            return '/api/county/' + this.countyId + '/localities';
        },

        /**
         * Parses the response sent from the server
         * @param {object} response Server response
         * @return {array} List of localities
         */
        parse: function(response){

            return response.data;
        }
    });

    z.LocalitiesCollection = LocalitiesCollection;

})(window.z = window.z || {}, Backbone);