'use strict';

(function(z) {

    /**
     * Log grid
     */
    z.LogGrid = z.FiltersView.extend({

        /**
         * Bounding element
         */
        el: "#page-wrapper",

        /**
         * Overwrite the DEFAULT_LENGTH from FiltersView
         */
        DEFAULT_LENGTH: 100,

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);
            this.$(".chosen-select").chosen({
                search_contains: true
            });

            this.datepickerLoader(this.$('#logfilters-created_date_start-field'));
            this.datepickerLoader(this.$('#logfilters-created_date_end-field'));
        },

        /**
         * Load the datepicker when the field is clicked
         *
         * @param {string} $input -jquery element for the daterangepicker field
         *
         * @return {undefined}
         */
        datepickerLoader: function ($input) {
            var DATE_FORMAT = 'DD-MM-YYYY H:mm:ss';
            this.$($input).daterangepicker({
                autoUpdateInput: false,
                showDropdowns: true,
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true,
                timePickerSeconds: true,
                autoApply: true,
                locale: {
                    format: DATE_FORMAT
                }
            });

            // this is official solution for initial empty field
            this.$($input).on('apply.daterangepicker', function (event, picker) {
                $(this).val(picker.startDate.format(DATE_FORMAT));
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-log-datatable';
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return '';
        },

        getPath: function () {
            return 'logs';
        },

        applyDefaultSorting: function () {

        }
    });

    // z.logsGrid = new LogGrid();

})(window.z = window.z || {});
