"use strict";

(function(z, Backbone, _){

    var OrderRescheduleModel = Backbone.Model.extend({

        ADDITIONAL_COMMENT : "3",

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function(){
            return '/api/orders/' + this.orderId + '/reschedule';
        },

        /**
         * Setting the order id on the model
         * @param {int} id The order id
         * @returns {OrderRescheduleModel} The Order Reschedule Model
         */
        setOrderId: function(id){
            this.orderId = id;
            return this;
        },

        /**
         * Setting backbone validation for the form fields
         * @returns {undefined}
         */
        validation: {
            type: [{
                required: true,
                msg: Translator.trans("js.error_message_reschedule_type_required")
            }],
            additionalComment: [
                { required: function(value, attr, computedState) {
                    if (computedState.type === this.ADDITIONAL_COMMENT && computedState.additionalComment === "") {
                        return true;
                    }
                },
                    msg: Translator.trans("js_error_message_additional_comment_required")
                },
                {   minLength: 1,
                    msg: Translator.trans("js.error_message_reschedule_type_minlength")
                }, {
                    maxLength: 250,
                    msg: Translator.trans("js.error_message_reschedule_type_maxlength")
                }
            ],
            offer: [{
                required: true,
                msg: Translator.trans('js.error_message_reschedule_offer_required')
            }]
        },

        /**
         * Parsing results
         * @param {object} response The response
         * @param {object} options Options
         * @returns {*} Response data
         */
        parse: function (response, options) {
            if (options.collection) {
                return response;
            }
            return response.data;
        }
    });

    z.OrderRescheduleModel = OrderRescheduleModel;

})(window.z = window.z || {}, Backbone, _);