"use strict";

(function(z, Backbone, _){

    z.OrderCommentForm = Backbone.View.extend({
        el: ".order-history-section",

        /**
         * Events
         */
        events: {
            'click #orderform-save-button': '_onFormSubmit',
            'click .delete-order-comment': '_onDeleteComment',
            'click .edit-order-comment': '_onEditComment',
            'click #order-comment-button-cancel-edit': '_onEditCancel'
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            // After the customer is edited, the comments box should be updated also
            $(document).on("comments.updateComments", function() {

                this._loadCommentsBox();
            }.bind(this));

            // Initializing backbone validation
            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: this.model
            });

            // Setting order id on collection
            this.collection.setOrderId(this._getOrderId());


            // Load the comments box
            this._loadCommentsBox();

            // Listening on collection events
            this.listenTo(this.collection, 'add', this._onCommentAdd);
            this.listenTo(this.collection, 'remove', this._onCollectionRemove);
            this.listenTo(this.collection, 'sync', this._onCollectionFinished);

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Hide the comments form
                this.$el.find('form').hide();

                // Remove grid buttons
                this._removeGridButtons();

            }.bind(this));

        },

        /**
         * Method that hides the wrapper that holds the table
         *
         * @param {string} wrapperName - the name of the div that acts as a wrapper
         * @private
         * @return {undefined}
         */
        _hideWrapper: function(wrapperName) {
            wrapperName.addClass("hidden");
        },

        /**
         * Loads the comments collection & box
         *
         * @private
         * @return {undefined}
         */
        _loadCommentsBox: function() {

            // Listening on request to show loader
            this.listenTo(this.collection, 'request', this._onCollectionLoad);

            // Initializing collection
            this._initializeCollection();
        },

        /**
         * Method that initializes the collection
         *
         * @return {undefined}
         */
        _initializeCollection: function()
        {
            this.collection.fetch();
        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function (view, attr) {
            this._dataValidation(attr);
        },

        /**
         *  Method that is called if the form is invalid
         *
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @param {Object} error Validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function (view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method that validates the fields in the form
         *
         * @param {Object} attr The attribute being set
         * @private
         * @return {undefined}
         */
        _dataValidation: function (attr) {
            var $fields = this.$('[data-validation]');
            if (attr) {
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that returns the view's form element
         *
         * @private
         * @return {*} Returns form element
         */
        _getFormEl: function() {
            return this.$('#order-comment-form');
        },

        /**
         * Method called after user submits the vendor capacities form
         *
         * @param {object} event The jQuery event object
         * @private
         * @return {undefined}
         */
        _onFormSubmit: function(event){
            event.preventDefault();
            this._submitForm();
        },

        /**
         * Method used when the collection is loading
         *
         * @private
         * @return {undefined}
         */
        _onCollectionLoad: function()
        {
            this._onLoad(true);
        },

        /**
         * Method used when the collection finished loading
         *
         * @private
         * @return {undefined}
         */
        _onCollectionFinished: function()
        {
            this._onLoad(false);
        },

        /**
         * Method that shows the loader
         *
         * @param {bool} loading Whether to show the loader or not
         * @private
         * @return {undefined}
         */
        _onLoad: function(loading)
        {
            if( loading ){
                this.$('.order-history').addClass('sk-loading');
            } else {
                this.$('.order-history').removeClass('sk-loading');
            }
        },

        /**
         * Retrieves the normalized form data
         *
         * @private
         * @return {object} The form data
         */
        _getFormRecord: function(){
            return {
                comment: this.$('#orderform-comment-field').val()
            };
        },

        /**
         * Retrieves the order id if it is available
         * @returns {number|null} The order id
         * @private
         */
        _getOrderId: function(){

            return z.boot.order.id;
        },

        /**
         * Submit the form
         *
         * @private
         * @return {undefined}
         */
        _submitForm: function(){

            this.model.clear();

            if (this.commentEditId) {
                var row = this.collection.get(this.commentEditId);
                this.model.set(row.toJSON());
            }

            var record = this._getFormRecord();

            this.model.set(record);
            this._prepareModel(this.model);

            if (!this.model.isValid(true)) {
                return;
            }

            this._isLoading();

            if (this.commentEditId) {

                var addingButtonTitle = Translator.trans('js.btn_add');
                this.$('#orderform-save-button').text(addingButtonTitle);
                this.$('#order-comment-button-cancel-edit').addClass('hidden');
            }

            var comment = new z.OrderCommentModel(this.model.toJSON());

            comment.save(null, {
                success: function () {

                    // Escape the comment string when it's added automatically to the collection
                    comment.set('comment', _.escape(comment.get('comment')));

                    if (this.commentEditId) {
                        var item = this.collection.get(this.commentEditId);
                        item.set(comment.toJSON());
                        this._renderCommentRow(comment);
                    } else {
                        this.collection.add(comment);
                    }

                    this.commentEditId = null;

                    this._resetForm();

                    this._showSuccess(Translator.trans('js.success_message_order_comment'));

                }.bind(this),
                error: function () {

                    this._showError(Translator.trans('js.error_message_order_comment'));

                }.bind(this)
            });

        },

        /**
         * Method that prepares the model to be saved
         *
         * @param {Object} model The model
         * @private
         * @return {undefined}
         */
        _prepareModel: function(model)
        {
            model.set('order', this._getOrderId());
        },

        /**
         * Method that resets the form
         *
         * @private
         * @return {undefined}
         */
        _resetForm: function()
        {
            var $form = this._getFormEl();
            $form[0].reset();
        },

        /**
         * Enables or disables the loading mask
         *
         * @param {bool} loading Should show or hide the mask?
         * @private
         * @return {undefined}
         */
        _isLoading: function (loading) {
            loading = loading ? loading : _.isUndefined(loading);
            var $form = this._getFormEl();
            var $submitBtn = $form.find('button');
            var $formFields = $form.find('fieldset');

            var ladda = $submitBtn.data('ladda') ? $submitBtn.data('ladda') : $submitBtn.ladda().data('ladda');

            if (loading) {
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Method that gets triggered when an element is added
         * into the collection
         *
         * @param {Object} model The model that is being checked
         * @private
         * @return {undefined}
         */
        _onCommentAdd: function(model)
        {
            if (this.collection.size() >= 0) {
                this._renderCommentRow(model);
            }
        },

        /**
         * Rendering the comment row
         *
         * @param {Object} model The model that is being checked
         * @param {bool} remove - true for is delete, else false
         * @private
         * @return {undefined}
         */
        _renderCommentRow: function(model, remove)
        {
            var $tableName = this.$('#order-comments');

            $tableName.removeClass('hidden');

            var comment = model.get('text');

            remove = _.isUndefined(remove) ? false : remove;

            if (model.get('text') === undefined) {
                comment = model.get('comment');
            }

            var createdAt = moment(model.get("createdAt")).format('DD-MM-YYYY - H:mm');
            var id = model.get('id') ? model.get('id') : model.cid;
            var $row = $tableName.find('tbody tr[data-id="' + id + '"]');
            var $deleteIcon = $('<div class="delete-order-comment btn"><i class="fa fa-trash-o"></i></div>');
            var $editIcon = $('<div class="edit-order-comment btn"><i class="fa fa-pencil"></i></div>');

            if( remove ){

                $row = $tableName.find('tbody tr[data-id="' + id + '"]');

                $row.remove();
                this.collection.remove(model.cid);

                // If there are no items in the collection, remove wrapper and table
                if (this.collection.size() === 0) {
                    this._hideWrapper($tableName);
                }
            } else if (this.commentEditId) {
                $row.find('td:eq(0)').text(createdAt);

                if (!_.isUndefined(model.get('user'))) {
                    $row.find('td:eq(1)').text(model.get("user").firstName + " " + model.get("user").lastName);
                } else {
                    $row.find('td:eq(1)').text('-');
                }

                $row.find('td:eq(2)').html(model.get('comment'));
            } else {
                $row = $('<tr data-id="' + model.get('id') + '">');
                // Created at
                $('<td>')
                    .text(createdAt)
                    .appendTo($row);

                if (!_.isUndefined(model.get('user'))) {

                    // User
                    $('<td>')
                        .text(model.get("user").firstName + " " + model.get("user").lastName)
                        .appendTo($row);

                } else {

                    // User
                    $('<td>')
                        .text('-')
                        .appendTo($row);
                }

                // Comment
                $('<td>')
                    .html(comment)
                    .appendTo($row);

                //edit icon
                if (model.get('editable')) {
                    $('<td>')
                        .append($editIcon)
                        .appendTo($row);
                } else {
                    $('<td>')
                        .append(' ')
                        .appendTo($row);
                }

                //Delete icon
                if (model.get('deletable')) {
                    $('<td>')
                        .append($deleteIcon)
                        .appendTo($row);
                } else {
                    $('<td>')
                        .append(' ')
                        .appendTo($row);
                }


                $tableName.find("tbody").append($row);
            }
        },

        /**
         * On delete comments event
         * @param {Event} e - delete event
         * @private
         * @return {undefined}
         */
        _onDeleteComment: function(e) {

            var id = $(e.target).parents('tr').attr("data-id");

            var comment = this.collection.get(id);
            var order = comment.get('order');

            // In the Backbone request we need only the order id for the order field
            comment.set('order', order.id);

            //Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.order_delete_order_comment_confirm_window_title'),
                text: Translator.trans('js.order_delete_order_comment_confirm_window_message'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.order_delete_order_comment_confirm_btn')
            }).then(function () {

                // Delete the attachment
                this._deleteComment(comment);

            }.bind(this)).catch(function(){
                comment.set('order', order);
            });
        },

        /**
         * Delete the order comment
         * @param {Object} comment - the order attachment model
         * @private
         * @return {undefined}
         */
        _deleteComment: function(comment) {

            // Delete the comment
            comment.destroy({

                // Waits for the server response before removing the model from collection
                wait: true,

                // On success
                success: function() {

                    this._onEditCancel();
                    this._showSuccess(Translator.trans('js.order_delete_order_comment_success_message'));
                    this._renderCommentRow(comment, true);
                    this._onLoad(false);

                }.bind(this),

                // On error
                error: function() {

                    this._showError(Translator.trans('js.order_delete_order_comment_error_message'));

                }.bind(this)
            });
        },

        /**
         * Display the success message
         *
         * @param {string} message The message to be displayed
         * @private
         * @return {undefined}
         */
        _showSuccess: function(message){

            this._isLoading(false);

            var $success = this._getInformationPanel();
            $success
                .removeClass('hidden')
                .text(message)
                .fadeTo('slow', 1)
                .delay(5000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Display the error message
         *
         * @param {string} message The message to be shown
         * @private
         * @return {undefined}
         */
        _showError: function(message) {

            this._isLoading(false);

            var $error = this._getInformationPanel();
            $error
                .removeClass('hidden alert-success')
                .addClass('alert-danger')
                .text(message)
                .fadeTo('slow', 1)
                .delay(5000)
                .fadeTo('slow', 0, function(){
                    $error.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that returns the name of the information panel
         *
         * @private
         * @return {*} The information panel
         */
        _getInformationPanel: function() {
            return this.$('#orderform-information-panel');
        },

        /**
         * On edit function
         * @param {Event} e - edit event
         * @private
         * @return {undefined}
         */
        _onEditComment: function (e) {
            var editingButonTitle = Translator.trans('js.btn_edit');
            this.$('#orderform-save-button').text(editingButonTitle);

            this.$('#order-comment-button-cancel-edit').removeClass('hidden');

            var id = $(e.target).parents('tr').attr("data-id");

            var commentRow = this.collection.get(id);
            this.commentEditId = id;

            this.$('#orderform-comment-field').val(_.unescape(commentRow.get('comment')));
        },

        /**
         * Method called when the cancel button is pressed
         * @private
         * @return {undefined}
         */
        _onEditCancel: function () {
            this._resetForm();
            var addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#orderform-save-button').text(addingButtonTitle);
            this.commentEditId = null;
            this.$('#order-comment-button-cancel-edit').addClass('hidden');
        },

        /**
         * Remove comment grid buttons
         * @private
         * @return {undefined}
         */
        _removeGridButtons: function() {

            this.$('.edit-order-comment').remove();
            this.$('.delete-order-comment').remove();
        }
        
    });

})(window.z = window.z || {}, Backbone, _);
