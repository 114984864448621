"use strict";

(function(z, Backbone){

    var ServiceConfigModel = Backbone.Model.extend({

        /**
         * Url for this model
         * @return {string} The url
         */
        url: function(){

            return '/api/services/' + this.serviceId + '/config';
        },

        /**
         * Set service id
         * @param {string} id The service id
         * @return {undefined}
         */
        setServiceId: function(id){

            this.serviceId = id;
        }
    });

    z.ServiceConfigModel = ServiceConfigModel;

})(window.z = window.z || {}, Backbone);
