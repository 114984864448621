"use strict";

(function (z, _, $) {

    z.ServiceOptionsForm = z.ServiceGridForm.extend({

        ENTER_KEY: 13,

        el: "#serviceconfig-options-form",

        /**
         * Events
         * @return {void|*} The events
         */
        events: {
            'click #serviceconfig-optionSave-btn': '_onSubmitClick',
            'click #serviceconfig-optionCancel-btn': '_onCancelClick',
            'click #serviceconfig-options-grid .edit-option': '_onEditClick',
            'click #serviceconfig-options-grid .delete-option': '_onDeleteClick',
            'keydown #serviceconfig-optionName-field': '_onNameKeydown',
            'keydown #serviceconfig-optionValue-field': '_onValueKeydown',
            'keydown #serviceconfig-optionPercent-field': '_onPercentKeydown'
        },

        /**
         * Selectors
         */
        selectors: {
            'form.btn.cancel': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-optionCancel-btn');
            },
            'form.btn.save': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-optionSave-btn');
            },
            'grid.table': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-options-grid');
            },
            'grid.table.body': function () {
                var $table = this.getEl('grid.table');
                return $table.find('tbody');
            },
            'grid.table.value': function () {
                var $table = this.getEl('grid.table');
                return $table.find('.table-value-col');
            },
            'grid.table.percent': function () {
                var $table = this.getEl('grid.table');
                return $table.find('.table-percent-col');
            },
            'grid.empty': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-options-emptylist');
            },
            'field.name': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-optionName-field');
            },
            'field.value': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-optionValue-field');
            },
            'field.percent': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-optionPercent-field');
            },
            'group.value': function () {
                var $el = this.getEl('field.value');
                return $el.parents('.form-group:first');
            },
            'group.percent': function () {
                var $el = this.getEl('field.percent');
                return $el.parents('.form-group:first');
            }
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function () {

            z.ServiceGridForm.prototype.initialize.apply(this, arguments);

            this.listenTo(z.serviceParamsForm, 'edit', this._onParamEdit);
            this.listenTo(z.serviceParamsForm, 'reset', this._onParamFormReset);
        },

        /**
         * Method called when the param form gets reset
         * @param {object} param The parameter
         * @return {undefined}
         * @private
         */
        _onParamFormReset: function (param) {

            this.param = param;

            this._reset();

            this.collection.reset();
        },

        /**
         * Method called when the user edits a parameter
         * @param {object} param The parameter
         * @return {undefined}
         * @private
         */
        _onParamEdit: function (param) {

            this.param = param;

            var options = this.param.get('options');

            this.collection.reset(options);
        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr){

            var $form = this.getEl();

            $form
                .find('[data-validation="' + attr + '"]')
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error){

            var $form = this.getEl();

            $form
                .find('[data-validation="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after the keydown event
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onNameKeydown: function(event){

            if (event.which !== this.ENTER_KEY) {
                return;
            }

            event.preventDefault();

            this._submitForm();
        },

        /**
         * Method called after the keydown event
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onValueKeydown: function(event){

            if (event.which !== this.ENTER_KEY) {
                return;
            }

            event.preventDefault();

            this._submitForm();
        },

        /**
         * Method called after the keydown event
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onPercentKeydown: function(event){

            if (event.which !== this.ENTER_KEY) {
                return;
            }

            event.preventDefault();

            this._submitForm();
        },

        /**
         * Resets the entire view
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _reset: function(model){

            model = _.isUndefined(model) ? new z.ServiceOptionModel() : model;

            z.ServiceGridForm.prototype._reset.apply(this, [model]);
        },

        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        _resetForm: function () {

            z.ServiceGridForm.prototype._resetForm.apply(this, arguments);

            // we can't use form.reset() because this is not a form (you cannot include a form inside another form)
            // so instead we have to manually reset all the fields
            var $name = this.getEl('field.name');
            var $value = this.getEl('field.value');
            var $percent = this.getEl('field.percent');

            $name.val('');
            $value.val('');
            $percent.val('');
        },

        /**
         * Builds the html grid row for the supplied option
         * @param {object} option The param's option
         * @return {*|jQuery|HTMLElement} The table row
         * @private
         */
        _buildGridRow: function (option) {

            var id = option.get(option.idAttribute) ? option.get(option.idAttribute) : option.cid;

            var $row = $('<tr>')
                .attr('data-id', id);

            $('<td>')
                .text(option.get('name'))
                .appendTo($row);

            $('<td>')
                .addClass('text-center table-value-col')
                .text(option.get('value'))
                .appendTo($row);

            $('<td>')
                .addClass('text-center table-percent-col')
                .text(option.get('percent'))
                .appendTo($row);

            var $editBtn = $('<a>')
                .addClass('btn btn-primary btn-xs edit-option')
                .append(
                    $('<i>')
                        .addClass('fa fa-pencil')
                );

            var $deleteBtn = $('<a>')
                .addClass('btn btn-danger btn-xs delete-option')
                .append(
                    $('<i>')
                        .addClass('fa fa-trash')
                );

            $('<td>')
                .addClass('text-center table-action-col')
                .append($editBtn, ' ', $deleteBtn)
                .appendTo($row);

            return $row;
        },

        /**
         * Retrieves the data from the form
         * @return {object} The form data
         * @private
         */
        _getFormRecord: function () {

            var $name = this.getEl('field.name');
            var $value = this.getEl('field.value');
            var $percent = this.getEl('field.percent');

            var value = $value.val();
            var percent = $percent.val();

            return {
                name: $name.val(),
                value: value.length ? value : 0,
                percent: percent.length ? percent : 0
            };
        },

        /**
         * Sets the fields which the validation will check
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _prepareModelValidation: function(model){

            var isValueRequired = false;
            var isPercentRequired = false;

            var increaseType = parseInt(this.param.get('increaseType'));

            if (increaseType === z.serviceParamsForm.INCREASE_VALUE) {

                isValueRequired = true;
            }

            if (increaseType === z.serviceParamsForm.INCREASE_PERCENT) {

                isPercentRequired = true;
            }

            model.set({
                isValueRequired: isValueRequired,
                isPercentRequired: isPercentRequired
            });
        },

        /**
         * Loads the supplied option into the form
         * @param {object} option The option model
         * @return {undefined}
         * @private
         */
        _loadModel: function (option) {

            var $nameField = this.getEl('field.name');
            var $value = this.getEl('field.value');
            var $percent = this.getEl('field.percent');

            if (option.get('name')) {

                $nameField.val(option.get('name'));
            }

            if (option.get('value')) {

                $value.val(option.get('value'));
            }

            if (option.get('percent')) {

                $percent.val(option.get('percent'));
            }

            $nameField.trigger('change');
            $value.trigger('change');
            $percent.trigger('change');
        }
    });

})(window.z = window.z || {}, _, $);
