'use strict';

(function(app, Backbone, _){

    app.OrderAttachmentForm = z.AttachmentFormView.extend({

        /**
         * Element
         */
        el: '#order-attachment-formpanel',

        /**
         * Events
         */
        events: {
            'submit #order-attachment-form': '_onFormSubmit'
        },

        /**
         * Selectors
         */
        selectors: {
            'form': function(){
                var $el = this.getEl();
                return $el.find('form');
            },
            'form.submit': function(){
                var $form = this.getEl('form');
                return $form.find('button[type="submit"]');
            },
            'form.fieldset': function(){
                var $form = this.getEl('form');
                return $form.find('fieldset');
            },
            'form.comment': function(){
                var $form = this.getEl('form');
                return $form.find('#attachment-comment-field');
            },
            'form.file': function(){
                var $form = this.getEl('form');
                return $form.find('#attachment-file-field');
            }
        },

        /**
         * Constructor
         *
         * @return {undefined}
         */
        initialize: function(){

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: this.model
            });

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Hide the attachments form
                this.$el.find('form').hide();

            }.bind(this));

            z.AttachmentFormView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Retrieves the cached element from selectors
         * @param {string} key The key
         * @param {bool} fresh Should we fetch it again
         * @returns {*} The element or undefined
         */
        getEl: function(key, fresh){

            if (_.isUndefined(key) || key === 'el') {
                return this.$el;
            }

            var selectors = _.result(this, 'selectors');

            if (!selectors) {
                return null;
            }

            var cache = this.elsCache = this.elsCache || {};

            if (!_.has(cache, key) || fresh) {

                var selector = _.isFunction(selectors[key]) ? selectors[key].call(this) : selectors[key];

                var result = null;

                if (selector) {

                    var $query = $(selector); // result of $($($('.class'))) is the same as $('.class')

                    result = $query.length ? $query : null;
                }

                cache[key] = result;
            }

            return cache[key];
        },

        /**
         * Retrieves the order id if it is available,
         * this method is also used in the grid.
         * @returns {number|null} The order id
         */
        getOrderId: function(){

            return z.boot.order.id;
        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr){

            var $form = this.getEl('form');

            $form
                .find('[data-validation="' + attr + '"]')
                .removeClass('has-error')
                .find('.help-block:first')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error){

            var $form = this.getEl('form');

            $form
                .find('[data-validation="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block:first')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after the form is submitted
         * @param {object} event The jQuery event object
         * @return {undefined}
         * @private
         */
        _onFormSubmit: function(event){

            event.preventDefault();

            this._submitForm();
        },

        /**
         * Retrieves the normalized form data
         * @return {object} The form data
         * @private
         */
        _getFormRecord: function(){

            return {
                comment: this.getEl('form.comment').val(),
                order: this.getOrderId()
            };
        },

        /**
         * Retrieves the FormData record
         * @returns {FormData} The form data
         * @private
         */
        _getFormDataRecord: function(){

            var $form = this.getEl('form');
            var $file = this.getEl('form.file');
            var name = $form.attr('name');
            var file = $file.get(0).files[0];
            var record = this._getFormRecord();
            var data = new FormData();

            data.append(name + '[order]', record.order);
            data.append(name + '[file]', file);
            data.append(name + '[comment]', record.comment);

            return data;
        },

        /**
         * Submit the form
         * @return {undefined}
         * @private
         */
        _submitForm: function(){

            this._prepareModelData(this.model);

            if (!this.model.isValid(true)) {

                return;
            }

            var $file = this.getEl('form.file');

            if (!this.isValidUpload($file, true)) {

                return;
            }

            var invalid = function(error){
                this._onInvalid(this, 'file', error);
            }.bind(this);

            var valid = function(){
                this._onValid(this, 'file');
            }.bind(this);

            this._isLoading();

            this._uploadFile({
                success: function(response){

                    this._isLoading(false);

                    valid();

                    var records = response.data;

                    app.orderAttachmentGrid.collection.add(records);

                    this._resetForm();

                }.bind(this),
                error: function(res){

                    this._isLoading(false);

                    var result = res.responseJSON;

                    if (result.errors && result.errors.file) {

                        invalid(Translator.trans('js.error_message_attachmentform_name_invalidtype'));

                        return;
                    }

                    invalid(Translator.trans('js.error_message_attachmentform_name_invalidform'));

                }.bind(this)
            });
        },

        /**
         * Resets the form with the default values
         * @return {undefined}
         */
        _resetForm: function(){

            this.getEl('form').get(0).reset();
        },

        /**
         * Sends the request to the server
         * @param {Object} options Additional ajax options
         * @return {undefined}
         * @private
         */
        _uploadFile: function(options){

            options = options || {};

            options.data = this._getFormDataRecord();
            options.processData = false;
            options.contentType = false;

            var url = _.result(this.model, 'url');

            app.request.make(url, null, null, options);
        },

        /**
         * Prepare model with all the necessary fields
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _prepareModelData: function(model){

            var record = this._getFormRecord();

            model.clear();

            model.set(record);
        },

        /**
         * Enables or disables the loading mask
         * @param {bool} loading Should show or hide the mask?
         * @return {undefined}
         * @private
         */
        _isLoading: function (loading) {

            loading = loading ? loading : _.isUndefined(loading);

            var $submitBtn = this.getEl('form.submit');
            var $formFields = this.getEl('form.fieldset');

            var ladda = $submitBtn.data('ladda') ? $submitBtn.data('ladda') : $submitBtn.ladda().data('ladda');

            if (loading) {

                ladda.start();

                $formFields.attr('disabled', true);

            } else {

                ladda.stop();

                $formFields.removeAttr('disabled');
            }
        }
    });

})(window.z = window.z || {}, Backbone, _);