"use strict";

(function(z){

    z.discountForm = z.FormView.extend({


        DATE_FORMAT: 'DD-MM-YYYY',
        el: "#orderdiscountform-modal",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            z.FormView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {
        },
        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addDiscount';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editDiscount';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_discount');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_discount');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#orderdiscount-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#orderdiscount-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return '/discounts';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'discount.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_discountForm');
        },

        /**
         * Set custom labels for the reseller modal form
         *
         * @param {string} action - action name
         * @return {undefined}
         */
        setCustomLabels: function(action) {

            // todo uncomment when API Details will be implemented
            switch (action) {
                case 'add':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
                    break;
                case 'edit':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         */
        getFormData: function() {

            var $valueField         = this.$('#orderdiscountform-value-field');
            var $typeField          = this.$('#orderdiscountform-type-field');

            return {
                'value': $valueField.val(),
                'type': $typeField.val(),
            };

        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                'orderdiscountform-value-field'           : 'value',
                'orderdiscountform-type-field'            : 'type',
            };

            Object.keys(map).forEach(function(key){

                var value = this.model.get(map[key]);
                switch (key) {

                    case "orderdiscountform-type-field":
                        if(typeof value === 'undefined'){
                            return;
                        }
                        debugger;
                        this.$("#" + key)
                            .find('option[value=' + value + ']').prop('selected', true)
                            .end().trigger('chosen:updated');
                        break;

                    case "orderdiscountform-value-field":

                        if(typeof value === 'undefined'){

                            this.$el.find("#"+key).val(0);
                        } else {

                            this.$el.find("#"+key).val(value);
                        }
                        break;

                    default:
                        this.$el.find("#"+key).val(value);
                }

            }.bind(this));
        },

        /**
         * Method that edits the entity when the correct route is being reached.
         *
         * @param {int} id - the vendor id that is being taken from the request
         * @param {int} discountId - the discount id that is being taken from the request
         * @private
         * @return {undefined}
         */
        _onRouterEditEntity: function(id){

            this._beforeEditEntity(id);

            this._setLabels('edit');

            this.resetForm();

            this.model.set('id', id);

            this.model.fetch({
                success: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
            this._showModal();
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.discount_create_error_message_mobile');
            this.$el.find('#discount-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#discount-create-general-error-panel').addClass('hidden');
        },
    });
})(window.z = window.z || {}, _);