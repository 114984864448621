"use strict";

(function(z, Backbone){

    var LocalityModel = Backbone.Model.extend({

    });

    z.LocalityModel = LocalityModel;

})(window.z, Backbone);
