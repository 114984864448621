"use strict";

(function(z){

    z.tariffVendorPromotionForm = z.FormView.extend({

        DATE_FORMAT: 'DD-MM-YYYY',
        el: "#tariffPromotionForm-modal",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            z.FormView.prototype.initialize.apply(this, arguments);

            this.promotionId = this._getPromotionId();
            this.initializeEvents();
            this._initializeDateFields();
        },
        _initializeDateFields: function() {

            $('.datepicker').daterangepicker({
                autoApply: true,
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: false,
                locale: {
                    format: this.DATE_FORMAT
                }
            });
            // this is official solution for initial empty field
            this.$('#tariffPromotionForm-dateStart-field').on('apply.daterangepicker', function (ev, picker) {
                this.$('#tariffPromotionForm-dateStart-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            this.$('#tariffPromotionForm-dateStart-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
            this.$('#tariffPromotionForm-dateEnd-field').on('apply.daterangepicker', function (ev, picker) {
                this.$('#tariffPromotionForm-dateEnd-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            this.$('#tariffPromotionForm-dateEnd-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
            $('#tariffPromotion-dateStart-field').on('apply.daterangepicker', function (ev, picker) {
                $('#tariffPromotion-dateStart-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            $('#tariffPromotion-dateStart-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
            $('#tariffPromotion-dateEnd-field').on('apply.daterangepicker', function (ev, picker) {
                $('#tariffPromotion-dateEnd-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            $('#tariffPromotion-dateEnd-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {
            this.$("#tariffPromotionForm-service-field").chosen({
                search_contains: true,
                width: "100%"
            });
        },
        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addTariffPromotion';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editTariffPromotion';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_tariff_promotion');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_tariff_promotion');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#tariffPromotionForm-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#tariffPromotionForm-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'tariff-vendor-promotion' + this._getPromotionId();
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'tariffPromotion.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_tariffPromotionForm');
        },

        /**
         * Set custom labels for the promotion modal form
         *
         * @param {string} action - action name
         * @return {undefined}
         */
        setCustomLabels: function(action) {

            // todo uncomment when API Details will be implemented
            switch (action) {
                case 'add':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
                    break;
                case 'edit':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         */
        getFormData: function() {

            var $typeField          = this.$('#tariffPromotionForm-type-field');
            var $valueField         = this.$('#tariffPromotionForm-value-field');
            var $dateStartField = this.$('#tariffPromotionForm-dateStart-field');
            var $dateEndField           = this.$('#tariffPromotionForm-dateEnd-field');

            var $return =  {
                'type' : $typeField.val(),
                'value': $valueField.val(),
                'dateStart': $dateStartField.val(),
                'dateEnd': $dateEndField.val(),
            };

            return $return;
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                'tariffPromotionForm-type-field'            : 'type',
                'tariffPromotionForm-value-field'              : 'value',
                'tariffPromotionForm-dateStart-field'          : 'dateStart',
                'tariffPromotionForm-dateEnd-field'            : 'dateEnd',
            };
            Object.keys(map).forEach(function(key){
                var value = this.model.get(map[key]);
                switch (key) {

                    case "tariffPromotionForm-type-field":
                        if(typeof value === 'undefined'){
                            return;
                        }
                        this.$("#" + key)
                            .find('option[value=' + value + ']').prop('selected', true)
                            .end().trigger('chosen:updated');
                        break;

                    case "tariffPromotionForm-value-field":

                        if(typeof value === 'undefined'){

                            this.$el.find("#"+key).val(0);
                        } else {

                            this.$el.find("#"+key).val(value);
                        }
                        break;

                    case "tariffPromotionForm-dateStart-field":
                    case "tariffPromotionForm-dateEnd-field":

                        if(typeof value === 'undefined'){

                            this.$el.find("#"+key).val('');
                        } else {
                            var now = new Date(value);
                            var day = ("0" + now.getDate()).slice(-2);
                            var month = ("0" + (now.getMonth() + 1)).slice(-2);
                            var date = (day) +"-"+(month)+"-" + now.getFullYear();
                            this.$el.find("#"+key).val(date);
                        }

                        break;

                    default:
                        this.$el.find("#"+key).val(value);
                }

            }.bind(this));
        },

        /**
         * Retrieves the promotion id if it is available
         * @returns {number|null} The promotion id
         * @private
         */
        _getPromotionId: function() {

            var regex = /^\/tariff-vendor-promotion\/(\d+)(.*)$/g;
            var result = regex.exec(window.location.pathname);

            if (!result) {

                return null;
            }

            return result[1];
        },

        /**
         * Method that edits the entity when the correct route is being reached.
         *
         * @param {int} id - the promotion id that is being taken from the request
         * @param {int} tariffPromotionId - the tariffPromotion id that is being taken from the request
         * @private
         * @return {undefined}
         */
        _onRouterEditEntity: function(id, tariffPromotionId){
            this._beforeEditEntity(tariffPromotionId);
            this._setLabels('edit');
            this.resetForm();
            this.model.set('id', tariffPromotionId);

            this.model.fetch({
                success: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
            this._showModal();
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.tariff_promotion_create_error_message_mobile');
            this.$el.find('#tariff_promotion-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#tariff_promotion-create-general-error-panel').addClass('hidden');
        },
    });

})(window.z = window.z || {}, _);