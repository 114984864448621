'use strict';

(function(z, _) {

    /**
     * Tariff association grid
     */
    z.DiscountGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#discount-page",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click .discount-edit-button": "onEditEntry",
                "click .discount-delete-button": "onDeleteEntry",
                "click .discount-approve-button": "onApproveEntry",
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-discount-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {

            return '/discounts';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'discount-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_discount_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'discount.success';
        },
        onApproveEntry: function(event) {
            event.preventDefault();
            this.entryId = $(event.currentTarget).attr('data-id');

            // Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.discount_approve_confirm_window_title'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.discount_approve_confirm_btn')
            }).then(function () {
                this._approveEntry(this.entryId);
            }.bind(this)).catch(swal.noop);
        },
        _approveEntry: function(id){

            var url =  '/api/discount/approve/' + id;
            var opts = {
                method      : 'PUT',
                dataType    : 'html',
                contentType : 'text/plain'
            };

            z.request.make(url, null, function(jqXhr, status){
                debugger;
                if (status !== 'success') {
                    return;
                }
                this.applyFilters();
            }.bind(this), opts);

        },
        /**
         * Method that opens a confirm box when an entry is deleted
         *
         * @param {Event} event click event
         * @return {undefined}
         */
        onDeleteEntry: function(event) {

            event.preventDefault();

            this.entryId = $(event.currentTarget).attr('data-id');

            // Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.discount_cancel_confirm_window_title'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.discount_cancel_confirm_btn')
            }).then(function () {
                this._deleteEntry(this.entryId);
            }.bind(this)).catch(swal.noop);
        },
        /**
         * Delete the entry
         *
         * @param {int} id - entry id
         * @return {undefined}
         * @private
         */
        _deleteEntry: function(id) {

            var entryModel = new z.DiscountModel({ id: id });

            // Delete the model
            entryModel.destroy({

                // On success
                success: function() {

                    // On success, apply the filters
                    this.applyFilters();

                }.bind(this),

                // on error
                error: function() {

                    // todo

                }.bind(this)
            });
        }

    });

})(window.z = window.z || {}, _);
