"use strict";

(function(z, Backbone){

    var PriceRuleModel = Backbone.Model.extend({
        urlRoot: "/api/price-rules",
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_priceruleform_name_required")
            }, {
                minLength: 1,
                msg: Translator.trans("js.error_message_priceruleform_name_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_priceruleform_name_maxlength")
            }],
            service: [{
                required: true,
                msg: Translator.trans("js.error_message_priceruleform_service_required")
            }],
            status: [{
                required: true,
                msg: Translator.trans("js.error_message_priceruleform_status_required")
            }],
            remainingDays: [{
                required: true,
                msg: Translator.trans("js.error_message_priceruleform_remainingDays_required")
            },{
                min: 0,
                msg: Translator.trans("js.error_message_priceruleform_remainingDays_min_value")
            }],
            capacityLoad: [{
                required: true,
                msg: Translator.trans("js.error_message_priceruleform_capacityLoad_required")
            },{
                min: 0,
                msg: Translator.trans("js.error_message_priceruleform_capacityLoad_min_value")
            },{
                max: 100,
                msg: Translator.trans("js.error_message_priceruleform_capacityLoad_max_value")
            }],
            priceAction: [{
                required: true,
                msg: Translator.trans("js.error_message_priceruleform_priceAction_required")
            },{
                min: 0,
                msg: Translator.trans("js.error_message_priceruleform_priceAction_min_value")
            }],
            exceptions: [{
                required: false
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.PriceRuleModel = PriceRuleModel;

})(window.z = window.z || {}, Backbone);
