"use strict";

(function(z, Backbone, _){

    var OrderScheduleModel = Backbone.Model.extend({

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function(){
            return '/api/orders/' + this.orderId + '/schedule';
        },

        validation: {
            offer: [{
                required: true,
                msg: Translator.trans("js.error_message_orderform_offer_required")
            }]
        },

        parse: function(response) {
            return response.data;
        },

        /**
         * Setting the order id on the model
         * @param {int} id The order id
         * @returns {OrderScheduleModel} The Order Schedule Model
         */
        setOrderId: function(id){
            this.orderId = id;
            return this;
        }
    });

    z.OrderScheduleModel = OrderScheduleModel;

})(window.z = window.z || {}, Backbone, _);