"use strict";

(function(z, _, Backbone){

    var ServiceParamsCollection = Backbone.Collection.extend({

        /**
         * The model
         */
        model: z.ServiceParamModel,

        /**
         * Get the URL
         * @return {string} - the url
         */
        url: function(){
            return '/api/services/' + this.serviceId + '/params';
        },

        /**
         * Parse the response
         * @param {Object} response - response
         * @return {*} - response data
         */
        parse: function (response) {

            _.each(response.data, function(param){

                // denormalize
                param.overridesBasePrice = param.overridesBasePrice ? 1 : 0;

            }, this);

            return response.data;
        },

        /**
         * Sets service id
         * @param {string|int} id The service id
         * @return {Backbone.Collection} Return myself
         */
        setServiceId: function(id){

            this.serviceId = id;

            return this;
        }
    });

    z.ServiceParamsCollection = ServiceParamsCollection;

})(window.z = window.z || {}, _, Backbone);