'use strict';
(function (z) {
    /**
     * Price Rule grid
     */
    z.OrderGrid = z.FiltersView.extend({

        /**
         * Bounding element
         */
        el: "#page-wrapper",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        countyFieldSelector: 'ordersfilters-county-field',
        localityFieldSelector: 'ordersfilters-locality-field',

        /**
         * Events function
         * @return {*} events
         */
        events: function () {
            return _.extend({}, z.FiltersView.prototype.events, {
                "change #ordersfilters-county-field": "_onCountyChange",
                "change #ordersfilters-showFinalStatusOrders-field": '_onFinalStatusChange',
                "change #ordersfilters-unhandledOrdersFromPast-field": '_onUnhandledChange'
            });
        },

        /**
         * Initialize function
         * @return {undefined}
         */
        initialize: function () {

            this.$(".chosen-select:not(.chosen-select-multiple)").chosen({
                search_contains: true,
                width: "100%"
            });

            this.$("#ordersfilters-status-field").chosen({
                search_contains: true,
                width: "100%",
                placeholder_text_multiple: Translator.trans('js.placeholder_choose_status')
            });

            this.$("#ordersfilters-paymentType-field").chosen({
                search_contains: true,
                width: "100%",
                placeholder_text_multiple: Translator.trans('js.placeholder_choose_payment_type')
            });

            this.$("#ordersfilters-paymentStatus-field").chosen({
                search_contains: true,
                width: "100%",
                placeholder_text_multiple: Translator.trans('js.placeholder_choose_payment_status')
            });

            var DATE_FORMAT = 'DD-MM-YYYY';
            this.$('.date-range-picker').daterangepicker({
                autoUpdateInput: false,
                showDropdowns: true,
                autoApply: true,
                locale: {
                    format: DATE_FORMAT
                }
            });

            this.$('#ordersfilters-statusUpdatedAt-field').oninvalid = function(event) {
                event.target.setCustomValidity('Sunt permise doar valori pozitive.');
            }
            // this is official solution for initial empty field
            this.$('.date-range-picker').on('apply.daterangepicker', function (ev, picker) {
                $(this).val(picker.startDate.format(DATE_FORMAT) + ' - ' + picker.endDate.format(DATE_FORMAT));
            });

            this.$('.date-range-picker').on('cancel.daterangepicker', function () {
                $(this).val('');
            });

            // Order::STATUS_FINALIZED and Order::STATUS_CANCELED
            this.finalStatus = ['4', '5'];
            //Order::STATUS_APPOINTED
            this.appointedStatus = '3';

            this.localitiesCollection = new z.LocalitiesCollection();

            this.listenTo(this.localitiesCollection, 'add', this._onLocalitiesCollectionAdd);
            this.listenTo(this.localitiesCollection, 'reset', this._onLocalitiesCollectionReset);

            z.GridView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Method that makes the request to get the data
         * for the localities select
         *
         * @private
         * @return {undefined}
         */
        _onCountyChange: function () {
            var id = this.$("#" + this.countyFieldSelector + " option:selected").val();
            this.localitiesCollection.setCountyId(id);
            this.localitiesCollection.reset(null);
            this.localitiesCollection.fetch({
                success: function () {
                    if (this.urlFilters.locality) {
                        this.$("#" + this.localityFieldSelector).val(this.urlFilters.locality);
                    }
                    this.$("#" + this.localityFieldSelector).trigger("chosen:updated");
                    this.$("#" + this.localityFieldSelector).trigger('load');

                }.bind(this)
            });
        },

        /**
         * Method that applies on final status checkbox change
         *
         * @param {object} e The event
         * @private
         * @return {undefined}
         */
        _onFinalStatusChange: function (e) {
            if (e.currentTarget.checked === true) {
                this._updateChosenField('ordersfilters-status-field', this.finalStatus);
            }
        },

        /**
         * Method that applies on unhandled checkbox change
         *
         * @param {object} e The event
         * @private
         * @return {undefined}
         */
        _onUnhandledChange: function (e) {
            if (e.currentTarget.checked === true) {
                this._updateChosenField('ordersfilters-status-field', this.appointedStatus);

                var start = moment().subtract(29, 'days');
                var end = moment().subtract(1, 'days');
                this.$('#ordersfilters-appointmentDate-field').daterangepicker({
                    startDate: start,
                    endDate: end
                });
            }
        },

        /**
         * Method that updated the chosen field
         *
         * @param {string} id The element id attribute
         * @param {string} value The value to set
         * @private
         * @return {undefined}
         */
        _updateChosenField: function (id, value) {
            this.$('#' + id).val(value);
            this.$('#' + id).trigger("chosen:updated");
        },

        /**
         * Method triggered on the locality collection add
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionAdd: function (model) {
            this._renderLocality(model);
        },

        /**
         * Method triggered on the locality collection reset
         *
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionReset: function () {
            this._renderLocalityReset();
        },

        /**
         * Method that renders the locality select
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _renderLocality: function (model) {
            var $option = $('<option>').attr('value', model.get('id')).text(model.get('name'));
            this.$('#' + this.localityFieldSelector).append($option);
        },

        /**
         * Method that resets the locality select
         *
         * @private
         * @return {undefined}
         */
        _renderLocalityReset: function () {
            var $option = this.$("#" + this.localityFieldSelector + " option:eq(0)").detach();
            this.$('#' + this.localityFieldSelector).empty().append($option);
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function () {
            return 'page-orders-datatable';
        },
        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function () {
            return '';
        },

        /**
         * Method that return the path for orders
         *
         * @return {string} - the url of the page
         */
        getPath: function () {
            return 'orders';
        },


        /**
         * Get individual order path
         * @param {int} id - order id
         * @returns {string} - the individual order path
         */
        getIndividualOrderPath: function(id) {
            return this.getPath() + '/' + id;
        },

        /**
         * Display a link to the individual order
         * @param {mixed} data - datatable param
         * @return {string} - the two names concatenated
         */
        displayLinkToIndividualOrder: function(data) {

            return '<a href="' + this.getIndividualOrderPath(data) + '" class="orderLink">' + data + '</a>';
        },

        /**
         * Color in red a td with an Order with status !==4 or 5, and appointmentDate < currentDate
         * @param {mixed} row - datatable param for the current row
         * @param {mixed} data - datatable param
         * @return {undefined}
         */
        markRow: function(row, data) {

            if (data.appointmentDate === null) {
                return;
            }

            var appointment = moment.unix(data.appointmentDate.timestamp);
            var now = moment();
            now = now.subtract(1, "days");

            if (data.status !== 4 && data.status !== 5 && appointment.isBefore(now)) {
                $(row).addClass( 'color-red' );
            }
        }

    });

})(window.z = window.z || {});