'use strict';

(function(z, _) {

    /**
     * Service grid
     */
    z.ServiceGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#service-page",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click #add-service-modal-btn": "onAddEntry",
                "click .service-edit-button": "onEditEntry",
                "click .service-configure-button": "onConfigureEntry"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$("#servicesfilters-category-field").chosen({
                search_contains: true
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-service-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return 'services';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'service-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_service_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'service.success';
        },

        /**
         * Method called after the user clicks the configure button from grid
         * @param {Object} event The event object
         * @return {undefined}
         */
        onConfigureEntry: function(event){

            event.preventDefault();
            var entryId = $(event.currentTarget).data('id');
            z.router.navigate(z.serviceConfigForm.getPagePath() + z.serviceConfigForm.CONFIGURE_URL + '/' + entryId, {trigger:true});
        }
    });

})(window.z = window.z || {}, _);
