'use strict';

(function(app, Backbone, _, moment){

    app.OrderAttachmentGrid = Backbone.View.extend({

        /**
         * Url from where we can download an attachment
         */
        ATTACHMENT_DOWNLOAD_URL: '/attachments/{attachment}/download',

        /**
         * Element
         */
        el: '#order-attachment-gridpanel',

        /**
         * Events
         */
        events: {
            'click .delete-order-attachment': '_onDeleteAttachment'
        },

        /**
         * Selectors
         */
        selectors: {
            'content': function(){
                var $el = this.getEl();
                return $el.find('.ibox-content');
            },
            'grid.table': function(){
                var $el = this.getEl();
                return $el.find('#order-attachment-grid');
            },
            'grid.table.body': function(){
                var $table = this.getEl('grid.table');
                return $table.find('tbody');
            },
            'grid.empty': function(){
                var $el = this.getEl();
                return $el.find('#order-attachment-emptylist');
            }
        },

        /**
         * Constructor
         *
         * @return {undefined}
         */
        initialize: function(){

            this.listenTo(this.collection, 'add',       this._onCollectionAdd);
            this.listenTo(this.collection, 'remove',    this._onCollectionRemove);
            this.listenTo(this.collection, 'request',   this._onCollectionRequest);
            this.listenTo(this.collection, 'sync',      this._onCollectionSync);
            this.listenTo(this.collection, 'error',     this._onCollectionError);

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Remove grid buttons
                this._removeGridButtons();

            }.bind(this));

            this._initializeCollection();
        },

        /**
         * Retrieves the cached element from selectors
         * @param {string} key The key
         * @param {bool} fresh Should we fetch it again
         * @returns {*} The element or undefined
         */
        getEl: function(key, fresh){

            if (_.isUndefined(key) || key === 'el') {
                return this.$el;
            }

            var selectors = _.result(this, 'selectors');

            if (!selectors) {
                return null;
            }

            var cache = this.elsCache = this.elsCache || {};

            if (!_.has(cache, key) || fresh) {

                var selector = _.isFunction(selectors[key]) ? selectors[key].call(this) : selectors[key];

                var result = null;

                if (selector) {

                    var $query = $(selector); // result of $($($('.class'))) is the same as $('.class')

                    result = $query.length ? $query : null;
                }

                cache[key] = result;
            }

            return cache[key];
        },

        /**
         * Render the view
         * @return {object} The view
         */
        render: function(){

            var $table = this.getEl('grid.table');
            var $tbody = this.getEl('grid.table.body');
            var $empty = this.getEl('grid.empty');

            $tbody.empty();

            if (!this.collection.length) {

                $table.addClass('hidden');
                $empty.removeClass('hidden');

                return this;
            }

            $table.removeClass('hidden');
            $empty.addClass('hidden');

            var $rows = [];

            this.collection.each(function(attachment){

                var $row = this._buildGridRow(attachment);

                $rows.push($row);

            }, this);

            $tbody.append($rows);

            return this;
        },

        /**
         * Method called after models are added into the collection
         * @param {object} model The model
         * @param {object} collection The collection
         * @return {undefined}
         * @private
         */
        _onCollectionAdd: function(){

            this.render();
        },

        /**
         * Method called after models are removed from the collection
         * @return {undefined}
         * @private
         */
        _onCollectionRemove: function(){

            this.render();
            this._isLoading(false);
        },

        /**
         * Method called after the collection sends the request
         * @return {undefined}
         * @private
         */
        _onCollectionRequest: function(){

            this._isLoading();
        },

        /**
         * Method called after the collection finishes the sync
         * @return {undefined}
         * @private
         */
        _onCollectionSync: function(){

            this._isLoading(false);
        },

        /**
         * Method called after the collection request fails
         * @return {undefined}
         * @private
         */
        _onCollectionError: function(){

            this._isLoading(false);
        },

        /**
         * Initializes the collection
         * @return {undefined}
         * @private
         */
        _initializeCollection: function(){

            var orderId = app.orderAttachmentForm.getOrderId();

            this.collection.setOrderId(orderId);

            this.collection.fetch();
        },

        /**
         * Builds a grid row with the supplied model data
         * @param {object} attachment The attachment
         * @returns {*|jQuery|HTMLElement} The table row
         * @private
         */
        _buildGridRow: function(attachment){

            var $row = $('<tr data-id="' + attachment.get('id') + '" data-deletable="' + attachment.get('deletable') + '">');

            var date = this._formatDate(attachment.get('createdAt'));

            $('<td>')
                .text(date)
                .appendTo($row);

            var title = Translator.trans('label_download', {name: attachment.get('name')});
            var url = this._getAttachmentDownloadUrl(attachment.get('id'));
            var user = '-';

            var $link = $('<a>')
                .attr({href: url, title: title})
                .text(attachment.get('name'));

            var $deleteIcon = $('<div class="delete-order-attachment btn"><i class="fa fa-trash-o"></i></div>');

            $('<td>')
                .append($link)
                .appendTo($row);

            if (attachment.get('user')) {

                user = attachment.get('user').username;
            }

            $('<td>')
                .text(user)
                .appendTo($row);

            $('<td>')
                .text(attachment.get('comment'))
                .appendTo($row);

            if (attachment.get('deletable')) {

                $('<td>')
                    .append($deleteIcon)
                    .appendTo($row);
            } else {

                // Append an empty span to have the same color on the entire row
                $('<td>')
                    .append('<span></span>')
                    .appendTo($row);
            }

            return $row;
        },

        /**
         * Format the supplied date
         * @param {string} date The date
         * @returns {string} Formatted date
         * @private
         */
        _formatDate: function(date){

            return moment(date).format('DD-MM-YYYY - H:mm');
        },

        /**
         * Returns the url from where you can download the attachment
         * @param {int|string} attachmentId The attachment id
         * @returns {string} The url
         * @private
         */
        _getAttachmentDownloadUrl: function(attachmentId){

            return this.ATTACHMENT_DOWNLOAD_URL.replace('{attachment}', attachmentId);
        },

        /**
         * Enables or disables the loading mask
         * @param {bool} loading Should show or hide the mask?
         * @return {undefined}
         * @private
         */
        _isLoading: function(loading){

            loading = loading ? loading : _.isUndefined(loading);

            var $content = this.getEl('content');

            if (loading) {

                $content.addClass('sk-loading');

            } else {

                $content.removeClass('sk-loading');
            }
        },

        /**
         * On delete attachment event
         * @param {Event} e - delete event
         * @private
         * @return {undefined}
         */
        _onDeleteAttachment: function(e) {

            var id = $(e.target).parents('tr').attr("data-id");

            //this.collection.fetch();

            var attachment = this.collection.get(id);
            var order = attachment.get('order');

            // In the Backbone request we need only the order id for the order field
            attachment.set('order', order.id);

            // Implementing sweet alert confirmation
            swal({
                title: Translator.trans('js.order_delete_order_attachment_confirm_window_title'),
                text: Translator.trans('js.order_delete_order_attachment_confirm_window_message'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#18a689',
                cancelButtonColor: 'rgb(113, 125, 145)',
                confirmButtonText: Translator.trans('js.order_delete_order_attachment_confirm_btn')
            }).then(function () {

                // Delete the attachment
                this._deleteAttachment(attachment);

            }.bind(this)).catch(function(){
                attachment.set('order', order);
            });

        },

        /**
         * Delete the order attachment
         * @param {Object} attachment - the order attachment model
         * @private
         * @return {undefined}
         */
        _deleteAttachment: function(attachment) {

            // Delete the attachment
            attachment.destroy({

                // Waits for the server response before removing the model from collection
                wait: true,

                // On success
                success: function() {

                    this._showSuccess();

                }.bind(this),

                // On error
                error: function() {

                    this._showError();

                }.bind(this)
            });
        },

        /**
         * Show error message
         * @private
         * @return {undefined}
         */
        _showError: function() {

            var $errorContainer = this.$('#order-attachments-error-message');

            $errorContainer.removeClass('hidden')
                .fadeTo('slow', 1)
                .show('slow')
                .delay(3000)
                .fadeTo('slow',0)
                .hide('slow');
        },

        /**
         * Show success message
         * @private
         * @return {undefined}
         */
        _showSuccess: function() {

            var $successContainer = this.$('#order-attachments-success-message');

            $successContainer.removeClass('hidden')
                .fadeTo('slow', 1)
                .show('slow')
                .delay(3000)
                .fadeTo('slow',0)
                .hide('slow');
        },

        /**
         * Remove order attachement gird buttons
         * @private
         * @return {undefined}
         */
        _removeGridButtons: function() {

            this.$('.delete-order-attachment').remove();
        }
    });

})(window.z = window.z || {}, Backbone, _, moment);