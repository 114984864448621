"use strict";

(function(z){

    z.tariffResellerAdminForm = z.FormView.extend({

        /**
         * Consts for status
         */
        STATUS_ACTIVE: 1,
        STATUS_INACTIVE: 0,

        SERVICE_FORM_URL: '/tariff-reseller/create/form/service/{service}/tariff/{tariff}',
        DATE_FORMAT: 'DD-MM-YYYY',
        el: "#tariffResellerForm-modal",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            $("#tariffResellerForm-reseller-field").chosen({ placeholder_text_multiple: "--Alege resellerul--"});
            z.FormView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
            this._initializeDateFields();
            this._removeAllServiceFieldValidations(this.model);
        },
        _initializeDateFields: function() {

            $('.datepicker').daterangepicker({
                autoApply: true,
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: false,
                locale: {
                    format: this.DATE_FORMAT
                }
            });

            // this is official solution for initial empty field
            this.$('#tariffResellerForm-dateStart-field').on('apply.daterangepicker', function (ev, picker) {
                this.$('#tariffResellerForm-dateStart-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            this.$('#tariffResellerForm-dateStart-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
            this.$('#tariffResellerForm-dateEnd-field').on('apply.daterangepicker', function (ev, picker) {
                this.$('#tariffResellerForm-dateEnd-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            this.$('#tariffResellerForm-dateEnd-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
            $('#tariffReseller-dateStart-field').on('apply.daterangepicker', function (ev, picker) {
                $('#tariffReseller-dateStart-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            $('#tariffReseller-dateStart-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
            $('#tariffReseller-dateEnd-field').on('apply.daterangepicker', function (ev, picker) {
                $('#tariffReseller-dateEnd-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            $('#tariffReseller-dateEnd-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {
            this.$("#tariffResellerForm-worksite-field").chosen({
                search_contains: true,
                width: "100%"
            });

            this.$("#tariffResellerForm-service-field").chosen({
                search_contains: true,
                width: "100%"
            });
            this.$("#tariffResellerForm-service-field").change(
                function(){
                    z.tariffResellerAdminForm._onServiceChange(false);
                }
            );
            this.$("#tariffResellerForm-allResellers-field").click(
                function(){
                    z.tariffResellerAdminForm._onAllResellerChange();
                }
            );
        },
        _onAllResellerChange : function(){

            var $allResellersField = $('#tariffResellerForm-allResellers-field');
            var $resellerField = $('#tariffResellerForm-reseller-field');
            z.tariffResellerAdminForm._toggle([$resellerField]);
            if($allResellersField.prop('checked') == true){
                delete this.model.validation['reseller'];
            }
            else{
                this.model.validation['reseller'] = [{
                    required : true,
                    msg: Translator.trans("js.error_message_tariffReseller_reseller_required")
                }];
            }
        },
        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addTariffResellerAdmin';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editTariffResellerAdmin';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_tariff_reseller');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_tariff_reseller');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#tariffResellerForm-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#tariffResellerForm-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return '/tariff-reseller-admin';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'tariffReseller.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_tariffResellerForm');
        },

        /**
         * Set custom labels for the reseller modal form
         *
         * @param {string} action - action name
         * @return {undefined}
         */
        setCustomLabels: function(action) {

            // todo uncomment when API Details will be implemented
            switch (action) {
                case 'add':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
                    break;
                case 'edit':
                    // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
                    break;
            }
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         */
        getFormData: function() {

            var $resellerField         = this.$('#tariffResellerForm-reseller-field');
            var $serviceField          = this.$('#tariffResellerForm-service-field');
            var $valueField         = this.$('#tariffResellerForm-value-field');
            var $dateStartField = this.$('#tariffResellerForm-dateStart-field');
            var $dateEndField           = this.$('#tariffResellerForm-dateEnd-field');
            var $allResellers           = this.$('#tariffResellerForm-allResellers-field');
            var $param = [];

            var $return =  {
                'reseller': $resellerField.val(),
                'service': $serviceField.val(),
                'value': $valueField.val(),
                'dateStart': $dateStartField.val(),
                'dateEnd': $dateEndField.val(),
                'allResellers': $allResellers.is(":checked"),
            };
            var params = $("#tariffResellerForm-service-container").children();
            params.each(function( index, value ) {
                var fieldName = $(this).data('validation');
                var value = $('#tariff_reseller_form_' + fieldName).val();
                $return[fieldName] = value;
            });

            return $return;
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                'tariffResellerForm-worksite-field'           : 'worksite',
                'tariffResellerForm-service-field'            : 'service',
                'tariffResellerForm-value-field'              : 'value',
                'tariffResellerForm-dateStart-field'          : 'dateStart',
                'tariffResellerForm-dateEnd-field'            : 'dateEnd',
                'tariffResellerForm-allWorksites-field'            : 'allWorksites',
            };

            Object.keys(map).forEach(function(key){

                var value = this.model.get(map[key]);
                switch (key) {

                    case "tariffResellerForm-worksite-field":
                        if(typeof value === 'undefined'){
                            return;
                        }
                        value.forEach(function(v){
                            if(typeof v === 'undefined'){
                                return;
                            }
                            $("#" + key)
                                .find('option[value=' + v.worksiteId + ']').prop('selected', true)
                                .end().trigger('chosen:updated');
                        });
                        break;
                    case "tariffResellerForm-service-field":
                        if(typeof value === 'undefined'){
                            return;
                        }
                        this.$("#" + key)
                            .find('option[value=' + value.id + ']').prop('selected', true)
                            .end().trigger('chosen:updated');
                        break;

                    case "tariffResellerForm-value-field":

                        if(typeof value === 'undefined'){

                            this.$el.find("#"+key).val(0);
                        } else {

                            this.$el.find("#"+key).val(value);
                        }
                        break;

                    case "tariffResellerForm-dateStart-field":
                    case "tariffResellerForm-dateEnd-field":

                        if(typeof value === 'undefined'){

                            this.$el.find("#"+key).val('');
                        } else {
                            var now = new Date(value);
                            var day = ("0" + now.getDate()).slice(-2);
                            var month = ("0" + (now.getMonth() + 1)).slice(-2);
                            var date = (day) +"-"+(month)+"-" + now.getFullYear();
                            this.$el.find("#"+key).val(date);
                        }

                        break;

                    default:
                        this.$el.find("#"+key).val(value);
                }

            }.bind(this));
            z.tariffResellerAdminForm._onServiceChange(true);
        },

        /**
         * Method that edits the entity when the correct route is being reached.
         *
         * @param {int} id - the reseller id that is being taken from the request
         * @param {int} tariffResellerId - the tariffReseller id that is being taken from the request
         * @private
         * @return {undefined}
         */
        _onRouterEditEntity: function(id){

            this._beforeEditEntity(id);

            this._setLabels('edit');

            this.resetForm();

            this.model.set('id', id);

            this.model.fetch({
                success: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
            this._showModal();
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.tariff_reseller_create_error_message_mobile');
            this.$el.find('#tariff_reseller-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the error message if the form is submitted, or the modal has been closed
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#tariff_reseller-create-general-error-panel').addClass('hidden');
        },

        /**
         * Method called after the service drop down gets changed
         * @return {undefined}
         * @private
         */
        _onServiceChange: function(isEdit){

            this._loadServiceForm(isEdit);

        },
        /**
         * Loads the service form according to the service dropdown
         * @param {Function} callback The callback
         * @return {undefined}
         * @private
         */
        _loadServiceForm: function(isEdit){

            var tariffId = 0;
            if(isEdit){
                var url = window.location.pathname;
                tariffId = url.substring(url.lastIndexOf('/') + 1);
            }
            var record = this._getFormRecord();
            var serviceId = record.service;
            var $holder = this._getServiceContainerEl();
            var toggle = this._toggleOfferAssociatedFields.bind(this); // this._toggleServiceField();
            $holder.empty();

            if (!serviceId) {

                return;
            }
            var url = this._getServiceFormUrl(serviceId, tariffId);

            var opts = {
                method      : 'GET',
                dataType    : 'html',
                contentType : 'text/plain'
            };

            toggle();

            z.request.make(url, null, function(jqXhr, status){

                toggle();

                if (status !== 'success') {

                    return;
                }

                $holder.append(jqXhr.responseText);

                this._removeAllServiceFieldValidations(this.model);
                this._getServiceParamsFields(this.model);
                $('#tariffResellerForm-service-field').addClass('chosen-select');
            }.bind(this), opts);
        },
        /**
         *
         * @private
         */
        _getServiceParamsFields: function(model){

            var params = $("#tariffResellerForm-service-container").children();
            params.each(function( index, value ) {
                var fieldName = $(this).data('validation');
                model.validation[fieldName] = [{
                    required: true,
                    msg: Translator.trans('js.error_message_orderform_service_param_required')
                }];
                model.serviceParamsFields.push(fieldName)
                ;
            });
        },
        _removeAllServiceFieldValidations(model){

            _.each(model.serviceParamsFields, function(fieldName){
                delete model.validation[fieldName];
            });
        },
        /**
         * Returns the url where you can find the service form
         * @param {int|string} serviceId The service id
         * @returns {string} The url
         * @private
         */
        _getServiceFormUrl: function(serviceId, tariffId){
            var url =  this.SERVICE_FORM_URL.replace('{service}', serviceId);
            if(typeof tariffId == 'undefined'){
                tariffId = 0;
            }
            var url =  url.replace('{tariff}', tariffId);
            return url;
        },
        /**
         * Retrieves the service holder element
         * @returns {object} The service holder
         * @private
         */
        _getServiceContainerEl: function(){
            return this.$('#tariffResellerForm-service-container');
        },
        /**
         * Retrieves the normalized form data
         * @param {boolean} forOffers Return the offer record used for offers
         * @return {object} The form data
         * @private
         */
        _getFormRecord: function(forOffers){

            var record = {
                service: this._getServiceFieldEl().val(),
            };
            return record;
        },
        /**
         * Retrieves the service dropdown element
         * @returns {object} The service holder
         * @private
         */
        _getServiceFieldEl: function(){
            return this.$('#tariffResellerForm-service-field');
        },

        /**
         *
         * @private
         */
        _toggleOfferAssociatedFields: function(){
            var $service = this._getServiceFieldEl();

            var list = [
                $service,
            ];

            this._toggle(list);
        },
        _toggle: function($fields, display, override) {

            $fields = _.isArray($fields) ? $fields : [$fields];

            _.each($fields, function($field){

                var show = function(){
                    $field.removeClass('hidden');
                };

                var hide = function(){
                    $field.addClass('hidden');
                };

                var enable = function(){
                    $field.removeAttr('disabled');
                };

                var disable = function(){
                    $field.attr('disabled', 'disabled');
                };

                var isToggled = _.isUndefined(override) ? this._isToggled($field, display) : !override;
                var toggleOn = display ? show : enable;
                var toggleOff = display ? hide : disable;

                if (isToggled) {

                    toggleOff();

                } else {

                    toggleOn();
                }

                $field.trigger('chosen:updated');

            }, this);
        },
        /**
         * Returns true if the supplied filed is toggled, false otherwise
         * @param {object} $field The field
         * @param {boolean} display The type of toggle: true for display, false for state
         * @returns {boolean} The status
         * @private
         */
        _isToggled: function($field, display){
            var isDisabled = $field.attr('disabled');
            var isHidden = $field.hasClass('hidden');

            return display ? !isHidden : !isDisabled;
        },
        _initServicesValidation: function(){
            var model = this.model;
            var serviceRequiredFields = this._getServiceRequiredFields(model);

            this._removeAllServiceFieldValidations(model);

            _.each(serviceRequiredFields, function(name){

                this._addServiceFieldValidation(name, model);

            }, this);
        },
    });

})(window.z = window.z || {}, _);