
'use strict';
(function(app, Backbone, _){

    var Request = app.Class.extend({

        /**
         * Makes a HTTP Ajax request with the supplied options.
         * @param {string} url The url where to make the request.
         * @param {object} [data] The data to be sent to that request.
         * @param {function} [done] Callback function to be called after the request is complete.
         * @param {object} [opts] Additional options passed to jQuery Ajax.
         * @return {undefined}
         */
        make: function(url, data, done, opts){

            opts = _.isObject(opts) ? opts : {};

            opts = _.defaults(opts, {
                method      : 'POST',
                url         : url,
                dataType    : 'json',
                contentType : 'application/json',
                data        : data? JSON.stringify(data) : null,
                complete    : done ? done : _.noop()
            });

            $.ajax(opts);
        }
    });

    app.request = new Request();

})(window.z = window.z || {}, Backbone, _);
