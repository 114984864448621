'use strict';
(function(app, $){

    var Helper = app.Class.extend({

        /**
         * Method from https://gist.github.com/jonathanpglick/814331c32b314103f5a5#file-deparam-js-L38.
         *
         * Deserialize a params string into an object, optionally coercing numbers,
         * booleans, null and undefined values; this method is the counterpart to the
         * internal jQuery.param method.
         *
         * Usage:
         *    deparam( params [, coerce ] );
         *
         * @param {string} params - the query string
         * @param {boolean} coerce - if true, coerces any numbers or true, false, null, and undefined to their actual value. Defaults to false if omitted.
         * @returns {{}} - an object representing the deserialized params string.
         * @see https://github.com/cowboy/jquery-bbq/blob/master/jquery.ba-bbq.js
         */
        deparam: function( params, coerce ) {
            var obj = {},
                coerce_types = { 'true': !0, 'false': !1, 'null': null };

            // Iterate over all name=value pairs.
            $.each( params.replace( /\+/g, ' ' ).split( '&' ), function(j,v){
                var param = v.split( '=' ),
                    key = decodeURIComponent( param[0] ),
                    val,
                    cur = obj,
                    i = 0,

                // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
                // into its component parts.
                    keys = key.split( '][' ),
                    keys_last = keys.length - 1;

                // If the first keys part contains [ and the last ends with ], then []
                // are correctly balanced.
                if ( /\[/.test( keys[0] ) && /\]$/.test( keys[ keys_last ] ) ) {
                    // Remove the trailing ] from the last keys part.
                    keys[ keys_last ] = keys[ keys_last ].replace( /\]$/, '' );

                    // Split first keys part into two parts on the [ and add them back onto
                    // the beginning of the keys array.
                    keys = keys.shift().split('[').concat( keys );

                    keys_last = keys.length - 1;
                } else {
                    // Basic 'foo' style key.
                    keys_last = 0;
                }

                // Because it takes the first param with "?"
                key = key.replace("?", "");

                // Are we dealing with a name=value pair, or just a name?
                if ( param.length === 2 ) {
                    val = decodeURIComponent( param[1] );

                    // Coerce values.
                    if ( coerce ) {
                        val = val && !isNaN(val)            ? +val              // number
                            : val === 'undefined'             ? undefined         // undefined
                            : coerce_types[val] !== undefined ? coerce_types[val] // true, false, null
                            : val;                                                // string
                    }

                    if ( keys_last ) {
                        // Complex key, build deep object structure based on a few rules:
                        // * The 'cur' pointer starts at the object top-level.
                        // * [] = array push (n is set to array length), [n] = array if n is
                        //   numeric, otherwise object.
                        // * If at the last keys part, set the value.
                        // * For each keys part, if the current level is undefined create an
                        //   object or array based on the type of the next keys part.
                        // * Move the 'cur' pointer to the next level.
                        // * Rinse & repeat.
                        for ( ; i <= keys_last; i++ ) {

                            key = keys[i] === '' ? cur.length : keys[i];

                            // Make this verification because the key can be a string or a number
                            if (typeof key === 'string') {
                                // Because it takes the first param with "?"
                                key = key.replace("?", "");
                            }

                            cur = cur[key] = i < keys_last ? cur[key] || ( keys[i+1] && isNaN( keys[i+1] ) ? {} : [] ) : val;
                        }

                    } else {
                        // Simple key, even simpler rules, since only scalars and shallow
                        // arrays are allowed.

                        if ( $.isArray( obj[key] ) ) {
                            // val is already an array, so push on the next value.
                            obj[key].push( val );

                        } else if ( obj[key] !== undefined ) {
                            // val isn't an array, but since a second value has been specified,
                            // convert val into an array.
                            obj[key] = [ obj[key], val ];

                        } else {
                            // val is a scalar.
                            obj[key] = val;
                        }
                    }

                } else if ( key ) {
                    // No value was defined, so set something meaningful.
                    obj[key] = coerce ? undefined : '';
                }
            });

            return obj;
        },


        /**
         * Verify if a string is  valid JSON.
         *
         * @param {string} str - string to be verified
         * @return {boolean} - true if the string is a valid JSON, otherwise false
         */
        isJson: function(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        /**
         * Method that find if an array contains another array
         * @param {array} needle What we are looking for
         * @param {array} haystack Where we are looking for it
         * @return {boolean} If the needle is in the haystack
         */
        arrayContainsAnotherArray: function(needle, haystack) {
            for(var i = 0; i < needle.length; i++){
                if(haystack.indexOf(needle[i]) === -1)
                    return false;
            }
            return true;
        }
    });

    app.helper = new Helper({});

})(window.z = window.z || {}, $);