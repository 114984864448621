'use strict';
(function (z) {
    z.AllocationDashboardGrid = z.GridView.extend({
        el: "#page-wrapper",

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-allocation-dashboard-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return 'allocation-dashboard';
        },
    })

})(window.z = window.z || {});