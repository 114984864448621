"use strict";

(function (z, _, $, Backbone, Translator) {

    var ServiceGridForm = Backbone.View.extend({

        /**
         * Events
         * @return {void|*} The events
         */
        events: {},

        /**
         * Selectors
         */
        selectors: {},

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){

            this._bindModel(this.model);

            this._loadModel(this.model);

            this._bindCollection(this.collection);

            this.listenTo(z.serviceConfigForm, 'reset', this._onModalReset);
        },

        /**
         * Retrieves the cached element from selectors
         * @param {string} key The key
         * @param {bool} fresh Should we fetch it again
         * @returns {*} The element or undefined
         */
        getEl: function(key, fresh){

            if (_.isUndefined(key) || key === 'el') {
                return this.$el;
            }

            var selectors = _.result(this, 'selectors');

            if (!selectors) {
                return null;
            }

            var cache = this.elsCache = this.elsCache || {};

            if (!_.has(cache, key) || fresh) {

                var selector = _.isFunction(selectors[key]) ? selectors[key].call(this) : selectors[key];

                var result = null;

                if (selector) {

                    var $query = $(selector); // result of $($($('.class'))) is the same as $('.class')

                    result = $query.length ? $query : null;
                }

                cache[key] = result;
            }

            return cache[key];
        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: _.noop,

        /**
         * Method called if the form validation fails
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: _.noop,

        /**
         * Method called when the parent modal form gets reset
         * @return {undefined}
         * @private
         */
        _onModalReset: function(){

            this._reset();
        },

        /**
         * Method called after the user clicks the add button from the options form
         * @return {undefined}
         * @private
         */
        _onSubmitClick: function(){

            this._submitForm();
        },

        /**
         * Method called after clicking the cancel button from the param form
         * @return {undefined}
         * @private
         */
        _onCancelClick: function(){

            this._reset();
        },

        /**
         * Method called after the user clicks on the edit param button
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onEditClick: function(event){

            var $btn = $(event.currentTarget);
            var $row = $btn.parents('tr:first');
            var id = $row.data('id');

            var model = this.collection.get(id);

            this._reset(model);

            this._toggleFormCancelBtn(true);

            this._setFormSaveBtnText(Translator.trans('js.btn_save'));

            this.trigger('edit', model);
        },

        /**
         * Method called after the user clicks on the delete button
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onDeleteClick: function(event){

            var $btn = $(event.currentTarget);
            var $row = $btn.parents('tr:first');
            var id = $row.data('id');

            this.collection.remove(id);

            this._reset();
        },

        /**
         * Method called after models are added or removed from the collection
         * @return {undefined}
         * @private
         */
        _onCollectionUpdate: function(){

            this._renderGrid();
        },

        /**
         * Method called after collection is reset
         * @return {undefined}
         * @private
         */
        _onCollectionReset: function(){

            this._renderGrid();
        },

        /**
         * Method called after the collection sends the request
         * @return {undefined}
         * @private
         */
        _onCollectionRequest: function(){

            this._isLoading(true);
        },

        /**
         * Method called after the collection finishes the sync
         * @return {undefined}
         * @private
         */
        _onCollectionSync: function(){

            this._isLoading(false);

            this.trigger('sync');
        },

        /**
         * Method called after the collection request fails
         * @return {undefined}
         * @private
         */
        _onCollectionError: function(){

            this._isLoading(false);
        },

        /**
         * Method called after the model's attributes get changed
         * @return {undefined}
         * @private
         */
        _onModelChange: function(){

            this._renderGrid();
        },

        /**
         * Binds the view to the supplied model
         * @param {object} model The model to bind to
         * @return {undefined}
         * @private
         */
        _bindModel: function(model){

            // remove previous listeners
            this.stopListening(this.model);

            // set the new model
            this.model = model;

            // start listening to model events
            this.listenTo(this.model, 'change', this._onModelChange);
        },

        /**
         * Binds the view to the supplied collection
         * @param {object} collection The collection to bind to
         * @return {undefined}
         * @private
         */
        _bindCollection: function(collection){

            // remove previous listeners
            this.stopListening(this.collection);

            // set the new collection
            this.collection = collection;

            this.listenTo(this.collection, 'update',    this._onCollectionUpdate);
            this.listenTo(this.collection, 'reset',     this._onCollectionReset);
            this.listenTo(this.collection, 'request',   this._onCollectionRequest);
            this.listenTo(this.collection, 'sync',      this._onCollectionSync);
            this.listenTo(this.collection, 'error',     this._onCollectionError);
        },

        /**
         * Method that binds validation to the chosen model
         * @param {object} model The model that the validation is performed on
         * @private
         * @return {undefined}
         */
        _bindValidation: function(model) {

            Backbone.Validation.unbind(this, { model: model });

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Enables or disables the loading mask for params section
         * @param {bool} loading Should show or hide the mask?
         * @return {undefined}
         * @private
         */
        _isLoading: function(loading){

            loading = loading ? loading : _.isUndefined(loading);

            var $loader = this.getEl('loader');

            if (loading) {

                $loader.removeClass('hidden');

            } else {

                $loader.addClass('hidden');
            }
        },

        /**
         * Loads the supplied model into the form
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _loadModel: _.noop,

        /**
         * Renders the grid
         * @return {undefined}
         */
        _renderGrid: function(){

            var $table = this.getEl('grid.table');
            var $tbody = this.getEl('grid.table.body');
            var $empty = this.getEl('grid.empty');

            $tbody.empty();

            if (!this.collection.length) {

                $table.addClass('hidden');
                $empty.removeClass('hidden');

                return this;
            }

            $table.removeClass('hidden');
            $empty.addClass('hidden');

            var $rows = [];

            this.collection.each(function(model){

                var $row = this._buildGridRow(model);

                $rows.push($row);

            }, this);

            $tbody.append($rows);

            return this;
        },

        /**
         * Builds the html grid row for the supplied model
         * @param {object} model The model
         * @return {*|jQuery|HTMLElement} The table row
         * @private
         */
        _buildGridRow: _.noop,

        /**
         * Sets the text for the save button
         * @param {string} value The new text
         * @return {undefined}
         * @private
         */
        _setFormSaveBtnText: function(value){

            var $btn = this.getEl('form.btn.save');

            $btn.text(value);
        },

        /**
         * Show or hide the param form cancel button
         * @param {boolean} show Show or hide
         * @return {undefined}
         * @private
         */
        _toggleFormCancelBtn: function(show){

            var $btn = this.getEl('form.btn.cancel');

            show ? $btn.removeClass('hidden') : $btn.addClass('hidden');
        },

        /**
         * Resets the entire view
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _reset: function (model) {

            this._resetForm();

            this._resetValidation();

            this._bindModel(model);

            this._loadModel(model);

            this.trigger('reset', model);
        },

        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        _resetForm: function() {

            this._toggleFormCancelBtn(false);

            this._setFormSaveBtnText(Translator.trans('js.btn_add'));
        },

        /**
         * Resets the validation state of the fields
         * @return {undefined}
         * @private
         */
        _resetValidation: function(){

            var $fields = this.$('[data-validation]');

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Submits the form
         * @return {undefined}
         * @private
         */
        _submitForm: function(){

            this._prepareModelData(this.model);

            this._prepareModelValidation(this.model);

            this._bindValidation(this.model);

            if (!this.model.isValid(true)) {
                return;
            }

            this.collection.add(this.model); // if it already exists, it will be skipped automatically

            this._reset();
        },

        /**
         * Prepare model with all the necessary fields
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _prepareModelData: function(model){

            var record = this._getFormRecord();

            var id = this.model.get(this.model.idAttribute);

            if (id) {

                record[this.model.idAttribute] = id;
            }

            model.clear();

            model.set(record);
        },

        /**
         * Sets the fields which the validation will check
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _prepareModelValidation: _.noop,

        /**
         * Retrieves the data from the form
         * @return {object} The form data
         * @private
         */
        _getFormRecord: _.noop,

        /**
         * Retrieves the service id for which we are configuring
         * @return {*} The service id
         * @private
         */
        _getServiceId: function(){

            var regex = /^\/services\/configure\/(\d+)(.*)$/g;

            var result = regex.exec(window.location.pathname);

            if (!result) {

                return null;
            }

            return result[1];
        }
    });

    z.ServiceGridForm = ServiceGridForm;

})(window.z = window.z || {}, _, $, Backbone, Translator);
