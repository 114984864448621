"use strict";

(function(z, Backbone){

    var RepresentativesCollection = Backbone.Collection.extend({
        // nothing
    });

    z.RepresentativesCollection = RepresentativesCollection;

})(window.z = window.z || {}, Backbone);