"use strict";

(function(z, Backbone){

    var ResellerModel = Backbone.Model.extend({
        urlRoot: "/api/resellers",
        validation: {
            brandName: [{
                required: true,
                msg: Translator.trans("js.error_message_resellerform_brandname_required")
            },{
                minLength: 1,
                msg: Translator.trans("js.error_message_resellerform_brandname_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_resellerform_brandname_maxlength")
            }],
            companyName: [{
                required: true,
                msg: Translator.trans("js.error_message_resellerform_companyname_required")
            }, {
                minLength: 3,
                msg: Translator.trans("js.error_message_resellerform_companyname_minlength")
            }, {
                maxLength: 64,
                msg: Translator.trans("js.error_message_resellerform_companyname_maxlength")
            }],
            phoneNumber: [{
                required: true,
                msg: Translator.trans("js.error_message_resellerform_phonenumber_required")
            }, {
                minLength: 10,
                msg: Translator.trans("js.error_message_resellerform_phonenumber_minlength")
            }, {
                maxLength: 16,
                msg: Translator.trans("js.error_message_resellerform_phonenumber_maxlength")
            }, {
                pattern: "digits",
                msg: Translator.trans("js.error_message_resellerform_number_format")
            }]
            // apiUser: [{
            //     minLength: 1,
            //     msg: Translator.trans("js.error_message_resellerform_apiuser_minlength")
            // }, {
            //     maxLength: 50,
            //     msg: Translator.trans("js.error_message_resellerform_apiuser_maxlength")
            // }],
            // apiKey: [{
            //     minLength: 1,
            //     msg: Translator.trans("js.error_message_resellerform_apikey_minlength")
            // }, {
            //     maxLength: 50,
            //     msg: Translator.trans("js.error_message_resellerform_apikey_maxlength")
            // }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            if( !_.isUndefined(response.data) ){

                return response.data;
            }

            return response;
        }
    });

    z.ResellerModel = ResellerModel;

})(window.z = window.z || {}, Backbone);
