"use strict";

(function(z, Backbone){

    var TariffResellerModel = Backbone.Model.extend({
        urlRoot: "/api/tariff-reseller",
        serviceParamsFields: [],

        validation: {
            service: [{
                required: true,
                msg: Translator.trans("js.error_message_tariffReseller_service_required")
            }],
            value: [{
                required: true,
                msg: Translator.trans("js.error_message_tariffReseller_value_required")
            },
            {
                min: 1,
                msg: Translator.trans('js.error_message_tariffReseller_value_format')
            }],
            dateStart: function(value) {
                if( typeof value == 'undefined' || ( typeof value !== 'undefined' && value == '')){
                    return Translator.trans("js.error_message_tariffReseller_dateStart_required");
                }
                var dateReg = /^\d{2}([-])\d{2}\1\d{4}$/;

                if(! value.match(dateReg)){
                    var now = new Date(value);
                    var day = ("0" + now.getDate()).slice(-2);
                    var month = ("0" + (now.getMonth() + 1)).slice(-2);
                    var date = (day) +"-"+(month)+"-" + now.getFullYear();
                    if(! date.match(dateReg)){
                        return Translator.trans("js.error_message_tariffReseller_dateStart_format");
                    }
                }
            }
            ,
            dateEnd: function(value) {
                if( typeof value == 'undefined' || ( typeof value !== 'undefined' && value == '')){
                    return Translator.trans("js.error_message_tariffReseller_dateEnd_required");
                }
                var dateReg = /^\d{2}([-])\d{2}\1\d{4}$/;

                if(! value.match(dateReg)){
                    var now = new Date(value);
                    var day = ("0" + now.getDate()).slice(-2);
                    var month = ("0" + (now.getMonth() + 1)).slice(-2);
                    var date = (day) +"-"+(month)+"-" + now.getFullYear();
                    if(! date.match(dateReg)){
                        return Translator.trans("js.error_message_tariffReseller_dateEnd_format");
                    }
                }
            },
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.TariffResellerModel = TariffResellerModel;

})(window.z = window.z || {}, Backbone);