"use strict";

(function(z, Backbone, _){

    z.OrderIndividual = Backbone.View.extend({

        /**
         * Date format
         */
        DATE_FORMAT: 'DD-MM-YYYY',

        /**
         * Order statuses
         */
        STATUS_APPOINTED: 3,
        STATUS_CANCELED: 5,
        STATUS_ALLOCATED: 6,

        // Edit inline route
        API_EDIT_ROUTE: '/api/edit/orders/',

        el: "#order-page",

        events: {
            'click #order-invoice-date-field.editable-click': '_initializeDateFields'
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            $.fn.editable.defaults.mode = 'inline';
            $.fn.editable.defaults.ajaxOptions = {type: "PUT"};

            // Initialize editable fields
            this.initEditableFields();

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Disable editable order fields when the order is canceled
                this._disableEditableOrderFields();

                if (typeof z.boot.order !== "undefined" && z.boot.order.invoiceable) {

                    // Disable the editable showroom field
                    $('#order-showroom-field').editable('disable');
                }

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.finalized", function() {

                // Enable the editable order fields
                this._enableEditableOrderFields();

                if (typeof z.boot.order !== "undefined" && z.boot.order.invoiceable) {

                    // Disable the editable showroom field
                    $('#order-showroom-field').editable('disable');
                }

            }.bind(this));

            $(document).on("order.status.appointed", function() {

                // Update the status
                this._updateStatus();

                // Display the print button
                this._displayPrintButton();

            }.bind(this));

            $(document).on("client.success", function() {

                // Disable the editable showroom field
                $('#order-showroom-field').editable('disable');

            }.bind(this));

            $(document).on("client.success", function() {

                // Disable the editable showroom field
                $('#order-showroom-field').editable('disable');

            }.bind(this));

            $(document).on("order.updateInvoiceDetails", function() {

                // Update invoice details
                this._disableEditableOrderFields();

            }.bind(this));
            this.orderVendorsCollection = new z.VendorsCollection();
            // this._addAllocatedAdfterwardOption();
            var $warningMessage = this.$('#customer-warning-message');

            if (!$warningMessage.hasClass('hidden')) {
                
                $("#orderform-status-field option[value='6']").remove();
                $("#orderform-status-field option[value='4']").remove();
            }
        },

        /**
         * Update status
         * @return {undefined}
         * @private
         */
        _updateStatus: function() {

            $('#order-status').text(Translator.trans('js.order_status_appointed'));
        },

        /**
         * Display the print button
         * @return {undefined}
         * @private
         */
        _displayPrintButton: function() {

            $('#order-print-btn-container').removeClass('hidden');
        },

        /**
         * Init editable fields
         * @return {undefined}
         */
        initEditableFields: function() {

            this._initShowroomEditableField();
            this._initInputsEditableFields();
            this._initScheduleButtonForInvoiceable();
            
            this._initDeliveryStatusEditableField();
        },

        /**
         *
         * @private
         */
        _initScheduleButtonForInvoiceable: function () {
            var isInvoiceable = z.boot.order.invoiceable;
            var reallocateHistoryButon = $('#reallocate-order-history-modal-btn');
            var reallocateButon = $('#reallocation-order-modal-btn');

            if (isInvoiceable)
            {
                reallocateHistoryButon.hide();
                reallocateButon.hide();
            }

        },

        /**
         * Init inputs editable fields
         * @private
         * @return {undefined}
         */
        _initInputsEditableFields: function() {

            var _this = this;

            $('.order-editable-field').editable({
                url: this.API_EDIT_ROUTE + this._getOrderId(),
                send: 'always',
                validate: function(value) {

                    if ($(this).attr('id') === 'order-invoice-number-field') {

                        // Validate to not be empty
                        if (!value || !value.trim()) {
                            return Translator.trans('js.error_message_order_editable_invoice_number');
                        }
                    }

                    if ($(this).attr('id') === 'order-invoice-date-field') {

                        // Validate to not be empty
                        if (!value || !value.trim()) {
                            return Translator.trans('js.error_message_order_editable_invoice_date');
                        }

                        var date = moment(value, _this.DATE_FORMAT);
                        var today = moment();

                        // If the date is not valid or it's in the future, return an error
                        if (!date.isValid() || today.diff(date) < 0) {

                            return Translator.trans('js.error_message_order_editable_invoice_date');
                        } else {

                            return {newValue: date.format(_this.DATE_FORMAT)};
                        }
                    }
                },
                success: function(response) {

                    var data = response.data;

                    // Update the invoice details
                    z.boot.order.invoiceNumber = data.invoiceNumber;
                    z.boot.order.invoiceDate = data.invoiceDate;

                    // Update the comments section
                    $(document).trigger("comments.updateComments");
                },
                error: function(response) {

                    if (response.status === 500) {
                        return Translator.trans('js.error_message_order_editable');
                    } else {

                        var error = response.responseJSON.errors[0];

                        switch (error.field) {

                            case 'data.invoiceNumber':
                                return Translator.trans('js.error_message_order_editable_invoice_number');

                            case 'data.invoiceDate':
                                return Translator.trans('js.error_message_order_editable_invoice_date');

                            default:
                                return Translator.trans('js.error_message_order_editable');
                        }
                    }
                }
            });
        },

        /**
         * Init showroom editable field
         * @private
         * @return {undefined}
         */
        _initShowroomEditableField: function() {

            var _this = this;

            // Init the chosen select
            $('#order-showroom-field').on('shown', function() {

                _this.$(".chosen-select").chosen({
                    search_contains: true,
                    width: "100%",
                });
            });

            // Init the editable field
            $('#order-showroom-field').editable({
                source: z.boot.showrooms,
                url: this.API_EDIT_ROUTE + this._getOrderId(),
                send: 'always',
                inputclass: 'chosen-select',
                success: function(response) {

                    if (!response.hasOwnProperty('data') && !response.data.hasOwnProperty('showroom')) {
                        return;
                    }

                    // Update the order showroom to use it later
                    z.boot.order.showroom = response.data.showroom;

                    // If the order was invoiceable and now it's not anymore, update the z.boot.order and trigger an event
                    if (z.boot.order.invoiceable && !response.data.invoiceable) {

                        z.boot.order.invoiceable = response.data.invoiceable;
                        z.boot.order.status = response.data.status;

                        // If the status is appointed, update it
                        if (z.boot.order.status === _this.STATUS_APPOINTED) {

                            _this._updateStatus();
                        }

                        // Update the comments section
                        $(document).trigger("invoice.disable");

                        // Enable the invoice fields
                        _this._enableEditableOrderFields();
                    }

                    // Update the comments section
                    $(document).trigger("comments.updateComments");

                    // Handle the invoiceable field visibility
                    $(document).trigger("schedule.handleFieldsVisibility");
                },
                error: function(response) {

                    if (response.status === 500) {

                        return Translator.trans('js.error_message_order_editable');

                    } else {

                        return Translator.trans('js.error_message_reseller_editable');
                    }
                }
            });
        },

        /**
         * Retrieves the order id if it is available
         * @returns {number|null} The order id
         * @private
         */
        _getOrderId: function(){

            return z.boot.order.id;
        },

        /**
         * Enable editable order fields
         * @private
         * @return {undefined}
         */
        _enableEditableOrderFields: function() {

            var order = z.boot.order;

            if (typeof order === "undefined")
            {
                return false;
            }

            // If the order is invoiceable, the invoice fields should not be enabled
            if (order.invoiceable) {
                return;
            }

            var resetValue = _.isUndefined(order.invoiceNumber);

            var $invoiceNumberField = $('#order-invoice-number-field');
            var $invoiceNumberParent = $invoiceNumberField.parent();
            var invoiceNumber = resetValue ? '-' : order.invoiceNumber;

            var $invoiceDateField = $('#order-invoice-date-field');
            var $invoiceDateParent = $invoiceDateField.parent();
            var invoiceDate = resetValue ? '-' : moment(order.invoiceDate).format('DD-MM-YYYY');

            $invoiceNumberField.remove();
            $invoiceDateField.remove();

            $('<a>')
                .attr('id', 'order-invoice-number-field')
                .addClass('order-editable-field')
                .text(invoiceNumber)
                .attr('href', '#')
                .data('type', 'text')
                .data('name', 'invoiceNumber')
                .appendTo($invoiceNumberParent);

            $('<a>')
                .attr('id', 'order-invoice-date-field')
                .addClass('order-editable-field')
                .text(invoiceDate)
                .attr('href', '#')
                .data('type', 'text')
                .data('name', 'invoiceDate')
                .appendTo($invoiceDateParent);

            // Initialize the invoice input fields
            this._initInputsEditableFields();
        },

        /**
         * Disable editable order fields
         * @private
         * @return {undefined}
         */
        _disableEditableOrderFields: function() {

            var order = z.boot.order;

            if (typeof order === "undefined")
            {
                return false;
            }

            // If the order is not invoiceable and the status is not canceled, the invoice fields should not be disabled
            // if (!order.invoiceable && !order.status === this.STATUS_CANCELED) {
            if (!order.invoiceable && order.status !== this.STATUS_CANCELED) {
                return;
            }

            var resetValue = _.isUndefined(order.invoiceNumber);

            var $invoiceNumberField = $('#order-invoice-number-field');
            var $invoiceNumberParent = $invoiceNumberField.parent();
            var invoiceNumber = resetValue ? '-' : order.invoiceNumber;

            var $invoiceDateField = $('#order-invoice-date-field');
            var $invoiceDateParent = $invoiceDateField.parent();
            var invoiceDate = resetValue ? '-' : moment(order.invoiceDate).format('DD-MM-YYYY');

            $invoiceNumberField.remove();
            $invoiceDateField.remove();

            $('<p>')
                .attr('id', 'order-invoice-number-field')
                .text(invoiceNumber)
                .appendTo($invoiceNumberParent);

            $('<p>')
                .attr('id', 'order-invoice-date-field')
                .text(invoiceDate)
                .appendTo($invoiceDateParent);
        },

        /**
         * Initialize date fields
         * @return {undefined}
         * @private
         */
        _initializeDateFields: function() {

            var $invoiceDateField = $('.order-invoice-date-container input');
            var invoiceDate = $.trim($('#order-invoice-date-field').text());

            if (invoiceDate === '-') {

                invoiceDate = moment().format(this.DATE_FORMAT);
            }

            $invoiceDateField.val(invoiceDate);

            $invoiceDateField.daterangepicker({
                autoApply: true,
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: false,
                maxDate: moment(),
                locale: {
                    format: this.DATE_FORMAT
                }
            });

            // this is official solution for initial empty field
            this.$('.order-invoice-date-container input').on('apply.daterangepicker', function (ev, picker) {
                this.$('.order-invoice-date-container input').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            this.$('.order-invoice-date-container input').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
        },

        /**
         * Checks if the vendor container field is visible
         * @return {boolean} Is visible
         * @private
         */
        _isVendorFieldVisible: function(){

            var $container = this._getVendorContainerEl();

            return !$container.is('.hidden');
        },


        /**
         * Retrieves the list of vendors (syncs the vendors collection)
         * @param {Function} [callback] The callback
         * @return {undefined}
         * @private
         */
        _loadVendors: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var record = this._getFormRecord();

            this.orderVendorsCollection.reset();

            if (!this._isVendorFieldVisible()) {

                return;
            }

            if (!this._isValidVendorRequest()) {

                return;
            }

            this.orderVendorsCollection.setAvailable(true);
            this.orderVendorsCollection.setServiceId(record.service);
            this.orderVendorsCollection.setCountyId(record.customer.county);

            if (record.sort === this.SORT_BY_DISTANCE + '') {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_EXTENDED);
            } else {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_FIXED);
            }

            if (record.fields.quantity) {
                this.orderVendorsCollection.setQuantity(record.fields.quantity);
            }

            this.orderVendorsCollection
                .fetch()
                .then(function(){

                    callback.apply(this, arguments);

                })
                .catch(function(){

                    callback.apply(this, arguments);
                });
        },

        /**
         * Add allocated afterwards options
         * @return {undefined}
         * @private
         */
        _addAllocatedAdfterwardOption: function() {

            // var $option = this._getAllocatedAfterwardOption();
            // this.$('#orderform-vendor-field').append($option).trigger("chosen:updated");
        },

        /**
         * Get allocated afterward option
         * @return {*|jQuery} - return the allocated afterward option
         * @private
         */
        _getAllocatedAfterwardOption: function() {

            return $('<option>')
                .attr('value', this.ALLOCATED_AFTERWARD_VALUE)
                .text(Translator.trans('js.allocated_afterward'));
        },

        /**
         * Init delivery status field
         * @private
         * @returns {undefined}
         */
        _initDeliveryStatusEditableField: function() {

            var _this = this;

            // Init the chosen select
            $('#order-delivery-status-field').on('shown', function() {

                _this.$(".chosen-select").chosen({
                    search_contains: true,
                    width: "100%",
                });
            });

            // Init the editable field
            $('#order-delivery-status-field').editable({
                source: [{value:'0',text:'no'},{value:'1',text:'yes'}],
                url: this.API_EDIT_ROUTE + this._getOrderId(),
                send: 'always',
                inputclass: 'chosen-select',
                success: function(response) {
                    var successMessage = Translator.trans('js.order_delivery_status_success_message');

                    if (!response.hasOwnProperty('data') && !response.data.hasOwnProperty('delivered')) {
                        return;
                    }

                    // Update the order
                    z.boot.order.delivered = response.data.delivered;
                    console.log(z.boot.order);
                },
                error: function(response) {
                    console.log(response);
                    _this._displayError(response);
                }
            });
        }

    });

})(window.z = window.z || {}, Backbone, _);

