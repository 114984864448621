'use strict';

(function(z, _) {

    /**
     * Notification grid
     */
    z.NotificationGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#page-wrapper",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click .notification-edit-button": "onEditEntry"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-notificationTranslations-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return 'notifications';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'notification-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_notification_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'notification.success';
        }
    });

    // z.notificationGrid = new NotificationGrid();

})(window.z = window.z || {}, _);
