"use strict";

(function(z, Backbone, _){
    var OrderStatusModel = Backbone.Model.extend({

        /**
         * Url
         */
        url: '/api/orders/status',

        /**
         * Validation
         */
        validation: {
            status: [{
                required: true,
                msg: Translator.trans("js.error_message_order_status_required")
            },{
                fn: function(value, attr, computedState) {
                    if (computedState.orderStatus === computedState.oldStatus) {
                        return Translator.trans('js.error_message_order_same_status');
                    }

                    if (value === z.OrderStatus.STATUS_FINALIZED && !computedState.isProgressComplete) {
                        return Translator.trans('js.error_message_order_progress_incomplete');
                    }
                }
            }],
            statusComment: [{
                maxLength: 250,
                msg: Translator.trans("js.error_message_orderform_comment_status_maxlength")
            }]
        },
        parse: function(response) {
            return response.data;
        }
    });

    z.OrderStatusModel = OrderStatusModel;

})(window.z = window.z || {}, Backbone, _);