"use strict";

(function(z, Backbone){

    var UserModel = Backbone.Model.extend({
        urlRoot: "/api/users",
        validation: {
            email: [
                {
                    required: true,
                    msg: Translator.trans("js.error_message_userform_email_required")
                },
                {
                    pattern: 'email',
                    msg: Translator.trans("js.error_message_userform_email_invalid")
                }
            ],
            "passwordPlain.first": [
                {
                    required: function(value, attr, computedState){
                        return typeof computedState.id === 'undefined';
                    },
                    msg: Translator.trans("js.error_message_userform_password_required")
                },
                {
                    minLength: 6,
                    msg: Translator.trans("js.error_message_userform_password_minLength")
                },
                {
                    maxLength: 80,
                    msg: Translator.trans("js.error_message_userform_password_maxLength")
                }
            ],
            "passwordPlain.second": function(value, attr, computedState) {

                if ((typeof computedState.id === 'undefined' || computedState.passwordPlain.first.length !== 0) && value.length === 0) {
                    return Translator.trans("js.error_message_userform_confirmPassword_required");
                }

                if (value.length !== 0 && value.length < 6) {
                    return Translator.trans("js.error_message_userform_confirmPassword_minLength");
                }

                if (value.length !== 0 && value.length > 80) {
                    return Translator.trans("js.error_message_userform_confirmPassword_maxLength");
                }

                if (value.length !== 0 && value !== computedState.passwordPlain.first) {
                    return Translator.trans("js.error_message_userform_passwords_notEqual");
                }
            },
            firstName: [
                {
                    required: true,
                    msg: Translator.trans("js.error_message_userform_firstName_required")
                },
                {
                    minLength: 2,
                    msg: Translator.trans("js.error_message_userform_firstName_minLength")
                },
                {
                    maxLength: 80,
                    msg: Translator.trans("js.error_message_userform_firstName_maxLength")
                }
            ],
            lastName: [
                {
                    required: true,
                    msg: Translator.trans("js.error_message_userform_lastName_required")
                },
                {
                    minLength: 2,
                    msg: Translator.trans("js.error_message_userform_lastName_minLength")
                },
                {
                    maxLength: 80,
                    msg: Translator.trans("js.error_message_userform_lastName_maxLength")
                }
            ],
            language: [
                {
                    required: true,
                    msg: Translator.trans("js.error_message_userform_language_required")
                }
            ],
            dbRoles: function(value, attr, computedState) {

                if (value.length === 0) {

                    return Translator.trans("js.error_message_userform_roles_required");
                }

                var roles =  _.map(computedState.dbRoles, function(role) {

                    return role.name;
                });

                if (!z.helper.arrayContainsAnotherArray(roles, z.security.getVendorRoles()) &&
                    !z.helper.arrayContainsAnotherArray(roles, z.security.getInternalRoles()) &&

                    // We cannot have reseller admin & operator together
                    !z.helper.arrayContainsAnotherArray(roles, [z.security.ROLE_RESELLER_ADMIN]) &&
                    !z.helper.arrayContainsAnotherArray(roles, [z.security.ROLE_RESELLER_OPERATOR])
                ) {

                    return Translator.trans("js.error_message_roles_faulty_combination");
                }
            },
            status: [
                {
                    required: true,
                    msg: Translator.trans("js.error_message_userform_status_required")
                }
            ],
            vendor: [
                {
                    required: function(value, attr, computedState) {

                        var isRequired = false;

                        computedState.dbRoles.forEach(function(dbRole) {

                            if (isRequired) {

                                return;
                            }

                            if ($.inArray(dbRole.name, z.security.getVendorRoles()) !== -1 && computedState.vendor === "") {

                                isRequired = true;
                            }
                        });

                        return isRequired;
                    },
                    msg:  Translator.trans("js.error_message_userform_vendor_required"),
                }
            ],
            reseller: [
                {
                    required: function(value, attr, computedState) {

                        var isRequired = false;

                        computedState.dbRoles.forEach(function(dbRole) {

                            if (isRequired) {

                                return;
                            }

                            if ($.inArray(dbRole.name, z.security.getResellerRoles()) !== -1 && computedState.reseller === "") {

                                isRequired = true;
                            }
                        });

                        return isRequired;
                    },
                    msg: Translator.trans("js.error_message_userform_reseller_required")
                }
            ],
            showroom: [
                {
                    required: function(value, attr, computedState) {

                        if (computedState.dbRoles.length > 0 && computedState.dbRoles[0].name === z.security.ROLE_RESELLER_OPERATOR) {

                            return true;
                        }
                    },
                    msg: Translator.trans("js.error_message_userform_showroom_required")
                }
            ],
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.UserModel = UserModel;

})(window.z, Backbone);
