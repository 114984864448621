"use strict";

(function(z, Backbone){

    var ServiceModel = Backbone.Model.extend({
        urlRoot: "/api/services",
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceform_name_required")
            },{
                minLength: 2,
                msg: Translator.trans("js.error_message_serviceform_name_minlength")
            },{
                maxLength: 80,
                msg: Translator.trans("js.error_message_serviceform_name_maxlength")
            }],
            category: {
                required: true,
                msg: Translator.trans("js.error_message_serviceform_category_required")
            },
            status: {
                required: true,
                msg: Translator.trans("js.error_message_serviceform_status_required")
            },
            isQuoted: {
                required: true,
                msg: Translator.trans("js.error_message_serviceform_isQuoted_required")
            },
            multipleQuantity: {
                required: true,
                msg: Translator.trans("js.error_message_serviceform_multipleQuantity_required")
            },
            deliveryDays: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceform_deliveryDays_required")
            },{
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceform_deliveryDays_int_format")
            }, {
                min: 0,
                msg: Translator.trans("js.error_message_serviceform_deliveryDays_min_value")
            }],
            basePrice: [{
                required: function(value, attr, computedState) {

                    if (computedState.isQuoted === '0') {
                        return true;
                    }

                    return false;
                },
                msg: Translator.trans("js.error_message_serviceform_basePrice_required")
            }, {
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceform_basePrice_float_format")
            }, {
                min: 0,
                msg: Translator.trans("js.error_message_serviceform_basePrice_min_value")
            }],
            hasTransportCost: {
                required: true,
                msg: Translator.trans("js.error_message_serviceform_hasTransportCost_required")
            },
            unpaidDistance: [{
                required: function (value, attr, computedState) {

                    if (computedState.hasTransportCost === '1') {
                        return true;
                    }

                    return false;
                },
                msg: Translator.trans("js.error_message_serviceform_unpaidDistance_required")
            }, {
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceform_unpaidDistance_integer_format")
            }, {
                min: 0,
                msg: Translator.trans("js.error_message_serviceform_unpaidDistance_min_value")
            }, {
                max: 32767,
                msg: Translator.trans("js.error_message_serviceform_unpaidDistance_max_value")
            }],
            pricePerKm: [{
                required: function (value, attr, computedState) {

                    if (computedState.hasTransportCost === '1') {
                        return true;
                    }

                    return false;
                },
                msg: Translator.trans("js.error_message_serviceform_pricePerKm_required")
            }, {
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceform_pricePerKm_float_format")
            }, {
                min: 0,
                msg: Translator.trans("js.error_message_serviceform_pricePerKm_min_value")
            }],
            hasUpload: {
                required: true,
                msg: Translator.trans("js.error_message_serviceform_hasUpload_required")
            },
            uploadName: [{
                required: false,
            },{
                minLength: 2,
                msg: Translator.trans("js.error_message_serviceform_uploadName_minlength")
            },{
                maxLength: 50,
                msg: Translator.trans("js.error_message_serviceform_uploadName_maxlength")
            }],
            uploadDescription: [{
                required: false,
            },{
                minLength: 2,
                msg: Translator.trans("js.error_message_serviceform_uploadDescription_minlength")
            },{
                maxLength: 250,
                msg: Translator.trans("js.error_message_serviceform_uploadDescription_maxlength")
            }],
            isUploadRequired: {
                required: false,
            },
            description: {
                required: false,
            },
            vat: [{
                required: true,
                msg: Translator.trans("js.error_message_serviceform_vat_required")
            }, {
                pattern: "number",
                msg: Translator.trans("js.error_message_serviceform_vat_float_format")
            }, {
                min: 0,
                msg: Translator.trans('js.error_message_serviceform_vat_min_value')
            }, {
                max: 100,
                msg: Translator.trans('js.error_message_serviceform_vat_max_value')
            }],
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.ServiceModel = ServiceModel;

})(window.z = window.z || {}, Backbone);
