"use strict";

(function(z, Backbone, _){

    z.OrderProgress = Backbone.View.extend({

        /**
         * Element
         */
        el: '#orderform-progress',

        /**
         * Events
         */
        events: {
            'click #progress-increase': '_onProgressIncreaseClick',
            'click #progress-decrease': '_onProgressDecreaseClick',
            'click #progress-save': '_onProgressSaveClick'
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            this._bind();

            this._initProgressBar();
        },

        /**
         * Binds events to the element
         * @return {undefined}
         * @private
         */
        _bind: function(){

            this.listenTo(this, 'minreached', this._onMinReached);
            this.listenTo(this, 'maxreached', this._onMaxReached);
            this.listenTo(this, 'overmin', this._onOverMin);
            this.listenTo(this, 'undermin', this._onUnderMin);
            this.listenTo(this, 'undermax', this._onUnderMax);

            this.listenTo(this.model, 'request', this._onModelRequest);
            this.listenTo(this.model, 'sync', this._onModelSync);
            this.listenTo(this.model, 'error', this._onModelError);

            this.listenTo(z.OrderStatus, 'order.closed', this._onOrderClosed);
            this.listenTo(z.orderScheduleForm, 'orderScheduleSuccess', this._onOrderScheduled);

            $(document).on("order.status.appointed", function() {

                this._onOrderScheduled();

            }.bind(this));

            // On invoice disable, enable the progress section
            $(document).on("invoice.disable", function() {
                
                this._onOrderScheduled();

            }.bind(this));
        },

        /**
         * Initialize the progressbar
         * @return {undefined}
         * @private
         */
        _initProgressBar: function(){

            this._setTotal(this.model.get('quantity'));
            this._setMin(z.security.isAdmin() ? 0 : this.model.get('progress'));
            this._setProgress(this.model.get('progress'));
        },

        /**
         * Method called after the user clicks the save button.
         * @return {undefined}
         * @private
         */
        _onProgressSaveClick: function(){

            this._saveProgress();
        },

        /**
         * Method called after the user clicks the increase button.
         * @return {undefined}
         * @private
         */
        _onProgressIncreaseClick: function(){

            this._increaseProgress();
        },

        /**
         * Method called after the user clicks the decrease button.
         * @return {undefined}
         * @private
         */
        _onProgressDecreaseClick: function(){

            this._decreaseProgress();
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onMinReached: function(){

            this._toggleProgressDecrease(false);
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onMaxReached: function(){

            this._toggleProgressIncrease(false);
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onOverMin: function(){

            this._toggleProgressDecrease(true);
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onUnderMin: function(){

            this._toggleProgressDecrease(false);
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onUnderMax: function(){

            this._toggleProgressIncrease(true);
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onOrderClosed: function(){

            if (z.security.isAdmin()) {

                return;
            }

            this._toggleControls(false);
            this._toggleSave(false, true);
        },

        /**
         * Method called after the event is triggered.
         * @private
         * @return {undefined}
         */
        _onOrderScheduled: function() {

            // Display progress container
            $('#orderform-progress').removeClass('hidden');

            if (!z.security.isResellerAssociated()) {

                // Display progress save button
                $('#progress-save').removeClass('hidden');

                // Display progress controls
                $('#progress-controls').removeClass('hidden');
            }
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onModelRequest: function(){

            this._isLoading(true);
        },

        /**
         * Method called after the event is triggered.
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _onModelSync: function(model){

            this._isLoading(false);

            if (!z.security.isAdmin()) {

                this._setMin(this.progress);

            } else {

                this._setProgress(this.progress);
            }

            z.boot.order = model.toJSON();

            z.orderCommentForm.collection.fetch();
        },

        /**
         * Method called after the event is triggered.
         * @return {undefined}
         * @private
         */
        _onModelError: function(){

            this._isLoading(false);
        },

        /**
         * Increases the progress by one unit.
         * @return {undefined}
         * @private
         */
        _increaseProgress: function(){

            this._setProgress(this.progress+1);
        },

        /**
         * Decreases the progress by one unit.
         * @return {undefined}
         * @private
         */
        _decreaseProgress: function(){

            this._setProgress(this.progress-1);
        },

        /**
         * Updates the visual progressbar with the current percentage.
         * @return {undefined}
         * @private
         */
        _updateProgressBar: function(){

            var percentage = this._getPercentage();

            this.$('.progress-bar')
                .css('width', percentage + '%')
                .text(this.progress + '/' + this.total);
        },

        /**
         * Calculates the current percentage.
         * @return {undefined}
         * @private
         */
        _getPercentage: function(){

            return this.progress * 100 / this.total;
        },

        /**
         * Enable or disable a certain element based on jquery selector.
         * @param {Object} $selector The jquery selector element
         * @param {boolean} override Override the toggle
         * @return {undefined}
         * @private
         */
        _toggleState: function($selector, override){

            var isToggled = _.isUndefined(override) ? !$selector.is('.disabled') : !override;

            if (isToggled) {

                $selector.addClass('disabled');

            } else {

                $selector.removeClass('disabled');
            }

        },

        /**
         * Enable or disable a certain element based on jquery selector.
         * @param {Object} $selector The jquery selector element
         * @param {boolean} override Override the toggle
         * @return {undefined}
         * @private
         */
        _toggleDisplay: function($selector, override){

            var isToggled = _.isUndefined(override) ? !$selector.is('.hidden') : !override;

            if (isToggled) {

                $selector.addClass('hidden');

            } else {

                $selector.removeClass('hidden');
            }
        },

        /**
         * Enable or disable the progress controls.
         * @param {boolean} override Override the toggle
         * @return {undefined}
         * @private
         */
        _toggleControls: function(override){

            var $btn = this.$('#progress-controls');

            this._toggleDisplay($btn, override);
        },

        /**
         * Enable or disable the progress increase button.
         * @param {boolean} override Override the toggle
         * @return {undefined}
         * @private
         */
        _toggleProgressIncrease: function(override){

            var $btn = this.$('#progress-increase');

            this._toggleState($btn, override);
        },

        /**
         * Enable or disable the progress decrease button.
         * @param {boolean} override Override the toggle
         * @return {undefined}
         * @private
         */
        _toggleProgressDecrease: function(override){

            var $btn = this.$('#progress-decrease');

            this._toggleState($btn, override);
        },

        /**
         * Enable or disable the progress save button.
         * @param {boolean} override Override the toggle
         * @param {boolean} display How toggle should work
         * @return {undefined}
         * @private
         */
        _toggleSave: function(override, display){

            var $btn = this.$('#progress-save');

            display ? this._toggleDisplay($btn, override) : this._toggleState($btn, override);
        },

        /**
         * Save the new progress in the database
         * @return {undefined}
         * @private
         */
        _saveProgress: function(){

            this.model.set('progress', this.progress);

            if (!this.model.isValid()) {

                return;
            }

            this.model.save();
        },

        /**
         * Set the minimum value (percentage)
         * @param {int} value The value
         * @return {undefined}
         * @private
         */
        _setMin: function(value){

            this.min = value;

            this.trigger('minreached');

            if (this.min === this.total) {

                this.trigger('maxreached');
            }
        },

        /**
         * Set the progress value
         * @param {int} value The value
         * @return {undefined}
         * @private
         */
        _setProgress: function(value){

            if (value >= this.total) {

                value = this.total;
            }

            if (value < this.min) {

                value = this.min;
            }

            this.progress = value;

            this._updateProgressBar();

            if (this.progress === this.total) {

                this.trigger('maxreached');

            } else {

                this.trigger('undermax');
            }

            if (this.progress === this.min) {

                this.trigger('minreached');

            } else {

                this.trigger('overmin');
            }
        },

        /**
         * Set the total value
         * @param {int} value The value
         * @return {undefined}
         * @private
         */
        _setTotal: function(value){

            this.total = value;
        },

        /**
         * Enable or disable the loading mask
         * @param {boolean} loading Enable or disable
         * @return {undefined}
         * @private
         */
        _isLoading: function(loading){

            this._toggleProgressIncrease(!loading);
            this._toggleProgressDecrease(!loading);
            this._toggleSave(!loading);
        }
    });

})(window.z = window.z || {}, Backbone, _);