"use strict";

(function(z, Backbone, _){

    z.CommentsImport = Backbone.View.extend({

        /**
         * Maximum upload size in bytes
         */
        UPLOAD_MAX_SIZE: 10000000,

        /**
         * Allowed file types
         */
        UPLOAD_ALLOWED_TYPES: [
            'text/csv',
            'text/plain',
            'application/vnd.ms-excel'
        ],

        /**
         * Element
         */
        el: '#comments-import-panel',

        /**
         * Events
         */
        events: {
            'click #comments-import-upload-button': '_onUploadClick'
        },

        /**
         * Initialize method.
         * @return {undefined}
         */
        initialize: function() {

            this._bindModel();

            this._bindEvents();
        },

        /**
         * Binds the model to this view.
         *
         * @param {object} [model] The model
         * @return {undefined}
         * @private
         */
        _bindModel: function(model){

            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Bind events to this object.
         * @return {undefined}
         * @private
         */
        _bindEvents: function(){

            this.listenTo(this.model, 'request', this._onModelRequest);
            this.listenTo(this.model, 'sync', this._onModelSync);
            this.listenTo(this.model, 'error', this._onModelError);
        },

        /**
         * Method called after the user clicks the upload button.
         * @return {undefined}
         * @private
         */
        _onUploadClick: function(){

            this._uploadTemplate();
        },

        /**
         * Method called if the form validation succeeds.
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr){

            var $form = this.$('#comments-import-form');

            $form
                .find('[data-validation="' + attr + '"]')
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails.
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error){

            var $form = this.$('#comments-import-form');

            $form
                .find('[data-validation="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after event
         * @return {undefined}
         * @private
         */
        _onModelRequest: function(){

            this._isLoading(true);
        },

        /**
         * Method called after event
         * @return {undefined}
         * @private
         */
        _onModelSync: function(){

            this._isLoading(false);

            this._showSuccess(Translator.trans('js.label_import_success'));

            this._resetForm();
        },

        /**
         * Method called after event
         * @param {object} model The model
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _onModelError: function(model, res){

            this._isLoading(false);

            if (!res.responseJSON) {
                return;
            }

            var errors = res.responseJSON.errors;
            var messages = [];

            _.each(errors, function(error){

                messages.push(error.message);
            });

            this._setImportErrors(messages);

            this._showImportErrors();
        },

        /**
         * Redirects to download page.
         * @return {undefined}
         * @private
         */
        _uploadTemplate: function(){

            this.model.clear();

            this._hideImportErrors();

            this._prepareModelData(this.model);

            this._prepareModelValidation(this.model);

            if (!this.model.isValid(true)) {

                return;
            }

            var data = this._getFormData(this.model);

            var options = {
                type: 'POST',
                data: data,
                processData: false,
                contentType: false
            };

            this.model.save(null, options);
        },

        /**
         * Reset the form
         * @return {undefined}
         * @private
         */
        _resetForm: function(){

            this.$('form').get(0).reset();
        },

        /**
         * Retrieves the form data record.
         * @param {Object} model The model
         * @returns {FormData} The form data
         * @private
         */
        _getFormData: function(model){

            var $form = this.$('form');
            var name = $form.attr('name');
            var data = new FormData();

            if (model.has('commentsTemplate')) {

                data.append(name + '[commentsTemplate]' , model.get('commentsTemplate'));
            }

            return data;
        },

        /**
         * Prepare model data.
         * @param {Object} model - the model
         * @return {undefined}
         * @private
         */
        _prepareModelData: function(model) {

            var $form = this.$('form');
            var $file = $form.find('input[type="file"]');

            if ($file.length > 0) {

                var file = $file.get(0).files[0];

                model.set('commentsTemplate', file);
            }
        },

        /**
         * Prepares the model validation.
         * @param {Object} model - The model
         * @return {undefined}
         */
        _prepareModelValidation: function(model) {

            if (!model.has('commentsTemplate')) {

                model.set('isTemplateEmpty', true);

            } else {

                if (!this._isValidUploadType(model.get('commentsTemplate').type)) {

                    model.set('isTemplateInvalidType', true);
                }

                if (!this._isValidUploadSize(model.get('commentsTemplate').size)) {

                    model.set('isTemplateInvalidSize', true);
                }
            }
        },

        /**
         * Checks if the supplied type is a valid upload type
         * @param {string} type The type
         * @returns {boolean} Is valid
         */
        _isValidUploadType: function(type){

            return this.UPLOAD_ALLOWED_TYPES.indexOf(type) >= 0;
        },

        /**
         * Checks if the supplied size is less then or equal to maximum upload size.
         * @param {int} size The size
         * @returns {boolean} Is valid
         */
        _isValidUploadSize: function(size){

            return size <= this.UPLOAD_MAX_SIZE;
        },

        /**
         * Toggle the loading mask.
         * @param {boolean} [loading] Is loading
         * @return {undefined}
         * @private
         */
        _isLoading: function(loading){

            loading = loading ? loading : _.isUndefined(loading);

            var $panel = this.$('.ibox-content');

            $panel.toggleClass('sk-loading', loading);
        },

        /**
         * Shows the import errors
         * @return {undefined}
         * @private
         */
        _showImportErrors: function(){

            this.$('#comments-import-errors').removeClass('hidden');
        },

        /**
         * Hide the import errors
         * @return {undefined}
         * @private
         */
        _hideImportErrors: function(){

            this.$('#comments-import-errors').addClass('hidden');
        },

        /**
         * Set the import errors
         * @param {object} errors The errors
         * @return {undefined}
         * @private
         */
        _setImportErrors: function(errors){

            var $holder = this.$('#comments-import-errors-holder');

            $holder.empty();

            if (!errors) {

                return;
            }

            var $row;

            _.each(errors, function(message){

                $row = $('<span>')
                    .addClass('help-block text-left')
                    .text(message);

                $holder.append($row);
            });
        },

        /**
         * Display the success notification
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showSuccess: function(message){

            var $success = $('#comments-import-success-message');

            $success
                .text(message)
                .removeClass('hidden')
                .fadeOut(0)
                .fadeIn('slow')
                .delay(5000)
                .fadeOut('slow', function(){

                    $success.addClass('hidden');
                });
        }
    });

})(window.z = window.z || {}, Backbone, _);