"use strict";

(function(z){

    z.VendorForm = z.FormView.extend({
        el:"#vendorform-modal",

        events: function() {
            return _.extend({}, z.FormView.prototype.events,{
                "click #vendorform-save-button": "_onSubmitForm",
                "change #worksiteform-county-field": "_onCountyChange",
                "click #worksiteForm-add-button": "_onCreateWorksite",
                "click .edit-worksite-form": "_onEditWorksiteForm",
                "click #representativeForm-add-button": "_onCreateRepresentative",
                "click .edit-representative-form" : "_onEditRepresentativeForm",
                "click .delete-representative-form" : "_onDeleteRepresentativeRow"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            z.FormView.prototype.initialize.apply(this, arguments);

            // Initializing Backbone Validation Library
            this._bindValidation();

            // Adding collections to view
            this.worksitesCollection = new z.WorksitesCollection();
            this.representativesCollection = new z.RepresentativesCollection();
            this.localitiesCollection = new z.LocalitiesCollection();

            // Listening on collection events
            this.listenTo(this.localitiesCollection, 'add', this._onLocalitiesCollectionAdd);
            this.listenTo(this.localitiesCollection, 'reset', this._onLocalitiesCollectionReset);
            this.listenTo(this.worksitesCollection, 'add remove', this._onWorksitesAddRemove);
            this.listenTo(this.worksitesCollection, 'reset', this._onWorksitesCollectionReset);
            this.listenTo(this.worksitesCollection, 'change', this._onWorksitesChange);
            this.listenTo(this.representativesCollection, 'add', this._onRepresentativesCollectionAdd);
            this.listenTo(this.representativesCollection, 'remove', this._onRepresentativesCollectionRemove);
            this.listenTo(this.representativesCollection, 'reset', this._onRepresentativesCollectionReset);
            this.listenTo(this.representativesCollection, 'change', this._onRepresentativesCollectionChange);
        },

        /**
         * Method that disabled the delete button for representatives
         * when there is onmly one representative in the collection
         * @private
         * @return {undefined}
         */
        _disableDeleteButton: function() {
            var $deleteButton = this.$('.delete-representative-form');
            if (this.representativesCollection.size() === 1) {
                $deleteButton.prop('disabled', true);
                $deleteButton.addClass('disabled');
            } else if (this.representativesCollection.size() > 1) {
                $deleteButton.prop('disabled', false);
                $deleteButton.removeClass('disabled');
            }
        },

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function(){
            this._cleanupForm();
            z.FormView.prototype.render.apply(this, arguments);
            this._initializeChosen();
            return this;
        },

        /**
         * Initializing the chosen js library
         * @private
         * @return {undefined}
         */
        _initializeChosen: function () {
            this.$('.chosen-select-locality').chosen({width: "100%"});
            this.$('.chosen-select-county').chosen({width: "100%"});
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addVendor';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editVendor';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.vendorform-modal-title-add');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.vendorform-modal-title-edit');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#vendorform-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#vendorform-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'vendors';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'vendor.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_vendor_form');
        },

        /**
         * Method that modifies the modal title and button label
         * according to the action name being passed as param
         *
         * @param {string} action - name of the action that is being performed
         * @private
         * @return {undefined}
         */
        _setLabels: function(action) {

            switch (action) {
                case 'add':
                    this.modifyModalTitle(this.getModalFormAddTitleText());
                    break;
                case 'edit':
                    this.modifyModalTitle(this.getModalFormEditTitleText());
                    break;
            }

            // Set custom labels in every view that will extend FormView
            this.setCustomLabels(action);
        },

        /**
         * Method that resets the form fields, resets the collections and
         * hides the tables holding data
         *
         * @private
         * @return {undefined}
         */
        _cleanupForm: function() {

            var  $representativeWrapperName = this.$('.representative-list');
            var  $worksiteWrapperName = this.$('.worksite-list');

            // Emptying collections
            this.worksitesCollection.reset(null);
            this.representativesCollection.reset(null);
            this.localitiesCollection.reset(null);

            // Resetting form
            this._resetFields("worksiteform");
            this._resetFields("representativeform");
            this._resetFields("vendorform");

            // Hiding all extra elements
            this._hideWrapper($representativeWrapperName);
            this._hideWrapper($worksiteWrapperName);

            // Hiding all extra divs
            this._hideWorksiteValidationError();
            this._hideRepresentativeValidationError();

            // Seting the Add button
            var addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#worksiteForm-add-button').html(addingButtonTitle);
            this.worksiteEditAddId = undefined;

            // When the edit window is being opened reset the locality and county fields
            this._readonlyAndDisabled(false);

            this.$('.chosen-select-locality').trigger("chosen:updated");
            this.$('.chosen-select-county').trigger("chosen:updated");
        },

        /**
         * Method that handles the form submission
         *
         * @return {undefined}
         */
        _onSubmitForm: function () {
            var formData = this._getVendorData();
            this.model.set(formData);

            var hasRepresentatives = this._isValidRepresentatives();
            var hasWorksites = this._isValidWorksites();
            var isValid = this.model.isValid(true);

            if (hasWorksites) {
                this.$('.worksite-validation-error').addClass('hidden');
            }

            if (hasRepresentatives) {
                this.$('.representative-validation-error').addClass('hidden');
            }

            if( !isValid ){
                this.showMobileAlert();
                return;
            }

            this._isLoading(true, this.getSubmitButtonSelector());
            this._addWorksiteRepresentativesModel(this.model);

            this.model.save(null, {
                success: function (i) {
                    this.model.trigger("success", i);
                }.bind(this)
            });
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.vendor_create_error_message_mobile');

            this.$el.find('#vendor-create-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this.hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the general error message shown if the form is not valid
         * @return {undefined}
         */
        hideMobileAlert: function()
        {
            this.$el.find('#vendor-create-general-error-panel').addClass('hidden');
        },

        /**
         * Method used to hide the worksite validation error div
         * @private
         * @return {undefined}
         */
        _hideWorksiteValidationError: function()
        {
            this.$('.worksite-validation-error').addClass('hidden');
        },

        /**
         * Method used to hide the representative validation error div
         * @return {undefined}
         * @private
         */
        _hideRepresentativeValidationError: function()
        {
            this.$('.representative-validation-error').addClass('hidden');
        },

        /**
         * Method that fills in the tables with the representative data
         *
         * @param {object} model - The representative model
         * @private
         * @return {undefined}
         */
        _fillRepresentativesForm: function(model) {
            // Getting data from model
            var data = model.attributes.representatives;

            if (!data) {
                return;
            }

            // Showing table
            var $wrapperName = this.$('.representative-list');
            this._displayWrapper($wrapperName);

            // Pushing data in collection
            Object.keys(data).forEach(function(key) {

                var representative = {
                    name: data[key].name,
                    position: data[key].position,
                    phoneNumber: data[key].phoneNumber,
                    email: data[key].email
                };

                this.representativesCollection.push(representative);
            }.bind(this));
        },

        /**
         * Method that fills in the tables with the worksites data
         *
         * @param {object} model - The worksite model
         * @private
         * @return {undefined}
         */
        _fillWorksitesForm: function(model) {
            // Getting data from model
            var data = model.attributes.worksites;

            if (!data) {
                return;
            }

            // Showing table
            var $wrapperName = this.$('.worksite-list');
            this._displayWrapper($wrapperName);

            // Pushing data in collection
            Object.keys(data).forEach(function(key) {

                if (data[key].status === 1) {
                    var status = 1;
                    var statusName = Translator.trans("js.label_status_active");
                } else {
                    status = 0;
                    statusName = Translator.trans("js.label_status_inactive");
                }

                var worksite = {
                    county: data[key].county.id,
                    county_name: data[key].county.nameLatin,
                    locality: data[key].locality.id,
                    locality_name: data[key].locality.nameLatin + " - " + data[key].locality.region3Latin ,
                    status: status,
                    status_name: statusName,
                    address: data[key].address,
                    email: data[key].email,
                    editable: data[key].editable
                };

                this.worksitesCollection.push(worksite);
            }.bind(this));
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {
            this._fillRepresentativesForm(this.model);
            this._fillWorksitesForm(this.model);

            var map = {
                "vendorform-brandName-field" : "brandName",
                "vendorform-companyName-field" : "companyName",
                "vendorform-companyIdentificationNumber-field" : "companyIdentificationNumber",
                "vendorform-registryOfCommerceNumber-field" : "registryOfCommerceNumber",
                "vendor_form_type" : "type",
                "vendorform-bankAccount-field" : "bankAccount",
                "vendorform-bankName-field" : "bankName",
                "vendorform-accountHolder-field" : "accountHolder",
                "vendorform-contractNumber-field" : "contractNumber"
            };

            Object.keys(map).forEach(function(key){
                if(key === "vendor_form_type") {
                    var value = this.model.get(map[key]);

                    if (value === 1) {
                        this.$("#" + key + "_0").prop("checked", true);
                    } else {
                        this.$("#" + key + "_1").prop("checked", true);
                    }
                } else {
                    this.$el.find("#"+key).val(this.model.get(map[key]));
                }
            }.bind(this));
        },

        /**
         * Collects data from form fields
         *
         * @private
         * @returns {{brandNameField, companyNameField, type, companyIdentificationNumber, personalIdentificationNumber,
         * registryOfCommerceNumber, bankAccount, bankName, accountHolder, contractNumber, county, locality,
         * address, status, name, position, phoneNumber, email}} - Fields values
         */
        _getVendorData: function () {
            var $brandNameField = this.$('#vendorform-brandName-field');
            var $companyNameField = this.$('#vendorform-companyName-field');
            var $type = this.$('[name=vendor_form\\[type\\]]:checked');
            var $companyIdentificationNumber = this.$('#vendorform-companyIdentificationNumber-field');
            var $registryOfCommerceNumber = this.$('#vendorform-registryOfCommerceNumber-field');
            var $bankAccount = this.$('#vendorform-bankAccount-field');
            var $bankName = this.$('#vendorform-bankName-field');
            var $accountHolder = this.$('#vendorform-accountHolder-field');
            var $contractNumber = this.$('#vendorform-contractNumber-field');

            return {
                'brandName': $brandNameField.val(),
                'companyName': $companyNameField.val(),
                'type': $type.val(),
                'companyIdentificationNumber': $companyIdentificationNumber.val(),
                'registryOfCommerceNumber': $registryOfCommerceNumber.val(),
                'bankAccount': $bankAccount.val(),
                'bankName': $bankName.val(),
                'accountHolder': $accountHolder.val(),
                'contractNumber': $contractNumber.val(),
                'status' : 1
            };
        },

        /**
         * Method that gathers the data associated with the worksite form
         *
         * @private
         * @return {object} {{county, locality, address, status, email}} - Fields values
         */
        _getWorksiteData: function() {
            var $county = this.$('#worksiteform-county-field');
            var $locality = this.$('#worksiteform-locality-field');
            var $address = this.$('#worksiteform-address-field');
            var $status = this.$('#worksiteform-status-field');
            var $email = this.$('#worksiteform-email-field');

            return {
                'county': $county.val(),
                'locality': $locality.val(),
                'address': $address.val(),
                'status': $status.val(),
                'email': $email.val(),
            };
        },

        /**
         * Method that returns the representative data fields
         *
         * @private
         * @return {object} {{name, position, phoneNumber, email}} - Fields values
         */
        _getRepresentativesData: function() {
            var $name = this.$('#representativeform-name-field');
            var $position = this.$('#representativeform-position-field');
            var $phoneNumber = this.$('#representativeform-phoneNumber-field');
            var $email = this.$('#representativeform-email-field');

            return {
                'name': $name.val(),
                'position': $position.val(),
                'phoneNumber': $phoneNumber.val(),
                'email': $email.val()
            };
        },

        /**
         * Method that binds validation to the chosen model
         *
         * @param {object} model The model that the validation is being performend on
         * @private
         * @return {undefined}
         */
        _bindValidation: function(model){
            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Method triggered on the locality collection add
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionAdd: function(model){
            this._renderLocality(model);
        },

        /**
         * Method triggered on the locality collection reset
         *
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionReset: function(){
            this._renderLocalityReset();
        },

        /**
         * Method that renders the locality select
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _renderLocality: function(model){
            var $option = $('<option>').attr('value', model.get('id')).text(model.get('name'));
            this.$('#worksiteform-locality-field').append($option);
        },

        /**
         * Method that resets the locality select
         *
         * @private
         * @return {undefined}
         */
        _renderLocalityReset: function(){
            var $option = this.$("#worksiteform-locality-field option:eq(0)").detach();
            this.$('#worksiteform-locality-field').empty().append($option);
        },

        /**
         * Method that listens to the add and remove events of the collection
         *
         * @param {object} model - The model
         * @param {object} collection - The collection
         * @param {object} event - The event
         * @private
         * @return {undefined}
         */
        _onWorksitesAddRemove: function(model, collection, event){
            if (event.remove){
                return;
            }
            this._renderRow(model);
        },

        /**
         * Method that is triggered on worksite collection reset
         *
         * @private
         * @return {undefined}
         */
        _onWorksitesCollectionReset: function() {
            this._renderWorksiteReset();
        },

        /**
         * Method that deletes the table rows on worksite collection reset
         *
         * @private
         * @return {undefined}
         */
        _renderWorksiteReset: function() {
            this.$("#worksiteform-list-field").find("tbody tr").remove();
        },

        /**
         * Method that is triggered on representative collection reset
         *
         * @private
         * @return {undefined}
         */
        _onRepresentativesCollectionReset: function() {
            this._renderRepresentativeReset();
        },

        /**
         * Method that deletes the table rows on representative collection reset
         *
         * @private
         * @return {undefined}
         */
        _renderRepresentativeReset: function() {
            this.$("#representativeform-list-field").find("tbody tr").remove();
        },

        /**
         * Method that renders the table row
         *
         * @param {object} model - The model used
         * @private
         * @return {undefined}
         */
        _renderRow: function(model){

            var id = model.get('id') ? model.get('id') : model.cid;

            // General elements
            var $tableName = this.$('#worksiteform-list-field');
            var $wrapperName = this.$('.worksite-list');
            var $row = $tableName.find('tbody tr[data-id="' + id + '"]');
            var isEdit = !!$row.length;
            var email = '-';

            if (typeof model.get('email') !== 'undefined' && model.get('email').length) {

                email = model.get('email');
            }

            if (!isEdit) {

                var label = Translator.trans('js.btn_edit');

                // Creating dom elements
                var $editButton = this._createEditButton('edit-worksite', label);

                // Displaying table wrapper
                this._displayWrapper($wrapperName);

                $row = $('<tr>')
                    .attr({
                        "data-county-id": model.get('county'),
                        "data-locality-id": model.get('locality'),
                        "data-status-value": model.get('status'),
                        "data-email-value": model.get('email'),
                        "data-id": id
                    });

                // Worksite
                $('<td>')
                    .text(model.get('county_name') + ", " + model.get('locality_name') + ", " + model.get('address'))
                    .appendTo($row);

                // Email
                $('<td>')
                    .text(email)
                    .appendTo($row);

                // Status
                $('<td>')
                    .text(model.get('status_name'))
                    .appendTo($row);

                // Edit button
                $('<td>')
                    .append($editButton)
                    .appendTo($row);

                $tableName.find("tbody").append($row);
            } else {
                $row.find('td:eq(0)').text(model.get('county_name') + ", " + model.get('locality_name') + ", " + model.get('address'));
                $row.find('td:eq(1)').text(email);
                $row.find('td:eq(2)').text(model.get('status_name'));
                $row.attr({
                    "data-county-id": model.get('county'),
                    "data-locality-id": model.get('locality'),
                    "data-status-value": model.get('status'),
                    "data-email-value": model.get('email'),
                    "data-id": id
                });
            }
        },

        /**
         * Method that iterates through the localities
         * returned from php and sets them as options in the
         * locality select
         *
         * @param {object} localities The loclaities corresponding to the county
         * @private
         * @return {undefined}
         */
        _setLocalityOptions: function (localities) {
            for (var index in localities) {
                var opt = document.createElement("option");
                opt.value = localities[index].id;
                opt.innerHTML = localities[index].name;
                this.$('#worksiteform-locality-field').append(opt);
            }
        },

        /**
         * Method that makes the request to get the data
         * for the localities select
         *
         * @private
         * @return {undefined}
         */
        _onCountyChange: function () {
            var id = this.$("#worksiteform-county-field option:selected").val();
            this.localitiesCollection.setCountyId(id);
            this.localitiesCollection.reset(null);

            this.localitiesCollection.fetch({
                success: function(){
                    this.$('.chosen-select-locality').trigger("chosen:updated");
                    this.$("#worksiteform-locality-field").trigger('load');
                }.bind(this)
            });
        },

        /**
         * Method that is called if the form is valid
         *
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function (view, attr) {
            this._dataValidation(attr);
        },

        /**
         *  Method that is called if the form is invalid
         *
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @param {Object} error Validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function (view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @private
         * @return {undefined}
         */
        _dataValidation: function (attr) {
            var $fields = this.$('[data-validation]');
            if (attr) {
                $fields = this.$('[data-validation~="' + attr + '"]');
            }
            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that displays the error message if the validation fails
         * per form section
         *
         * @private
         * @returns {boolean} - If the collection is being populated
         */
        _isValidRepresentatives: function() {
            var errorValidationRepresentative = Translator.trans('js.error_validation_representative');
            this.$('.representative-validation-error').removeClass("hidden").text(errorValidationRepresentative);
            return this.representativesCollection.size() > 0;
        },

        /**
         * Method that displays the error message if the validation fails
         * per form section
         *
         * @private
         * @returns {boolean} - If the collection is being populated
         */
        _isValidWorksites: function() {
            var errorValidationWorksite = Translator.trans('js.error_validation_worksite');
            this.$('.worksite-validation-error').removeClass('hidden').text(errorValidationWorksite);

            return this.worksitesCollection.size() > 0;
        },

        /**
         * Method that sets the response in a specific form before saving it to the database
         *
         * @param {Object} model - The vendor model
         * @private
         * @return {undefined}
         */
        _addWorksiteRepresentativesModel: function(model) {
            var worksites = [];
            var representatives = [];

            // Unsetting data from the worksites collection
            this.worksitesCollection.each(function(worksite){

                var data = worksite.toJSON();
                data.locality = worksite.get('locality');

                worksites.push(data);
            });

            this.representativesCollection.each(function(representative){
                representatives.push(representative.toJSON());
            });

            // Setting extra fields worksites and representatives on model
            model.set("worksites", worksites);
            model.set("representatives", representatives);
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected county field option
         */
        _getCountyValue: function () {
            return this.$('#worksiteform-county-field').find("option:selected").text();
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected locality field option
         */
        _getLocalityValue: function () {
            return this.$('#worksiteform-locality-field').find("option:selected").text();
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected status field option
         */
        _getStatusValue: function () {
            return this.$('#worksiteform-status-field').find("option:selected").text();
        },

        /**
         * Method that populates the array with the data that is
         * added by the user in worksite form
         *
         * @private
         * @return {object} Object filled with model data
         */
        _populateWorksiteData: function () {
            // Getting form data
            var $data = this._getWorksiteData();

            // Getting fields text values
            var $county = this._getCountyValue();
            var $locality = this._getLocalityValue();
            var $status = this._getStatusValue();
            return {
                county: $data.county,
                county_name: $county,
                locality: $data.locality,
                locality_name: $locality,
                status: $data.status,
                status_name: $status,
                address: $data.address,
                email: $data.email,
            };
        },
        /**
         * Method that shows the wrapepr that holds the table
         *
         * @param {string} wrapperName - Name of the wrapper o show
         * @private
         * @return {undefined}
         */
        _displayWrapper: function(wrapperName) {
            if (wrapperName.hasClass("hidden")) {
                wrapperName.removeClass("hidden");
            }
        },

        /**
         * Method that hides the wrapper that holds the table
         *
         * @param {string} wrapperName - the name of the div that acts as a wrapper
         * @private
         * @return {undefined}
         */
        _hideWrapper: function(wrapperName) {
            wrapperName.addClass("hidden");
        },

        /**
         * Method that creates a button to be used in the table
         *
         * @param {string} name - Button name
         * @param {string} label - String that will be translated
         * @private
         * @return {string} - The html for the button created
         */
        _createEditButton: function(name, label) {
            return "<span class='"+ name +"-form btn btn-primary'>" + label + "</span>";
        },

        /**
         * Method that encapsulates and element in <td> tags
         *
         * @param {string} element - Html for the element that will be enclosed in the table data cell
         * @private
         * @return {string} - The html for the table cell created
         */
        _createTableDataElement: function(element) {
            // todo DELETE this method
            return "<td>" + element + "</td>";
        },

        /**
         * Method that creates the table that holds the list with all the
         * worksites the user has added
         *
         * @private
         * @return {undefined}
         */
        _onCreateWorksite: function () {

            // Populating data array
            var data = this._populateWorksiteData();
            var record = new z.WorksiteModel(data);
            this._bindValidation(record);

            if(!record.isValid(true)){
                return;
            }

            this._bindValidation();

            if (this.worksiteEditAddId){
                var worksite = this.worksitesCollection.get(this.worksiteEditAddId);
                worksite.set(record.toJSON());
            } else {
                this.worksitesCollection.push(record);
            }

            var $addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#worksiteForm-add-button').html($addingButtonTitle);

            this.worksiteEditAddId = undefined;

            this.$('.worksite-validation-error').addClass('hidden');

            this._readonlyAndDisabled(false);

            // Resetting fields after value is being added in table
            this._resetFields("worksite");
            this.$('.chosen-select-county').trigger("chosen:updated");
            this.$('.chosen-select-locality').trigger("chosen:updated");
        },

        /**
         * Method that listens to the change event of the collection
         *
         * @param {object} model - The model
         * @private
         * @return {undefined}
         */
        _onWorksitesChange: function(model){
            this._renderRow(model);

            this._readonlyAndDisabled(false);
        },
        /**
         * Method that resets the specified fields according to id passed
         *
         * @param {string} value Name of the id to search for
         * @private
         * @return {undefined}
         */
        _resetFields: function (value) {
            this.$('[id^=' + value + ']').val("");
        },

        /**
         * Method that fills in the values for the fields that are being edited
         *
         * @param {object} e The element on which the click is being made
         * @private
         * @return {undefined}
         */
        _onEditWorksiteForm: function (e) {
            var $editingButonTitle = Translator.trans('js.btn_edit');
            this.$('#worksiteForm-add-button').html($editingButonTitle);

            var id = $(e.target).parents("tr").data("id");
            var worksite = this.worksitesCollection.get(id);
            this.worksiteEditAddId = id;

            if ( worksite.get('editable') === true ) {
                this._readonlyAndDisabled(true);
            } else {
                this._readonlyAndDisabled(false);
            }

            this.$('#worksiteform-locality-field').one('load', function(){
                this.$('#worksiteform-locality-field').val(worksite.get('locality'));
                this.$('.chosen-select-locality').trigger("chosen:updated");
            }.bind(this));

            this.$('#worksiteform-county-field').val(worksite.get('county')).trigger("change");
            this.$('#worksiteform-address-field').val(worksite.get('address'));
            this.$('#worksiteform-status-field').val(worksite.get('status'));
            this.$('#worksiteform-email-field').val(worksite.get('email'));

            // Refresh the chosen library fields
            this.$('.chosen-select-county').trigger("chosen:updated");

        },

        /**
         * Method that enable or disable the county and locality fields if the vendor has or no an order on that worksite
         * @param {bool} value Whether to block edit on county and locality, or not
         * @private
         * @return {undefined}
         */
        _readonlyAndDisabled: function(value) {
            var $county = this.$('#worksiteform-county-field');
            var $locality = this.$('#worksiteform-locality-field');

            $county.attr('readonly', value);
            $county.attr('disabled', value);
            $locality.attr('readonly', value);
            $locality.attr('disabled', value);
        },

        /**
         * Method that gets triggered when the representative is being created
         *
         * @private
         * @return {undefined}
         */
        _onCreateRepresentative: function () {
            // Populating data array
            var data = this._populateRepresentativeData();
            var record = new z.RepresentativeModel(data);

            this._bindValidation(record);

            if(!record.isValid(true)){
                return;
            }

            this._bindValidation();

            if (this.representativeEditAddId){
                var representatives = this.representativesCollection.get(this.representativeEditAddId);
                representatives.set(record.toJSON());
            } else {
                this.representativesCollection.push(record);
            }

            var addingButtonTitle = Translator.trans('js.btn_add');
            this.$('#representativeForm-add-button').html(addingButtonTitle);

            this.$('.representative-validation-error').addClass('hidden');

            this.representativeEditAddId = undefined;

            // Resetting fields after value is being added in table
            this._resetFields("representative");
        },

        /**
         * Populating the general array with representative data from the form
         *
         * @private
         * @return {object} Object filled with model data
         */
        _populateRepresentativeData: function() {
            var $data = this._getRepresentativesData();

            return {
                name: $data.name,
                position: $data.position,
                phoneNumber: $data.phoneNumber,
                email: $data.email
            };
        },

        /**
         * Method that listens to the add and remove events of the collection
         *
         * @param {object} model - The model that is binded to the collection
         * @private
         * @return {undefined}
         */
        _onRepresentativesAdd: function(model){
            this._renderRepresentativeRow(model);
        },

        /**
         * Method that removes the selected entry from the collection
         *
         * @param {object} model - The model that is binded to the collection
         * @private
         * @return {undefined}
         */
        _onRepresentativesRemove: function(model){
            this.representativesCollection.remove(this.representativeEditAddId);
            this._renderRepresentativeRow(model);
        },

        /**
         * Method that listens to the change event of the collection
         *
         * @param {object} model - The model
         * @private
         * @return {undefined}
         */
        _onRepresentativesChange: function(model){
            this._renderRepresentativeRow(model);
        },

        /**
         * Method that deletes a representatives row
         *
         * @param {object} e - Event
         * @private
         * @return {undefined}
         */
        _onDeleteRepresentativeRow: function(e) {
            var id = $(e.target).parents('tr').attr("data-id");
            this.representativesCollection.remove(id);
            this._resetFields("representative");
            this.representativeEditAddId = undefined;
            this._disableDeleteButton();
        },

        /**
         * Method that removes the representative from the collection
         *
         * @param {Object} model - Model that is binded to the collection
         * @private
         * @return {undefined}
         */
        _onRepresentativesCollectionRemove: function(model){
            this._renderRepresentativeRow(model, true);
        },

        /**
         * Method that adds a representative to the collection
         *
         * @param {Object} model - Model that is binded to the collection
         * @private
         * @return {undefined}
         */
        _onRepresentativesCollectionAdd: function (model) {
            this._renderRepresentativeRow(model);
        },

        /**
         * Method that catches the change event on the collection
         *
         * @param {Object} model - Model that is binded to the collection
         * @private
         * @return {undefined}
         */
        _onRepresentativesCollectionChange: function(model) {
            this._renderRepresentativeRow(model);
        },

        /**
         * Method that processes data for the representative form
         *
         * @param {object} e - the event
         * @private
         * @return {undefined}
         */
        _onEditRepresentativeForm: function (e) {
            var $editingButtonTitle = Translator.trans('js.btn_edit');
            this.$('#representativeForm-add-button').html($editingButtonTitle);

            var id = $(e.target).parents('tr').data("id");
            var representatives = this.representativesCollection.get(id);
            this.representativeEditAddId = id;

            this.$('#representativeform-name-field').val(representatives.get('name'));
            this.$('#representativeform-position-field').val(representatives.get('position'));
            this.$('#representativeform-phoneNumber-field').val(representatives.get('phoneNumber'));
            this.$('#representativeform-email-field').val(representatives.get('email'));
        },

        /**
         * Method that renders the rows for representatives
         *
         * @param {Object} model - The model for the collection
         * @param {bool} remove - If true, the action is removal of rows
         * @private
         * @return {undefined}
         */
        _renderRepresentativeRow: function(model, remove) {
            var $wrapperName = this.$('.representative-list');

            remove = _.isUndefined(remove) ? false : remove;
            var id = model.get('id') ? model.get('id') : model.cid;

            // General elements
            var $tableName = this.$('#representativeform-list-field');
            var $row = $tableName.find('tbody tr[data-id="' + id + '"]');
            var isEdit = !!$row.length;

            if( remove ){
                $row.remove();
                this.representativesCollection.remove(model.cid);

                // If there are no items in the collection, remove wrapper and table
                if (this.representativesCollection.size() === 0) {
                    this._hideWrapper($wrapperName);
                }
                return;
            }

            if( !isEdit ){
                // Creating dom elements
                var editButtonLabel = Translator.trans('js.btn_edit');
                var $editButton = this._createEditButton('edit-representative', editButtonLabel);
                var $deleteButton = '<div class="delete-representative-form btn"><i class="fa fa-trash-o"></i></div>';

                // Displaying table wrapper
                this._displayWrapper($wrapperName);

                $row = $('<tr>')
                    .attr("data-id", id);

                // Name
                $('<td>')
                    .text(model.get('name'))
                    .appendTo($row);

                // Position
                $('<td>')
                    .text(model.get('position'))
                    .appendTo($row);

                // Phone number
                $('<td>')
                    .text(model.get('phoneNumber'))
                    .appendTo($row);

                // Email
                $('<td>')
                    .text(model.get('email'))
                    .appendTo($row);

                // Actions
                $('<td>')
                    .append($editButton)
                    .append($deleteButton)
                    .appendTo($row);

                // Appending table elements to table
                $tableName.find("tbody").append($row);
            } else {

                $row.find('td:eq(0)').text(model.get('name'));
                $row.find('td:eq(1)').text(model.get('position'));
                $row.find('td:eq(2)').text(model.get('phoneNumber'));
                $row.find('td:eq(3)').text(model.get('email'));
                $row.attr({
                    "data-id": id
                });
            }
            this._disableDeleteButton();
        },

        /**
         * Concatenate brand name with company name
         *
         * @param {mixed} data - datatable param
         * @param {mixed} type - datatable param
         * @param {mixed} row - datatable param
         * @return {string} - the two names concatenated
         */
        concatBrandName: function(data, type, row) {
            return row.brandName + ' - ' + row.companyName;
        }

    });

})(window.z = window.z || {}, Backbone);
