"use strict";

(function(z, Backbone, _){

    z.OrderScheduleForm = Backbone.View.extend({

        // Setting the element to be the order page so that we can update the info warning
        // panel when the order is being scheduled
        el:"#order-page",

        events: {
            'click #schedule-order-modal-btn': '_displayScheduleModalForm',
            'click #orderscheduleform-modal .close-link' : '_hideScheduleModalForm',
            'click #orderscheduleform-refresh-offers': '_onRefreshOffers',
            'change #orderscheduleform-sort-field': '_onSortChange',
            'change #orderscheduleform-vendor-field': '_onVendorChange',
            'ifChecked #orderscheduleform-offers-table input[type="radio"]': '_onOfferCheck',
            'submit .modal-form#order-schedule-form': '_onSubmitForm'
        },

        /**
         * The time in seconds after which the offers expire
         */
        OFFER_EXPIRE_TIME: 3 * 60,

        /**
         * Constants used to fill the sort field
         */
        SORT_BY_AVAILABILITY: 1,
        SORT_BY_DISTANCE: 2,

        /**
         * Constants used for the order status
         */
        STATUS_FOR_PAYMENT: 2,
        STATUS_APPOINTED: 3,

        /**
         * Constructor.
         * @return {undefined}
         */
        initialize: function() {
            this._loadShowroom( function() {

                this._displayInvoiceableEl();
            });

            // Manage fields visibility
            $(document).on("schedule.handleFieldsVisibility", function() {

                this._loadShowroom( function() {

                    this._displayInvoiceableEl();
                });

            }.bind(this));

            this.modalElement = this.$('#orderscheduleform-modal');
            this.$errorMsg = this.$el.find('#orderscheduleform-error-panel');
            this.additionalCommentGroup = this.$el.find("#schedule-order-form .additional-comment-group");

            this.offersCollection = new z.OffersCollection();
            this.orderVendorsCollection = new z.VendorsCollection();

            if (!z.security.isResellerAssociated()) {
                this._loadService();
            }

            Backbone.Validation.bind(this, {
                valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });

            this.setEvents();
        },

        /**
         * Setting events when the modal is being loaded
         * @return {undefined}
         */
        setEvents: function()
        {
            this.additionalCommentGroup.addClass('hidden');

            // On success
            this.listenTo(this.model, 'success', function(res) {

                var successMessage = Translator.trans('js.order_schedule_success_message');

                // Show success message, stop loader, hide modal
                this._hideScheduleModalForm();

                this._isLoading(false, this._getSubmitButtonSelector());
                // Changing fields values
                this._changeVendorFieldsValues(res.get('vendor').companyName + ' - ' + res.get('vendor').brandName);

                this._updateDateFields(this.model);
                this._updatePriceRule(this.model);
                this._updateCostInfo(this.model);
                this._updateStatus(this.model);

                // Hide the schedule button
                this._hideScheduleButton();

                // Display the print button
                this._displayPrintButton();

                // Showing success message
                this._showSuccess(successMessage);

                // Updating order history section
                $(document).trigger("comments.updateComments");

            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(model, res) {

                // Show error message and stop button loader
                this._isLoading(false, this._getSubmitButtonSelector());

                // Showing error messages
                this._displayError(res);

            }.bind(this));

            this.listenTo(this, "schedule.load.offers", function() {

                // Reset the cost fields
                this._toggleTransportCostAndServiceValue();
                this._getTotalCostEl().text('');

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.canceled", function() {

                // Hide the schedule button if the order was canceled
                this._hideScheduleButton();

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.finalized", function() {

                // Hide the schedule button if the order was finalized
                this._hideScheduleButton();

            }.bind(this));

            this.listenTo(z.OrderStatus, "order.status.finalized", function() {

                // Hide the schedule button if the order was finalized
                this._hideScheduleButton();

            }.bind(this));

            this.listenTo(this.offersCollection, 'request',   this._onOffersCollectionRequest);
            this.listenTo(this.offersCollection, 'sync',    this._onOffersCollectionSync);
            this.listenTo(this.offersCollection, 'reset',   this._onOffersCollectionReset);
            this.listenTo(this.offersCollection, 'update',  this._onOffersCollectionUpdate);
            this.listenTo(this.offersCollection, 'error',   this._onOffersCollectionError);

            this.listenTo(this.orderVendorsCollection, 'sync', this._onOrderVendorsCollectionSync);
            this.listenTo(this.orderVendorsCollection, 'reset', this._onOrderVendorsCollectionReset);
        },

        /**
         * Display the invoiceable element if it's valid
         * @private
         * @return {undefined}
         */
        _displayInvoiceableEl: function() {

            if (this._isValidForInvoice()) {

                this._toggleInvoiceableEl(true);

            } else {

                this._toggleInvoiceableEl(false);
            }
        },

        /**
         * Verify if the order is valid to be invoiceable or not
         * @return {boolean} Is valid to be invoiceable or not
         * @private
         */
        _isValidForInvoice: function() {

            return this._canResellerInvoice();
        },

        /**
         * Verify if the reseller can invoice
         * @return {boolean} can invoice or not
         * @private
         */
        _canResellerInvoice: function() {

            if (_.isUndefined(this.showroom)) {

                return false;
            }

            var reseller = this.showroom.get('reseller');

            if (reseller.hasOwnProperty('canInvoice')) {

                return reseller.canInvoice;
            }

            return false;
        },

        /**
         * Method that sets the showroom model for the selected showroom
         * @param {function} callback the callback function
         * @private
         * @return {undefined}
         */
        _loadShowroom: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var showroomId = z.boot.order.showroom.id;

            if (showroomId) {

                this.showroom = new z.ShowroomModel();

                this.showroom.set({id: showroomId});

                this.showroom.fetch({
                    success: function(){

                        callback.apply(this, arguments);

                    }.bind(this),
                    error: function(){

                        callback.apply(this, arguments);

                    }.bind(this)
                });
            }
        },

        /**
         * Display schedule modal form
         * @return {undefined}
         * @private
         */
        _displayScheduleModalForm: function() {

            var id = this._getOrderId();

            this._resetForm();

            // Displaying the modal
            this.modalElement.modal("show");

            // Setting the order id on the model
            this.model.setOrderId(id);

            // Fetching collection data
            this._loadOffers();
        },

        /**
         * Retrieves the current order's id
         * @return {string} The order id
         * @private
         */
        _getOrderId: function(){

            return this.$('#reschedule-order-form').data('id');
        },

        /**
         * Retrieves the current service's id
         * @return {string} The service id
         * @private
         */
        _getServiceId: function(){

            return this.$('#order-service').data('id');
        },

        /**
         * Retrieves the current county's id
         * @return {string} The service id
         * @private
         */
        _getCountyId: function(){

            return this.$('#order-customer-county').data('id');
        },

        /**
         * Retrieves the current quantity
         * @return {string} The service id
         * @private
         */
        _getQuantity: function(){

            return this.$('#order-quantity').data('value');
        },

        /**
         * Retrieves the order's sort field element
         * @returns {object} The element
         * @private
         */
        _getSortFieldEl: function(){
            return this.$('#orderscheduleform-sort-field');
        },

        /**
         * Retrieves the order's vendor field element
         * @returns {object} The element
         * @private
         */
        _getVendorFieldEl: function(){
            return this.$('#orderscheduleform-vendor-field');
        },

        /**
         * Retrieves the order's assoc vendor field element
         * @returns {object} The element
         * @private
         */
        _getAssocVendorFieldEl: function(){
            return this.$('#orderscheduleform-vendor-field');
        },

        /**
         * Retrieves the order's vendor container element
         * @returns {object} The element
         * @private
         */
        _getVendorContainerEl: function(){
            return this._getVendorFieldEl().parent();
        },

        /**
         * Retrieves the service cost element
         * @returns {object} The element
         * @private
         */
        _getServiceCostEl: function(){
            return this.$('#orderschedule-service-cost');
        },

        /**
         * Retrieves the transport cost value element
         * @returns {object} The element
         * @private
         */
        _getTransportCostEl: function(){
            return this.$('#orderschedule-transport-cost');
        },

        /**
         * Retrieves the total cost element
         * @returns {object} The element
         * @private
         */
        _getTotalCostEl: function(){
            return this.$('#orderschedule-total-cost');
        },

        /**
         * Retrieves the total field element
         * @returns {object} The element
         * @private
         */
        _getTotalFieldEl: function(){
            return this.$('#orderform-total-field');
        },

        /**
         * Retrieves the order's invoiceable field element
         * @returns {object} The element
         * @private
         */
        _getInvoiceableFieldEl: function(){
            return this.$('#orderscheduleform-invoiceable-field');
        },

        /**
         * Retrieves the order's invoiceable container element
         * @returns {object} The element
         * @private
         */
        _getInvoiceableContainerEl: function(){
            return this.$('#orderscheduleform-invoiceable-container');
        },

        /**
         * Method used for hiding the schedule order button
         * @private
         * @return {undefined}
         */
        _hideScheduleButton: function() {

            this.$el.find('#schedule-button-container').hide();
            this.$el.find('#schedule-order-modal-btn').hide();
        },

        /**
         * Display the print button
         * @private
         * @return {undefined}
         */
        _displayPrintButton: function() {

            this.$('#order-print-btn-container').removeClass('hidden');
        },

        /**
         * Method that updates the vendor name fields in the order
         * @param {object} vendorName the name of the vendor
         * @returns {undefined}
         */
        _changeVendorFieldsValues: function(vendorName)
        {
            this.$('#associated-vendor').text(vendorName);
            this.$('#vendor-company-name').text(vendorName);
        },

        /**
         * Method used for field update
         * @param {Object} model the view's model
         * @returns {undefined}
         * @private
         */
        _updateDateFields: function(model) {

            var newDate = this._renderScheduleDate(model.get('order').appointmentDate);

            this.$('#order-appointment-date').text(newDate);
            this.$('#order-processing-scheduled-date').text(newDate);
        },

        /**
         * Method used for field update
         * @param {Object} model the view's model
         * @returns {undefined}
         * @private
         */
        _updatePriceRule: function(model) {

            var priceRule = model.get('order').priceRule ? model.get('order').priceRule.name : '-';

            this.$('#order-price-rule').text(priceRule);
        },

        /**
         * Update the status
         * @param {Object} model the view's model
         * @private
         * @returns {undefined}
         */
        _updateStatus: function(model) {

            var status = '-';

            switch (model.get('order').status) {

                case this.STATUS_APPOINTED:
                    status = Translator.trans('js.order_status_appointed');
                    break;

                case this.STATUS_FOR_PAYMENT:
                    status = Translator.trans('js.order_status_for_payment');
            }

            this.$('#order-status').text(status);
        },

        /**
         * Method used for field update
         * @param {Object} model the view's model
         * @returns {undefined}
         * @private
         */
        _updateCostInfo: function(model) {

            var transportCost = model.get('order').info.content.transport.cost;
            var totalCost = model.get('order').total;
            var serviceCost = totalCost - transportCost;

            this.$('#order-service-cost').text(serviceCost);
            this.$('#order-transport-cost').text(transportCost);
            this.$('#order-total-cost').text(totalCost);
        },

        /**
         * Renders a date as string
         * @param {string} value The string representation of the date
         * @return {string} The formatted date
         * @private
         */
        _renderScheduleDate: function(value){
            return moment(value).locale('ro').format('DD-MM-Y');
        },

        /**
         * Methid that returns the selector for the save button
         * @returns {*} string - the selector for the save button
         * @private
         */
        _getSubmitButtonSelector: function(){
            return this.$('#orderscheduleform-save-button');
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method used to show a general error message if the user is
         * browsing the app on mobile
         * @return {undefined}
         */
        _showMobileAlert: function()
        {
            var errorMessage = Translator.trans('js.order_reschedule_error_message_mobile');

            this.$el.find('#order-schedule-general-error-panel')
                .removeClass('hidden')
                .text(errorMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this._hideMobileAlert();
                }.bind(this));
        },

        /**
         * Method used to hide the general error message shown if the form is not valid
         * @return {undefined}
         */
        _hideMobileAlert: function()
        {
            this.$el.find('#order-schedule-general-error-panel').addClass('hidden');
        },

        /**
         * Retrieves the offers table element
         * @returns {object} The element
         * @private
         */
        _getOffersTableEl: function(){
            return this.$('#orderscheduleform-offers-table');
        },

        /**
         * Retrieves the offers table row elements
         * @returns {object} The element
         * @private
         */
        _getOffersTableRowEls: function(){
            return this._getOffersTableEl().find('tr');
        },

        /**
         * Retrieves the offers empty element
         * @returns {object} The element
         * @private
         */
        _getOffersEmptyEl: function(){
            return this.$('#orderscheduleform-offers-empty');
        },

        /**
         * Retrieves the offer field el
         * @returns {object} The element
         * @private
         */
        _getOfferFieldEl: function(){
            return this.$('#orderscheduleform-offer-field');
        },

        /**
         * Retrieves the offers table body element
         * @returns {object} The element
         * @private
         */
        _getOffersTableBodyEl: function(){
            return this._getOffersTableEl().find('tbody');
        },

        /**
         * Method that hides the modal window.
         * @return {undefined}
         * @private
         */
        _hideScheduleModalForm: function() {

            var $table = this._getOffersTableEl();

            this.modalElement.modal("hide");

            this._resetForm();

            $table.addClass('hidden');

            this.offersCollection.reset();

        },

        /**
         * Method that resets the form
         * @return {undefined}
         * @private
         */
        _resetForm: function() {

            this.$('#order-schedule-form').get(0).reset();

            this.dataValidation();

            this._clearError();

            this._hideMobileAlert();
        },

        /**
         * If the third option is selected, the second field is being shown
         * @param {Object} element The element that is selected
         * @return {undefined}
         * @private
         */
        _onFieldChange: function(element) {
            if (element.target.value === "3") {
                this.additionalCommentGroup.removeClass('hidden');
                this.additionalCommentGroup.find("label[for='orderscheduleform-additional-comment-field']").addClass('required');
                this.additionalCommentGroup.find("input").prop('required', true);
            } else {
                this.additionalCommentGroup.addClass("hidden");
            }
        },

        /**
         * Collecting data from form inputs.
         * @return {Object} {{brandName: *, companyName: *, address: *, phoneNumber: *, status: *, apiUser: *, apiKey: *}}
         * @private
         */
        _getFormData: function() {

            var isInvoiceable = z.boot.order.invoiceable;

            return {
                'offer' : this._getOfferRecord().id,
                'invoiceable': isInvoiceable
            };
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $panel = this.$('#orderscheduleform-modal .modal-content');

            $panel.toggleClass('sk-loading', loading);

            if (!buttonSelector) {

                return;
            }

            var $button = this.$(buttonSelector);
            var ladda = $button.data('ladda') ? $button.data('ladda') : $button.ladda().data('ladda');

            if( loading ){

                ladda.start();

            } else {

                ladda.stop();
            }
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showSuccess: function(message) {

            var $success = this.$('#order-reschedule-success-message');

            $success
                .removeClass('hidden')
                .html(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that shows the error message
         * @param {string} message the error message
         * @return {undefined}
         * @private
         */
        _showError: function(message) {

            var $error = this.$('#orderscheduleform-error-panel');

            $error
                .text(message)
                .removeClass('hidden')
                .slideUp(0)
                .slideDown()
                .delay(5000);
        },

        /**
         * Clears the error from the user interface
         * @return {undefined}
         * @private
         */
        _clearError: function(){

            this._hideMobileAlert();

            var $error = this.$('#orderscheduleform-error-panel');

            $error
                .finish()
                .slideUp(0)
                .addClass('hidden');
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         * @private
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var formData = this._getFormData();

            this.model.clear();

            this.model.set(formData);

            if( !this.model.isValid(true) ){
                this._showMobileAlert();
                return;
            }

            this._isLoading(true, this._getSubmitButtonSelector());

            this.model.save(null,{
                success: function(i) {

                    z.boot.order = this.model.get('order');

                    this._clearOfferTimer();
                    this.model.trigger("success", i);

                    // Update order invoice details
                    $(document).trigger("order.updateInvoiceDetails");

                    // Trigger the event only if the order is appointed to display other things in the interface (reschedule btn, quantity progress, etc.)
                    if (z.boot.order.status === this.STATUS_APPOINTED) {

                        this.trigger('orderScheduleSuccess');
                    }

                    if (z.boot.order.status === this.STATUS_FOR_PAYMENT && z.boot.order.invoiceable) {

                        this.trigger('enableOrderInvoice');
                    }

                    setTimeout(function () {
                        location.reload();
                    },1500);

                }.bind(this)
            });


        },

        /**
         * Refreshes the order offers
         * @return {undefined}
         * @private
         */
        _onRefreshOffers: function(){

            this._loadOffers();
        },

        /**
         * Method called after the sort gets changed
         * @return {undefined}
         * @private
         */
        _onSortChange: function() {

            this._loadOffers();

            this._loadVendors();
        },

        /**
         * Method called after the vendor gets changed
         * @return {undefined}
         * @private
         */
        _onVendorChange: function(){

            this._loadOffers();
        },

        /**
         * Marks the offer as reserved
         * @param {function} success The callback
         * @param {function} error The callback
         * @return {undefined}
         * @private
         */
        _reserveSelectedOffer: function(success, error){

            success = _.isFunction(success) ? success : _.noop;

            var record = this._getOfferRecord();
            var offer = this.offersCollection.get(record.id);
            var date = moment();

            offer.set('reservationDate', date.toISOString());

            offer.save(null, {
                success: function(){

                    success.apply(this, arguments);

                }.bind(this),
                error: function(){

                    error.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Selects the offer with the specified id from the list
         * @return {undefined}
         * @private
         */
        _selectPreviousOffer: function(){

            var $rows = this._getOffersTableRowEls();
            var $row = null;

            if (!this.previousOffer) {

                $check = this._getOffersChecksEl().filter(':checked');

                $check.iCheck('uncheck');

                return;
            }

            var offerId = this.previousOffer.id;

            $rows.each(function(){

                var $this = $(this);

                if ('' + $this.data('id') === '' + offerId) {

                    $row = $this;

                    return false;
                }
            });

            if (!$row) {

                $check = this._getOffersChecksEl().filter(':checked');

                $check.iCheck('uncheck');

                return;
            }

            var $check = $row.find('input[type="radio"]');

            $check
                .data('silentCheck', true) // workaround
                .iCheck('check');
        },

        /**
         * Sets the offer alert expiration timer
         * @return {undefined}
         * @private
         */
        _setOfferTimer: function(){

            this._clearOfferTimer();

            this.offerTimer = setTimeout(function(){

                this._showError(Translator.trans('js.order_reschedule_offers_expired_message'));

            }.bind(this), this.OFFER_EXPIRE_TIME * 1000);
        },

        /**
         * Removes the offer alert expiration timer
         * @return {undefined}
         * @private
         */
        _clearOfferTimer: function(){

            if (this.offerTimer) {
                clearTimeout(this.offerTimer);
            }
        },

        /**
         * Attaches the offer to the form
         * @return {undefined}
         * @private
         */
        _attachSelectedOffer: function() {

            var record = this._getOfferRecord();

            this.previousOffer = record;

            this._setOffer(record.id);
            this._setTotal(record.total, record.transport);
        },

        /**
         * Sets the offer into the form
         * @param {string} offer The offer id
         * @return {undefined} - undefined
         * @private
         */
        _setOffer: function(offer){

            var $field = this._getOfferFieldEl();

            $field.val(offer);
        },

        /**
         * Renders the offer
         * @param {object} offer The offer model
         * @return {undefined}
         * @private
         */
        _renderOffer: function(offer) {

            var $tbody = this._getOffersTableBodyEl();

            var $row = $('<tr>').attr({
                'data-id': offer.get('id'),
                'data-total': offer.get('total'),
                'data-transport': offer.get('transportCost')
            });

            var $checkbox = $('<input>').attr({
                type: 'radio',
                name: 'offer'
            });

            var isReseller = z.security.isResellerAssociated();
            var isVendor = z.security.isVendorAssociated();
            var canViewVendorField = !(isReseller || isVendor);

            if(isReseller || !this.service.get('hasTransportCost')) {
                this._getOrderTableThDistance().addClass('hidden');
                this._getOrderTableThTransport().addClass('hidden');
            } else {
                this._getOrderTableThDistance().removeClass('hidden');
                // this._getOrderTableThTransport().removeClass('hidden');
            }

            var date = this._renderDate(offer.get('appointmentDate'));
            var total = this._renderPrice(offer.get('total'));
            var distance = this._renderDistance(offer);
            var transportCost = this._renderPrice(offer.get('transportCost'));

            var $check = $('<td>').append($checkbox);
            var $date = $('<td>').text(date);
            var $interval = $('<td>').addClass('text-center text-danger font-bold').text(offer.get('daysInterval'));
            var $price = $('<td>').addClass('text-center').text(total);
            var $vendor = $('<td>').text(this._getVendorNameFromOffer(offer));
            var $distance = $('<td>').addClass('text-center').text(distance);
            var $transportCost = $('<td>').addClass('text-center').text(transportCost);

            if (canViewVendorField) {

                this._getOrderTableThVendor().removeClass('hidden');
            } else {

                this._getOrderTableThVendor().addClass('hidden');
            }

            if (canViewVendorField) {

                if (typeof this.service !== 'undefined' && this.service.get('hasTransportCost') === true) {

                    $row.append($check, $date, $interval, $vendor, $price, $distance, $transportCost);
                } else {

                    $row.append($check, $date, $interval, $vendor, $price, $distance);
                }


            } else {
                    this._getSortFieldEl().hide();
                if (typeof this.service !== 'undefined' && this.service.get('hasTransportCost') === true) {

                    $row.append($check, $date, $interval);
                } else {

                    $row.append($check, $date, $interval);
                }

            }

            $tbody.append($row);
        },

        /**
         * Renders the price accordingly
         * @param {string|int} value The price
         * @return {string} The formatted price
         * @private
         */
        _renderPrice: function(value){
            return value;
        },

        /**
         * Method that returns the offer distance.
         * @param {object} offer - the offer object
         * @private
         * @return {float} - the distance
         */
        _renderDistance: function (offer) {
            return offer.get('distance');
        },

        /**
         * Retrieves the distance th element
         * @private
         * @return {object} - the distance element
         */
        _getOrderTableThDistance: function () {
            return this.$('#order-schedule-table-th-distance ');
        },

        /**
         * Retrieves the transport cost th element
         * @private
         * @return {object} - the transport el
         */
        _getOrderTableThTransport: function () {
            return this.$('#order-schedule-table-th-transport');
        },

        /**
         * Retrieves the vendor th element
         * @private
         * @return {object} -the  vendor el
         */
        _getOrderTableThVendor: function () {
            return this.$('#order-schedule-table-th-vendor');
        },

        /**
         * Method that builds the name of the vendor from brandName and Company name
         * @param {object} offer - the offer object
         * @return {string} - the vendor name
         * @private
         */
        _getVendorNameFromOffer: function (offer) {
            return offer.get('worksite').vendor.companyName;
        },

        /**
         * Retrieves the vendor el
         * @return {object} - the vendor el
         * @private
         */
        _getorderscheduleformVendor: function () {
            return this.$('#order-schedule-form-vendor');
        },

        /**
         * Renders a date as string
         * @param {string} value The string representation of the date
         * @return {string} The formatted date
         * @private
         */
        _renderDate: function(value){
            return moment(value).locale('ro').format('dddd, D MMMM');
        },

        /**
         * Retrieves the checks from offers table
         * @returns {object} The element
         * @private
         */
        _getOffersChecksEl: function(){
            return this._getOffersTableBodyEl().find('input[type="radio"]');
        },

        /**
         * Bind iCheck from radios and checkboxes
         * @return {undefined}
         * @private
         */
        _unbindICheck: function(){
            var $els = this._getOffersChecksEl();

            $els.iCheck('destroy');
        },

        /**
         * Bind iCheck to radios and checkboxes
         * @return {undefined}
         * @private
         */
        _bindICheck: function(){
            var $els = this._getOffersChecksEl();

            $els.iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
                increaseArea: '20%' // optional
            });
        },

        /**
         * Method that renders the offers in the modal window
         * @return {undefined}
         * @private
         */
        _renderOffers: function() {
            var $tbody = this._getOffersTableBodyEl();
            var $table = this._getOffersTableEl();
            var $empty = this._getOffersEmptyEl();

            this._unbindICheck();

            $tbody.empty();

            if (!this.offersCollection.length) {

                $empty.removeClass('hidden');
                $table.addClass('hidden');

                return;
            }

            $empty.addClass('hidden');
            $table.removeClass('hidden');

            this.offersCollection.each(function (offer) {

                this._renderOffer(offer);

            }, this);

            this._bindICheck();
        },

        /**
         * Retrieves the record form with the offer
         * @returns {object} The offer
         * @private
         */
        _getOfferRecord: function() {
            var $checked = this._getOffersChecksEl().filter(':checked');

            if (!$checked.length) {

                return {
                    id: null,
                    total: null,
                    transport: null
                };
            }

            var $row = $checked.parents('tr:first');

            return {
                id: $row.data('id'),
                total: $row.data('total'),
                transport: $row.data('transport')
            };
        },

        /**
         * Method called after offers get checked
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onOfferCheck: function(event){

            var $check = $(event.currentTarget);

            if ($check.data('silentCheck')) {

                $check.removeData('silentCheck');

                return;
            }

            this._reserveSelectedOffer(function(){

                this._setOfferTimer();

                this._attachSelectedOffer();

                this._clearError();

            }, function(){

                this._selectPreviousOffer();
            });
        },

        /**
         * Method called after the request event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionRequest: function(){

            this._clearOfferTimer();

            this._clearError();

            this._isLoading(true);
        },

        /**
         * Method called after the sync event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionSync: function(){

            this._isLoading(false);
        },

        /**
         * Method called after the reset event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionReset: function(){

            this._renderOffers();
        },

        /**
         * Method called after the sync event is triggered for offers collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionUpdate: function(){

            this._renderOffers();
        },

        /**
         * Method called after the error event is triggered for offers collection
         * @param {Object} collection The collection
         * @param {Object} response The response
         * @return {undefined}
         * @private
         */
        _onOffersCollectionError: function(collection, response){

            this._displayOffersError(response);
        },

        /**
         * Method called after the order's vendors collection is synced
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionSync: function(){
            this._renderOrderVendors();
        },

        /**
         * Method called after the order's vendors collection is reset
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionReset: function(){
            this._renderOrderVendors();
        },

        /**
         * Renders all items from vendors collection into html elements
         * @return {undefined}
         * @private
         */
        _renderOrderVendors: function(){

            var $field = this._getVendorFieldEl();

            var collection = this.orderVendorsCollection;

            this._renderChosenField($field, collection, function(model){

                return model.get('companyName');
            });
        },

        /**
         * Renders the options inside the chosen field based on the supplied collection
         * @param {object} $field The select field
         * @param {Backbone.Collection} collection The collection
         * @param {string|function} name How to select the name of the item
         * @return {undefined}
         * @private
         */
        _renderChosenField: function($field, collection, name){

            if (!collection) {

                $field.trigger('chosen:updated');

                return;
            }

            var $placeholder = $field.find('option:first').detach();

            var options = [$placeholder];

            $field.empty();

            // Add the allocated afterward option
            if ($field.attr('id') === 'orderform-vendor-field') {

                var $allocatedAfterwardOption = this._getAllocatedAfterwardOption();
                // options.push($allocatedAfterwardOption);
            }

            collection.each(function(item){

                var display;

                if (_.isFunction(name)) {
                    display = name.call(this, item);
                } else {
                    display = item.get(name);
                }

                var $option = $('<option>')
                    .attr('value', item.get('id'))
                    .text(display);

                options.push($option);
            });

            $field.append(options);

            $field.trigger('chosen:updated');
        },

        /**
         * Displays the appropriate offer error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayOffersError: function(res){

            this._isLoading(false);

            this._clearError();

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._showError(Translator.trans('js.error_message_orderform_offers_unknown_error'));

                return;
            }

            var errors = res.responseJSON.errors;
            var exception = _.first(errors).class;

            switch (exception) {
                case 'OrderBundle\\Service\\Pricing\\Exception\\OfferUnavailableException':
                case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                    this._showError(Translator.trans('js.error_message_orderform_offers_unavailable'));
                    return;
                default:
                    this._showError(Translator.trans('js.error_message_orderform_offers_unknown_error'));
                    return;
            }
        },

        /**
         * Method called when the "strict" checkbox gets checked/unchecked
         * @return {undefined}
         * @private
         */
        _onStrictChange: function() {

            var isChecked = this.$('#orderscheduleform-strict-field').is(':checked');

            this._clearError();

            this._loadOffers(!isChecked);
        },

        /**
         * Retrieves the list of offers (syncs the offers collection)
         * @param {Function} callback The callback to be called after loading the offers
         * @return {undefined}
         * @private
         */
        _loadOffers: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var id = this._getOrderId();
            var sort = this._getSortFieldEl().val();
            var vendor = this._getVendorFieldEl().val();
            var assocVendor = '';
            var forceSort = '';

            if (!_.isUndefined(vendor) && vendor != '')
            {
                this.offersCollection.setVendorId(vendor);
            }
            else
            {
                if (typeof z.boot.order.worksite != "undefined")
                {
                    assocVendor = this.$('#associated-vendor-input').val();
                }

                if(!_.isUndefined(assocVendor))
                {
                    forceSort = 2;
                    this.offersCollection.setVendorId(assocVendor);
                    this.offersCollection.setSort(forceSort);
                }

            }

            this.offersCollection.setOrderId(id);

            if (forceSort == '')
            {
                this.offersCollection.setSort(sort);
            }

            this.offersCollection.reset();

            this.offersCollection.fetch({

                success: function(){

                    this.trigger('schedule.load.offers');
                    callback.apply(this, arguments);

                }.bind(this),

                error: function(model, res){

                    // todo change this after the branch with offerVendors list will be integrated
                    if (res.responseJSON.errors[0]['class'] === 'OrderBundle\\Exceptions\\VendorUnavailableException') {
                        this._loadVendors();
                    }

                    callback.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Checks if the vendor container field is visible
         * @return {boolean} Is visible
         * @private
         */
        _isVendorFieldVisible: function(){

            var $container = this._getVendorContainerEl();

            return !$container.is('.hidden');
        },

        /**
         * Checks if all required fields for a vendor request are not empty
         * @return {boolean} Is valid
         * @private
         */
        _isValidVendorRequest: function(){

            var quantity = this._getQuantity();

            if (!_.isUndefined(quantity) && !quantity) {
                return false;
            }

            var serviceId = this._getServiceId();

            if (!serviceId) {
                return false;
            }

            var countyId = this._getCountyId();

            if (!countyId) {
                return false;
            }

            return true;
        },

        /**
         * Retrieves the list of vendors (syncs the vendors collection)
         * @param {Function} [callback] The callback
         * @return {undefined}
         * @private
         */
        _loadVendors: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var sort = this._getSortFieldEl().val();
            var serviceId = this._getServiceId();
            var countyId = this._getCountyId();
            var quantity = this._getQuantity();

            this.orderVendorsCollection.reset();

            if (!this._isVendorFieldVisible()) {

                return;
            }

            if (!this._isValidVendorRequest()) {

                return;
            }

            this.orderVendorsCollection.setAvailable(true);
            this.orderVendorsCollection.setServiceId(serviceId);
            this.orderVendorsCollection.setCountyId(countyId);

            if (sort === this.SORT_BY_DISTANCE + '') {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_EXTENDED);
            } else {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_FIXED);
            }

            if (quantity) {
                this.orderVendorsCollection.setQuantity(quantity);
            }

            this.orderVendorsCollection
                .fetch()
                .then(function(){

                    callback.apply(this, arguments);

                })
                .catch(function(){

                    callback.apply(this, arguments);
                });
        },

        /**
         * Retrieves the first error class from the supplied response
         * @param {Object} response The response
         * @return {*|string} The error class
         * @private
         */
        _getFirstErrorClass: function(response){

            if (!response.responseJSON){
                return;
            }

            if (!response.responseJSON.errors) {
                return;
            }

            if (!response.responseJSON.errors[0]) {
                return;
            }

            if (!response.responseJSON.errors[0].class) {
                return;
            }

            return response.responseJSON.errors[0].class;
        },

        /**
         * Method that sets the service model for the selected service
         * @private
         * @return {undefined}
         */
        _loadService: function () {

            var serviceId = this.$('#order-service').data('id');

            if (serviceId) {
                this.service = new z.ServiceModel();
                this.service.set({id: serviceId});

                this.service.fetch();
            }

        },

        /**
         * Sets the total price with the specified value
         * @param {string|int|null} total The total price
         * @param {string|int} transport the transport cost
         * @return {undefined}
         * @private
         */
        _setTotal: function(total, transport) {

            var $field = this._getTotalFieldEl();
            var $display = this._getTotalCostEl();

            var $transportCost = this._getTransportCostEl();
            var $serviceValue = this._getServiceCostEl();

            var showTransport = !z.security.isResellerAssociated() && this.service.get('hasTransportCost') === true && transport !== 0;

            var finalPrice = !total || total < 0 ? 0 : total;

            if (showTransport) {

                finalPrice += transport;

            }

            $field.val(finalPrice);

            $transportCost.text(this._renderPrice(transport));
            $serviceValue.text(this._renderPrice(total));
            $display.text(this._renderPrice(finalPrice));

            this._toggleTransportCostAndServiceValue(showTransport);
        },

        /**
         * Reset the total price
         * @return {undefined}
         * @private
         */
        _resetTotal: function() {
            var $display = this._getTotalCostEl();

            var $transportCost = this._getTransportCostEl();
            var $serviceValue = this._getServiceCostEl();

            $display.text('');
            $transportCost.text('');
            $serviceValue.text('');

            this._toggleTransportCostAndServiceValue(false);
        },

        /**
         * Method that hide/show the transport cost and service value fields and their labels
         * @param {object} value is true for Show and false for hide
         * @private
         * @return {undefined}
         */
        _toggleTransportCostAndServiceValue: function (value) {

            var $wrapper = this.$('.orderschedule-total-service-transport');

            if (value) {
                $wrapper.removeClass('hidden');
            } else {
                $wrapper.addClass('hidden');
            }
        },

        /**
         * Show or hide the invoiceable field
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggleInvoiceableEl: function(override) {

            var $el = this._getInvoiceableContainerEl();
            // var $invoiceableEl = this._getInvoiceableFieldEl();

            // Check it if visible, uncheck if not
            // todo uncomment this line if the invoiceable field should be checked by default
            // $invoiceableEl.prop('checked', override);
            // $invoiceableEl.removeAttr('checked');

            this._toggle($el, true, override);
        },

        /**
         * Enable/disable or show/hide a form field
         * @param {object} $fields The field
         * @param {boolean} display The type of toggle: true for display, false for state
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggle: function($fields, display, override){
            $fields = _.isArray($fields) ? $fields : [$fields];

            _.each($fields, function($field){

                var show = function(){
                    $field.removeClass('hidden');
                };

                var hide = function(){
                    $field.addClass('hidden');
                };

                var enable = function(){
                    $field.removeAttr('disabled');
                };

                var disable = function(){
                    $field.attr('disabled', 'disabled');
                };

                var isToggled = _.isUndefined(override) ? this._isToggled($field, display) : !override;
                var toggleOn = display ? show : enable;
                var toggleOff = display ? hide : disable;

                if (isToggled) {

                    toggleOff();

                } else {

                    toggleOn();
                }

                $field.trigger('chosen:updated');

            }, this);
        },
        _displayError: function(res){

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._showError(Translator.trans('js.order_reschedule_error_message'));

                return;
            }

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var message = _.first(errors).message;

                if (typeof message !== 'undefined') {

                    switch (message) {
                        case "no_tariff_vendor_defined":
                            this._showError(Translator.trans('js.no_tariff_vendor_defined'));
                            return;
                        case "no_tariff_reseller_defined":
                            this._showError(Translator.trans('js.no_tariff_reseller_defined'));
                            return;
                        default:
                            this._showError(Translator.trans('js.order_reschedule_error_message'));
                    }
                }
            }
        },
    });

})(window.z = window.z || {}, Backbone, _);
