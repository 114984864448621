"use strict";

(function(z, Backbone){

    z.OrderDiscount = Backbone.View.extend({

        el: "#order-discount-section",

        events: {
            'click #orderdiscount-client-modal-btn': '_displayDiscountModalForm',
            'click #orderdiscount-cancel-discount-btn': '_cancelDiscount',
            'click .close-modal-link' : '_hideClientModalForm',
            'submit .modal-form#orderdiscount-modal-form': '_onSubmitForm',
        },

        initialize: function(){
            this.$modalElement = this.$('#orderdiscountform-modal');
            this.$errorMsg = this.$el.find('#orderdiscountform-error-panel');
            this.order = z.boot.order;
            Backbone.Validation.bind(this, {
                valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });
            this.setEvents();
        },

        setEvents: function(){

            // On success
            this.listenTo(this.model, 'success', function() {
                var successMessage = Translator.trans('js.order_discount_success_message');

                // Show the success mesasage
                this._showSuccess(successMessage);

                $('a#orderdiscount-client-modal-btn').text('Modifica discount');
                $('a#orderdiscount-cancel-discount-btn').removeClass('hidden');
                // Hide the modal
                this._hideClientModalForm();

                // Stop the loading
                this._isLoading(false, this.$("#orderdiscount-save-button"));

                // Trigger comments section update
                $(document).trigger("comments.updateComments");

            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(model, res) {

                this._displayError(res);

                this._isLoading(false, this.$("#orderdiscount-save-button"));

            }.bind(this));
        },

        _onSubmitForm: function(e){

            e.preventDefault();

            var formData = this._getFormData()
            this.model.set(formData);
            this.model.set('order', this.order.id);
            if (!this.model.isValid(true)) {
                return;
            }

            this._isLoading(true, this.$("#orderdiscount-save-button"));

            this.model.save(null,{

                success: function(i) {
                    this.model.trigger("success", i);
                    // Update z.boot.order.client
                    z.boot.order.discount = this.model.attributes;
                    this.order = z.boot.order;

                }.bind(this)

            });
        },

        _getFormData:function(){

            var $valueField         = this.$('#orderdiscountform-value-field');
            var $typeField         = this.$('#orderdiscountform-type-field');

            // It's a flag to know if we add the default email or not

            return {
                'value'        : $valueField.val().trim(),
                'type'        : $typeField.val().trim(),
            };
        },

        _displayDiscountModalForm: function () {
            this.$modalElement.modal("show");
        },

        _hideClientModalForm:function () {
            this.$modalElement.modal("hide");
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);
            var $submitBtn   = this.$(buttonSelector);
            var $formFields  = this.$('fieldset');
            var ladda =  $submitBtn.data('ladda') ?  $submitBtn.data('ladda') :  $submitBtn.ladda().data('ladda');

            if( loading ){
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @returns {undefined}
         * @private
         */
        _showSuccess: function(message) {
            var $success = $('#orderdiscount-success-message');

            $success
                .removeClass('hidden')
                .text(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Displays the appropriate error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayError: function(res) {

            this._removeErrorMsg();

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._setErrorMsg(Translator.trans('js.error_message_discountform_error'));

                return;
            }

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var exception = _.first(errors).class;

                if (typeof exception !== 'undefined') {

                    this._setErrorMsg('Eroare: '+ exception);
                    return;
                }
            }
        },
        /**
         * Method that sets the error messages.
         *
         * @param {string} message The message to be displayed
         * @private
         * @return {undefined}
         */
        _setErrorMsg: function(message){

            this.$errorMsg
                .text(message)
                .removeClass('hidden');
        },

        /**
         * Method that removes the error message.
         *
         * @private
         * @return {undefined}
         */
        _removeErrorMsg: function() {

            this.$errorMsg
                .addClass('hidden');
        },

        _cancelDiscount: function() {

            this._makeCancelRequest(function(response, status) {

                if (status === 'error') {

                    response.responseJSON = JSON.parse(response.responseText);

                    alert(response.toJSON());
                }

                // Stop the loading
                this._isLoading(false, this.$("#orderdiscount-cancel-discount-btn"));
                $(document).trigger("comments.updateComments");
                this.$("#orderdiscount-cancel-discount-btn").addClass('hidden');
                this.$("#orderdiscount-client-modal-btn").text('Adauga discount');
            });
        },

        _makeCancelRequest: function (callback) {
            callback = _.isFunction(callback) ? callback : _.noop;

            this.model.set('order', this.order.id);
            var url = this.model.urlRoot();

            var opts = {
                method      : 'DELETE',
                dataType    : 'html',
                contentType : 'text/plain'
            };

            z.request.make(url, null, function(jqXhr) {

                if (!jqXhr.hasOwnProperty('responseText')) {
                    return;
                }

                var response = JSON.parse(jqXhr.responseText);
                response = response.data;

                callback.apply(this, arguments);

            }.bind(this), opts);

            this._isLoading(true, this.$("#orderdiscount-cancel-discount-btn"));

        }

    });

})(window.z = window.z || {}, Backbone);