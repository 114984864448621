"use strict";

(function(z, Backbone, _){

    z.OrderReallocationHistory = Backbone.View.extend({

        el:"#reschedule-order-form",

        events: {
            'click #reallocate-order-history-modal-btn': '_displayReallocateHistoryModal',
            'click #order-reallocation-history-form-modal .close-link' : '_hideReallocateHistoryModal'
        },

        /**
         * Constructor.
         * @return {undefined}
         */
        initialize: function(){
            this.$modalElement = this.$('#order-reallocation-history-form-modal');
            this.reallocateCollection = new z.OrderReallocatesCollection();
            this.listenTo(z.orderReallocationForm, 'orderReallocationSuccess', this._displayReallocationHistoryButton);
        },

        /**
         * Method that displays the reallocation history button when
         * a reallocation is being done
         * @private
         * @return {undefined}
         */
        _displayReallocationHistoryButton: function()
        {
            var $reallocateHistoryButton = this._getReallocateHistoryButton();

            if (this._isReallocated() < 1) {
                this.$("#reallocate-history-button-container").append($reallocateHistoryButton);
            }
        },

        /**
         * Method that returns if the order is reallocate or not
         * @private
         * @return {undefined}
         */
        _isReallocated: function()
        {
            return this.$("#reallocate-order-history-modal-btn").length;
        },

    /**
         * Method that constructs the html for the reallocate
         * history button
         * @returns {string} The html for the reallocate history button
         * @private
         */
        _getReallocateHistoryButton: function()
        {
            var buttonName = Translator.trans("js.reallocation_history_button");

            return "<div class='col-lg-2'><a id='reallocate-order-history-modal-btn' class='btn btn-w-m btn-warning align-btn' data-style='slide-left'>" +
            buttonName + "</a></div>";
        },

        /**
         * Method that displays the modal element
         * @private
         * @return {undefined}
         */
        _displayReallocateHistoryModal: function()
        {
            var id = this._getOrderId();

            this.reallocateCollection.reset();
            this._removeTableElements();
            this.reallocateCollection.setOrderId(id);

            this.$modalElement.modal("show");

            // Fetching collection data
            this.reallocateCollection.fetch({
                success: function(response){
                    this._renderReallocationRows(response);
                }.bind(this),
                error: function(){
                    var message = Translator.trans("js.error_fetching_reallocate_history_collection");
                    this._showError(message);
                }.bind(this)});
        },

        /**
         * Method that hides the modal
         * @private
         * @return {undefined}
         */
        _hideReallocateHistoryModal: function()
        {
            this.$modalElement.modal("hide");
        },

        /**
         * Method that returns the order's id
         * @private
         * @return {*} The order's id
         */
        _getOrderId: function()
        {
            return $('#reschedule-order-form').data('id');
        },

        /**
         * Display the error message
         *
         * @param {string} message The message to be shown
         * @private
         * @return {undefined}
         */
        _showError: function(message)
        {
            // this._isLoading(false);

            var $error = this._getInformationPanel();
            $error
                .removeClass('hidden alert-success')
                .addClass('alert-danger')
                .text(message)
                .fadeTo('slow', 1)
                .delay(5000)
                .fadeTo('slow', 0, function(){
                    $error.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that returns the information panel element
         * for the modal
         * @private
         * @return {undefined}
         */
        _getInformationPanel: function()
        {
            return this.$("#order-reallocation-history-general-error-panel");
        },

        /**
         * Method used to render reallocation rows
         * @param {*} response The collection response
         * @private
         * @returns {undefined}
         */
        _renderReallocationRows: function(response)
        {
            response.each(function(data){
                this._renderReallocationRow(data);
            }.bind(this));
        },

        /**
         * Method that returns the table element
         * @private
         * @returns {string} Table element
         */
        _getTableName: function()
        {
            return this.$('#order-reallocation-history');
        },

        /**
         * Method that removes all the table rows from the
         * table body
         * @private
         * @returns {undefined}
         */
        _removeTableElements: function()
        {
            this.$('#order-reallocation-history tbody tr').remove();
        },

        /**
         * Method used to render the rows in the
         * reallocation history modal
         * @param {*} model Collection model that holds data
         * @private
         * @returns {undefined}
         */
        _renderReallocationRow: function(model)
        {
            var $tableName = this._getTableName();
            $tableName.removeClass('hidden');

            // Declaring elements used to display the table values
            var reasonText = this._getReallocationTypeText(model.get("type"));

            var $row = $('<tr>');

            // Initial date
            $('<td>')
                .text(moment(model.get("initialDate")).format('DD-MM-YYYY'))
                .appendTo($row);

            // reallocation date
            $('<td>')
                .text(moment(model.get("reallocationDate")).format('DD-MM-YYYY'))
                .appendTo($row);
            // User
            $('<td>')
                .text(model.get("user"))
                .appendTo($row);
            if(model.get('canViewVendorInfo')){
                // Old vendor
                $('<td>')
                    .text(model.get("oldVendor"))
                    .appendTo($row);
                // New vendor
                $('<td>')
                    .text(model.get("newVendor"))
                    .appendTo($row);
            }

            // Reason comment
            $('<td>')
                .text(reasonText)
                .appendTo($row);

            $tableName.find("tbody").append($row);
        },

        /**
         * Method used to generate a javascript label for each type of
         * reallocation
         * @param {*} type The type of the reallocation
         * @private
         * @returns {string} The translation string used for each reallocation type
         */
        _getReallocationTypeText: function(type)
        {
            var message = '';

            switch (type) {
                case 1:
                    message = Translator.trans("js.label_reallocation_history_partner_no_capacity");
                    break;
                case 2:
                    message = Translator.trans("js.label_reallocation_history_partner_close_colaboration");
                    break;
            }

            return message;
        }
    });

})(window.z = window.z || {}, Backbone, _);
