"use strict";

(function(z){

    z.UserProfileForm = z.FormView.extend({
        el:"#userform-profile-page",

        events: {
            "click #userform-profile-save-button": "_onSubmitForm"
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){
            // Initializing Backbone Validation Library
            this._bindValidation();
        },

        /**
         * Method that binds validation to the chosen model
         *
         * @param {object} model The model that the validation is being performend on
         * @private
         * @return {undefined}
         */
        _bindValidation: function(model){
            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#userprofile-error-message';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonId: function() {
            return '#userform-profile-save-button';
        },

        /**
         * Method that returns the form data
         *
         * @return {{passwordPlain: {first, second}, firstName, lastName, language}} - Fields values
         * @private
         */
        _getUserData: function() {
            var $passwordField = this.$('#userform-profile-password-field');
            var $passwordConfirmation = this.$('#userform-profile-confirmPassword-field');
            var $firstName = this.$('#userform-profile-firstName-field');
            var $lastName = this.$('#userform-profile-lastName-field');
            var $language = this.$('#userform-profile-language-field');

            return {
                'passwordPlain': {
                    'first': $passwordField.val().trim(),
                    'second': $passwordConfirmation.val().trim()
                },
                'firstName': $firstName.val(),
                'lastName': $lastName.val(),
                'language': $language.val()
            };
        },

        /**
         * Method that shows loader on submit button
         *
         * @param {bool} loading If it is loading
         * @param {string} buttonSelector Selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $submitBtn   = this.$(buttonSelector);
            var $formFields  = this.$('fieldset');
            var ladda =  $submitBtn.data('ladda') ?  $submitBtn.data('ladda') :  $submitBtn.ladda().data('ladda');

            if( loading ){
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @private
         * @return {undefined}
         */
        _dataValidation: function (attr) {
            var $fields = this.$('[data-validation]');
            if (attr) {
                $fields = this.$('[data-validation~="' + attr + '"]');
            }
            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that returns the name of the information panel to display form messages
         *
         * @private
         * @return {undefined}
         */
        _getInformationPanel: function() {
            return this.$('#userform-error-panel');
        },

        /**
         * Method that is called if the form is valid
         *
         * @param {Object} view The view being passed
         * @param {Object} attr Attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function (view, attr) {
            this._dataValidation(attr);
        },

        /**
         * Method that shows a success message
         *
         * @private
         * @return {undefined}
         */
        _showSuccessMessage: function() {
            var successMessage = Translator.trans("js.my_profile_save_success_message");

            this._getInformationPanel()
                .removeClass("alert-danger hidden")
                .addClass("alert-success")
                .text(successMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this._getInformationPanel().addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that shows a error message
         *
         * @private
         * @return {undefined}
         */
        _showErrorMessage: function() {
            var successMessage = Translator.trans("js.my_profile_save_error_message");

            this._getInformationPanel()
                .removeClass("hidden")
                .text(successMessage)
                .fadeTo('slow', 1)
                .delay(3000)
                .fadeTo('slow', 0, function(){
                    this._getInformationPanel().addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that submits form
         *
         * @param {object} e Element
         * @private
         * @return {undefined}
         */
        _onSubmitForm: function(e) {
            e.preventDefault();

            // Reset the model because it has data we don't want to update (password, vendor, reseller, locality, county)
            this.model = new z.UserProfileModel();

            var formData = this._getUserData();
            this.model.set(formData);

            // Set the id of the current user to always make a PUT request
            var currentUser = z.security.getUser();
            this.model.set('id', currentUser.id);

            var isValid = this.model.isValid(true);

            if( !isValid ){
                return;
            }

            this._isLoading(true, this.getSubmitButtonId());

            this.model.save(null, {
                success: function (i) {
                    this.model.trigger("success", i);
                    this._isLoading(false, this.getSubmitButtonId());
                    this._showSuccessMessage();

                    // Update the logged user info from frontend
                    z.security.setUser(this.model.toJSON());

                }.bind(this),
                error: function() {
                    this._isLoading(false, this.getSubmitButtonId());
                    this._showErrorMessage();
                }.bind(this)
            });
        }
    });

})(window.z, Backbone);
