"use strict";

(function(z, Backbone, _){

    z.OrderInvoice = Backbone.View.extend({

        el:"#order-invoice-section",

        events: {
            'click #orderinvoice-send-payment-link-btn': '_onSendingPaymentLink',
            'click #orderinvoice-send-proforma-btn': '_onSendingProforma',
            'click #orderinvoice-generate-invoice-btn': '_onGeneratingInvoice',
            'click #orderinvoice-preview-invoice-btn': '_onPreviewingInvoice',
        },

        /**
         * Url from where we can download the invoice
         */
        INVOICE_DOWNLOAD_URL: '/api/orders/{hash}/invoice',

        /**
         * Url from where we can download the attachment
         */
        ATTACHMENT_DOWNLOAD_URL: '/attachments/{attachment}/download',

        /**
         * Attachment invoice type
         */
        TYPE_INVOICE: 2,

        /**
         * Date format
         */
        DATE_FORMAT: 'DD-MM-YYYY',

        /**
         * Url for the sending payment link action
         */
        SEND_PAYMENT_LINK_URL: '/orders/{hash}/payment-link',

        /**
         * Url for the sending proforma action
         */
        SEND_PROFORMA_URL: '/orders/{hash}/proforma',

        /**
         * Url for the generating invoice action
         */
        GENERATE_INVOICE_URL: '/api/orders/{order}/invoice',

        /**
         * Route for the inline editing
         */
        API_EDIT_ROUTE: '/api/edit/orders/',

        /**
         * Payment types consts
         */
        PAYMENT_TYPE_ONLINE: 3,
        PAYMENT_TYPE_OP: 4,

        /**
         * Payment statuses consts
         */
        STATUS_PAYMENT_WAITING: 1,
        STATUS_PAYMENT_SUCCESS: 2,

        /**
         * Order statuses consts
         */
        STATUS_APPOINTED: 3,

        /**
         * Constructor.
         * @returns {undefined}
         */
        initialize: function() {

            $.fn.editable.defaults.mode = 'inline';
            $.fn.editable.defaults.ajaxOptions = {type: "PUT"};

            this.$errorMsg = $('#orderinvoice-error-message');

            // Initialize editable fields
            this._initEditableFields();

            // Set the events
            this.setEvents();
        },

        /**
         * Setting events when the modal is being loaded
         * @returns {undefined}
         */
        setEvents: function() {

            // On client success, make the payment type editable
            $(document).on("client.success", function() {

                this._editablePaymentTypeField();

            }.bind(this));

            // On invoice disable, disable the invoice section
            $(document).on("invoice.disable", function() {

                this._hideInvoiceSection();

            }.bind(this));

            this.listenTo(z.orderScheduleForm, 'enableOrderInvoice', this._showInvoiceSection);
        },

        /**
         * Get payment link element
         * @returns {object} the payment link label el
         * @private
         */
        _getPaymentLinkEl: function() {
            return this.$('#orderinvoice_payment_link_sent');
        },

        /**
         * Get payment provider reference element
         * @returns {object} the payment provider reference el
         * @private
         */
        _getPaymentProviderReferenceEl: function() {
            return this.$('#orderinvoice-payment-link-sent');
        },

        /**
         * Get proforma element
         * @returns {object} the proforma label el
         * @private
         */
        _getProformaEl: function() {
            return this.$('#orderinvoice-proforma-sent');
        },

        /**
         * Get payment type el
         * @returns {object} the payment type field el
         * @private
         */
        _getPaymentTypeEl: function() {
            return this.$('#orderinvoice-payment-type-field');
        },

        /**
         * Get payment status el
         * @returns {object} the payment status field el
         * @private
         */
        _getPaymentStatusEl: function() {
            return this.$('#orderinvoice-payment-status-field');
        },

        /**
         * Get invoice section el
         * @returns {object} the invoice section el
         * @private
         */
        _getInvoiceSectionEl: function() {
            return $('#order-invoice-section');
        },

        /**
         * Get payment online section el
         * @returns {object} the payment online section el
         * @private
         */
        _getPaymentOnlineSectionEl: function() {
            return $('#orderinvoice-payment-online-section');
        },

        /**
         * Get payment link section el
         * @returns {object} the payment op section el
         * @private
         */
        _getPaymentOpSectionEl: function() {
            return $('#orderinvoice-payment-op-section');
        },

        /**
         * Get payment link section el
         * @returns {object} the invoice generation section el
         * @private
         */
        _getInvoiceGenerationSectionEl: function() {
            return $('#orderinvoice-invoice-generation-section');
        },

        /**
         * Get send payment link btn element
         * @returns {object} the send payment link btn el
         * @private
         */
        _getSendPaymentLinkBtnEl: function() {
            return $('#orderinvoice-send-payment-link-btn');
        },

        /**
         * Get send proforma btn element
         * @returns {object} the send proforma btn el
         * @private
         */
        _getSendProformaBtnEl: function() {
            return $('#orderinvoice-send-proforma-btn');
        },

        /**
         * Get generate invoice btn element
         * @returns {object} the send proforma btn el
         * @private
         */
        _getGenerateInvoiceBtnEl: function() {
            return $('#orderinvoice-generate-invoice-btn');
        },

        /**
         * Get preview invoice btn element
         * @returns {object} the send proforma btn el
         * @private
         */
        _getPreviewInvoiceBtnEl: function() {
            return $('#orderinvoice-preview-invoice-btn');
        },

        /**
         * Get client btn element
         * @returns {object} the send proforma btn el
         * @private
         */
        _getClientBtnEl: function() {
            return $('#orderinvoice-client-modal-btn');
        },

        /**
         * Init editable fields
         * @returns {undefined}
         * @private
         */
        _initEditableFields: function() {

            this._initPaymentTypeEditableField();
            this._initPaymentStatusEditableField();
        },

        /**
         * Show the invoice section
         * @returns {undefined}
         * @private
         */
        _showInvoiceSection: function() {

            var $invoiceSectionEl = this._getInvoiceSectionEl();
            var $clientBtnEl = this._getClientBtnEl();

            // Display the invoice section
            $invoiceSectionEl
                .removeClass('hidden')
                .fadeTo('slow', 1);

            // Display the client btn
            $clientBtnEl.removeClass('hidden');
        },

        /**
         * Hide the invoice section
         * @returns {undefined}
         * @private
         */
        _hideInvoiceSection: function() {

            var $invoiceSectionEl = this._getInvoiceSectionEl();
            var $clientBtnEl = this._getClientBtnEl();

            // Display the invoice section
            $invoiceSectionEl
                .addClass('hidden')
                .fadeTo('slow', 1);

            // Display the client btn
            $clientBtnEl.addClass('hidden');
        },

        /**
         * Init payment type field
         * @private
         * @returns {undefined}
         */
        _initPaymentTypeEditableField: function() {

            var _this = this;
            var $paymentTypeEl = this._getPaymentTypeEl();

            // Init the chosen select
            $paymentTypeEl.on('shown', function() {

                _this.$(".chosen-select").chosen({
                    search_contains: true,
                    width: "100%",
                    placeholder_text: Translator.trans('js.placeholder_choose_payment_type')
                });
            });

            // Init the editable field
            $paymentTypeEl.editable({
                source: z.boot.paymentTypes,
                url: this.API_EDIT_ROUTE + this._getOrderId(),
                send: 'always',
                inputclass: 'chosen-select',
                disabled: !this._canEditPaymentType(),
                validate: function(value) {

                    // Validate to not be empty
                    if (!value || !value.trim()) {
                        return Translator.trans('js.error_message_order_editable_payment_type');
                    }
                },
                success: function(response) {

                    var successMessage = Translator.trans('js.order_payment_type_success_message');

                    if (!response.hasOwnProperty('data') && !response.data.hasOwnProperty('paymentType')) {
                        return;
                    }

                    // Update the order paymentType to use it later
                    z.boot.order.paymentType = response.data.paymentType;

                    // Showing success message
                    _this._showSuccess(successMessage);

                    // Update payment status field
                    _this._editablePaymentStatusField();

                    // Update payment type field
                    _this._editablePaymentTypeField();

                    // Display the correct payment section based on the payment type
                    _this._displayPaymentSection();

                    // Update the comments section
                    $(document).trigger("comments.updateComments");
                },
                error: function(res) {

                    return _this._displayInlineEditableError(res);
                }
            });
        },

        /**
         * Displays the appropriate error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayInlineEditableError: function(res) {

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                return Translator.trans('js.error_message_order_editable_payment_type');
            }

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var exception = _.first(errors).class;
                
                if (typeof exception !== 'undefined') {

                    switch (exception) {

                        case 'OrderBundle\\Exceptions\\InvalidPaymentType':
                            return Translator.trans('js.error_message_order_default_email');

                        default:
                            return Translator.trans('js.error_message_order_editable_payment_type');
                    }
                }
            }
        },

        /**
         * Make the payment status element ediable or not
         * @returns {undefined}
         * @private
         */
        _editablePaymentStatusField: function() {

            var $paymentStatusEl = this._getPaymentStatusEl();

            if (this._canEditPaymentStatus()) {

                $paymentStatusEl.editable('enable');

            } else {

                $paymentStatusEl.editable('disable');
            }
        },

        /**
         * Make the payment type element ediable or not
         * @returns {undefined}
         * @private
         */
        _editablePaymentTypeField: function() {

            var $paymentTypeEl = this._getPaymentTypeEl();

            if (this._canEditPaymentType()) {

                $paymentTypeEl.editable('enable');

            } else {

                $paymentTypeEl.editable('disable');
            }
        },

        /**
         * Display payment section
         * @returns {undefined}
         * @private
         */
        _displayPaymentSection: function() {

            var order = z.boot.order;
            var $paymentOnlineSectionEl = this._getPaymentOnlineSectionEl();
            var $sendPaymentLinkBtnEl = this._getSendPaymentLinkBtnEl();
            var $paymentOpSectionEl = this._getPaymentOpSectionEl();
            var $sendProformaBtnEl = this._getSendProformaBtnEl();

            if (_.isUndefined(order.paymentType)) {
                return;
            }

            switch (order.paymentType) {

                case this.PAYMENT_TYPE_ONLINE:

                    // Update payment link info, since the payment link was sent automatically
                    this._updatePaymentLinkInfo();

                    this._toggle($paymentOnlineSectionEl, true, true);
                    this._toggle($sendPaymentLinkBtnEl, true, true);

                    this._toggle($paymentOpSectionEl, true, false);
                    this._toggle($sendProformaBtnEl, true, false);

                    break;

                case this.PAYMENT_TYPE_OP:

                    // Update proforma info, since the proforma was sent automatically
                    this._updateProformaInfo();

                    this._toggle($paymentOpSectionEl, true, true);
                    this._toggle($sendProformaBtnEl, true, true);

                    this._toggle($paymentOnlineSectionEl, true, false);
                    this._toggle($sendPaymentLinkBtnEl, true, false);

                    break;

                default:

                    this._toggle($paymentOnlineSectionEl, true, false);
                    this._toggle($paymentOpSectionEl, true, false);
            }
        },

        /**
         * Init payment status field
         * @private
         * @returns {undefined}
         */
        _initPaymentStatusEditableField: function() {

            var _this = this;
            var $paymentStatusEl = this._getPaymentStatusEl();

            // Init the chosen select
            $paymentStatusEl.on('shown', function() {

                _this.$(".chosen-select").chosen({
                    search_contains: true,
                    width: "100%"
                });
            });

            // Init the editable field
            $paymentStatusEl.editable({
                source: z.boot.paymentStatuses,
                url: this.API_EDIT_ROUTE + this._getOrderId(),
                send: 'always',
                inputclass: 'chosen-select',
                disabled: !this._canEditPaymentStatus(),
                validate: function(value) {

                    // Validate to not be empty
                    if (!value || !value.trim()) {
                        return Translator.trans('js.error_message_order_editable_payment_status');
                    }
                },
                success: function(response) {

                    var successMessage = Translator.trans('js.order_payment_status_success_message');

                    if (!response.hasOwnProperty('data') && !response.data.hasOwnProperty('paymentStatus')) {
                        return;
                    }

                    // Update the order
                    z.boot.order = response.data;

                    // Showing success message
                    _this._showSuccess(successMessage);

                    // Update payment status field
                    _this._editablePaymentStatusField();

                    // Update payment status
                    _this._updatePaymentStatusInfo();

                    // Update payment type field
                    _this._editablePaymentTypeField();

                    // Handle the paid order
                    _this._handlePaidOrderSuccess(response);

                    // Update the comments section
                    $(document).trigger("comments.updateComments");
                },
                error: function(response) {

                    _this._displayError(response);
                }
            });
        },

        /**
         * Handle paid order changes
         * @param {Object} response - the api response
         * @return {undefined}
         * @private
         */
        _handlePaidOrderSuccess: function(response) {

            var $sendPaymentLinkBtnEl = this._getSendPaymentLinkBtnEl();
            var $sendProformaBtnEl = this._getSendProformaBtnEl();
            var $invoiceGenerationSectionEl = this._getInvoiceGenerationSectionEl();
            var $generateInvoiceBtnEl = this._getGenerateInvoiceBtnEl();
            var $previewInvoiceBtnEl = this._getPreviewInvoiceBtnEl();

            var order = z.boot.order;

            // When the payment status is changed into successful, the order will automatically become appointed
            if (order.paymentStatus === this.STATUS_PAYMENT_SUCCESS &&
                order.status === this.STATUS_APPOINTED
            ) {
                $(document).trigger("order.status.appointed");

                this._toggle($sendPaymentLinkBtnEl, true, false);
                this._toggle($sendProformaBtnEl, true, false);

                this._toggle($invoiceGenerationSectionEl, true, true);

                // Update the reschedule section
                $(document).trigger("order.appointed");

                // If the invoice wasn't generated
                if (_.isUndefined(order.invoiceNumber)) {

                    // Hide the generate invoice button
                    $generateInvoiceBtnEl.removeClass('hidden');

                    // Display the preview invoice button
                    $previewInvoiceBtnEl.addClass('hidden');

                    // Display the generate invoice error
                    this._displayError(response);

                } else {

                    // Hide the generate invoice button
                    $generateInvoiceBtnEl.addClass('hidden');

                    // Display the preview invoice button
                    $previewInvoiceBtnEl.removeClass('hidden');

                    // Handle generating invoice success
                    this._handleGeneratingInvoiceSuccess();
                }

                // Update the invoice details
                $(document).trigger("order.updateInvoiceDetails");
            }
        },

        /**
         * Verify if the payment status can be edited
         * @returns {bool} payment status can be edited or not
         * @private
         */
        _canEditPaymentStatus: function() {

            var order = z.boot.order;

            return !(_.isUndefined(order.paymentType) || order.paymentType === this.PAYMENT_TYPE_ONLINE || order.paymentStatus !== this.STATUS_PAYMENT_WAITING);
        },

        /**
         * Verify if the payment type can be edited
         * @returns {bool} payment type can be edited or not
         * @private
         */
        _canEditPaymentType: function() {

            var order = z.boot.order;

            return !(_.isUndefined(order.paymentStatus) || _.isUndefined(order.customer.client) || order.paymentStatus !== this.STATUS_PAYMENT_WAITING);
        },

        /**
         * On sending payment link event
         * @returns {undefined}
         * @private
         */
        _onSendingPaymentLink: function() {

            // Send the payment link
            this._sendPaymentLink( function() {

                this._updatePaymentLinkInfo();

                this._isLoadingSendPaymentLinkBtn(false);

                // Update the comment section
                $(document).trigger("comments.updateComments");
            });
        },

        /**
         * Send payment link action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _sendPaymentLink: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getSendingPaymentLinkUrl();

            this._makeRequest(url, callback);

            this._isLoadingSendPaymentLinkBtn(true);
        },

        /**
         * Update payment link info
         * @returns {undefined}
         * @private
         */
        _updatePaymentLinkInfo: function() {

            var $paymentLinkEl = this._getPaymentLinkEl();
            var $paymentProviderReference = this._getPaymentProviderReferenceEl();

            $paymentLinkEl.text(Translator.trans('js.label_true'));
            $paymentProviderReference.text(this.model.get('hash'));
        },

        /**
         * Update payment status info
         * @private
         * @return {undefined}
         */
        _updatePaymentStatusInfo: function() {

            var $paymentStatusEl = this._getPaymentStatusEl();

            $paymentStatusEl.text(Translator.trans('js.order_payment_status_success'));
        },

        /**
         * On sending proforma event
         * @returns {undefined}
         * @private
         */
        _onSendingProforma: function() {

            // Send the payment link
            this._sendProforma( function() {

                this._updateProformaInfo();

                this._isLoadingSendProformaBtn(false);

                // Update the comment section
                $(document).trigger("comments.updateComments");
            });
        },

        /**
         * On generating the invoice
         * @returns {undefined}
         * @private
         */
        _onGeneratingInvoice: function() {

            // Send the payment link
            this._generateInvoice( function(response, status) {

                // Get the response
                var res = JSON.parse(response.responseText);
                var order = res.data;

                // If there were errors when generating the invoice
                if (status === 'error') {

                    this._displayError(response);

                    // Stop the loading for the generate invoice button
                    this._isLoadingGenerateInvoiceBtn(false);

                    return;
                }

                // Update z.boot.order
                z.boot.order.invoiceNumber = order.invoiceNumber;
                z.boot.order.invoiceDate = moment(order.invoiceDate).format(this.DATE_FORMAT);

                // Update invoice info
                this._updateInvoiceInfo();

                // Handle generating invoice success
                this._handleGeneratingInvoiceSuccess();

                // Update the comment section
                $(document).trigger("comments.updateComments");

                // Stop the loading for the generate invoice button
                this._isLoadingGenerateInvoiceBtn(false);

            });
        },

        /**
         * Handle generating invoice success
         * @private
         * @return {undefined}
         */
        _handleGeneratingInvoiceSuccess: function() {

            var $generateInvoiceBtnEl = this._getGenerateInvoiceBtnEl();
            var $previewInvoiceBtnEl = this._getPreviewInvoiceBtnEl();
            var $clientBtnEl = this._getClientBtnEl();

            // Update invoice info
            this._updateInvoiceInfo();

            // Hide the manage client button
            $clientBtnEl.addClass('hidden');

            // Hide the generate invoice button
            $generateInvoiceBtnEl.addClass('hidden');

            // Display the preview invoice button
            $previewInvoiceBtnEl.removeClass('hidden');

            // Update the comment section
            $(document).trigger("comments.updateComments");

            // Stop the loading for the generate invoice button
            this._isLoadingGenerateInvoiceBtn(false);
        },


        /**
         * Handle on previewing invoice
         * @param {Event} e the event
         * @private
         * @return {undefined}
         */
        _onPreviewingInvoice: function(e) {

            e.preventDefault();

            // Download the invoice
            this._downloadInvoice( function(response, status) {

                // If there were errors when downloading the invoice
                if (status === 'error') {

                    this._displayError(response);

                    // Stop the loading for the preview invoice button
                    this._isLoadingPreviewInvoiceBtn(false);

                    return;
                }

                var res = JSON.parse(response.responseText);
                var attachment = res.data;

                if (attachment && attachment.hasOwnProperty('id')) {

                    window.location = this._getAttachmentDownloadUrl(attachment);
                } else {

                    this._setErrorMsg(Translator.trans('js.error_message_orderinvoice_not_generated_error'));
                    return;
                }

                // Stop the loading for the preview invoice button
                this._isLoadingPreviewInvoiceBtn(false);

            });
        },

        /**
         * Generate invoice action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _downloadInvoice: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getInvoiceDownloadUrl();

            this._makeRequest(url, callback, 'GET');

            this._isLoadingPreviewInvoiceBtn(true);
        },

        /**
         * Returns the url from where you can download the invoice
         * @return {string} The url
         * @private
         */
        _getInvoiceDownloadUrl: function() {

            var order = z.boot.order;

            return this.INVOICE_DOWNLOAD_URL.replace('{hash}', order.hash);
        },

        /**
         * Returns the url from where you can download the invoice attachment
         * @param {Object} attachment the invoice attachment
         * @return {string} the url
         * @private
         */
        _getAttachmentDownloadUrl: function(attachment) {

            return this.ATTACHMENT_DOWNLOAD_URL.replace('{attachment}', attachment.id);
        },

        /**
         * Method displays the error.
         *
         * @param {Object} response The response
         * @private
         * @return {undefined}
         */
        _displayError: function(response) {

            var res;

            if (!_.isUndefined(response.errors)) {

                res = response;

            } else {

                res = JSON.parse(response.responseText);
            }

            if (!res || !res.errors || res.errors.length === 0) {

                this._setErrorMsg(Translator.trans('js.error_message_orderinvoice_generation_error'));

                return;
            }

            var errors = res.errors;

            if (errors.length === 1) {

                var exception = _.first(errors).class;

                if (_.isUndefined(exception)) {

                    exception = _.first(errors);
                }

                var message = _.first(errors).message;

                if (!_.isUndefined(exception)) {

                    switch (exception) {

                        case 'EmagBundle\\Service\\Sap\\Exceptions\\Result':
                        case 'SAPNWRFC\\ConnectionException':

                            // Display the error message from SAP
                            this._setErrorMsg('[SAP ERROR] ' + message);
                            return;

                        case 'EmagBundle\\Service\\Sap\\Exceptions\\Invalid':

                            // Display the error message from SAP
                            this._setErrorMsg(Translator.trans('[ERROR] ' + message));
                            return;

                        case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                            this._setErrorMsg(Translator.trans('js.error_message_orderinvoice_not_generated_error'));
                            return;

                        case 'OrderBundle\\Exceptions\\DiscountNotApprovedException':
                            this._setErrorMsg(Translator.trans('js.error_message_discount_not_approved')) ;
                            return;

                        default:
                            this._setErrorMsg(Translator.trans('js.error_message_orderinvoice_generation_error'));
                            return;
                    }
                }
            }
        },

        /**
         * Method that sets the error messages.
         *
         * @param {string} message The message to be displayed
         * @private
         * @return {undefined}
         */
        _setErrorMsg: function(message) {

            this.$errorMsg
                .removeClass('hidden')
                .text(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    this.$errorMsg.addClass('hidden');
                }.bind(this));
        },

        /**
         * Update invoice info
         * @returns {undefined}
         * @private
         */
        _updateInvoiceInfo: function() {

            var $invoiceNumberField = $('#order-invoice-number-field');
            var $invoiceDateField = $('#order-invoice-date-field');

            // Update the invoice number field and make it non-editable
            $invoiceNumberField.text(z.boot.order.invoiceNumber);
            $invoiceNumberField.editable('disable');

            // Update the invoice date field and make it non-editable
            $invoiceDateField.text(z.boot.order.invoiceDate);
            $invoiceDateField.editable('disable');
        },

        /**
         * Generate invoice action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _generateInvoice: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getGeneratingInvoiceUrl();

            this._makeRequest(url, callback);

            this._isLoadingGenerateInvoiceBtn(true);
        },

        /**
         * Send payment link action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _sendProforma: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getSendingProformaUrl();

            this._makeRequest(url, callback);

            this._isLoadingSendProformaBtn(true);
        },

        /**
         * Update payment link info
         * @returns {undefined}
         * @private
         */
        _updateProformaInfo: function() {

            var $proformaEl = this._getProformaEl();

            $proformaEl.text(Translator.trans('js.label_true'));
        },

        /**
         * Make ajax requests
         * @param {string} url request URL
         * @param {Function} callback callback funcyopm
         * @param {string} method request method
         * @returns {undefined}
         * @private
         */
        _makeRequest: function(url, callback, method) {

            callback = _.isFunction(callback) ? callback : _.noop;
            method = _.isUndefined(method) ? 'POST' : method;

            var opts = {
                method      : method,
                dataType    : 'html',
                contentType : 'text/plain'
            };

            z.request.make(url, null, function() {

                callback.apply(this, arguments);

            }.bind(this), opts);
        },

        /**
         * Get sending payment link action URL
         * @returns {string} send payment link action URL
         * @private
         */
        _getSendingPaymentLinkUrl: function() {

            return this.SEND_PAYMENT_LINK_URL.replace('{hash}', this.model.get('hash'));
        },

        /**
         * Get sending proforma action URL
         * @returns {string} send proforma action URL
         * @private
         */
        _getSendingProformaUrl: function() {

            return this.SEND_PROFORMA_URL.replace('{hash}', this.model.get('hash'));
        },

        /**
         * Get generating invoice action URL
         * @returns {string} send proforma action URL
         * @private
         */
        _getGeneratingInvoiceUrl: function() {

            return this.GENERATE_INVOICE_URL.replace('{order}', this.model.get('id'));
        },

        /**
         * Retrieves the order id if it is available
         * @returns {number|null} The order id
         * @private
         */
        _getOrderId: function(){

            return z.boot.order.id;
        },

        /**
         * Enable/disable or show/hide a form field
         * @param {object} $fields The field
         * @param {bool} display The type of toggle: true for display, false for state
         * @param {bool} override Override the toggle logic and turn it on or off by yourself
         * @returns {undefined}
         * @private
         */
        _toggle: function($fields, display, override) {

            $fields = _.isArray($fields) ? $fields : [$fields];

            _.each($fields, function($field){

                var show = function(){
                    $field.removeClass('hidden');
                };

                var hide = function(){
                    $field.addClass('hidden');
                };

                var enable = function(){
                    $field.removeAttr('disabled');
                };

                var disable = function(){
                    $field.attr('disabled', 'disabled');
                };

                var isToggled = _.isUndefined(override) ? this._isToggled($field, display) : !override;
                var toggleOn = display ? show : enable;
                var toggleOff = display ? hide : disable;

                if (isToggled) {

                    toggleOff();

                } else {

                    toggleOn();
                }

                $field.trigger('chosen:updated');

            }, this);
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @returns {undefined}
         * @private
         */
        _showSuccess: function(message) {

            var $success = this.$('#orderinvoice-success-message');

            $success
                .removeClass('hidden')
                .html(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Enables or disables the loading animation
         * @param {bool} loading Should show or hide the animation
         * @param {object} $btn Button object
         * @return {undefined}
         * @private
         */
        _isLoading: function (loading, $btn) {

            loading = loading ? loading : _.isUndefined(loading);

            var ladda = $btn.data('ladda') ? $btn.data('ladda') : $btn.ladda().data('ladda');

            if (loading) {

                ladda.start();

            } else {

                ladda.stop();
            }
        },

        /**
         * Loading animation for send payment link btn
         * @param {bool} loading Should show or hide the animation
         * @returns {undefined}
         * @private
         */
        _isLoadingSendPaymentLinkBtn: function(loading) {

            var $sendPaymentLinkBtnEl = this._getSendPaymentLinkBtnEl();
            this._isLoading(loading, $sendPaymentLinkBtnEl);
        },

        /**
         * Loading animation for send proforma link btn
         * @param {bool} loading Should show or hide the animation
         * @returns {undefined}
         * @private
         */
        _isLoadingSendProformaBtn: function(loading) {

            var $sendProformaBtnEl = this._getSendProformaBtnEl();
            this._isLoading(loading, $sendProformaBtnEl);
        },

        /**
         * Loading animation for generate invoice btn
         * @param {bool} loading Should show or hide the animation
         * @returns {undefined}
         * @private
         */
        _isLoadingGenerateInvoiceBtn: function(loading) {

            var $generateInvoiceBtnEl = this._getGenerateInvoiceBtnEl();
            this._isLoading(loading, $generateInvoiceBtnEl);
        },

        /**
         * Loading animation for preview invoice btn
         * @param {bool} loading Should show or hide the animation
         * @returns {undefined}
         * @private
         */
        _isLoadingPreviewInvoiceBtn: function(loading) {

            var $previewInvoiceBtnEl = this._getPreviewInvoiceBtnEl();
            this._isLoading(loading, $previewInvoiceBtnEl);
        }

    });

})(window.z = window.z || {}, Backbone, _);
