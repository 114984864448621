"use strict";

(function(z, Backbone){

    var DiscountModel = Backbone.Model.extend({
        urlRoot: function(){
            return '/api/discount/';
        },

        validation: {
            'value': [{
                required: true,
                msg: Translator.trans('js.error_message_discountform_value_required')
            },
                {
                    min: 1,
                    msg: Translator.trans('js.error_message_discountform_value_format')
                },
            ],
            'type': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_type_required')
            }],
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.DiscountModel = DiscountModel;

})(window.z = window.z || {}, Backbone);