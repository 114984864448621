'use strict';

(function(z, Backbone, _) {

    /**
     * Orders form
     */
    z.OrdersForm = z.AttachmentFormView.extend({

        /**
         * Date format
         */
        DATE_FORMAT: 'DD-MM-YYYY',

        /**
         * Url from where the service form should be loaded
         */
        SERVICE_FORM_URL: '/orders/create/service/{service}/form',

        /**
         * The time in seconds after which the offers expire
         */
        OFFER_EXPIRE_TIME: 3 * 60,

        /**
         * The vendor dropdown value for allocating afterward
         */
        ALLOCATED_AFTERWARD_VALUE: 0,

        /**
         * Constants used to fill the sort field
         */
        SORT_BY_AVAILABILITY: 1,
        SORT_BY_DISTANCE: 2,

        /**
         * Bounding element
         */
        el: "#order-create-page",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: {
            'change #orderform-service-field': '_onServiceChange',
            'change #customerform-county-field': '_onCustomerCountyChange',
            'change #customerform-locality-field': '_onCustomerLocalityChange',
            'change #orderform-service-container :input[name*="option_"]': '_onServiceParamsChange',
            'change #orderform-quantity-field': '_onQuantityChange',
            'change #orderform-custody-field': '_onCustodyChange',
            'change #orderform-vendor-field': '_onVendorChange',
            'change #orderform-sort-field': '_onSortChange',
            'change #orderform-showroom-field': '_onShowroomChange',
            'ifChecked #orderform-offers-table input[type="radio"]': '_onOfferCheck',
            'submit #order-create-form': '_onFormSubmit',
            'click #order-page-refresh-offers': '_onOfferRefreshClick'
        },

        /**
         * Constructor
         *
         * @return {undefined}
         */
        initialize: function(){

            this.customerLocalitiesCollection = new z.LocalitiesCollection();
            this.worksiteLocalitiesCollection = new z.LocalitiesCollection();
            this.orderVendorsCollection = new z.VendorsCollection();
            this.offersCollection = new z.OffersCollection();

            this.listenTo(this.customerLocalitiesCollection, 'sync', this._onCustomerLocalitiesCollectionSync);
            this.listenTo(this.customerLocalitiesCollection, 'reset', this._onCustomerLocalitiesCollectionReset);

            this.listenTo(this.orderVendorsCollection, 'sync', this._onOrderVendorsCollectionSync);
            this.listenTo(this.orderVendorsCollection, 'reset', this._onOrderVendorsCollectionReset);

            this.listenTo(this.offersCollection, 'request', this._onOffersCollectionRequest);
            this.listenTo(this.offersCollection, 'sync', this._onOffersCollectionSync);
            this.listenTo(this.offersCollection, 'reset', this._onOffersCollectionReset);
            this.listenTo(this.offersCollection, 'error', this._onOffersCollectionError);

            this._prefillSeviceForm();

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: this.model
            });

            this._bindChosen();

            this._initializeDateFields();

            this._addAllocatedAdfterwardOption();

            z.AttachmentFormView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Initialize date fields
         * @return {undefined}
         * @private
         */
        _initializeDateFields: function() {

            $('#orderform-invoiceDate-field').daterangepicker({
                autoApply: true,
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: true,
                maxDate: moment(),
                locale: {
                    format: this.DATE_FORMAT
                }
            });

            // this is official solution for initial empty field
            this.$('#orderform-invoiceDate-field').on('apply.daterangepicker', function (ev, picker) {
                this.$('#orderform-invoiceDate-field').val(picker.startDate.format(this.DATE_FORMAT));
            }.bind(this));

            this.$('#orderform-invoiceDate-field').on('cancel.daterangepicker', function () {
                $(this).val('');
            });
        },

        /**
         * Method called after the user clicks the refresh button from offers
         * @return {undefined}
         */
        _onOfferRefreshClick: function() {

            // this._loadOffers();
        },

        /**
         * Prefill service form with the first available service
         * @return {undefined}
         * @private
         */
        _prefillSeviceForm: function() {

            this._onServiceChange();
        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr){

            var $form = this._getFormEl();

            $form
                .find('[data-validation="' + attr + '"]')
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error){

            var $form = this._getFormEl();

            $form
                .find('[data-validation="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after the service drop down gets changed
         * @return {undefined}
         * @private
         */
        _onServiceChange: function(){

            this._loadServiceForm(function(){

                // this._loadOffers();

                this._loadVendors();
            });

            if (!z.security.isResellerAssociated()) {

                this._loadService();
            }
        },

        /**
         * Method that sets the service model for the selected service
         * @private
         * @return {undefined}
         */
        _loadService: function () {

            var serviceId = this._getFormRecord().service;

            if (serviceId) {

                this.service = new z.ServiceModel();

                this.service.set({id: serviceId});

                this.service.fetch();
            }
        },

        /**
         * Method called after any of the service dynamic params get changed
         * @return {undefined}
         * @private
         */
        _onServiceParamsChange: function(){

            // this._loadOffers();
        },

        /**
         * Method called after the quantity gets changed
         * @return {undefined}
         * @private
         */
        _onQuantityChange: function(){
// alert('123');
            // this._loadOffers();

            this._loadVendors();
        },

        /**
         * Method called after the custody gets changed
         * @return {undefined}
         * @private
         */
        _onCustodyChange: function(){

            // this._loadOffers();
        },

        /**
         * Method called after the customer county gets changed
         * @return {undefined}
         * @private
         */
        _onCustomerCountyChange: function(){

            this._loadCustomerLocalitiesField(function(){

                // this._loadOffers();

                this._loadVendors();

                this._loadWorksiteLocalitiesField(function() {

                    this._hasAvailableWorksites();
                });
            });
        },

        /**
         * Display a warning message if there are no worksites in the selected county
         * @return {undefined}
         * @private
         */
        _hasAvailableWorksites: function() {

            var $warningMessage = this.$('#customer-warning-message');
            var record = this._getFormRecord();
            var countyId = record.customer.county;

            if (!countyId) {

                return;
            }

            if (this.worksiteLocalitiesCollection.length === 0 && $warningMessage.hasClass('hidden')) {
                $warningMessage.removeClass('hidden');
            } else if(this.worksiteLocalitiesCollection.length !== 0 && !$warningMessage.hasClass('hidden')) {
                $warningMessage.addClass('hidden');
            }
        },

        /**
         * Method called after the customer locality gets changed
         * @return {undefined}
         * @private
         */
        _onCustomerLocalityChange: function(){

            // this._loadOffers();
        },

        /**
         * Method called after the vendor gets changed
         * @return {undefined}
         * @private
         */
        _onVendorChange: function(){

            // this._loadOffers();

            this._displayInvoiceableEl();
        },

        /**
         * Method called after the sort mode gets changed
         * @return {undefined}
         * @private
         */
        _onSortChange: function(){

            // this._loadOffers();

            this._loadVendors();
        },

        /**
         * Method called after the showroom gets changed
         * @return {undefined}
         * @private
         */
        _onShowroomChange: function() {

            // this._loadOffers();

            this._loadVendors();

            this._loadShowroom( function() {

                this._displayInvoiceableEl();
            });
        },

        /**
         * Display the invoiceable element if it's valid
         * @private
         * @return {undefined}
         */
        _displayInvoiceableEl: function() {

            if (this._isValidForInvoice()) {

                this._toggleInvoiceableEl(true);

            } else {

                this._toggleInvoiceableEl(false);
            }
        },

        /**
         * Verify if the order is valid to be invoiceable or not
         * @return {boolean} Is valid to be invoiceable or not
         * @private
         */
        _isValidForInvoice: function() {

            var $vendor = this._getVendorFieldEl();

            // If the reseller can invoice and the command is not allocated afterwards
            if (this._canResellerInvoice() &&  parseInt($vendor.val()) !== this.ALLOCATED_AFTERWARD_VALUE) {

                return true;
            }

            return false;
        },

        /**
         * Verify if the reseller can invoice
         * @return {boolean} can invoice or not
         * @private
         */
        _canResellerInvoice: function() {

            if (_.isUndefined(this.showroom)) {

                return false;
            }

            var reseller = this.showroom.get('reseller');

            if (reseller.hasOwnProperty('canInvoice')) {

                return reseller.canInvoice;
            }

            return false;
        },

        /**
         * Method that sets the showroom model for the selected showroom
         * @param {function} callback the callback function
         * @private
         * @return {undefined}
         */
        _loadShowroom: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var showroomId = this._getFormRecord().showroom;

            if (showroomId) {

                this.showroom = new z.ShowroomModel();

                this.showroom.set({id: showroomId});

                this.showroom.fetch({
                    success: function(){

                        callback.apply(this, arguments);

                    }.bind(this),
                    error: function(){

                        callback.apply(this, arguments);

                    }.bind(this)
                });
            }
        },

        /**
         * Method called after the form is submitted
         * @param {object} event The jQuery event object
         * @return {undefined}
         * @private
         */
        _onFormSubmit: function(event){

            event.preventDefault();

            this._submitForm();
        },

        /**
         * Method called after the customer's localities collection is synced
         * @return {undefined}
         * @private
         */
        _onCustomerLocalitiesCollectionSync: function(){
            this._renderCustomerLocalities();
        },

        /**
         * Method called after the customer's localities collection is reset
         * @return {undefined}
         * @private
         */
        _onCustomerLocalitiesCollectionReset: function(){
            this._renderCustomerLocalities();
        },

        /**
         * Method called after the order's vendors collection is synced
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionSync: function(){
            this._renderOrderVendors();
        },

        /**
         * Method called after the order's vendors collection is reset
         * @return {undefined}
         * @private
         */
        _onOrderVendorsCollectionReset: function(){
            this._renderOrderVendors();
        },

        /**
         * Method called after the offers collection is sending a request
         * @return {undefined}
         * @private
         */
        _onOffersCollectionRequest: function(){

            this._clearOfferTimer();

            this._hideOfferError();

            this._offersIsLoading(true);
        },

        /**
         * Method called after the offers collection is synced
         * @param {object} collection The collection
         * @return {undefined}
         * @private
         */
        _onOffersCollectionSync: function(collection){

            this._offersIsLoading(false);

            // when we save the offer model, backbone also triggers a sync event for the collection
            // in that case, we don't want to rerender the offers
            if (collection instanceof Backbone.Model) {

                return;
            }

            this._renderOffers();
        },

        /**
         * Method called after the offers collection encounters an error
         * @param {object} collection The collection
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _onOffersCollectionError: function(collection, res){

            this._offersIsLoading(false);

            this._displayOffersError(res);
        },

        /**
         * Method called after the offers collection is reset
         * @return {undefined}
         * @private
         */
        _onOffersCollectionReset: function(){
            this._renderOffers();
        },

        /**
         * Method called after offers get checked
         * @param {object} event The event
         * @return {undefined}
         * @private
         */
        _onOfferCheck: function(event){

            var $check = $(event.currentTarget);

            if ($check.data('silentCheck')) {

                $check.removeData('silentCheck');

                return;
            }

            this._reserveSelectedOffer(function(){

                this._setOfferTimer();

                this._attachSelectedOffer();

            }, function(){

                this._selectPreviousOffer();
            });
        },

        /**
         * Returns the jQuery form element
         * @return {object} The form element
         * @private
         */
        _getFormEl: function(){
            return this.$('#order-create-form');
        },

        /**
         * Retrieves the service holder element
         * @returns {object} The service holder
         * @private
         */
        _getServiceContainerEl: function(){
            return this.$('#orderform-service-container');
        },

        /**
         * Retrieves the service dropdown element
         * @returns {object} The service holder
         * @private
         */
        _getServiceFieldEl: function(){
            return this.$('#orderform-service-field');
        },

        /**
         * Retrieves the quantity dropdown element
         * @returns {object} The quantity field
         * @private
         */
        _getQuantityFieldEl: function(){
            return this.$('#orderform-quantity-field');
        },

        /**
         * Retrieves the custody dropdown element
         * @returns {object} The quantity field
         * @private
         */
        _getCustodyFieldEl: function(){
            return this.$('#orderform-custody-field');
        },

        /**
         * Retrieves the file element
         * @returns {object} The file element
         * @private
         */
        _getFileFieldEl: function(){
            return this.$('#orderform-file-field');
        },

        /**
         * Retrieves all service params
         * @returns {object} The service holder
         * @private
         */
        _getServiceParamsEl: function(){
            return this._getServiceContainerEl().find(':input[name*="option_"]');
        },

        /**
         * Retrieves all service details
         * @returns {object} The service holder
         * @private
         */
        _getServiceDetailsEl: function(){
            return this._getServiceContainerEl().find(':input[name*="detail_"]');
        },

        /**
         * Retrieves the customer's county dropdown element
         * @returns {object} The locality field
         * @private
         */
        _getCustomerCountyFieldEl: function(){
            return this.$('#customerform-county-field');
        },

        /**
         * Retrieves the customer's locality dropdown element
         * @returns {object} The locality field
         * @private
         */
        _getCustomerLocalityFieldEl: function(){
            return this.$('#customerform-locality-field');
        },

        /**
         * Retrieves the order's vendor container element
         * @returns {object} The element
         * @private
         */
        _getVendorContainerEl: function(){
            return this._getVendorFieldEl().parent();
        },

        /**
         * Retrieves the offers sort field element
         * @returns {object} The element
         * @private
         */
        _getSortFieldEl: function(){
            return this.$('#orderform-sort-field');
        },

        /**
         * Retrieves the order's vendor field element
         * @returns {object} The element
         * @private
         */
        _getVendorFieldEl: function(){
            return this.$('#orderform-vendor-field');
        },

        /**
         * Retrieves the order's showroom field element
         * @returns {object} The element
         * @private
         */
        _getShowroomFieldEl: function(){
            return this.$('#orderform-showroom-field');
        },

        /**
         * Retrieves the order's showroom holder element
         * @returns {object} The element
         * @private
         */
        _getShowroomHolderEl: function(){
            return this.$('#orderform-showroom-field').parents('.form-group:first');
        },

        /**
         * Retrieves the order's invoiceable field element
         * @returns {object} The element
         * @private
         */
        _getInvoiceableFieldEl: function(){
            return this.$('#orderform-invoiceable-field');
        },

        /**
         * Retrieves the order's invoiceable container element
         * @returns {object} The element
         * @private
         */
        _getInvoiceableContainerEl: function(){
            return this.$('#orderform-invoiceable-container');
        },


        /**
         * Retrieves a list containing all the chosen fields from this page
         * @returns {Array} The list
         * @private
         */
        _getChosenFieldsEl: function(){
            var $customerCountyField = this._getCustomerCountyFieldEl();
            var $customerLocalityField = this._getCustomerLocalityFieldEl();
            var $showroomField = this._getShowroomFieldEl();
            var $vendorField = this._getVendorFieldEl();

            return [
                $customerCountyField,
                $customerLocalityField,
                $showroomField,
                $vendorField
            ];
        },

        /**
         * Retrieves the offers panel element
         * @returns {object} The element
         * @private
         */
        _getOffersPanelEl: function(){
            return this._getOffersTableEl().parents('.ibox:first');
        },

        /**
         * Retrieves the offer panel's content element
         * @returns {object} The element
         * @private
         */
        _getOffersContentEl: function(){
            return this._getOffersPanelEl().find('.ibox-content:first');
        },

        /**
         * Retrieves the offers table element
         * @returns {object} The element
         * @private
         */
        _getOffersTableEl: function(){
            return this.$('#orderform-offers-table');
        },

        /**
         * Retrieves the offers table row elements
         * @returns {object} The element
         * @private
         */
        _getOffersTableRowEls: function(){
            return this._getOffersTableEl().find('tr');
        },

        /**
         * Retrieves the offers table body element
         * @returns {object} The element
         * @private
         */
        _getOffersTableBodyEl: function(){
            return this._getOffersTableEl().find('tbody');
        },

        /**
         * Retrieves the offers empty element
         * @returns {object} The element
         * @private
         */
        _getOffersEmptyEl: function(){
            return this.$('#orderform-offers-empty');
        },

        /**
         * Retrieves the offers invalid element
         * @returns {object} The element
         * @private
         */
        _getOffersInvalidEl: function(){
            return this.$('#orderform-offers-invalid');
        },

        /**
         * Retrieves the checks from offers table
         * @returns {object} The element
         * @private
         */
        _getOffersChecksEl: function(){
            return this._getOffersTableBodyEl().find('input[type="radio"]');
        },

        /**
         * Retrieves the total field element
         * @returns {object} The element
         * @private
         */
        _getTotalFieldEl: function(){
            return this.$('#orderform-total-field');
        },

        /**
         * Retrieves the offer field element
         * @returns {object} The element
         * @private
         */
        _getOfferFieldEl: function(){
            return this.$('#orderform-offer-field');
        },

        /**
         * Retrieves the total display element
         * @returns {object} The element
         * @private
         */
        _getTotalDisplayEl: function(){
            return this.$('#orderform-total-display');
        },

        /**
         * Retrieves the normalized form data
         * @param {boolean} forOffers Return the offer record used for offers
         * @return {object} The form data
         * @private
         */
        _getFormRecord: function(forOffers){

            var offer = this._getOfferRecord();

            var record = {
                service: this._getServiceFieldEl().val(),
                fields: this._getServiceFormRecord(forOffers),
                customer: this._getCustomerFormRecord(),
                showroom: this._getShowroomFieldEl().val(),
                sort: this._getSortFieldEl().val(),
                vendor: this._getVendorFieldEl().val()
            };

            var $invoiceableField = this._getInvoiceableFieldEl();
            record['invoiceable'] = $invoiceableField.length ? $invoiceableField.is(':checked') : false;

            if (!forOffers) {
                
                var invoiceDate = this.$('#orderform-invoiceDate-field').val();

                if (invoiceDate !== 'undefined' && invoiceDate) {
                    invoiceDate = moment(this.$('#orderform-invoiceDate-field').val(), this.DATE_FORMAT);
                }

                record.extraObservations = this.$('#customerform-extraObservations-field').val();
                record.invoiceNumber = this.$('#orderform-invoiceNumber-field').val();
                record.invoiceDate = invoiceDate;
                record.offer = offer.id;
            }

            return record;
        },

        /**
         * Retrieves the normalized service form data
         * @param {boolean} forOffers Return the offer record used for offers
         * @returns {object} The customer
         * @private
         */
        _getServiceFormRecord: function(forOffers){
            var record = {};

            var $params = this._getServiceParamsEl();
            var $details = this._getServiceDetailsEl();
            var $quantityField = this._getQuantityFieldEl();
            var $custodyField = this._getCustodyFieldEl();
            var $fileField = this._getFileFieldEl();
            var $fields = $().add($params).add($details);

            $fields.each(function(index, element){

                var $element = $(element);

                var name = this._getServiceParamName($element.attr('name'));

                record[name] = $element.val();

            }.bind(this));

            if ($quantityField.length) {

                var quantity = parseInt($quantityField.val());

                if (_.isNaN(quantity)) {
                    quantity = 0;
                }

                record['quantity'] = quantity ? '' + quantity : null; // be sure not to have NaN and convert to string
            }

            if ($custodyField.length) {

                record['custody'] = $custodyField.val();
            }

            if (!forOffers && $fileField.length) {

                var file = $fileField.get(0).files[0];

                if (file) {

                    record['file'] = file;
                }
            }

            return record;
        },

        /**
         * Retrieves the normalized customer form data
         * @returns {object} The customer
         * @private
         */
        _getCustomerFormRecord: function(){
            var $form = this._getFormEl();

            return {
                name: $form.find('#customerform-name-field').val(),
                phoneNumber: $form.find('#customerform-phoneNumber-field').val(),
                email: $form.find('#customerform-email-field').val(),
                county: $form.find('#customerform-county-field').val(),
                locality: this._getCustomerLocalityFieldEl().val(),
                address: $form.find('#customerform-address-field').val(),
            };
        },

        /**
         * Retrieves the record form with the offer
         * @returns {object} The offer
         * @private
         */
        _getOfferRecord: function(){
            var $checked = this._getOffersChecksEl().filter(':checked');

            if (!$checked.length) {

                return {
                    id: null,
                    total: null,
                    transport: null
                };
            }

            var $row = $checked.parents('tr:first');

            return {
                id: $row.data('id'),
                total: $row.data('total'),
                transport: $row.data('transport')
            };
        },

        /**
         * Marks the offer as reserved
         * @param {function} success The callback
         * @param {function} error The callback
         * @return {undefined}
         * @private
         */
        _reserveSelectedOffer: function(success, error){

            success = _.isFunction(success) ? success : _.noop;

            var record = this._getOfferRecord();
            var offer = this.offersCollection.get(record.id);
            var date = moment();

            offer.set('reservationDate', date.toISOString());

            offer.save(null, {
                success: function(){

                    success.apply(this, arguments);

                }.bind(this),
                error: function(){

                    error.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Selects the offer with the specified id from the list
         * @return {undefined}
         * @private
         */
        _selectPreviousOffer: function(){

            var $rows = this._getOffersTableRowEls();
            var $row = null;
            var $check;

            if (!this.previousOffer) {

                $check = this._getOffersChecksEl().filter(':checked');

                $check.iCheck('uncheck');

                return;
            }

            var offerId = this.previousOffer.id;

            $rows.each(function(){

                var $this = $(this);

                if ('' + $this.data('id') === '' + offerId) {

                    $row = $this;

                    return false;
                }
            });

            if (!$row) {

                $check = this._getOffersChecksEl().filter(':checked');

                $check.iCheck('uncheck');

                return;
            }

            $check = $row.find('input[type="radio"]');

            $check
                .data('silentCheck', true) // workaround
                .iCheck('check');
        },

        /**
         * Sets the offer alert expiration timer
         * @return {undefined}
         * @private
         */
        _setOfferTimer: function(){

            this._clearOfferTimer();

            this.offerTimer = setTimeout(function(){

                swal({
                    title: Translator.trans('js.error_message_orderform_offers_expired_title'),
                    text: Translator.trans('js.error_message_orderform_offers_expired_message'),
                    type: 'warning'
                }).catch(swal.noop);

            }, this.OFFER_EXPIRE_TIME * 1000);
        },

        /**
         * Removes the offer alert expiration timer
         * @return {undefined}
         * @private
         */
        _clearOfferTimer: function(){

            if (this.offerTimer) {
                clearTimeout(this.offerTimer);
            }
        },

        /**
         * Returns the appropriate field name
         * @param {string} fieldName The original field name
         * @returns {string} The real field name
         * @private
         */
        _getServiceParamName: function(fieldName){
            var pattern = /\[([a-z]+_\d+)\]$/g;

            var result = pattern.exec(fieldName);

            return result[1];
        },

        /**
         * Returns the url where you can find the service form
         * @param {int|string} serviceId The service id
         * @returns {string} The url
         * @private
         */
        _getServiceFormUrl: function(serviceId){
            return this.SERVICE_FORM_URL.replace('{service}', serviceId);
        },

        /**
         * Show or hide the invoiceable field
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggleInvoiceableEl: function(override) {

            var $el = this._getInvoiceableContainerEl();
            var $invoiceableEl = this._getInvoiceableFieldEl();

            // Check it if visible, uncheck if not
            // todo uncomment this line if the invoiceable field should be checked by default
            // $invoiceableEl.prop('checked', override);
            $invoiceableEl.removeAttr('checked');

            this._toggle($el, true, override);
        },

        /**
         * Show or hide the offers table
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggleOffersTable: function(override){
            var $el = this._getOffersTableEl();

            this._toggle($el, true, override);
        },

        /**
         * Show or hide the offers empty element
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggleOffersEmpty: function(override){
            var $el = this._getOffersEmptyEl();

            $el.text(Translator.trans('js.label_no_offers'));

            this._toggle($el, true, override);
        },

        /**
         * Show or hide the offers invalid element
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggleOffersInvalid: function(override){
            var $el = this._getOffersInvalidEl();

            this._toggle($el, true, override);
        },

        /**
         * Enable or disable fields associated with generating the offer
         * @return {undefined}
         * @private
         */
        _toggleOfferAssociatedFields: function(){
            var $service = this._getServiceFieldEl();
            var $params = this._getServiceParamsEl();
            var $county = this._getCustomerCountyFieldEl();
            var $locality = this._getCustomerLocalityFieldEl();
            var $quantity = this._getQuantityFieldEl();
            var $custody = this._getCustodyFieldEl();
            var $vendor = this._getVendorFieldEl();
            var $sort = this._getSortFieldEl();

            var list = [
                $service,
                $params,
                $quantity,
                $custody,
                $county,
                $locality,
                $vendor,
                $sort
            ];

            this._toggle(list);
        },

        /**
         * Enable/disable or show/hide a form field
         * @param {object} $fields The field
         * @param {boolean} display The type of toggle: true for display, false for state
         * @param {boolean} override Override the toggle logic and turn it on or off by yourself
         * @return {undefined}
         * @private
         */
        _toggle: function($fields, display, override){
            $fields = _.isArray($fields) ? $fields : [$fields];

            _.each($fields, function($field){

                var show = function(){
                    $field.removeClass('hidden');
                };

                var hide = function(){
                    $field.addClass('hidden');
                };

                var enable = function(){
                    $field.removeAttr('disabled');
                };

                var disable = function(){
                    $field.attr('disabled', 'disabled');
                };

                var isToggled = _.isUndefined(override) ? this._isToggled($field, display) : !override;
                var toggleOn = display ? show : enable;
                var toggleOff = display ? hide : disable;

                if (isToggled) {

                    toggleOff();

                } else {

                    toggleOn();
                }

                $field.trigger('chosen:updated');

            }, this);
        },

        /**
         * Returns true if the supplied filed is toggled, false otherwise
         * @param {object} $field The field
         * @param {boolean} display The type of toggle: true for display, false for state
         * @returns {boolean} The status
         * @private
         */
        _isToggled: function($field, display){
            var isDisabled = $field.attr('disabled');
            var isHidden = $field.hasClass('hidden');

            return display ? !isHidden : !isDisabled;
        },

        /**
         * Binds the chosen to the select fields
         * @return {undefined}
         * @private
         */
        _bindChosen: function(){
            this._getCustomerCountyFieldEl().chosen({ width: '100%' });
            this._getCustomerLocalityFieldEl().chosen({ width: '100%' });
            this._getShowroomFieldEl().chosen({ width: '100%' });
            this._getVendorFieldEl().chosen({ width: '100%' });
        },

        /**
         * Bind iCheck to radios and checkboxes
         * @return {undefined}
         * @private
         */
        _bindICheck: function(){

            var $els = this._getOffersChecksEl();

            $els.iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
                increaseArea: '20%' // optional
            });
        },

        /**
         * Bind iCheck from radios and checkboxes
         * @return {undefined}
         * @private
         */
        _unbindICheck: function(){

            var $els = this._getOffersChecksEl();

            $els.iCheck('destroy');
        },

        /**
         * Loads the service form according to the service dropdown
         * @param {Function} callback The callback
         * @return {undefined}
         * @private
         */
        _loadServiceForm: function(callback){
            callback = _.isFunction(callback) ? callback : _.noop;

            var record = this._getFormRecord();
            var serviceId = record.service;
            var $holder = this._getServiceContainerEl();
            var toggle = this._toggleOfferAssociatedFields.bind(this); // this._toggleServiceField();

            $holder.empty();

            if (!serviceId) {

                return;
            }

            var url = this._getServiceFormUrl(serviceId);

            var opts = {
                method      : 'GET',
                dataType    : 'html',
                contentType : 'text/plain'
            };

            toggle();

            z.request.make(url, null, function(jqXhr, status){

                toggle();

                if (status !== 'success') {

                    return;
                }

                $holder.append(jqXhr.responseText);

                callback.apply(this, arguments);

            }.bind(this), opts);
        },

        /**
         * Loads the localities field
         * @param {Function} callback The callback
         * @return {undefined}
         * @private
         */
        _loadCustomerLocalitiesField: function(callback){
            callback = _.isFunction(callback) ? callback : _.noop;

            var record = this._getFormRecord();
            var countyId = record.customer.county;

            var collection = this.customerLocalitiesCollection;
            var toggle = this._toggleOfferAssociatedFields.bind(this);

            collection.reset();

            if (!countyId) {

                return;
            }

            collection.setCountyId(countyId);

            toggle();

            collection.fetch({
                success: function(){

                    toggle();

                    callback.apply(this, arguments);

                }.bind(this),
                error: function(){

                    toggle();

                    callback.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Loads the closest locality field
         * @param {Function} callback The callback
         * @return {undefined}
         * @private
         */
        _loadWorksiteLocalitiesField: function(callback){
            callback = _.isFunction(callback) ? callback : _.noop;

            var record = this._getFormRecord();

            var countyId = record.customer.county;
            var serviceId = record.service;

            var collection = this.worksiteLocalitiesCollection;

            collection.reset();

            if (!countyId || !serviceId) {

                return;
            }

            collection
                .setCountyId(countyId)
                .setServiceId(serviceId)
                .setArea(collection.AREA_TYPE_EXTENDED);

            collection.fetch({
                success: function(){

                    callback.apply(this, arguments);

                }.bind(this),
                error: function(){

                    callback.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Retrieves the list of offers (syncs the offers collection)
         * @return {undefined}
         * @private
         */
        _loadOffers: function(){

            this._resetTotal();

            var record = this._getFormRecord(true);
            var toggle = this._toggleOfferAssociatedFields.bind(this);

            // Set the total 0 for afterward allocation
            if (record.vendor === "0") {

                this._setTotal(0, 0);
            }

            this.offersCollection.reset();

            if (!this._isValidOfferRequest()) {

                this._toggleOffersEmpty(false);
                this._toggleOffersInvalid(true);

                return;
            }

            this._toggleOffersInvalid(false);

            toggle();

            this.offersCollection.fetch({
                contentType: 'application/json',
                type: 'POST',
                data: JSON.stringify(record),
                success: function(){

                    toggle();

                }.bind(this),
                error: function(model, res){

                    if (res.responseJSON.errors[0]['class'] === 'OrderBundle\\Exceptions\\VendorUnavailableException') {
                        this._loadVendors();
                    }

                    toggle();

                }.bind(this)
            });
        },

        /**
         * Retrieves the list of vendors (syncs the vendors collection)
         * @param {Function} [callback] The callback
         * @return {undefined}
         * @private
         */
        _loadVendors: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var record = this._getFormRecord();

            this.orderVendorsCollection.reset();

            if (!this._isVendorFieldVisible()) {

                return;
            }

            if (!this._isValidVendorRequest()) {

                return;
            }

            this.orderVendorsCollection.setAvailable(true);
            this.orderVendorsCollection.setServiceId(record.service);
            this.orderVendorsCollection.setCountyId(record.customer.county);

            if (record.sort === this.SORT_BY_DISTANCE + '') {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_EXTENDED);
            } else {
                this.orderVendorsCollection.setArea(this.orderVendorsCollection.AREA_TYPE_FIXED);
            }

            if (record.fields.quantity) {
                this.orderVendorsCollection.setQuantity(record.fields.quantity);
            }

            this.orderVendorsCollection
                .fetch()
                .then(function(){

                    callback.apply(this, arguments);

                })
                .catch(function(){

                    callback.apply(this, arguments);
                });
        },

        /**
         * Checks if all required fields for an offer request are not empty
         * @return {boolean} Is valid
         * @private
         */
        _isValidOfferRequest: function(){

            this._prepareModelData(this.model);

            var serviceFields = this._getServiceRequiredFields(this.model);
            var record = this._getFormRecord();

            var isServiceFieldEmpty = _.find(serviceFields, function(field){

                if (field === 'file') {

                    return false;
                }

                return !record.fields[field];
            });

            if (isServiceFieldEmpty) {
                return false;
            }

            var serviceId = record.service;

            if (!serviceId) {
                return false;
            }

            var countyId = record.customer.county;

            if (!countyId) {
                return false;
            }

            var localityId = record.customer.locality;

            if (!localityId) {
                return false;
            }

            var showroomId = record.showroom;

            // Verify the showroom only if the user is nor reseller operator
            if (!showroomId && !z.security.isResellerOperator()) {
                return false;
            }

            return true;
        },

        /**
         * Checks if all required fields for a vendor request are not empty
         * @return {boolean} Is valid
         * @private
         */
        _isValidVendorRequest: function(){

            var record = this._getFormRecord();

            var quantity = record.fields.quantity;

            if (!_.isUndefined(quantity) && !quantity) {
                return false;
            }

            var serviceId = record.service;

            if (!serviceId) {
                return false;
            }

            var countyId = record.customer.county;

            if (!countyId) {
                return false;
            }

            return true;
        },

        /**
         * Checks if the vendor container field is visible
         * @return {boolean} Is visible
         * @private
         */
        _isVendorFieldVisible: function(){

            var $container = this._getVendorContainerEl();

            return !$container.is('.hidden');
        },

        /**
         * Renders all items from customer's localities collection into html elements
         * @return {undefined}
         * @private
         */
        _renderCustomerLocalities: function(){

            var $field = this._getCustomerLocalityFieldEl();

            var collection = this.customerLocalitiesCollection;

            this._renderChosenField($field, collection, 'name');
        },

        /**
         * Renders all items from vendors collection into html elements
         * @return {undefined}
         * @private
         */
        _renderOrderVendors: function(){

            var $field = this._getVendorFieldEl();

            var collection = this.orderVendorsCollection;

            this._renderChosenField($field, collection, function(model){

                return model.get('companyName');
            });
        },

        /**
         * Renders the options inside the chosen field based on the supplied collection
         * @param {object} $field The select field
         * @param {Backbone.Collection} collection The collection
         * @param {string|function} name How to select the name of the item
         * @return {undefined}
         * @private
         */
        _renderChosenField: function($field, collection, name){

            if (!collection) {

                $field.trigger('chosen:updated');

                return;
            }

            var $placeholder = $field.find('option:first').detach();

            var options = [$placeholder];

            $field.empty();

            // Add the allocated afterward option
            if ($field.attr('id') === 'orderform-vendor-field') {

                var $allocatedAfterwardOption = this._getAllocatedAfterwardOption();
                options.push($allocatedAfterwardOption);
            }

            collection.each(function(item){

                var display;

                if (_.isFunction(name)) {
                    display = name.call(this, item);
                } else {
                    display = item.get(name);
                }

                var $option = $('<option>')
                    .attr('value', item.get('id'))
                    .text(display);

                options.push($option);
            });

            $field.append(options);

            $field.trigger('chosen:updated');
        },

        /**
         * Renders all the chosen located on this page fields.
         * @return {undefined}
         * @private
         */
        _renderAllChosenFields: function(){
            var fields = this._getChosenFieldsEl();

            _.each(fields, function($field){

                this._renderChosenField($field);

            }, this);
        },

        /**
         * Displays the appropriate offer error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayOffersError: function(res){

            this._hideOfferError();

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._showOfferError(Translator.trans('js.error_message_orderform_offers_unknown_error'));

                return;
            }

            var errors = res.responseJSON.errors;
            var exception = _.first(errors).class;

            switch (exception) {
                case 'OrderBundle\\Service\\Pricing\\Exception\\OfferUnavailableException':
                case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                    this._showOfferError(Translator.trans('js.error_message_orderform_offers_unavailable'));
                    return;
                case 'OrderBundle\\Exceptions\\VendorUnavailableException':
                    this._showOfferError(Translator.trans('js.error_message_vendorform_vendor_unavailable'));
                    return;
                default:
                    this._showOfferError(Translator.trans('js.error_message_orderform_offers_unknown_error'));
                    return;
            }
        },

        /**
         * Displays the appropriate error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayError: function(res){

            this._hideError();

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._showError(Translator.trans('js.error_message_orderform_order_unknown_error'));

                return;
            }

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var exception = _.first(errors).class;
                var message = _.first(errors).message;

                if (typeof exception !== 'undefined') {

                    switch (exception) {
                        case 'OrderBundle\\Service\\Pricing\\Exception\\OfferUnavailableException':
                        case 'Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException':
                            this._showError(Translator.trans('js.error_message_orderform_offers_unavailable'));
                            return;
                        case 'OrderBundle\\Exceptions\\VendorUnavailableException':
                            this._showError(Translator.trans('js.error_message_orderform_vendor_unavailable'));
                            return;
                        default:
                            this._showError(Translator.trans('js.error_message_orderform_order_unknown_error'));
                            return;
                    }
                }

                if (typeof message !== 'undefined') {

                    switch (message) {
                        case "future_invoice_date":
                            this._showError(Translator.trans('js.error_message_order_editable_invoice_date'));
                            return;
                        case "no_tariff_vendor_defined":
                            this._showError(Translator.trans('js.no_tariff_vendor_defined'));
                            return;
                        case "no_tariff_reseller_defined":
                            this._showError(Translator.trans('js.no_tariff_reseller_defined'));
                            return;
                    }
                }
            }

            _.each(errors, function(value) {

                if (value.field === "children[fields].children[file].data") {
                    this._showError(Translator.trans('js.error_message_attachmentform_name_invalidtype'));
                }

            }.bind(this));
        },

        /**
         * Renders the offers list
         * @return {undefined}
         * @private
         */
        _renderOffers: function(){
            var $tbody = this._getOffersTableBodyEl();

            this._unbindICheck();

            $tbody.empty();

            if (!this.offersCollection.length) {

                this._toggleOffersTable(false);
                this._toggleOffersEmpty(true);

                return;
            }

            this._toggleOffersTable(true);
            this._toggleOffersEmpty(false);

            this.offersCollection.each(function(offer){

                this._renderOffer(offer);

            }, this);

            this._bindICheck();
        },

        /**
         * Renders the offer
         * @param {object} offer The offer model
         * @return {undefined}
         * @private
         */
        _renderOffer: function(offer){
            var $tbody = this._getOffersTableBodyEl();
            var $row = $('<tr>').attr({
                'data-id': offer.get('id'),
                'data-total': offer.get('total'),
                'data-transport': offer.get('transportCost')
            });

            var $checkbox = $('<input>').attr({
                type: 'radio',
                name: 'offer'
            });

            var isReseller = z.security.isResellerAssociated();

            if(isReseller || !this.service.get('hasTransportCost')) {
                this._getOrderTableThDistance().addClass('hidden');
                this._getOrderTableThTransport().addClass('hidden');
            } else {
                this._getOrderTableThDistance().removeClass('hidden');
                this._getOrderTableThTransport().removeClass('hidden');
            }

            var date = this._renderDate(offer.get('appointmentDate'));
            var total = this._renderPrice(offer.get('total'));
            var distance = this._renderDistance(offer);
            var transportCost = this._renderPrice(offer.get('transportCost'));

            var $check = $('<td>').append($checkbox);
            var $date = $('<td>').text(date);
            var $interval = $('<td>').addClass('text-center text-danger font-bold').text(offer.get('daysInterval'));
            var $price = $('<td>').addClass('text-center').text(total);

            if (!isReseller ) {
                this._getOrderTableThVendor().removeClass('hidden');
                var $vendor = $('<td>').text(this._getVendorNameFromOffer(offer));
            } else {
                this._getOrderTableThVendor().addClass('hidden');
            }

            if (!isReseller && this.service.get('hasTransportCost') === true) {

                var $distance = $('<td>').addClass('text-center').text(distance);
                var $transportCost = $('<td>').addClass('text-center').text(transportCost);

                $row.append($check, $date, $interval, $vendor, $price, $distance, $transportCost);
            } else {

                if ( !isReseller ) {
                    $row.append($check, $date, $interval, $vendor, $price);
                } else {
                    $row.append($check, $date, $interval, $price);
                }
            }

            $tbody.append($row);
        },

        /**
         * Method that returns the offer distance.
         * @param {object} offer - the offer object
         * @private
         * @return {float} - the distance
         */
        _renderDistance: function (offer) {
            return offer.get('distance');
        },

        /**
         * Method that builds the name of the vendor from brandName and Company name
         * @param {object} offer - the offer object
         * @return {string} the vendor name
         * @private
         */
        _getVendorNameFromOffer: function (offer) {
            return offer.get('worksite').vendor.companyName;
        },

        /**
         * Retrieves the distance th element
         * @private
         * @return {object} - the distance element
         */
        _getOrderTableThDistance: function () {
            return this.$('#order-offers-table-th-distance ');
        },

        /**
         * Retrieves the transport cost th element
         * @private
         * @return {object} - the transport el
         */
        _getOrderTableThTransport: function () {
            return this.$('#order-offers-table-th-transport');
        },

        /**
         * Retrieves the vendor th element
         * @private
         * @return {object} -the  vendor el
         */
        _getOrderTableThVendor: function () {
            return this.$('#order-offers-table-th-vendor');
        },

        /**
         * Renders a date as string
         * @param {string} value The string representation of the date
         * @return {string} The formatted date
         * @private
         */
        _renderDate: function(value){
            // TODO: we should get the locale for current user
            return moment(value).locale('ro').format('dddd, D MMMM');
        },

        /**
         * Renders the price accordingly
         * @param {string|int} value The price
         * @return {string} The formatted price
         * @private
         */
        _renderPrice: function(value){
            return value;
        },

        /**
         * Prepare model with all the necessary fields
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _prepareModelData: function(model){

            var record = this._getFormRecord();

            model.clear();

            model.set(record);
        },

        /**
         * Sets the fields which the validation will check
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _prepareModelValidation: function(model){
            var $showroomHolder = this._getShowroomHolderEl();

            var isResellerRequired = this._isToggled($showroomHolder, true);

            var serviceRequiredFields = this._getServiceRequiredFields(model);

            this._removeAllServiceFieldValidations(model);

            _.each(serviceRequiredFields, function(name){

                this._addServiceFieldValidation(name, model);

            }, this);

            this.model.set({
                isResellerRequired: isResellerRequired
            });
        },

        /**
         * Returns a list containing all the required service fields
         * @param {object} model The model
         * @returns {Array} The list
         * @private
         */
        _getServiceRequiredFields: function(model){
            var data = model.toJSON();
            var fields = data.fields;

            var result = [];

            _.each(fields, function(value, name){

                if (!this._isServiceFieldRequired(name)) {

                    return;
                }

                result.push(name);

            }, this);

            return result;
        },

        /**
         * Returns true if the supplied service field name is required, false otherwise
         * @param {string} name The service field name
         * @returns {boolean} The result
         * @private
         */
        _isServiceFieldRequired: function(name){
            if (name.indexOf('option') === 0) {

                return true;
            }

            var $container = this._getServiceContainerEl();

            return !!$container.find(':input[name*="\[' + name + '\]"]').attr('required');
        },

        /**
         * Deletes all validations belonging to service fields
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _removeAllServiceFieldValidations: function(model){

            if (!model.validation) {
                return;
            }

            _.each(model.validation, function(validation, name){

                if (name.indexOf('fields.') !== 0) {
                    return;
                }

                delete model.validation[name];
            });
        },

        /**
         * Add validation to service field
         * @param {string} name The name of the field
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _addServiceFieldValidation: function(name, model){

            var fieldName = 'fields.' + name;

            if( name.indexOf('option') === 0 ){

                model.validation[fieldName] = [{
                    required: true,
                    msg: Translator.trans('js.error_message_orderform_service_param_required')
                }];

                return;
            }

            if( name.indexOf('detail') === 0 ){

                model.validation[fieldName] = [{
                    required: true,
                    msg: Translator.trans('js.error_message_orderform_service_detail_required')
                }, {
                    minLength: 4,
                    msg: Translator.trans('js.error_message_orderform_service_detail_minlength')
                }];

                return;
            }

            model.validation[fieldName] = [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_service_field_required')
            }];
        },

        /**
         * Submit the form
         * @return {undefined}
         * @private
         */
        _submitForm: function(){

            this._prepareModelData(this.model);

            this._prepareModelValidation(this.model);

            if (!this.model.isValid(true)) {

                return;
            }

            var $fileField = this._getFileFieldEl();

            if (!this.isValidUpload($fileField, false, 'fields.file')) {

                return;
            }

            this._isLoading();

            var dataOptions = this._getFormDataRecord();

            var options = {
                type: "POST",
                data: dataOptions,
                processData: false,
                contentType: false,
                validate: false
            };

            this.model.save(null, $.extend({}, options, {
                success: function(model, response){

                    this._resetFrom();

                    this._resetTotal();

                    this._clearOfferTimer();

                    this._displayMessageWithOrderLink(response.data.hash);

                    this._isLoading(false);

                    this._prefillSeviceForm();

                    if (!z.security.isResellerOperator()) {

                        this._toggleInvoiceableEl(false);
                    } else {

                        this._toggleInvoiceableEl(true);
                    }

                }.bind(this),
                error: function(model, res) {

                    this._isLoading(false);

                    this._displayError(res);

                }.bind(this)
            }));
        },

        /**
         * Retrieves the FormData record
         * @returns {FormData} The form data
         * @private
         */
        _getFormDataRecord: function(){

            var name = this.$('form').attr('name');
            var data = new FormData();

            data.append(name + '[extraObservations]', this.model.get('extraObservations'));
            data.append(name + '[invoiceNumber]', this.model.get('invoiceNumber'));
            data.append(name + '[invoiceDate]', this.model.get('invoiceDate'));
            data.append(name + '[offer]', this.model.get('offer'));
            data.append(name + '[showroom]', this.model.get('showroom'));
            data.append(name + '[service]', this.model.get('service'));

            if (this.model.get('invoiceable')) {
                data.append(name + '[invoiceable]', true);
            }

            _.each(this.model.get('fields'), function(value, key) {

                data.append(name + '[fields]' + '[' + key + ']', value);
            });

            _.each(this.model.get('customer'), function(value, key) {

                data.append(name + '[customer]' + '[' + key + ']', value);
            });

            return data;
        },

        /**
         * Display the success message with a link to see the order
         *
         * @param {String} hash - order hash
         * @return {undefined}
         * @private
         */
        _displayMessageWithOrderLink: function(hash) {
            var message = Translator.trans('js.success_message_orders');
            var orderLink = '<strong><a href="/orders/' + hash + '" target="_blank">' + hash + '</a></strong>';

            this._showSuccess(message.replace('ORDER_LINK', orderLink));
        },

        /**
         * Reset the form
         * @return {undefined}
         * @private
         */
        _resetFrom: function(){
            var $form = this._getFormEl();
            var $serviceField = this._getServiceFieldEl();
            var fields = this._getChosenFieldsEl();

            $form[0].reset();

            $serviceField.trigger('change');

            _.each(fields, function($field){

                $field.trigger('change');

            }, this);

            this._renderAllChosenFields();
        },

        /**
         * Attaches the offer to the form
         * @return {undefined}
         * @private
         */
        _attachSelectedOffer: function() {

            var record = this._getOfferRecord();

            this.previousOffer = record;

            this._setOffer(record.id);
            this._setTotal(record.total, record.transport);
        },

        /**
         * Sets the offer into the form
         * @param {string} offer The offer id
         * @return {undefined}
         * @private
         */
        _setOffer: function(offer){

            var $field = this._getOfferFieldEl();

            $field.val(offer);
        },

        /**
         * Sets the total price with the specified value
         * @param {string|int|null} total The total price
         * @param {string|int} transport the transport cost
         * @return {undefined}
         * @private
         */
        _setTotal: function(total, transport) {

            var $field = this._getTotalFieldEl();
            var $display = this._getTotalDisplayEl();

            var $transportCost = this._transportCostEl();
            var $serviceValue = this._serviceValueEl();

            var showTransport = !z.security.isResellerAssociated() && this.service && this.service.get('hasTransportCost') === true && transport !== 0;

            var finalPrice = !total || total < 0 ? 0 : total;

            if (showTransport) {

                finalPrice += transport;

            }

            $field.val(finalPrice);

            $transportCost.text(this._renderPrice(transport));
            $serviceValue.text(this._renderPrice(total));
            $display.text(this._renderPrice(finalPrice));

            this._toggleTransportCostAndServiceValue(showTransport);
        },

        /**
         * Reset the total price
         * @return {undefined}
         * @private
         */
        _resetTotal: function() {
            var $display = this._getTotalDisplayEl();

            var $transportCost = this._transportCostEl();
            var $serviceValue = this._serviceValueEl();

            $display.text('0');
            $transportCost.text('0');
            $serviceValue.text('0');

            this._toggleTransportCostAndServiceValue(false);
        },

        /**
         * Method that hide/show the transport cost and service value fields and their labels
         * @param {object} value is true for Show and false for hide
         * @private
         * @return {undefined}
         */
        _toggleTransportCostAndServiceValue: function (value) {

            var $wrapper = this.$('.orderform-total-service-transport');

            if (value) {
                $wrapper.removeClass('hidden');
            } else {
                $wrapper.addClass('hidden');
            }
        },

        /**
         * Retrieves the label object for the service-value element
         * @return {object} the object
         * @private
         */
        _serviceValueEl: function () {
            return this.$('#orderform-total-service-value');
        },

        /**
         * Retrieves the label object for the transport-cost element
         * @return {object} the object
         * @private
         */
        _transportCostEl: function () {
            return this.$('#orderform-total-transport-cost');
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showSuccess: function(message){

            this._hideError();

            var $success = this.$('#order-success-message');

            $success
                .html(message)
                .removeClass('hidden')
                .fadeOut(0)
                .fadeIn('slow')
                .delay(5000)
                .fadeOut('slow', function(){

                    $success.addClass('hidden');
                });
        },

        /**
         * Hide the success message
         * @return {undefined}
         * @private
         */
        _hideSuccess: function(){

            var $error = this.$('#order-success-message');

            $error
                .finish()
                .fadeOut(0)
                .addClass('hidden');
        },

        /**
         * Display the error message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showError: function(message){

            this._hideSuccess();

            var $error = this.$('#order-error-message');

            $error
                .text(message)
                .removeClass('hidden')
                .fadeOut(0)
                .fadeIn('slow')
                .delay(5000)
                .fadeOut('slow', function(){

                    $error.addClass('hidden');
                });
        },

        /**
         * Hide the error message
         * @return {undefined}
         * @private
         */
        _hideError: function(){

            var $error = this.$('#order-error-message');

            $error
                .finish()
                .fadeOut(0)
                .addClass('hidden');
        },

        /**
         * Shows the offer error
         * @param {string} message The message
         * @return {undefined}
         * @private
         */
        _showOfferError: function(message){

            var $error = this.$('#orderform-offers-error');

            $error
                .text(message)
                .removeClass('hidden')
                .slideUp(0)
                .slideDown()
                .delay(5000)
                .slideUp(function(){

                    $error.addClass('hidden');
                });
        },

        /**
         * Hide the offer error
         * @return {undefined}
         * @private
         */
        _hideOfferError: function(){

            var $error = this.$('#orderform-offers-error');

            $error
                .finish()
                .slideUp(0)
                .addClass('hidden');
        },

        /**
         * Enables or disables the loading mask
         * @param {bool} loading Should show or hide the mask?
         * @return {undefined}
         * @private
         */
        _isLoading: function (loading) {
            loading = loading ? loading : _.isUndefined(loading);

            var $form = this._getFormEl();
            var $submitBtn = $form.find('button[type="submit"]');
            var $formFields = $form.find('fieldset');

            var ladda = $submitBtn.data('ladda') ? $submitBtn.data('ladda') : $submitBtn.ladda().data('ladda');

            if (loading) {

                ladda.start();

                $formFields.attr('disabled', true);

            } else {

                ladda.stop();

                $formFields.removeAttr('disabled');
            }

            this._renderAllChosenFields();
        },

        /**
         * Enables or disables the loading mask for the offers component
         * @param {boolean} loading Should show or hide the mask?
         * @return {undefined}
         * @private
         */
        _offersIsLoading: function (loading) {

            loading = loading ? loading : _.isUndefined(loading);

            var $panel = this._getOffersContentEl();

            $panel.toggleClass('sk-loading', loading);
        },

        /**
         * Add allocated afterwards options
         * @return {undefined}
         * @private
         */
        _addAllocatedAdfterwardOption: function() {

            var $option = this._getAllocatedAfterwardOption();
            this.$('#orderform-vendor-field').append($option).trigger("chosen:updated");
        },

        /**
         * Get allocated afterward option
         * @return {*|jQuery} - return the allocated afterward option
         * @private
         */
        _getAllocatedAfterwardOption: function() {

            return $('<option>')
                .attr('value', this.ALLOCATED_AFTERWARD_VALUE)
                .text(Translator.trans('js.allocated_afterward'));
        }

    });

})(window.z = window.z || {}, Backbone, _);