"use strict";

(function(z, Backbone){

    var ServiceOptionsCollection = Backbone.Collection.extend({

        /**
         * The model
         */
        model: z.ServiceOptionModel
    });

    z.ServiceOptionsCollection = ServiceOptionsCollection;

})(window.z = window.z || {}, Backbone);