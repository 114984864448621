"use strict";

(function(z, Backbone){

    var OrderReallocatesCollection = Backbone.Collection.extend({

        url: function(){
            return "/api/orders/reallocations/"+ this.orderId;
        },

        setOrderId: function(id){
            this.orderId = id;
        },

        parse: function (response) {
            return response.data;
        }
    });

    z.OrderReallocatesCollection = OrderReallocatesCollection;

})(window.z = window.z || {}, Backbone);