'use strict';

(function(app, Backbone, _){

    var Class = function(){

        this.initialize.apply(this, arguments);
    };

    Class.extend = Backbone.Model.extend;

    _.extend(Class.prototype, Backbone.Events, {

        /**
         * Constructor
         */
        initialize: _.noop
    });

    app.Class = Class;

})(window.z=window.z||{}, Backbone, _);