'use strict';

(function(z, Backbone){

    var OrderCustomerModel = Backbone.Model.extend({

        urlRoot: '/api/orders/customers',
        validation: {
            'name': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_name_required')
            },{
                minLength: 3,
                msg: Translator.trans('js.error_message_orderform_customer_name_minlength')
            }],
            'phoneNumber': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_required')
            },{
                minLength: 10,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_minlength')
            },{
                maxLength: 16,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_maxlength')
            }, {
                pattern: 'digits',
                msg: Translator.trans('js.error_message_phoneNumber_format')
            }],
            'email': [{
                required: false,
                msg: Translator.trans('js.error_message_orderform_customer_email_required')
            },{
                minLength: 4,
                msg: Translator.trans('js.error_message_orderform_customer_email_minlength')
            },{
                maxLength: 64,
                msg: Translator.trans('js.error_message_orderform_customer_email_maxlength')
            },{
                pattern: 'email',
                msg: Translator.trans('js.error_message_email_format')
            }],
            'address': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_address_required')
            }, {
                minLength: 4,
                msg: Translator.trans('js.error_message_orderform_customer_address_minlength')
            }, {
                maxLength: 255,
                msg: Translator.trans('js.error_message_orderform_customer_address_maxlength')
            }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            return response.data;
        }
    });

    z.OrderCustomerModel = OrderCustomerModel;

})(window.z = window.z || {}, Backbone);
