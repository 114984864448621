"use strict";

(function (z, _, $, Backbone, Translator) {

    z.ServiceParamsForm = z.ServiceGridForm.extend({

        el: "#serviceconfig-params-form",

        /**
         * Increase type for params
         */
        INCREASE_NO_INCREASE: 0,
        INCREASE_PERCENT: 1,
        INCREASE_VALUE: 2,

        /**
         * Events
         * @return {void|*} The events
         */
        events: {
            'click #serviceconfig-paramSave-btn': '_onSubmitClick',
            'click #serviceconfig-paramCancel-btn': '_onCancelClick',
            'click #serviceconfig-params-grid .edit-param': '_onEditClick',
            'click #serviceconfig-params-grid .delete-param': '_onDeleteClick',
            'change #serviceconfig-paramOverridesBasePrice-field': '_onOverridesFieldChange'
        },

        /**
         * Selectors
         */
        selectors: {
            'form.title': function () {
                var $el = this.getEl();
                return $el.find('h3:first');
            },
            'form.btn.cancel': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-paramCancel-btn');
            },
            'form.btn.save': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-paramSave-btn');
            },
            'loader': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-params-loader');
            },
            'grid.table': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-params-grid');
            },
            'grid.table.body': function () {
                var $table = this.getEl('grid.table');
                return $table.find('tbody');
            },
            'grid.empty': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-params-emptylist');
            },
            'field.name': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-paramName-field');
            },
            'field.type': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-paramType-field');
            },
            'field.overridesBasePrice': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-paramOverridesBasePrice-field');
            },
            'field.increaseType': function () {
                var $el = this.getEl();
                return $el.find('#serviceconfig-paramIncreaseType-field');
            },
            'group.increaseType': function () {
                var $el = this.getEl('field.increaseType');
                return $el.parents('.form-group:first');
            }
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function () {

            z.ServiceGridForm.prototype.initialize.apply(this, arguments);

            z.serviceConfigForm.modal.on('shown.bs.modal', function () { // it doesn't work with listenTo

                this.render();

            }.bind(this));
        },

        /**
         * Method that renders the view
         * @return {undefined}
         */
        render: function () {

            this._loadCollection();
        },

        /**
         * Method called after the field changes it's value
         * @return {undefined}
         * @private
         */
        _onOverridesFieldChange: function () {

            var $field = this.getEl('field.overridesBasePrice');
            var $increaseField = this.getEl('field.increaseType');
            var $increaseGroup = this.getEl('group.increaseType');

            var overrides = parseInt($field.val()); // convert to bool value

            if (overrides) {

                $increaseGroup.addClass('hidden');

                $increaseField
                    .val(this.INCREASE_VALUE)
                    .trigger('change');

                return;
            }

            $increaseGroup.removeClass('hidden');

            if (this.model.get('overridesBasePrice')) {

                $increaseField
                    .val(this.INCREASE_NO_INCREASE)
                    .trigger('change');
            }
        },

        /**
         * Method called after the user clicks on the edit param button
         * @return {undefined}
         * @private
         */
        _onEditClick: function () {

            z.ServiceGridForm.prototype._onEditClick.apply(this, arguments);

            this._setFormTitle(Translator.trans('js.label_service_param_edit'));

            this._checkOverrideParams(this.model);
        },

        /**
         * Method called if the form validation succeeds
         * @param {object} view The view
         * @param {string} attr The valid attribute
         * @return {undefined}
         * @private
         */
        _onValid: function(view, attr){

            var $form = this.getEl();

            $form
                .find('[data-validation="' + attr + '"]:first')
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method called if the form validation fails
         * @param {object} view The view
         * @param {string} attr The invalid attribute
         * @param {string} error The error message
         * @return {undefined}
         * @private
         */
        _onInvalid: function(view, attr, error){

            var $form = this.getEl();

            $form
                .find('[data-validation="' + attr + '"]:first')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method called after models are added or removed from the collection
         * @return {undefined}
         * @private
         */
        _onCollectionUpdate: function(){

            z.ServiceGridForm.prototype._onCollectionUpdate.apply(this, arguments);

            this._checkOverrideParams();
        },

        /**
         * Loads the service params
         * @return {undefined}
         * @private
         */
        _loadCollection: function () {

            var serviceId = this._getServiceId();

            this.collection.setServiceId(serviceId);

            this.collection.reset();

            this.collection.fetch();
        },

        /**
         * Loads the supplied param into the form
         * @param {object} param The param model
         * @return {undefined}
         * @private
         */
        _loadModel: function (param) {

            var $nameField = this.getEl('field.name');
            var $typeField = this.getEl('field.type');
            var $overridesField = this.getEl('field.overridesBasePrice');
            var $increaseField = this.getEl('field.increaseType');

            if (!_.isUndefined(param.get('name'))) {

                $nameField.val(param.get('name'));
            }

            if (!_.isUndefined(param.get('type'))) {

                $typeField.val(param.get('type'));
            }

            if (!_.isUndefined(param.get('overridesBasePrice'))) {

                $overridesField.val(param.get('overridesBasePrice'));
            }

            if (!_.isUndefined(param.get('increaseType'))) {

                $increaseField.val(param.get('increaseType'));
            }

            $nameField.trigger('change');
            $typeField.trigger('change');
            $overridesField.trigger('change');
            $increaseField.trigger('change');
        },

        /**
         * Builds the html grid row for the supplied param
         * @param {object} param The service param
         * @return {*|jQuery|HTMLElement} The table row
         * @private
         */
        _buildGridRow: function (param) {

            var id = param.get(param.idAttribute) ? param.get(param.idAttribute) : param.cid;

            var $row = $('<tr>')
                .attr('data-id', id);

            var info = this._getInfoText(param);

            $('<td>')
                .text(param.get('name'))
                .append(
                    $('<div>')
                        .addClass('info')
                        .text(info)
                )
                .appendTo($row);

            var $editBtn = $('<a>')
                .addClass('btn btn-primary btn-xs edit-param')
                .append(
                    $('<i>')
                        .addClass('fa fa-pencil')
                );

            var $deleteBtn = $('<a>')
                .addClass('btn btn-danger btn-xs delete-param')
                .append(
                    $('<i>')
                        .addClass('fa fa-trash')
                );

            $('<td>')
                .addClass('text-center table-action-col')
                .append($editBtn, ' ', $deleteBtn)
                .appendTo($row);

            return $row;
        },

        /**
         * Sets the title for the param form
         * @param {string} value The new title
         * @return {undefined}
         * @private
         */
        _setFormTitle: function (value) {

            var $title = this.getEl('form.title');

            $title.text(value);
        },

        /**
         * Resets the entire view
         * @param {object} model The model
         * @return {undefined}
         * @private
         */
        _reset: function(model){

            model = _.isUndefined(model) ? new z.ServiceParamModel() : model;

            z.ServiceGridForm.prototype._reset.apply(this, [model]);

            this._checkOverrideParams(this.model);
        },

        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        _resetForm: function() {

            z.ServiceGridForm.prototype._resetForm.apply(this, arguments);

            this.getEl().get(0).reset();

            this._setFormTitle(Translator.trans('js.label_service_param_add'));
        },

        /**
         * Retrieves the data from the form
         * @return {object} The form data
         * @private
         */
        _getFormRecord: function () {

            var $nameField = this.getEl('field.name');
            var $typeField = this.getEl('field.type');
            var $overridesField = this.getEl('field.overridesBasePrice');
            var $increaseField = this.getEl('field.increaseType');

            var options = z.serviceOptionsForm.collection.toJSON();

            return {
                name: $nameField.val(),
                type: $typeField.val(),
                overridesBasePrice: parseInt($overridesField.val()),
                increaseType: parseInt($increaseField.val()),
                options: options
            };
        },

        /**
         * Builds the info text to be displayed along side with the param
         * @param {object} param The param model
         * @return {string} The text
         * @private
         */
        _getInfoText: function(param){

            var overridesBasePrice = param.get('overridesBasePrice');

            if (overridesBasePrice) {

                return Translator.trans('js.label_override_base_price');
            }

            var increaseType = param.get('increaseType');

            if (increaseType === this.INCREASE_VALUE) {

                return Translator.trans('js.label_increase_type_value');
            }

            if (increaseType === this.INCREASE_PERCENT) {

                return Translator.trans('js.label_increase_type_percent');
            }
        },

        /**
         * Method that enables/disables the "Override Base Price".
         * If you supply the parameter, it will also check if ids match.
         * @param {Object|undefined} param The optional parameter
         * @return {undefined}
         * @private
         */
        _checkOverrideParams: function(param){

            var exists = this._getOverrideParam();

            if (exists) {

                this._toggleOverrideField(false);

                if (!_.isUndefined(param) && param.cid === exists.cid) {

                    this._toggleOverrideField(true);
                }

            } else {

                this._toggleOverrideField(true);
            }
        },

        /**
         * Retrieves from collection the parameter that has "Override Base Price" enabled
         * @return {Object|undefined} The param or nothing
         * @private
         */
        _getOverrideParam: function(){

            return this.collection.find(function(param){

                return !!param.get('overridesBasePrice'); //convert to bool
            });
        },

        /**
         * Enable or disable the "Override Base Price" field
         * @param {boolean} enable Enable/disable
         * @return {undefined}
         * @private
         */
        _toggleOverrideField: function(enable){

            var $field = this.getEl('field.overridesBasePrice');

            if (enable) {

                $field.removeAttr('disabled');

                return;
            }

            $field.attr('disabled', 'disabled');
        }
    });

})(window.z = window.z || {}, _, $, Backbone, Translator);
