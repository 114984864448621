"use strict";

(function(z, Backbone){

    z.AppView = Backbone.View.extend({

        el: '#page-content',

        events: {
            'click #order-print-btn': '_printPage',
            'click #proforma-print-btn': '_printPage'
        },

        initialize: function(){
        },

        _printPage: function () {
            window.print();
        }
    });

})(window.z = window.z || {}, Backbone);