"use strict";

(function(app, Backbone){

    //noinspection UnnecessaryLocalVariableJS
    var AttachmentsCollection = Backbone.Collection.extend({

        /**
         * The model to be used for this collection
         */
        model: app.OrderAttachmentModel,

        /**
         * Retrieves the appropriate url to be used
         * @returns {string} The url
         */
        url: function() {

            return '/api/orders/' + this.orderId + '/attachments';
        },

        /**
         * Sets order id
         * @param {string|int} id The order id
         * @return {Backbone.Collection} Return myself
         */
        setOrderId: function(id){

            this.orderId = id;

            return this;
        },

        /**
         * Parses the response
         * @param {object} response The response
         * @return {undefined}
         */
        parse: function(response){

            return response.data;
        }
    });

    app.OrderAttachmentsCollection = AttachmentsCollection;

})(window.z = window.z || {}, Backbone);