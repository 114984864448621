'use strict';

(function(z, Backbone, _){

    var OrderModel = Backbone.Model.extend({

        /**
         * Endpoint
         */
        urlRoot: '/api/orders/create',

        /**
         * Parse the response.
         * @param {object} res The response
         * @return {object} The parsed response
         */
        parse: function(res){

            // convert status to string
            if (!_.isUndefined(res.data.status)) {
                res.data.status = res.data.status + '';
            }

            return res.data;
        },

        /**
         * Checks if the order is open.
         * @return {boolean} Check
         */
        isOpen: function(){
            return !_.contains([z.OrderStatus.STATUS_FINALIZED, z.OrderStatus.STATUS_ALLOCATED, z.OrderStatus.STATUS_CANCELED], this.get('status'));
        },

        /**
         * Validation
         */
        validation: {
            'service': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_service_required')
            }],
            'customer.name': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_name_required')
            },{
                minLength: 3,
                msg: Translator.trans('js.error_message_orderform_customer_name_minlength')
            }],
            'customer.phoneNumber': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_required')
            },{
                minLength: 10,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_minlength')
            },{
                maxLength: 16,
                msg: Translator.trans('js.error_message_orderform_customer_phoneNumber_maxlength')
            }, {
                pattern: 'digits',
                msg: Translator.trans('js.error_message_phoneNumber_format')
            }],
            'customer.email': [{
                required: false,
                msg: Translator.trans('js.error_message_orderform_customer_email_required')
            },{
                minLength: 4,
                msg: Translator.trans('js.error_message_orderform_customer_email_minlength')
            },{
                maxLength: 64,
                msg: Translator.trans('js.error_message_orderform_customer_email_maxlength')
            },{
                pattern: 'email',
                msg: Translator.trans('js.error_message_email_format')
            }],
            'customer.county': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_county_required')
            }],
            'customer.locality': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_locality_required')
            }],
            'customer.address': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_customer_address_required')
            }, {
                minLength: 4,
                msg: Translator.trans('js.error_message_orderform_customer_address_minlength')
            }, {
                maxLength: 255,
                msg: Translator.trans('js.error_message_orderform_customer_address_maxlength')
            }],
            offer: [{
                required: function(value, attr, computedState){

                    // return computedState.vendor !== '0';
                },
                msg: Translator.trans('js.error_message_orderform_offer_required')
            }],
            showroom: [{
                required: function(value, attr, computedState){
                    return computedState.isResellerRequired;
                },
                msg: Translator.trans('js.error_message_orderform_reseller_required')
            }],
            extraObservations: [{
                required: false,
                msg: Translator.trans('js.error_message_orderform_customer_email_required')
            },{
                maxLength: 500,
                msg: Translator.trans('js.error_message_orderform_extraObservations_maxlength')
            }],
            invoiceNumber: [{
                required: false
            },{
                maxLength: 255,
                msg: Translator.trans('js.error_message_orderform_invoiceNumber_maxlength')
            }],
            invoiceDate: function(value) {

                var today = moment();

                // If the date is not valid or it's in the future
                if (typeof value !== 'undefined' && value && (!value.isValid() || today.diff(value) < 0)) {

                    return Translator.trans('js.error_message_order_editable_invoice_date');
                }
            }
        }
    });

    z.OrderModel = OrderModel;

})(window.z = window.z || {}, Backbone, _);