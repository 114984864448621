'use strict';

(function(z, Backbone){

    z.TemplateModel = Backbone.Model.extend({

        /**
         * Model validation
         */
        validation: {
            'service': [{
                required: true,
                msg: Translator.trans('js.error_message_orderform_service_required')
            }]
        }
    });

})(window.z = window.z || {}, Backbone);