'use strict';

(function(z, Backbone, _) {

    /**
     * Payment Payu
     */
    z.PaymentPayu = Backbone.View.extend({

        /**
         * Bounding element
         */
        el: "#payment-payu-provider",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: {
        },

        /**
         * Constructor
         *
         * @return {undefined}
         */
        initialize: function() {

            // Apply the loading mask
            this._isLoadingMask(true);

            // Submit the live update form
            $('#payu-liveupdate-form').submit();
        },

        /**
         * Show the loading mask for the page.
         * @param {bool} loading - should show or hide the mask
         * @return {undefined}
         * @private
         */
        _isLoadingMask: function(loading) {

            loading = loading ? loading : _.isUndefined(loading);

            var $content = $('.ibox-content');

            if( loading ){

                $content.addClass('sk-loading');

            } else {

                $content.removeClass('sk-loading');
            }
        }


    });

})(window.z = window.z || {}, Backbone, _);