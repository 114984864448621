'use strict';

(function(z, $){

    /**
     * Grid view
     *
     * This class handles the add & edit events for the pages with a grid.
     *
     * All the views that will extend GridView will have to implement the next methods:
     *
     * 1. getSuccessMessageContainerId() - get the id for the success message container (eg. 'reseller-success-message')
     * 2. getSuccessMessageText() - get the success message text(eg. 'js.success_message_reseller_form')
     * 3. getSuccessEventName() - get the success event (eg. 'reseller.success')
     */
    var GridView = z.FiltersView.extend({

        /**
         * Route for adding a new entry
         */
        ADD_ROUTE: '/add',


        /**
         * Route for editing an existing entry
         */
        EDIT_ROUTE: '/edit',

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {

            $(document).on(this.getSuccessEventName(), function() {

                // On success, apply the filters
                this.applyFilters();

                this._showSuccessMessage();
            }.bind(this));

            z.FiltersView.prototype.initialize.apply(this, arguments);
        },


        /**
         * Method that opens the modal when a
         * new entry is being added
         *
         * @param {Event} e click event
         * @return {undefined}
         */
        onAddEntry: function(e) {

            e.preventDefault();

            z.router.navigate(this.getPath() + this.ADD_ROUTE, {trigger:true});
        },

        /**
         * Method that opens the modal when an
         * entry is being edited
         *
         * @param {Event} e click event
         * @return {undefined}
         */
        onEditEntry: function(e) {

            e.preventDefault();

            var entryId = e.currentTarget.getAttribute("data-id");
            z.router.navigate(this.getPath() + this.EDIT_ROUTE + '/' + entryId, {trigger:true});
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return '';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return '';
        },

        /**
         * Get success event name.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return '';
        },

        /**
         * Method that shows the success message in a page with a grid
         *
         * @private
         * @return {undefined}
         */
        _showSuccessMessage: function() {
            
            var $successContainer = this.$('#' + this.getSuccessMessageContainerId());
            $successContainer.removeClass('hidden')
                .text(this.getSuccessMessageText())
                .fadeTo('slow', 1)
                .show('slow')
                .delay(3000)
                .fadeTo('slow',0)
                .hide('slow');
        }
    });

    z.GridView = GridView;

})(window.z = window.z || {}, $);
