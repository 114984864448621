"use strict";

(function(z, _, $, Backbone, Translator){

    z.ServiceConfigForm = z.FormView.extend({

        /**
         * The bounding element
         */
        el:"#serviceconfig-modal",

        /**
         * Url path for this view
         */
        CONFIGURE_URL: '/configure',

        /**
         * Events
         * @return {void|*} The events
         */
        events: function() {
            return _.extend(z.FormView.prototype.events, {});
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function(){

            z.FormView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Get edit entity route name.
         * This function will be overridden by all the views that will extend FormView.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'configureService';
        },

        /**
         * Get modal form edit title text
         *
         * @param {Object} service The service model
         * @return {*|String} modal form edit title text
         */
        getModalFormEditTitleText: function(service) {

            if (!service) {

                return Translator.trans('js.btn_configure_service');
            }

            var name = service.get('name');

            return Translator.trans('js.btn_configure_service_with_name', { name: name });
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#serviceconfig-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#serviceconfig-save-btn';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'services';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'service.configure.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_serviceconfigform');
        },

        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        resetForm: function() {

            // reset error panel if exists
            if (this.getErrorPanelId()) {

                this.$(this.getErrorPanelId()).addClass('hidden').text('');
            }

            // reset model if exists
            if (this.model) {

                this.model.clear();
            }

            this.trigger('reset');
        },

        /**
         * Collecting data from form inputs.
         *
         * @return {Object} - the object with data from the form inputs
         */
        getFormData: function(){

            var params = z.serviceParamsForm.collection.map(function(param){

                var result = param.toJSON();

                delete result.service;

                return result;
            });

            return {
                params: params
            };
        },

        /**
         * Method called before router edit entity.
         * @return {undefined}
         * @private
         */
        _beforeEditEntity: function(){

            z.FormView.prototype._beforeEditEntity.apply(this, arguments);

            var id = this._getServiceId();

            this.model.setServiceId(id);
        },

        /**
         * Method that edits the entity when the correct route is being reached.
         *
         * @param {object} id - the entity id that is being taken from the request
         * @private
         * @return {undefined}
         */
        _onRouterEditEntity: function(id){

            this._beforeEditEntity(id);

            this._setLabels('edit');

            this.resetForm();

            var service = new z.ServiceModel();
            var serviceId = this._getServiceId();

            service.set('id', serviceId);

            service.fetch({
                success: function(){

                    this.modifyModalTitle(this.getModalFormEditTitleText(service));

                    this._afterEditEntity();

                }.bind(this)
            });

            this._showModal();
        },

        /**
         * Retrieves the service id for which we are configuring
         * @return {*} The service id
         * @private
         */
        _getServiceId: function(){

            var regex = /^\/services\/configure\/(\d+)(.*)$/g;

            var result = regex.exec(window.location.pathname);

            if (!result) {

                return null;
            }

            return result[1];
        }
    });

})(window.z = window.z || {}, _, $, Backbone, Translator);
