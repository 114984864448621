'use strict';

(function(z, _) {

    /**
     * Reseller grid
     */
    z.ResellersGrid = z.GridView.extend({

        /**
         * Bounding element
         */
        el: "#page-wrapper",

        /**
         * Events
         *
         * @return {undefined}
         */
        events: function() {
            return _.extend({}, z.FiltersView.prototype.events,{
                "click #add-reseller-modal-btn": "onAddEntry",
                "click .reseller-edit-button": "onEditEntry"
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {
            z.GridView.prototype.initialize.apply(this, arguments);

            this.initializeEvents();
        },

        /**
         * Initialize the events.
         *
         * @return {undefined}
         */
        initializeEvents: function() {

            this.$(".chosen-select").chosen({
                search_contains: true
            });
        },

        /**
         * Get datatable id.
         *
         * @return {string} - datatable id
         */
        getDatatableId: function() {
            return 'page-reseller-datatable';
        },

        /**
         * Get page path.
         *
         * @return {string} - page path
         */
        getPath: function() {
            return 'resellers';
        },

        /**
         * Get success message container id.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message container id
         */
        getSuccessMessageContainerId: function() {
            return 'reseller-success-message';
        },


        /**
         * Get success message text.
         * This function will be overridden by every view that will extend the GridView.
         *
         * @return {string} - success message text variable
         */
        getSuccessMessageText: function() {
            return Translator.trans('js.success_message_reseller_form');
        },

        /**
         * Get success event name
         *
         * @return {string} - success event name
         */
        getSuccessEventName: function() {
            return 'reseller.success';
        },

        /**
         * Concatenate brand name with company name
         *
         * @param {mixed} data - datatable param
         * @param {mixed} type - datatable param
         * @param {mixed} row - datatable param
         * @return {string} - the two names concatenated
         */
        concatBrandName: function(data, type, row) {
            return row.brandName + ' - ' + row.companyName;
        }
    });

})(window.z = window.z || {}, _);
