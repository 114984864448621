"use strict";

(function(z, Backbone){

    var RepresentativeModel = Backbone.Model.extend({
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_representativeform_name_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_representativeform_name_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_representativeform_name_maxlength")
            }],
            position: [{
                required: true,
                msg: Translator.trans("js.error_message_representativeform_position_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_representativeform_position_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_representativeform_position_maxlength")
            }],
            phoneNumber: [{
                required: true,
                msg: Translator.trans("js.error_message_representativeform_phoneNumber_required")
            },{
                minLength: 10,
                msg: Translator.trans("js.error_message_representativeform_phoneNumber_minlength")
            },{
                maxLength: 16,
                msg: Translator.trans("js.error_message_representativeform_phoneNumber_maxlength")
            }, {
                pattern: "digits",
                msg: Translator.trans("js.error_message_phoneNumber_format")
            }],
            email: [{
                required: true,
                msg: Translator.trans("js.error_message_representativeform_email_required")
            },{
                minLength: 4,
                msg: Translator.trans("js.error_message_representativeform_email_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_representativeform_email_maxlength")
            },{
                pattern: "email",
                msg: Translator.trans("js.error_message_email_format")
            }]
        },
    });

    z.RepresentativeModel = RepresentativeModel;

})(window.z = window.z || {}, Backbone);
